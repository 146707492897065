import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { LotOccupationViewControllerDelegate, LotOccupationViewController } from "../../Screen/LotOccupation/LotOccupationViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { LotOccupationViewModel } from '../../Screen/LotOccupation/LotOccupationViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class LotOccupationCoordinator implements IndexCoordinatorDelegate, LotOccupationViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepocupacaolote")
        return (<ProtectedRoute path="/:base/ocupacao-lote" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Ocupação Lote"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new LotOccupationViewModel()
        return (<ProtectedRoute path="/:base/ocupacao-lote/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<LotOccupationViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepocupacaolote") {
            navigation.history.push(`ocupacao-lote/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepocupacaolote") {
            navigation.history.push(`ocupacao-lote/cad/${idSelection}`);
            return;
        }
    }

}