import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { NavOptions, TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { AdminProcessViewController, AdminProcessViewControllerDelegate } from "../../Screen/AdminProcess/AdminProcessViewController";
import { AdminProcessViewModel } from "../../Screen/AdminProcess/AdminProcessViewModel";
import { TableGenericFilter } from "../TableGeneric/TableGenericInteractor";
import ProtectedRoute from '../../Components/ProtectedRoute';


export class AdminProcessCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, AdminProcessViewControllerDelegate{
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm(),
            this.routeTableChildreen(),
            this.routeFormChildreen(),
            this.routeTablePoint(),
            this.routeFormPoint(),
            this.routeTableProperty(),
            this.routeFormProperty(),
            this.routeTablePropertyBuilding(),
            this.routeFormPropertyBuilding()
        ]
    }

    private routeTable(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        arrayOptions.push({
            title: "Movimentar Processo", 
            action: (navigation: any, item: any, component: React.Component) => this.routerMovement(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Subprocessos", 
            action: (navigation: any, item: any, component: React.Component) => this.routerSubprocess(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Visitas", 
            action: (navigation: any, item: any, component: React.Component) => this.routerVisit(navigation, item, component), 
            isSelection: true
        })
        
        var viewModel = new TableGenericViewModel("gepprocessoadministrativo")
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/processo-administrativo" exact={true} component={(props:any) => {
            var filter = new TableGenericFilter('Origem', "NULL")
            return (<TableGenericViewController
                permissions={{
                    add: 'proc_admin.proc_admin.adicionar',
                    edit: 'proc_admin.proc_admin.editar',
                    delete: 'proc_admin.proc_admin.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Processo Administrativo"}
                options={arrayOptions}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new AdminProcessViewModel()
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.adicionar', 'proc_admin.proc_admin.editar']} path="/:base/processo-administrativo/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<AdminProcessViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    private routeTablePoint(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        arrayOptions.push({
            title: "Movimentar Processo", 
            action: (navigation: any, item: any, component: React.Component) => this.routerMovement(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Subprocessos", 
            action: (navigation: any, item: any, component: React.Component) => this.routerSubprocess(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Visitas", 
            action: (navigation: any, item: any, component: React.Component) => this.routerVisit(navigation, item, component), 
            isSelection: true
        })
        
        var viewModel = new TableGenericViewModel("gepprocessoadministrativo")
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/shape/:id/processo-administrativo" exact={true} component={(props:any) => {
            var filterSource = new TableGenericFilter('Origem', "NULL")
            var filterPoint = new TableGenericFilter('Shape', props.match.params.id)
            return (<TableGenericViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Processo Administrativo"}
                options={arrayOptions}
                filter={[filterSource, filterPoint]}
            />)
        }}/>)
    }

    private routeFormPoint(): JSX.Element {
        var viewModel = new AdminProcessViewModel()
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.adicionar', 'proc_admin.proc_admin.editar']} path="/:base/shape/:idPoint/processo-administrativo/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            var idPoint = props.match.params.idPoint
            return (<AdminProcessViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idShape={idPoint}
            />)
        }}/>)
    }

    private routeTableProperty(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        arrayOptions.push({
            title: "Movimentar Processo", 
            action: (navigation: any, item: any, component: React.Component) => this.routerMovement(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Subprocessos", 
            action: (navigation: any, item: any, component: React.Component) => this.routerSubprocess(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Visitas", 
            action: (navigation: any, item: any, component: React.Component) => this.routerVisit(navigation, item, component), 
            isSelection: true
        })
        
        var viewModel = new TableGenericViewModel("gepprocessoadministrativo")
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/propriedade/:id/processo-administrativo" exact={true} component={(props:any) => {
            var filterSource = new TableGenericFilter('Origem', "NULL")
            var filterProperty = new TableGenericFilter('Propriedade', props.match.params.id)
            return (<TableGenericViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Processo Administrativo"}
                options={arrayOptions}
                filter={[filterSource, filterProperty]}
            />)
        }}/>)
    }

    private routeFormProperty(): JSX.Element {
        var viewModel = new AdminProcessViewModel()
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/propriedade/:idProperty/processo-administrativo/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            var idProperty = props.match.params.idProperty
            return (<AdminProcessViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idProperty={idProperty}
            />)
        }}/>)
    }

    private routeTablePropertyBuilding(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        arrayOptions.push({
            title: "Movimentar Processo", 
            action: (navigation: any, item: any, component: React.Component) => this.routerMovement(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Subprocessos", 
            action: (navigation: any, item: any, component: React.Component) => this.routerSubprocess(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Visitas", 
            action: (navigation: any, item: any, component: React.Component) => this.routerVisit(navigation, item, component), 
            isSelection: true
        })
        
        var viewModel = new TableGenericViewModel("gepprocessoadministrativo")
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/edificacao/:id/processo-administrativo" exact={true} component={(props:any) => {
            var filterSource = new TableGenericFilter('Origem', "NULL")
            var filterPropertyBuilding = new TableGenericFilter('Edificação', props.match.params.id)
            return (<TableGenericViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Processo Administrativo"}
                options={arrayOptions}
                filter={[filterSource, filterPropertyBuilding]}
            />)
        }}/>)
    }

    private routeFormPropertyBuilding(): JSX.Element {
        var viewModel = new AdminProcessViewModel()
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.adicionar', 'proc_admin.proc_admin.editar']} path="/:base/edificacao/:idPropertyBuilding/processo-administrativo/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            var idPropertyBuilding = props.match.params.idPropertyBuilding
            return (<AdminProcessViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idPropertyBuilding={idPropertyBuilding}
            />)
        }}/>)
    }

    private routeTableChildreen(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        arrayOptions.push({
            title: "Movimentar Processo", 
            action: (navigation: any, item: any, component: React.Component) => this.routerMovement(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Subprocessos", 
            action: (navigation: any, item: any, component: React.Component) => this.routerSubprocess(navigation, item, component), 
            isSelection: true
        })

        arrayOptions.push({
            title: "Visitas", 
            action: (navigation: any, item: any, component: React.Component) => this.routerVisit(navigation, item, component), 
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepprocessoadministrativo")
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/processo-administrativo/:id/subprocessos" exact={true} component={(props:any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Origem', idFk)
            return (<TableGenericViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Processo Administrativo"}
                options={arrayOptions}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormChildreen(): JSX.Element {
        var viewModel = new AdminProcessViewModel()
        return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.adicionar', 'proc_admin.proc_admin.editar']} path="/:base/processo-administrativo/:origem/subprocessos/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            var idFk = props.match.params.origem
            return (<AdminProcessViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idProcessAdmin={idFk}
            />)
        }}/>)
    }

    routerDocuments(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/processo-administrativo/${item}/arquivos`);
    }

    routerMovement(navigation: any, item: any, component: React.Component) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/processo-administrativo/${item}/movimentacoes`);
    }

    routerSubprocess(navigation: any, item: any, component: React.Component) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/processo-administrativo/${item}/subprocessos`);
        window.location.reload()
    }

    routerVisit(navigation: any, item: any, component: React.Component) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/processo-administrativo/${item}/visita`);
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack()
        setTimeout(() => {
            window.location.reload()
        }, 500);
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepprocessoadministrativo" && filter == undefined){
            navigation.history.push(`processo-administrativo/cad/0`);
            return;
        }

        if(filter && filter.length == 1) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/processo-administrativo/${filter[0].getValue()}/subprocessos/cad/0`);
            return;
        }

        if(filter && filter.length == 2) {
            var database = localStorage.getItem('database')
            var typeFk = ""
            if(filter[1].getColumn() == "Shape") {
                typeFk = "shape"
            } else if(filter[1].getColumn() == "Propriedade") {
                typeFk = "propriedade"
            } else if(filter[1].getColumn() == "Edificação") {
                typeFk = "edificacao"
            }

            navigation.history.push(`/${database}/${typeFk}/${filter[1].getValue()}/processo-administrativo/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepprocessoadministrativo" && filter == undefined){
            navigation.history.push(`processo-administrativo/cad/${idSelection}`);
            return;
        }

        if(filter && filter.length == 1) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/processo-administrativo/${filter[0].getValue()}/subprocessos/cad/${idSelection}`);
            return;
        }

        if(filter && filter.length == 2) {
            var database = localStorage.getItem('database')
            var typeFk = ""
            if(filter[1].getColumn() == "Shape") {
                typeFk = "shape"
            } else if(filter[1].getColumn() == "Propriedade") {
                typeFk = "propriedade"
            } else if(filter[1].getColumn() == "Edificação") {
                typeFk = "edificacao"
            }

            navigation.history.push(`/${database}/${typeFk}/${filter[1].getValue()}/processo-administrativo/cad/${idSelection}`);
            return;
        }
    }

}