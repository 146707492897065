import { MapInitialInteractor, MapInitialDrawSaveRequest, PointRemoveRequest} from "../../Coordinator/MapInitial/MapInitialInteractor";
import { Shape } from "./MapInitialViewController";

export class MapInitialViewModel {
    private interactor: MapInitialInteractor;

	constructor() {
		this.interactor = new MapInitialInteractor();
    }
    
    public fetchSaveDraw(obj: Array<Shape>): PromiseLike<any> {
        var arrayResult = Array<MapInitialDrawSaveRequest>()
        for(var i = 0; i < obj.length; i++) {
            var item = new MapInitialDrawSaveRequest(obj[i].type, obj[i].geojson, obj[i].sshape, obj[i].scamada)
            arrayResult.push(item)
        }

        return new Promise((res, rej) => {
			this.interactor.fetchSaveDraw(arrayResult).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchRemovePoint(obj: any): PromiseLike<any> {
        var request = new PointRemoveRequest(
            obj['sshape'] ? parseInt(obj['sshape']) : 0
        )

        return new Promise((res, rej) => {
			this.interactor.fetchRemovePoint(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }    

    public fetchMapPDF(sshape: number): PromiseLike<any> {
        var request = new PointRemoveRequest(sshape)

        return new Promise((res, rej) => {
			this.interactor.fetchMapPDF(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }    
}