import { KinshipDegreeInteractor, KinshipDegreeRequest, KinshipDegreeStructRequest } from "../../Coordinator/KinshipDegree/KinshipDegreeInteractor";

export class KinshipDegreeViewModel {
    private interactor: KinshipDegreeInteractor;

	constructor() {
		this.interactor = new KinshipDegreeInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new KinshipDegreeRequest(
            obj["cgepgrauparentesco"] ? parseInt(obj["cgepgrauparentesco"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new KinshipDegreeStructRequest(
            obj["cgepgrauparentesco"] ? parseInt(obj["cgepgrauparentesco"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}