import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { ContactViewControllerDelegate, ContactViewController } from "../../Screen/Contact/ContactViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { ContactViewModel } from '../../Screen/Contact/ContactViewModel';
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class ContactCoordinator implements IndexCoordinatorDelegate, ContactViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTablePerson(),
            this.routeFormPerson()
        ]
    }

    private routeTablePerson(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepcontato")
        return (<ProtectedRoute requiredPermissions={['info_bas.cad_pessoas.*']} path="/:base/pessoas/:id/contatos" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Pessoa', idFk)
            return (<TableGenericViewController 
                permissions={{
                    add: 'info_bas.cad_pessoas.adicionar',
                    edit: 'info_bas.cad_pessoas.editar',
                    delete: 'info_bas.cad_pessoas.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Contatos"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormPerson(): JSX.Element {
        var viewModel = new ContactViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.cad_pessoas.adicionar', 'info_bas.cad_pessoas.editar']} path="/:base/pessoas/:idPerson/contatos/cad/:id" exact={true} component={(props: any) => {
            var idPerson = props.match.params.idPerson
            var idPk = props.match.params.id
            return (<ContactViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idPerson={idPerson}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepcontato" && filter) {
            var database = localStorage.getItem('database')
            var typeFk = filter[0].getColumn() == "Pessoa" ? 'pessoas' : ''
            navigation.history.push(`/${database}/${typeFk}/${filter[0].getValue()}/contatos/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepcontato") {
            navigation.history.push(`contatos/cad/${idSelection}`);
            return;
        }
    }

}