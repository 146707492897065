import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { PointViewControllerDelegate, PointViewController } from "../../Screen/Point/PointViewController";
import { PointViewModel } from '../../Screen/Point/PointViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PointCoordinator implements IndexCoordinatorDelegate, PointViewControllerDelegate {
    getRoute(): JSX.Element[] {
        return [ 
            this.routeFormPoint(),
            this.routeFormPointLatLng()
        ]
    }

    private routeFormPoint(): JSX.Element {
        var viewModel = new PointViewModel()
        return (<ProtectedRoute path="/:base/ponto/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<PointViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    private routeFormPointLatLng(): JSX.Element {
        var viewModel = new PointViewModel()
        return (<ProtectedRoute path="/:base/ponto/latitude/:lat/longitude/:lng" exact={true} component={(props: any) => {
            var lat = props.match.params.lat
            var lng = props.match.params.lng
            return (<PointViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={0} latitude={lat} longitude={lng}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    tapProperty(navigation: any, sgeppontos: number, pointType: string): void {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/ponto/${sgeppontos}/propriedade/tipo/${pointType}`);
    }
}