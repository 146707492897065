import React from 'react';
import { Route} from 'react-router-dom'
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { VisitViewControllerDelegate, VisitViewController } from "../../Screen/Visit/VisitViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { VisitViewModel } from '../../Screen/Visit/VisitViewModel';
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class VisitCoordinator implements IndexCoordinatorDelegate, VisitViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTableVisit(),
            this.routeFormVisit(),
            this.routeTableVisitShape(),
            this.routeFormVisitShape(),
            this.routeTableVisitAdminProcess(),
            this.routeFormVisitAdminProcess(),
            this.routeTableVisitProperty(),
            this.routeFormVisitProperty(),
            this.routeTableVisitPropertyBuilding(),
            this.routeFormVisitPropertyBuilding()
        ]
    }

    private routeTableVisit(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })
        
        var viewModel = new TableGenericViewModel("gepvisita")
        return (<ProtectedRoute path="/:base/ponto/:id/visita/" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Ponto', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Visita"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormVisit(): JSX.Element {
        var viewModel = new VisitViewModel()
        return (<ProtectedRoute path="/:base/ponto/:idPoint/visita/cad/:id" exact={true} component={(props: any) => {
            var idPoint = props.match.params.idPoint
            var idPk = props.match.params.id
            return (<VisitViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idPoint={idPoint}/>)
        }}/>)
    }

    private routeTableVisitShape(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepvisita")
        return (<ProtectedRoute path="/:base/shape/:id/visita/" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Shape', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Visita"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormVisitShape(): JSX.Element {
        var viewModel = new VisitViewModel()
        return (<ProtectedRoute path="/:base/shape/:idShape/visita/cad/:id" exact={true} component={(props: any) => {
            var idShape = props.match.params.idShape
            var idPk = props.match.params.id
            return (<VisitViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idShape={idShape}/>)
        }}/>)
    }

    private routeTableVisitAdminProcess(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepvisita")
        return (<ProtectedRoute path="/:base/processo-administrativo/:id/visita/" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('ProcessoAdministrativo', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Visita"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormVisitAdminProcess(): JSX.Element {
        var viewModel = new VisitViewModel()
        return (<ProtectedRoute path="/:base/processo-administrativo/:idAdminProcess/visita/cad/:id" exact={true} component={(props: any) => {
            var idAdminProcess = props.match.params.idAdminProcess
            var idPk = props.match.params.id
            return (<VisitViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idAdminProcess={idAdminProcess}/>)
        }}/>)
    }

    private routeTableVisitProperty(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepvisita")
        return (<ProtectedRoute path="/:base/propriedade/:id/visita/" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Propriedade', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Visita"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormVisitProperty(): JSX.Element {
        var viewModel = new VisitViewModel()
        return (<ProtectedRoute path="/:base/propriedade/:idProperty/visita/cad/:id" exact={true} component={(props: any) => {
            var idProperty = props.match.params.idProperty
            var idPk = props.match.params.id
            return (<VisitViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idProperty={idProperty}/>)
        }}/>)
    }

    private routeTableVisitPropertyBuilding(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepvisita")
        return (<ProtectedRoute path="/:base/edificacao/:id/visita/" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Edificação', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Visita"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormVisitPropertyBuilding(): JSX.Element {
        var viewModel = new VisitViewModel()
        return (<ProtectedRoute path="/:base/edificacao/:idPropertyBuilding/visita/cad/:id" exact={true} component={(props: any) => {
            var idPropertyBuilding = props.match.params.idPropertyBuilding
            var idPk = props.match.params.id
            return (<VisitViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idPropertyBuilding={idPropertyBuilding}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepvisita" && filter) {
            var database = localStorage.getItem('database')
            var typeFk = filter[0].getColumn() == "Ponto" ? 'ponto' : 'shape'
            if(filter[0].getColumn() == "ProcessoAdministrativo") {
                typeFk = "processo-administrativo"
            } else if(filter[0].getColumn() == "Propriedade") {
                typeFk = "propriedade"
            } else if(filter[0].getColumn() == "Edificação") {
                typeFk = "edificacao"
            }

            navigation.history.push(`/${database}/${typeFk}/${filter[0].getValue()}/visita/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepvisita") {
            navigation.history.push(`visita/cad/${idSelection}`);
            return;
        }
    }

    private routerDocuments(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/visita/${item}/arquivos`);
    }

}