import React from 'react';
import { MarkerImageViewModel } from './MarkerImageViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { LoaderComponente } from '../../Components/LoaderComponente';
import { ToastContainer, toast } from 'react-toastify';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';

import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';
import { InputColorComponent } from '../../Components/InputColorComponent';

interface MyProps {
    navigation: any
    delegate: MarkerImageViewControllerDelegate
    viewModel: MarkerImageViewModel
    idPrimary: number
}

export interface MarkerImageViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class MarkerImageViewController extends React.Component<MyProps> {
    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        typeLayer: Array<any>(),
        markerIcon: Array<any>(),
        markerIconData: Array<any>(),
        markerIconClick: Array<any>(),
        markerIconFilter: Array<any>()
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadTypeLayer()
        this.loadIcon()

        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
        <div>
            <NavBarComponente propNav={this.props.navigation}/>
            <div>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <div className="title">
                                <h3>Marcador Imagem</h3>
                            </div>
                            <div className="buttonClose">
                                <Image className="imageClose"
                                onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                src={fechar}/>
                            </div>
                        </Col>
                    </Row>
                    <Form className="containerForm">
                        <Row>
                            <Col lg="4" xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                id="smarcadorimagem"
                                defaultValue={json['smarcadorimagem'] ? json['smarcadorimagem'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col lg="4" xs="12" className="bottom10">
                                <Form.Label className="isRequired">Nome</Form.Label>
                                <Form.Control
                                id="nome"
                                defaultValue={json['nome'] ? json['nome'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                placeholder="Digite o nome" 
                                />
                            </Col>
                            <Col lg="4" xs="12" className="bottom10">
                                <Form.Label className="isRequired">Tipo</Form.Label>
                                <Select
                                id="tipo"
                                value={this.formEvent.onValueSelect('select', 'tipo', this.state.typeLayer)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, "tipo")}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.typeLayer}
                                isMulti={false}
                                />
                            </Col>
                        </Row>
                        <fieldset className="fieldset">
                            <legend>Ponto</legend>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Ícone</Form.Label>
                                    <Select
                                    id="smarcadorimagemicone"
                                    value={this.formEvent.onValueSelect('select', 'smarcadorimagemicone', this.state.markerIcon)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "smarcadorimagemicone")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.markerIcon}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Ícone com dados</Form.Label>
                                    <Select
                                    id="smarcadorimagemiconedados"
                                    value={this.formEvent.onValueSelect('select', 'smarcadorimagemiconedados', this.state.markerIconData)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "smarcadorimagemiconedados")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.markerIconData}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Ícone click</Form.Label>
                                    <Select
                                    id="smarcadorimagemiconeclick"
                                    value={this.formEvent.onValueSelect('select', 'smarcadorimagemiconeclick', this.state.markerIconClick)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "smarcadorimagemiconeclick")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.markerIconClick}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Ícone filtro</Form.Label>
                                    <Select
                                    id="smarcadorimagemiconefiltro"
                                    value={this.formEvent.onValueSelect('select', 'smarcadorimagemiconefiltro', this.state.markerIconFilter)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "smarcadorimagemiconefiltro")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.markerIconFilter}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                        <fieldset className="fieldset">
                            <legend>Polígono/Retângulo</legend>
                            <Row>
                                <Col lg="2" xs="12" className="bottom10">
                                    <Form.Label>Preenchimento</Form.Label>
                                    <InputColorComponent 
                                    valorInicial={json['preeenchimento'] ? json['preeenchimento'] : '#000000'}
                                    onChange={(e: any) => this.formEvent.onChange("color", e, "preeenchimento")}
                                    />
                                </Col>
                                <Col lg="2" xs="12" className="bottom10">
                                    <Form.Label>Preenchimento Click</Form.Label>
                                    <InputColorComponent 
                                    valorInicial={json['preeenchimentoclick'] ? json['preeenchimentoclick'] : '#000000'}
                                    onChange={(e: any) => this.formEvent.onChange("color", e, "preeenchimentoclick")}
                                    />
                                </Col>
                                <Col lg="2" xs="12" className="bottom10">
                                    <Form.Label>Preenchimento Filtro</Form.Label>
                                    <InputColorComponent 
                                    valorInicial={json['preenchimentofiltro'] ? json['preenchimentofiltro'] : '#000000'}
                                    onChange={(e: any) => this.formEvent.onChange("color", e, "preenchimentofiltro")}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Opacidade</Form.Label>
                                    <Form.Control
                                    id="opacidade"
                                    defaultValue={json['opacidade'] ? json['opacidade'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                                    placeholder="Digite a opacidade" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Opacidade borda</Form.Label>
                                    <Form.Control
                                    id="opacidadeborda"
                                    defaultValue={json['opacidadeborda'] ? json['opacidadeborda'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                                    placeholder="Digite a opacidade" 
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                        <fieldset className="fieldset">
                            <legend>Linha</legend>
                            <Row>
                                <Col lg="2" xs="12" className="bottom10">
                                    <Form.Label>Borda</Form.Label>
                                    <InputColorComponent 
                                    valorInicial={json['borda'] ? json['borda'] : '#000000'}
                                    onChange={(e: any) => this.formEvent.onChange("color", e, "borda")}
                                    />
                                </Col>
                                <Col lg="2" xs="12" className="bottom10">
                                    <Form.Label>Borda Click</Form.Label>
                                    <InputColorComponent 
                                    valorInicial={json['bordaclick'] ? json['bordaclick'] : '#000000'}
                                    onChange={(e: any) => this.formEvent.onChange("color", e, "bordaclick")}
                                    />
                                </Col>
                                <Col lg="2" xs="12" className="bottom10">
                                    <Form.Label>Borda Filtro</Form.Label>
                                    <InputColorComponent 
                                    valorInicial={json['bordafiltro'] ? json['bordafiltro'] : '#000000'}
                                    onChange={(e: any) => this.formEvent.onChange("color", e, "bordafiltro")}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Largura traço</Form.Label>
                                    <Form.Control
                                    id="largura"
                                    defaultValue={json['largura'] ? json['largura'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    placeholder="Digite a largura" 
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                        <fieldset className="fieldset">
                            <legend>Círculo</legend>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Raio</Form.Label>
                                    <Form.Control
                                    id="raio"
                                    defaultValue={json['raio'] ? json['raio'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    placeholder="Digite o raio" 
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                    <Image className="imageSave"
                                    src={salvar}/>
                                    Salvar
                                </Button>
                                <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                            </Col>
                        </Row>
                    </Form>
                    <ToastContainer />
                </Container>
            </div>
            <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
        </div>)
    }

    private save() {
        var json = this.state.json as any
        if(json["nome"] == undefined || json["tipo"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["nome"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadTypeLayer() {
        var array = Array<any>()
        array.push({value: "P", label: "Ponto", check: false})
        array.push({value: "L", label: "Linha", check: false})
        array.push({value: "G", label: "Polígono", check: false})
        array.push({value: "C", label: "Círculo", check: false})
        array.push({value: "R", label: "Retângulo", check: false})

        this.setState({
            typeLayer: array
        })
    }

    private loadIcon() {
        this.props.viewModel.fetchSelectIcon().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                markerIcon: array,
                markerIconData: array,
                markerIconClick: array,
                markerIconFilter: array
            })
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['smarcadorimagem'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}