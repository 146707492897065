import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { PersonViewModel } from '../../Screen/Person/PersonViewModel';
import { PersonViewController, PersonViewControllerDelegate } from '../../Screen/Person/PersonViewController';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PersonCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, PersonViewControllerDelegate {
    getRoute(): JSX.Element[] {
        return [
            this.routeTablePerson(),
            this.routeFormPerson()
        ]
    }

    private routeTablePerson(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Endereços", 
            action: (navigation: any, item: any, component: React.Component) => this.routerAddress(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Contatos", 
            action: (navigation: any, item: any, component: React.Component) => this.routerContact(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Aluno",
            action: (navigation: any, item: any, component: React.Component) => this.routerStudent(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Documento",
            action: (navigation: any, item: any, component: React.Component) => this.routerTpDocument(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Atividades Economicas",
            action: (navigation: any, item: any, component: React.Component) => this.routerEconActivity(navigation, item),
            isSelection: true
        })

        var arrayActions = new Array<NavOptions>()
        arrayActions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocuments(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("geppessoas")
        return (<ProtectedRoute requiredPermissions={['info_bas.cad_pessoas.*']} path="/:base/pessoas" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.cad_pessoas.adicionar',
                    edit: 'info_bas.cad_pessoas.editar',
                    delete: 'info_bas.cad_pessoas.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Pessoas"}
                options={arrayOptions}
                actions={arrayActions}
            />)
        }}/>)
    }

    private routeFormPerson(): JSX.Element {
        var viewModel = new PersonViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.cad_pessoas.adicionar', 'info_bas.cad_pessoas.editar']} path="/:base/pessoas/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<PersonViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this}
                idPrimary={idPk}
                isShowHeader={true}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geppessoas") {
            navigation.history.push(`pessoas/cad/0`);
            return;
        }
    }
    
    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geppessoas") {
            navigation.history.push(`pessoas/cad/${idSelection}`);
            return;
        }
    }

    private routerAddress(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/pessoas/${item}/enderecos`);
    }

    private routerContact(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/pessoas/${item}/contatos`);
    }

    private routerStudent(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/pessoa/${item}/aluno`);
    }

    private routerTpDocument(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/pessoa/${item}/tipo-documento`);
    }

    private routerEconActivity(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/pessoa/${item}/atividades-economicas`);
    }

    private routerDocuments(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/pessoa/${item.id}/arquivos`);
    }
}