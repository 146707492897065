import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Request } from "../Servidor/Request";
import { LoaderComponente } from "./LoaderComponente";

interface Props extends RouteProps {
	requiredPermissions?: string[];
}

class ProtectedRoute extends Route<Props> {
	constructor(props: Props) {
		super(props);
		this.state = {
			loading: true,
			check: false,
			permissions: this.sanitizePermissions(props.requiredPermissions ?? []),
		};
	}

	sanitizePermissions(permissions: string[]) {
		return permissions
			.reduce((passedArray: string[], item) => {
				if (item.indexOf("*") !== -1) {
					let cleanItem = item.slice(0, -2);
					passedArray.push(`${cleanItem}.ver`);
					passedArray.push(`${cleanItem}.adicionar`);
					passedArray.push(`${cleanItem}.editar`);
					passedArray.push(`${cleanItem}.excluir`);
				} else {
					passedArray.push(item);
				}
				return passedArray;
			}, [])
			.filter((item, pos, array) => array.indexOf(item) === pos);
	}

	checkPermissions() {
		if (this.props.requiredPermissions) {
			new Request()
				.requestPost("permissoes/checar", {
					permissoes: this.props.requiredPermissions,
				})
				.then((rs) => {
					this.setState({
						loading: false,
						check:
							rs.permissions.length > 0
								? Object.values(rs.permissions).some((t) => t === true)
								: true,
					});
				});
		} else {
			this.setState({
				loading: false,
				check: true,
			});
		}
	}

	componentDidMount() {
		this.checkPermissions();
	}

	render(): React.ReactNode {
		if (!this.state.loading) {
			if (!this.state.check) {
				return (
					<Redirect
						to={{
							pathname: "/",
						}}
					/>
				);
			}
			return super.render();
		} else {
			return <LoaderComponente hide={()=> {}} show={true}/>
		}
	}
}
export default ProtectedRoute;
