import { Request } from "../../Servidor/Request";

export class EconActivityRequest {
    private sgepatividadeseconomicas: number;
    private versao: string;
    private secao: string;
    private divisao: string;
    private grupo: string;
    private classe: string;
    private subclasse: string;
    private denominacao: string;

    constructor (sgepatividadeseconomicas:number, versao: string, secao: string, divisao: string, grupo: string, classe: string, subclasse: string, denominacao: string) {
        this.sgepatividadeseconomicas = sgepatividadeseconomicas;
        this.versao = versao;
        this.secao = secao;
        this.divisao = divisao;
        this.grupo = grupo;
        this.classe = classe;
        this.subclasse = subclasse;
        this.denominacao = denominacao;
    }

    /**
     * Getter sgepatividadeseconomicas
     * @return {number}
     */
    public getSgepatividadeseconomicas(): number {
        return this.sgepatividadeseconomicas;
    }

    /**
     * Getter versao
     * @return {string}
     */
    public getVersao(): string {
        return this.versao;
    }

    /**
     * Getter secao
     * @return {string}
     */
    public getSecao(): string {
        return this.secao;
    }

    /**
     * Getter divisao
     * @return {string}
     */
     public getDivisao(): string {
        return this.divisao;
    }

    /**
     * Getter grupo
     * @return {string}
     */
     public getGrupo(): string {
        return this.grupo;
    }

    /**
     * Getter classe
     * @return {string}
     */
     public getClasse(): string {
        return this.classe;
    }

    /**
     * Getter subclasse
     * @return {string}
     */
     public getSubclasse(): string {
        return this.subclasse;
    }

    /**
     * Getter denominacao
     * @return {string}
     */
     public getDenominacao(): string {
        return this.denominacao;
    }
}

export class EconActivityStructRequest {
    private sgepatividadeseconomicas: number;

    constructor (sgepatividadeseconomicas: number){
        this.sgepatividadeseconomicas = sgepatividadeseconomicas;
    }

    /**
     * Getter sgepatividadeseconomicas
     * @return {number}
     */
    public getSgepatividadeseconomicas(): number {
        return this.sgepatividadeseconomicas;
    }
}

export class EconActivityInteractor {
    public fetchSave(request: EconActivityRequest): PromiseLike<string>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/atividades-economicas/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res() 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: EconActivityStructRequest): PromiseLike<EconActivityRequest>{
        return new Promise ((res, rej) => {
            console.log(request)

            return new Request().requestPost("basico/atividades-economicas/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}