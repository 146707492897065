import React from 'react';
import { FormEvent } from '../ScreenGeneric/Models/FormEvent';
import { Modal, Row, Col, Container, Form, Button, Image } from 'react-bootstrap';
import { point, destination } from 'turf'
import { ToastContainer, toast } from 'react-toastify';
import adicionar from '../Imagens/add-16.png';
import deletar from '../Imagens/delete-16.png';
import Select from 'react-select';
import {polygon, lineString, featureCollection} from 'turf';

import "../Estilo/InputModalSelect.css"
import "../Estilo/Form.css";

interface MyProps {
    show: boolean
    clickHide: (isSave: boolean, json: any) => void
}

export class AddShapeModalComponente extends React.Component<MyProps> {

    private formEvent: FormEvent
    state = {
        json: {},
        layers: Array<any>(),
        listIds: new Array<number>(),
        disable: false
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadLayers()
    }

    refreshCoordinator(coordinates: Array<any>, typeShape: string) {
        this.loadCoordinators(coordinates, typeShape)
    }
    
    render() {
        return (
            <Modal show={this.props.show} dialogClassName="modal-full-screen">
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{fontSize: "20px", fontWeight: "bold"}}>Adicionar Vértice</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" xs="12" className="bottom10">
                                <Form.Label className="isRequired">Tipo de Camada</Form.Label>
                                <Select
                                id="layer"
                                value={this.formEvent.onValueSelect('select', 'layer', this.state.layers)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, "layer")}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.layers}
                                isMulti={false}
                                isDisabled={this.state.disable}
                                />
                            </Col>
                            <Col lg="4" xs="12">
                                <Button className={`buttonSave float-left ${window.innerWidth >= 700 ? "top30" : ""}`} type="button" variant="primary" onClick={() => this.addLine()}>
                                    <Image className="imageSave"
                                    src={adicionar}/>
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                        <div>
                            {this.getLines()}
                        </div>
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.save()}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.props.clickHide(false, {})}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    private save() {
        let listIds = this.state.listIds
        if(listIds.length < 3) {
            this.showFieldsEmpty("É necessário ao menos três vértices!")
            return
        }

        var json = this.state.json as any
        var array = new Array<any>()
        listIds.forEach((item) => {
            var latitude = parseFloat(json[`latitude-${item}`])
            var longitude = parseFloat(json[`longitude-${item}`])

            array.push([longitude, latitude])
        })  

        var obj = lineString(array) as any
        if(json['layer'] == 'P') {
            let lastItem = array[0]
            array.push([lastItem[0], lastItem[1]])
            obj = polygon([array])
        }

        this.props.clickHide(true, obj)
    }

    private loadCoordinators(coordinates: Array<any>, typeShape: string) {
        var json = {} as any
        var listIds = new Array<any>()

        for(var i = 0; i < coordinates.length; i++) {
            let index = i + 1
            let item = coordinates[i]

            json[`longitude-${index}`] = item['lng']
            json[`latitude-${index}`] = item['lat']
            listIds.push(index)
        }

        json['layer'] = typeShape
        this.setState({
            json: json,
            disable: typeShape == 'P' || typeShape == 'L',
            listIds: listIds
        })
    }

    private loadLayers() {
        var array = Array<any>()
        array.push({value: "L", label: "Linha", check: false})
        array.push({value: "P", label: "Polígono", check: false})

        this.setState({
            layers: array
        })
    }

    private getLines() {
        let array = new Array<any>()
        this.state.listIds.forEach((item) => {
            array.push(this.generateLine(item))
        })
        
        return array
    }

    private addLine() {
        let listIds = this.state.listIds
        let index = listIds.length + 1
        if(index > 1) {
            let lastIndex = listIds[listIds.length - 1]
            if(!this.calculateLatLng(lastIndex, lastIndex + 1)) {
                return
            }
        }

        listIds.push(index)
        this.setState({
            listIds: listIds
        })
    }

    private calculateLatLng(index: number, indexDestiny: number): Boolean {
        var json = this.state.json as any 
        if(json[`latitude-${index}`] == undefined || json[`longitude-${index}`] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return false
        }

        var latitude = parseFloat(json[`latitude-${index}`])
        var longitude = parseFloat(json[`longitude-${index}`])
        let newPoint = point([longitude, latitude])

        if(json[`distancia-${index}`] != undefined || json[`angulo-${index}`] != undefined) {
            var distancia = parseFloat(json[`distancia-${index}`])
            var angulo = parseFloat(json[`angulo-${index}`]) - 180
    
            if(angulo < -180 || angulo > 180) {
                this.showFieldsEmpty("Ângulo deve ser maior que 0 e menor que 360!")
                return false
            }

            let latlng = destination(newPoint, distancia, angulo, "meters")
            json[`longitude-${indexDestiny}`] = latlng.geometry.coordinates[0]
            json[`latitude-${indexDestiny}`] = latlng.geometry.coordinates[1]

            this.setState({
                json: json
            })
        }

        return true
    }

    private removeLine(index: number) {
        let listIds = this.state.listIds
        let listIdsNew = new Array<any>()
        listIds.splice(index, 1)

        let json = this.state.json as any
        let jsonFinal = {} as any

        for(var i = 0; i < listIds.length; i++) {
            let item = listIds[i];
            let index = i + 1;

            jsonFinal[`latitude-${index}`] = json[`latitude-${item}`]
            jsonFinal[`longitude-${index}`] = json[`longitude-${item}`]
            jsonFinal[`distancia-${index}`] = json[`distancia-${item}`]
            jsonFinal[`angulo-${index}`] = json[`angulo-${item}`]

            listIdsNew.push(index)
        }

        jsonFinal['layer'] = json['layer']
        this.setState({
            json: jsonFinal,
            listIds: listIdsNew
        })
    }

    private pressEnter(evt: any, index: number) {
        if(evt.keyCode != 13) {
            return
        }

        let listIds = this.state.listIds
        if(listIds.length == index) {
            return
        }

        if(this.calculateLatLng(index, index + 1)) {
            this.setState({
                listIds: listIds
            })
        }
    }

    private generateLine(index: number) {
        let json = this.state.json as any
        return (
            <Row key={`item-${index}`}>
                <Col xs="12" lg="3" className="bottom10">
                    <Form.Label className="isRequired">Latitude</Form.Label>
                    <Form.Control
                        id={`latitude-${index}`}
                        value={json[`latitude-${index}`] ? json[`latitude-${index}`] : ''}
                        onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                        placeholder="Digite a latitude" 
                    />
                </Col>
                <Col xs="12" lg="3" className="bottom10">
                    <Form.Label className="isRequired">Longitude</Form.Label>
                    <Form.Control
                        id={`longitude-${index}`}
                        value={json[`longitude-${index}`] ? json[`longitude-${index}`] : ''}
                        onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                        placeholder="Digite a longitude" 
                    />
                </Col>
                <Col xs="12" lg="2" className="bottom10">
                    <Form.Label>Distância (metros)</Form.Label>
                    <Form.Control
                        id={`distancia-${index}`}
                        value={json[`distancia-${index}`] ? json[`distancia-${index}`] : ''}
                        onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                        placeholder="Digite a distância" 
                    />
                </Col>
                <Col xs="12" lg="2" className="bottom10">
                    <Form.Label>Ângulo</Form.Label>
                    <Form.Control
                        id={`angulo-${index}`}
                        value={json[`angulo-${index}`] ? json[`angulo-${index}`] : ''}
                        onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                        onKeyDown={(e: any)=> this.pressEnter(e, index)}
                        placeholder="Digite o ângulo" 
                    />
                </Col>
                <Col xs="12" lg="2" className="top30">
                    <Button className="buttonSave" type="button" variant="danger" onClick={() => this.removeLine(index - 1)}>
                        <Image className="imageSave"
                        src={deletar}/>
                        Remover
                    </Button>
                </Col>
            </Row>
        )
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }
}