import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

interface MyProps {
    valorInicial: string;
    onChange    : (e: any) => any
}

export class InputColorComponent extends React.Component<MyProps> {

    state = {
        displayColorPicker: false,
        color: "#000000"
    };

    componentDidMount() {
        this.state.color = this.props.valorInicial;
    }

    componentDidUpdate() {
        if(this.state.color != this.props.valorInicial) {
            this.setState({
                color: this.props.valorInicial
            })
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color: any) => {
        this.props.onChange(color.hex);
        this.setState({ color: color.hex })
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width        : '30px',
                    height       : '100%',
                    borderRadius : '2px',
                    background   : this.state.color
                },
                swatch: {
                    display      : 'inline-block',
                    cursor       : 'pointer'
                }
            }
        });

        return (
            <div>
                <div style={ styles.swatch } className="form-control" onClick={ this.handleClick }>
                    <div style={ styles.color }>
                        <label style={{marginLeft: '35px'}}>
                            {this.state.color}
                        </label>
                    </div>
                </div>
                {this.state.displayColorPicker &&
                <div style={{position: "absolute", zIndex: 2}}>
                    <div style={{position: "fixed", margin: 0}} onClick={ this.handleClose }/>
                    <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                </div>}
            </div>
        )
    }
}