import React from 'react';
import { Route} from 'react-router-dom'
import { IndexCoordinatorDelegate } from '../IndexCoordinator';
import { NavOptions, TableGenericViewController, TableGenericViewControllerDelegate } from '../../Screen/TableGeneric/TableGenericViewController';
import { TableGenericViewModel } from '../../Screen/TableGeneric/TableGenericViewModel';
import { UserGroupViewModel } from '../../Screen/UserGroup/UserGroupViewModel';
import { UserGroupViewController, UserGroupViewControllerDelegate } from '../../Screen/UserGroup/UserGroupViewController';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class UserGroupCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, UserGroupViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        return [
            this.routeTableUserGroup(),
            this.routeFormUserGroup()
        ]
    }

    private routeTableUserGroup(): JSX.Element {
        var viewModel = new TableGenericViewModel("grusuario")
        return (<ProtectedRoute requiredPermissions={['config.grupo_usuarios.*']} path="/:base/grupo-usuario" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this}
                permissions={{
                    add: 'config.grupo_usuarios.adicionar',
                    edit: 'config.grupo_usuarios.editar',
                    delete: 'config.grupo_usuarios.excluir',
                }} 
                title={"Grupo de Usuários"}
            />)
        }}/>)
    }

    private routeFormUserGroup(): JSX.Element {
        var viewModel = new UserGroupViewModel()
        return (<ProtectedRoute requiredPermissions={['config.grupo_usuarios.adicionar', 'config.grupo_usuarios.editar']} path="/:base/grupo-usuario/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<UserGroupViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    public tapDimiss(navigation: any) {
        navigation.history.goBack();
    }

    public add(navigation: any, viewModel: TableGenericViewModel) {
        if(viewModel.getTable() == "grusuario") {
            navigation.history.push(`grupo-usuario/cad/0`);
            return;
        }
    }
    
    public edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel) {
        if(viewModel.getTable() == "grusuario") {
            navigation.history.push(`grupo-usuario/cad/${idSelection}`);
            return;
        }
    }
}