import { ZoningInteractor, ZoningRequest, ZoningStructRequest} from "../../Coordinator/Zoning/ZoningInteractor";

export class ZoningViewModel {
    private interactor: ZoningInteractor;

	constructor() {
		this.interactor = new ZoningInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new ZoningRequest(
            obj["sgepzoneamento"] ? parseInt(obj["sgepzoneamento"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new ZoningStructRequest(
            obj["sgepzoneamento"] ? parseInt(obj["sgepzoneamento"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}