import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { BuildingTypeViewControllerDelegate, BuildingTypeViewController } from "../../Screen/BuildingType/BuildingTypeViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { BuildingTypeViewModel } from '../../Screen/BuildingType/BuildingTypeViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class BuildingTypeCoordinator implements IndexCoordinatorDelegate, BuildingTypeViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geptipoedificacao")
        return (<ProtectedRoute requiredPermissions={['cad_imob.tipo_edif.*']} path="/:base/tipo-edificacao" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'cad_imob.tipo_edif.adicionar',
                    edit: 'cad_imob.tipo_edif.editar',
                    delete: 'cad_imob.tipo_edif.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Tipo de Edificação"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new BuildingTypeViewModel()
        return (<ProtectedRoute requiredPermissions={['cad_imob.tipo_edif.adicionar', 'cad_imob.tipo_edif.editar']} path="/:base/tipo-edificacao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<BuildingTypeViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geptipoedificacao") {
            navigation.history.push(`tipo-edificacao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geptipoedificacao") {
            navigation.history.push(`tipo-edificacao/cad/${idSelection}`);
            return;
        }
    }

}