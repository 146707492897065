import React from 'react'
import { RealEstateRegistrationViewModel } from './RealEstateRegistrationViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Tabs, Tab } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import { FormEvent, TypeComponent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';
import moment from 'moment';
import NumberFormat from 'react-number-format'

interface MyProps {
    navigation: any
    delegate: RealEstateRegistrationViewControllerDelegate
    viewModel: RealEstateRegistrationViewModel
    idPrimary: number
    idRealEstate: number
    pointType: string
}

export interface RealEstateRegistrationViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class RealEstateRegistrationViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        tabKey: "main",
        json: {},
        typeBuilding: [],
        buildingPermit: [],
        listItens: []
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.setKeyFK()
        this.loadBuildingPermit()
        this.loadBuildingType()
        this.loadListItens()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Cadastro Técnico</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Form className="containerFormNotBorder">
                                    <Row>   
                                        <Col xs="12">
                                            <Tabs
                                                id="controlled-tabs"
                                                activeKey={this.state.tabKey}
                                                onSelect={(key: any) => this.setTab(key)}>
                                                <Tab eventKey="main" title="Geral">
                                                    <Row className="tab-item-container">
                                                        <Col lg="4" xs="12" className="bottom10 hide">
                                                            <Form.Label>Sequencial</Form.Label>
                                                            <Form.Control
                                                            id="sgepcadastroimobiliario"
                                                            defaultValue={json['sgepcadastroimobiliario'] ? json['sgepcadastroimobiliario'] : ''}
                                                            onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                                            />
                                                        </Col>
                                                        <Col lg="4" xs="12" className="bottom10">
                                                            <Form.Label className="isRequired">Data Inicial</Form.Label>
                                                            <Form.Control
                                                            id="datainicial"
                                                            defaultValue={json['datainicial'] ? json['datainicial'] : ''}
                                                            onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                                            placeholder="Digite a data inicial" 
                                                            />
                                                        </Col>
                                                        <Col lg="4" xs="12" className="bottom10">
                                                            <Form.Label className="isRequired">Data Final</Form.Label>
                                                            <Form.Control
                                                            id="datafinal"
                                                            defaultValue={json['datafinal'] ? json['datafinal'] : ''}
                                                            onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                                            placeholder="Digite a data final" 
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {this.props.pointType != 'D' &&
                                                    <Row>
                                                        <Col lg="4" xs="12" className="bottom10">
                                                            <Form.Label className="isRequired">Tipo Edificação</Form.Label>
                                                            <Select
                                                            id="sgeptipoedificacao"
                                                            value={this.formEvent.onValueSelect('select', 'sgeptipoedificacao', this.state.typeBuilding)}
                                                            onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptipoedificacao")}
                                                            closeMenuOnSelect={true}
                                                            placeholder={"Selecione"}
                                                            options={this.state.typeBuilding}
                                                            isMulti={false}
                                                            />
                                                        </Col>
                                                        <Col lg="4" xs="12" className="bottom10">
                                                            <Form.Label>Inscrição Imobiliária Edificação</Form.Label>
                                                            <Form.Control
                                                            id="inscricaomunicipal"
                                                            defaultValue={json['inscricaomunicipal'] ? json['inscricaomunicipal'] : ''}
                                                            onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                            placeholder="Digite a inscrição municipal" 
                                                            />
                                                        </Col>
                                                    </Row>}
                                                    <Row>
                                                        <Col xs="12" className="bottom10">
                                                            <Form.Label>Descrição/Observação</Form.Label>
                                                            <Form.Control
                                                            id="descricao"
                                                            as="textarea" 
                                                            rows="3"
                                                            defaultValue={json['descricao'] ? json['descricao'] : ''}
                                                            onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                            placeholder="Digite uma descrição" 
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Tab>
                                                {this.state.listItens.length > 0 &&
                                                <Tab eventKey="itens" title="Itens">
                                                    <Row className="tab-item-container">
                                                        {this.getListItens()}
                                                    </Row>
                                                </Tab>}
                                            </Tabs>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={{marginTop: 20}}>
                                            <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                                <Image className="imageSave"
                                                src={salvar}/>
                                                Salvar
                                            </Button>
                                            <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setTab(key: string) {
        this.setState({
            tabKey: key
        })
    }

    private setKeyFK() {
        var json = this.state.json as any
        json['sgeppropedificacoes'] = this.props.idRealEstate

        this.setState({
            json: json
        })
    }

    private loadBuildingPermit() {
        this.props.viewModel.fetchBuildingPermit().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                buildingPermit: array
            })
        })
    }

    private loadBuildingType() {
        this.props.viewModel.fetchBuildingType().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                typeBuilding: array
            })
        })
    }

    private loadListItens() {
        this.props.viewModel.fetchRealEstateListItens(this.props.pointType).then((rs) => {
            var result = rs as Array<any>
            this.setState({
                listItens: result
            })
        });
    }

    private getListItens() {
        var json = this.state.json as any
        var arrayListItens = new Array<any>()
        var result = this.state.listItens as Array<any>
        result.forEach((item) => {
            var listItem = this.loadArraySelect(item['itens'])
            var isRequired = item['obrigatorio'] == 'S' ? 'isRequired' : ''
            arrayListItens.push(
                <Col xs="12" lg="4" className="bottom10">
                    <Form.Label className={isRequired}>{item.nome}</Form.Label>
                    {item.tipo == 'L' &&
                    <Select
                    id={`item-${item.sgepcadastroimobiliarioconfiguracao}`}
                    value={this.formEvent.onValueSelect('select', `item-${item.sgepcadastroimobiliarioconfiguracao}`, listItem)}
                    onChange={(e: any) => this.formEvent.onChange("select", e, `item-${item.sgepcadastroimobiliarioconfiguracao}`)}
                    closeMenuOnSelect={true}
                    placeholder={"Selecione"}
                    options={listItem}
                    isMulti={false}
                    />}
                    {item.tipo == 'V' &&
                    <NumberFormat
                        id={`item-${item.sgepcadastroimobiliarioconfiguracao}`}
                        className="form-control"
                        thousandSeparator="."  
                        decimalSeparator=","  
                        decimalScale={2}
                        prefix="R$"
                        value={json[`item-${item.sgepcadastroimobiliarioconfiguracao}`] ? json[`item-${item.sgepcadastroimobiliarioconfiguracao}`] : ''}
                        onValueChange={(values) => this.formEvent.onChange("money", values, `item-${item.sgepcadastroimobiliarioconfiguracao}`)}
                    />}
                    {item.tipo != 'L' && item.tipo != 'V' &&
                    <Form.Control
                    id={`item-${item.sgepcadastroimobiliarioconfiguracao}`}
                    defaultValue={json[`item-${item.sgepcadastroimobiliarioconfiguracao}`] ? json[`item-${item.sgepcadastroimobiliarioconfiguracao}`] : ''}
                    onChange={(e: any) => this.formEvent.onChange(this.getInput(item.tipo), e.target)}
                    />}
                </Col>
            )
        })

        return arrayListItens
    }

    private getInput(item: string): TypeComponent {
        switch(item) {
            case "A":
                return "varchar"
            
            case "D":
                return "date"

            case "N":
                return "double"

            case "T":
                return "varchar"

            default:
                return "varchar"
        }
    }

    private loadArraySelect(itens: Array<any>): Array<any> {
        var array = Array<any>()

        for(var i = 0; i < itens.length; i++) {
            var item = itens[i]
            array.push({value: item.sgepcadastroimobiliarioconfiguracaoitem, label: item.item, check: false})
        }

        return array
    }

    private save() {
        var json = this.state.json as any
        if(json["datainicial"] == undefined || json["datafinal"] == undefined || json["sgeptipoedificacao"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        var dateInitial = moment(json["datainicial"], "DD/MM/YYYY")
        if(!dateInitial.isValid()) {
            this.showFieldsEmpty("Data inicial inválida!")
            return
        }

        if(dateInitial.diff(new Date()) > 0) {
            this.showFieldsEmpty("Data inicial inválida!")
            return
        }

        var dateFinal = moment(json["datafinal"], "DD/MM/YYYY")
        if(!dateFinal.isValid()) {
            this.showFieldsEmpty("Data final inválida!")
            return
        }

        if(dateFinal.diff(new Date()) > 0) {
            this.showFieldsEmpty("Data final inválida!")
            return
        }

        if(dateInitial.diff(dateFinal) > 0) {
            this.showFieldsEmpty("Data inicial inválida!")
            return
        }

        for(var i = 0; i < this.state.listItens.length; i++) {
            var itemConf = this.state.listItens[i]
            if(itemConf['obrigatorio'] == 'S') {
                if(json[`item-${itemConf['sgepcadastroimobiliarioconfiguracao']}`] == null) {
                    this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                    return
                }

                if(json[`item-${itemConf['sgepcadastroimobiliarioconfiguracao']}`].length == 0) {
                    this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                    return
                }
            }

            if(itemConf['tipo'] == 'D') {
                if(json[`item-${itemConf['sgepcadastroimobiliarioconfiguracao']}`] != null) {
                    if(json[`item-${itemConf['sgepcadastroimobiliarioconfiguracao']}`].length != 0) {
                        var date = moment(json[`item-${itemConf['sgepcadastroimobiliarioconfiguracao']}`], "DD/MM/YYYY")
                        if(!date.isValid()) {
                            this.showFieldsEmpty("Data inválida!")
                            return
                        }
                    }
                }
            }
        }

        var itens = new Array<any>()
        var keys = Object.keys(json)
        keys.forEach((key) => {
            if(key.indexOf('item') != -1) {
                let itemId = key.substring(key.indexOf('-') + 1, key.length);
                itens.push({
                    key: itemId,
                    value: json[key]
                })
                // delete json[key]
            }
        })

        json['itens'] = itens
        this.setState({
            isLoading: true
        })

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            this.setState({
                isLoading: false
            })

            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgepcadastroimobiliario'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                var result = resultado[0]
                result['datainicial'] = result['datainicial'] ? moment(result['datainicial']).format('DD/MM/YYYY') : ""
                result['datafinal'] = result['datafinal'] ? moment(result['datafinal']).format('DD/MM/YYYY') : ""
                this.setState({
                    isLoading: false,
                    json: result
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}