import { LayerInteractor, LayerInteractorRequest, LayerStructRequest } from "../../Coordinator/Layer/LayerInteractor";

export class LayerViewModel {
    private interactor: LayerInteractor

    constructor() {
        this.interactor = new LayerInteractor()
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new LayerInteractorRequest(
            obj["scamada"] ? parseInt(obj["scamada"]) : 0,
            obj["camada"] ? obj["camada"] : "",
            obj["ordem"] ? parseInt(obj["ordem"]) : 0,
            obj["visivel"] ? obj["visivel"] : "",
            obj["displayonload"] ? obj["displayonload"] : "",
            obj["tipocamada"] ? obj["tipocamada"] : "",
            obj["smarcadorimagem"] ? parseInt(obj["smarcadorimagem"]) : 0,
            obj["scamadapai"] ? parseInt(obj["scamadapai"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new LayerStructRequest(
            obj["scamada"] ? parseInt(obj["scamada"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchImportShape(obj: any): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchImportShape(obj).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchImportDXF(obj: any): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchImportDXF(obj).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectMarkerImage(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectMarkerImage().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectLayers(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectLayers().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchRemoveShape(obj: any): PromiseLike<any> {
        var request = new LayerStructRequest(
            obj["scamada"] ? parseInt(obj["scamada"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchRemoveShape(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchImportTiff(obj: any): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchImportTiff(obj).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchRemoveTiff(obj: any): PromiseLike<any> {
        var request = new LayerStructRequest(
            obj["scamada"] ? parseInt(obj["scamada"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchRemoveTiff(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }
}