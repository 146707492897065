import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { FarmProductViewController, FarmProductViewControllerDelegate } from "../../Screen/FarmProduct/FarmProductViewController";
import { FarmProductViewModel } from "../../Screen/FarmProduct/FarmProductViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";

export class FarmProductCoordinator implements FarmProductViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[]{
        return[
            this.routeTableProduct(),
            this.routeFormProduct(),
        ]
    }

    private routeTableProduct(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepprodutosagropecuarios")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.produtos.*']} path="/:base/produto-agropecuario" exact={true} component={(props:any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.produtos.adicionar',
                    edit: 'censo_agrop.produtos.editar',
                    delete: 'censo_agrop.produtos.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Produto Agropecuário"}
            />)
        }}/>)
    }

    private routeFormProduct(): JSX.Element {
        var viewModel = new FarmProductViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.produtos.adicionar', 'censo_agrop.produtos.editar']} path="/:base/produto-agropecuario/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<FarmProductViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepprodutosagropecuarios"){
            navigation.history.push(`produto-agropecuario/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepprodutosagropecuarios"){
            navigation.history.push(`produto-agropecuario/cad/${idSelection}`);
            return;
        }
    }
}