import React from "react";
import { Button, Col, Row } from "react-bootstrap";

interface FiltroButtonsProps {

    clearMapFilters: () => void
    clearAllLayers: () => void
    saveFilters: () => void
    getPoints: () => void
    setGeoJson: (scamada: Array<any>, geojson: Array<any>, tiles: Array<any>, isFilter: boolean) => void
    isLast?: boolean
}
export const FiltroButtonsComponent: React.FC<FiltroButtonsProps> = ({ clearMapFilters, clearAllLayers, getPoints, saveFilters, setGeoJson, isLast }) => {
    return (

        <Row className={`filtro-col-button ${isLast && 'filtro-button-m2'}`}>
            <Col className='col-auto'>
                <Button type="button" className='filtro-button' onClick={() => { clearAllLayers(); saveFilters(); getPoints() }}>
                    Filtrar
                </Button>
            </Col>
            <Col className='col-auto'>
                <Button type="button" className='filtro-button' variant='success' onClick={() => { clearMapFilters(); setGeoJson([0], [], [], true); localStorage.removeItem('filters'); }}>
                    Limpar
                </Button>
            </Col>
        </Row>
    )
}