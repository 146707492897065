import { Request } from "../../Servidor/Request";

export type TypeFilter = '!=' | '='
export class TableGenericFilter {
    private column: String;
    private value: String;
    private type?: TypeFilter


	constructor(column: String, value: String, type?: TypeFilter) {
		this.column = column;
		this.value = value;
        this.type = type;
    }
    

    /**
     * Getter column
     * @return {String}
     */
	public getColumn(): String {
		return this.column;
	}

    /**
     * Getter value
     * @return {String}
     */
	public getValue(): String {
		return this.value;
	}

    /**
     * Getter type
     * @return {TypeFilter}
     */
	public getType(): TypeFilter {
		return this.type != null ? this.type : '=';
	}
}

export class TableGenericRequest {
    private table: String;
    private filter: Array<TableGenericFilter>;
    private page: number;

	constructor(table: String, filter: Array<TableGenericFilter>, page: number) {
        this.table = table;
        this.filter = filter;
        this.page = page;
    }
    

    /**
     * Getter table
     * @return {String}
     */
	public getTable(): String {
		return this.table;
    }
    

    /**
     * Getter filter
     * @return {Array<TableGenericFilter>}
     */
	public getFilter(): Array<TableGenericFilter> {
		return this.filter;
    }
    

    /**
     * Getter page
     * @return {number}
     */
	public getPage(): number {
		return this.page;
	}


}

export class TableGenericResponse {
    private column: String;
    private value: String;

	constructor(column: String, value: String) {
        this.column = column;
        this.value = value;
    }

    /**
     * Getter column
     * @return {String}
     */
	public getColumn(): String {
		return this.column;
	}

    /**
     * Getter value
     * @return {String}
     */
	public getValue(): String {
		return this.value;
	}


}

export class TableGenericDeleteRequest {
    private table: String;
    private idPrimary: number;


	constructor(table: String, idPrimary: number) {
        this.table = table;
        this.idPrimary = idPrimary;
    }   
    

    /**
     * Getter table
     * @return {String}
     */
	public getTable(): String {
		return this.table;
	}

    /**
     * Getter idPrimary
     * @return {number}
     */
	public getIdPrimary(): number {
		return this.idPrimary;
	}
}

export class TableGenericDeleteResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}


}

export class TableGenericInteractor {
    private server: Request;

	constructor() {
        this.server = new Request();
	}

    public fetchData(request: TableGenericRequest): PromiseLike<TableGenericResponse> {
        return new Promise((res, rej) => {
            return this.server.requestPost('tabela/buscarDados', request).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e);
            });
        });
    }

    public fetchDelete(request: TableGenericDeleteRequest): PromiseLike<TableGenericDeleteResponse> {
        return new Promise((res, rej) => {
            return this.server.requestPost('tabela/deletarDado', request).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e);
            });
        });
    }
}