import { Request } from "../../Servidor/Request";

export class CourseGroupRequest {
    private cgepcursogrupo: number;
    private nome: string;

    constructor (cgepcursogrupo:number, nome: string){
        this.cgepcursogrupo = cgepcursogrupo;
        this.nome = nome;
    }

    /**
     * Getter cgepcursogrupo
     * @return {number}
     */
    public getCgepcursogrupo(): number {
        return this.cgepcursogrupo;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }
}

export class CourseGroupStructRequest {
    private cgepcursogrupo: number;

    constructor (cgepcursogrupo:number){
        this.cgepcursogrupo = cgepcursogrupo;
    }

    /**
     * Getter cgepcursogrupo
     * @return {number}
     */
    public getCgepcursogrupo(): number {
        return this.cgepcursogrupo;
    }
}

export class CourseGroupInteractor {
    public fetchSave(request: CourseGroupRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/grupo-curso/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: CourseGroupStructRequest): PromiseLike<CourseGroupRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("basico/grupo-curso/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
