import { ResetPasswordRequest, UserInteractor } from "../../Coordinator/User/UserInteractor";

export class ResetPasswordViewModel {
    private interactor: UserInteractor;

	constructor() {
		this.interactor = new UserInteractor();
	}

    public fetchResetPassword(obj: any): PromiseLike<any> {
        var request = new ResetPasswordRequest(
            obj["cusuario"] ? parseInt(obj["cusuario"]) : 0,
            obj["senha"] ? obj["senha"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchResetPassword(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }
}