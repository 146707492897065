import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { CourtSituationViewControllerDelegate, CourtSituationViewController } from "../../Screen/CourtSituation/CourtSituationViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { CourtSituationViewModel } from '../../Screen/CourtSituation/CourtSituationViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class CourtSituationCoordinator implements IndexCoordinatorDelegate, CourtSituationViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepsituacaoquadra")
        return (<ProtectedRoute requiredPermissions={['prop.sit_quadra.*']} path="/:base/situacao-quadra" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                permissions={{
                    add: 'prop.sit_quadra.adicionar',
                    edit: 'prop.sit_quadra.editar',
                    delete: 'prop.sit_quadra.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Situação Quadra"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new CourtSituationViewModel()
        return (<ProtectedRoute requiredPermissions={['prop.sit_quadra.adicionar', 'prop.sit_quadra.editar']} path="/:base/situacao-quadra/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<CourtSituationViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepsituacaoquadra") {
            navigation.history.push(`situacao-quadra/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepsituacaoquadra") {
            navigation.history.push(`situacao-quadra/cad/${idSelection}`);
            return;
        }
    }

}