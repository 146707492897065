import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import { BrowserRouter, Switch, Route} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Pagina404Componente } from './Components/Pagina404Componente';
import { IndexCoordinator } from './Coordinator/IndexCoordinator';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {new IndexCoordinator().getRouter()}
      <Route path='*' exact={true} component={Pagina404Componente} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
