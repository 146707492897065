import { ProfessionalInteractor, ProfessionalRequest, ProfessionalStructRequest } from "../../Coordinator/Professional/ProfessionalInteractor";

export class ProfessionalViewModel {
    private interactor: ProfessionalInteractor;

	constructor() {
		this.interactor = new ProfessionalInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new ProfessionalRequest(
            obj["sgepusuariocargo"] ? parseInt(obj["sgepusuariocargo"]) : 0,
            obj["cusuario"] ? parseInt(obj["cusuario"]) : 0,
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new ProfessionalStructRequest(
            obj["sgepusuariocargo"] ? parseInt(obj["sgepusuariocargo"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchUser(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchUser().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchPerson(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchPerson().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}