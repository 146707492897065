import { TableGenericInteractor, TableGenericRequest, TableGenericDeleteRequest, TableGenericFilter } from "../../Coordinator/TableGeneric/TableGenericInteractor";

export class TableGenericViewModel {
  private table: String;
  private interactor: TableGenericInteractor
  private filter?: Array<TableGenericFilter>

	constructor(table: String) {
    this.table = table;
    this.interactor = new TableGenericInteractor()
  }
    
    /**
     * Getter table
     * @return {String}
     */
	public getTable(): String {
		return this.table;
  }

  public setFilter(filter: Array<TableGenericFilter>) {
    this.filter = filter
  }

  public getFilter(): Array<TableGenericFilter> | undefined {
    return this.filter
  }
  
  public fetchData(filter: Array<TableGenericFilter>, page: number): PromiseLike<any> {
    var request = new TableGenericRequest(this.table, filter, page);
    return this.interactor.fetchData(request)
  }

  public fetchDelete(idPrimary: number): PromiseLike<any> {
    var request = new TableGenericDeleteRequest(this.table, idPrimary)
    return this.interactor.fetchDelete(request)
  }

}