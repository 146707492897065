import { Request } from '../../Servidor/Request';

export class StudentRequest {
    private sgeppessoa: number;
    private cgeptiporesponsavel: number;
    private cgeppessoaresp: number;
    private cgepnecessidadesespecial: number;
    private idmec: string;
    private ativo: string;

	constructor(sgeppessoa: number, cgeptiporesponsavel: number, cgeppessoaresp: number, cgepnecessidadesespecial: number, idmec: string, ativo: string) {
		this.sgeppessoa = sgeppessoa;
		this.cgeptiporesponsavel = cgeptiporesponsavel;
		this.cgeppessoaresp = cgeppessoaresp;
		this.cgepnecessidadesespecial = cgepnecessidadesespecial;
		this.idmec = idmec;
        this.ativo = ativo;
	}


    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

    /**
     * Getter cgeptiporesponsavel
     * @return {number}
     */
	public getCgeptiporesponsavel(): number {
		return this.cgeptiporesponsavel;
	}

    /**
     * Getter cgeppessoaresp
     * @return {number}
     */
	public getCgeppessoaresp(): number {
		return this.cgeppessoaresp;
	}

    /**
     * Getter cgepnecessidadesespecial
     * @return {number}
     */
	public getCgepnecessidadesespecial(): number {
		return this.cgepnecessidadesespecial;
	}

    /**
     * Getter idmec
     * @return {string}
     */
	public getIdmec(): string {
		return this.idmec;
	}

    /**
     * Getter ativo
     * @return {number}
     */
	public getAtivo(): string {
		return this.ativo;
    }
}

export class StudentStructRequest {
    private sgeppessoa: number;

	constructor(sgeppessoa: number) {
        this.sgeppessoa = sgeppessoa;
	}


    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}
}

export class StudentInteractor {
    public fetchSave(request: StudentRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: StudentStructRequest): PromiseLike<StudentRequest> {
        return new Promise ((res, rej) => {
            return new Request().requestPost("aluno/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDelete(request: StudentStructRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/deletar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchCustody(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/tipo-responsavel/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSpecialNeeds(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/necessidade-especial/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}