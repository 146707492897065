import { Request } from '../../Servidor/Request';

export class RealEstateRegistrationItem {
    private sgepcadastroimobiliarioconfiguracaoitem: number;
    private item: string;
    private valor: number;


	constructor(sgepcadastroimobiliarioconfiguracaoitem: number, item: string, valor: number) {
		this.sgepcadastroimobiliarioconfiguracaoitem = sgepcadastroimobiliarioconfiguracaoitem;
		this.item = item;
		this.valor = valor;
	}


    /**
     * Getter sgepcadastroimobiliarioconfiguracaoitem
     * @return {number}
     */
	public getSgepcadastroimobiliarioconfiguracaoitem(): number {
		return this.sgepcadastroimobiliarioconfiguracaoitem;
	}

    /**
     * Getter item
     * @return {string}
     */
	public getItem(): string {
		return this.item;
	}

    /**
     * Getter valor
     * @return {number}
     */
	public getValor(): number {
		return this.valor;
	}
    
}

export class RealEstateRegistrationConfigRequest {
    private sgepcadastroimobiliarioconfiguracao: number;
    private nome: string;
    private tipo: string;
    private obrigatorio: string;
    private ordem: number;
    private itens: Array<RealEstateRegistrationItem>;
    private tipoorigem: string;


	constructor(sgepcadastroimobiliarioconfiguracao: number, nome: string, tipo: string, obrigatorio: string, ordem: number, itens: Array<RealEstateRegistrationItem>, tipoorigem: string) {
        this.sgepcadastroimobiliarioconfiguracao = sgepcadastroimobiliarioconfiguracao
        this.nome = nome
        this.tipo = tipo
        this.obrigatorio = obrigatorio
        this.ordem = ordem 
        this.itens = itens
        this.tipoorigem = tipoorigem
	}


    /**
     * Getter sgepcadastroimobiliarioconfiguracao
     * @return {number}
     */
	public getSgepcadastroimobiliarioconfiguracao(): number {
		return this.sgepcadastroimobiliarioconfiguracao;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
    }
    

    /**
     * Getter $tipo
     * @return {string}
     */
	public get $tipo(): string {
		return this.tipo;
    }
    

    /**
     * Getter $obrigatorio
     * @return {string}
     */
	public get $obrigatorio(): string {
		return this.obrigatorio;
	}
    

    /**
     * Getter ordem
     * @return {number}
     */
	public getOrdem(): number {
		return this.ordem;
	}

    /**
     * Getter itens
     * @return {Array<RealEstateRegistrationItem>}
     */
	public getItens(): Array<RealEstateRegistrationItem> {
		return this.itens;
	}

    /**
     * Getter $tipoorigem
     * @return {string}
     */
	public get $tipoorigem(): string {
		return this.tipoorigem;
	}
}

export class RealEstateRegistrationConfigStructRequest {
    private sgepcadastroimobiliarioconfiguracao: number;

	constructor(sgepcadastroimobiliarioconfiguracao: number) {
        this.sgepcadastroimobiliarioconfiguracao = sgepcadastroimobiliarioconfiguracao;
	}



    /**
     * Getter sgepcadastroimobiliarioconfiguracao
     * @return {number}
     */
	public getSgepcadastroimobiliarioconfiguracao(): number {
		return this.sgepcadastroimobiliarioconfiguracao;
	}
    

}

export class RealEstateRegistrationConfigInteractor {
    public fetchSave(request: RealEstateRegistrationConfigRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/configuracao-cadastro-imobiliario/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Status == "Sucesso")
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: RealEstateRegistrationConfigStructRequest): PromiseLike<RealEstateRegistrationConfigRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/configuracao-cadastro-imobiliario/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}