import { PropertyConfigInteractor, PropertyConfigRequest, PropertyConfigStructRequest } from "../../Coordinator/PropertyConfig/PropertyConfigInteractor";

export class PropertyConfigViewModel {
    private interactor: PropertyConfigInteractor;

	constructor() {
		this.interactor = new PropertyConfigInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PropertyConfigRequest(
            obj["sgeppropriedadeconfiguracao"] ? parseInt(obj["sgeppropriedadeconfiguracao"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["tipo"] ? obj["tipo"] : "",
            obj["obrigatorio"] ? obj["obrigatorio"] : "",
            obj["iscadastroimobiliario"] ? obj["iscadastroimobiliario"] : false,
            obj["ordem"] ? parseInt(obj["ordem"]) : 0,
            obj["itens"],
            obj["tipoorigem"] ? obj["tipoorigem"] : ""
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> { 
        var request = new PropertyConfigStructRequest(
            obj["sgeppropriedadeconfiguracao"] ? parseInt(obj["sgeppropriedadeconfiguracao"]) : 0
        )

        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}