import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import {
	NavOptions,
	TableGenericViewController,
	TableGenericViewControllerDelegate,
} from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";
import { AdminProcessTypeViewModel } from "../../Screen/AdminProcessType/AdminProcessTypeViewModel";
import {
	AdminProcessTypeViewController,
	AdminProcessTypeViewControllerDelegate,
} from "../../Screen/AdminProcessType/AdminProcessTypeViewController";

const TABLE_NAME = "geptipoprocesso";

export class AdminProcessTypeCoordinator
	implements
		IndexCoordinatorDelegate,
		TableGenericViewControllerDelegate,
		AdminProcessTypeViewControllerDelegate
{
	getRoute(): JSX.Element[] {
		return [this.routeTable(), this.routeForm()];
	}

	private routeTable(): JSX.Element {
		var arrayOptions = new Array<NavOptions>();
		var viewModel = new TableGenericViewModel(TABLE_NAME);
		return (
			<ProtectedRoute
				requiredPermissions={["proc_admin.tipo_proc.*"]}
				path="/:base/tipo-processo"
				exact={true}
				component={(props: any) => {
					return (
						<TableGenericViewController
							permissions={{
								add: "proc_admin.tipo_proc.adicionar",
								edit: "proc_admin.tipo_proc.editar",
								delete: "proc_admin.tipo_proc.excluir",
							}}
							navigation={props}
							viewModel={viewModel}
							delegate={this}
							title={"Tipo de Processo Administrativo"}
							options={arrayOptions}
						/>
					);
				}}
			/>
		);
	}

	private routeForm(): JSX.Element {
		var viewModel = new AdminProcessTypeViewModel();
		return (
			<ProtectedRoute
				requiredPermissions={[
					"proc_admin.tipo_proc.adicionar",
					"proc_admin.tipo_proc.editar",
				]}
				path="/:base/tipo-processo/cad/:id"
				exact={true}
				component={(props: any) => {
					var idPk = props.match.params.id;
					return (
						<AdminProcessTypeViewController
							navigation={props}
							viewModel={viewModel}
							delegate={this}
							idPrimary={idPk}
						/>
					);
				}}
			/>
		);
	}

	tapDimiss(navigation: any): void {
		navigation.history.goBack();
		setTimeout(() => {
			window.location.reload();
		}, 500);
	}

	add(navigation: any, viewModel: TableGenericViewModel): void {
		if (viewModel.getTable() == TABLE_NAME) {
			navigation.history.push(`tipo-processo/cad/0`);
			return;
		}
	}

	edit(
		navigation: any,
		idSelection: number,
		viewModel: TableGenericViewModel
	): void {
		var filter = viewModel.getFilter();
		if (viewModel.getTable() === TABLE_NAME && filter === undefined) {
			navigation.history.push(`tipo-processo/cad/${idSelection}`);
			return;
		}
	}
}
