import React from 'react';
import { Route } from 'react-router-dom'
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { ImobiliaryRegisterRelatoryViewController, ImobiliaryRegisterRelatory } from "../../Screen/ImobiliaryRegisterRelatory/ImobiliaryRegisterRelatoryViewController";
import { ImobiliaryRegisterRelatoryViewModel, ConsolidatedReportType } from '../../Screen/ImobiliaryRegisterRelatory/ImobiliaryRegisterRelatoryViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class ImobiliaryRegisterRelatoryCoordinator implements IndexCoordinatorDelegate, ImobiliaryRegisterRelatory {

    getRoute(): JSX.Element[] {
        return [
            this.routeImobiliaryRegisterRelatory(),
        ]
    }

    private routeImobiliaryRegisterRelatory(): JSX.Element {
        var viewModel = new ImobiliaryRegisterRelatoryViewModel()
        return (<ProtectedRoute requiredPermissions={['relatorio.cadastro_imobiliario.*']} path="/:base/relatorio/cadastro-imobiliario" exact={true} component={(props: any) => {
            return (<ImobiliaryRegisterRelatoryViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
}