import React from 'react';
import { PersonViewModel } from './PersonViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { LoaderComponente } from '../../Components/LoaderComponente';
import { ToastContainer, toast } from 'react-toastify';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ValidaCNPJCPF } from '../../Fabrica/ValidaCNPJCPF';
import moment from 'moment';

import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';

interface MyProps {
    navigation: any
    delegate: PersonViewControllerDelegate
    viewModel: PersonViewModel
    idPrimary: number
    isShowHeader: Boolean
}

export interface PersonViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class PersonViewController extends React.Component<MyProps> {
    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        type: Array<any>(),
        sex: Array<any>(),
        showPF: false,
        showPJ: false
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadType()
        this.loadSex()

        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
        <div>
            {this.props.isShowHeader &&
            <NavBarComponente propNav={this.props.navigation}/>
            }
            <div>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <div className="title">
                                <h3>Pessoas</h3>
                            </div>
                            <div className="buttonClose">
                                <Image className="imageClose"
                                onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                src={fechar}/>
                            </div>
                        </Col>
                    </Row>
                    <Form className="containerForm">
                        <Row>
                            <Col lg="4" xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                id="sgeppessoa"
                                defaultValue={json['sgeppessoa'] ? json['sgeppessoa'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col lg="4" xs="12" className="bottom10">
                                <Form.Label className="isRequired">Nome</Form.Label>
                                <Form.Control
                                id="nome"
                                defaultValue={json['nome'] ? json['nome'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                placeholder="Digite o nome" 
                                />
                            </Col>
                            <Col lg="4" xs="12" className="bottom10">
                                <Form.Label className="isRequired">Tipo</Form.Label>
                                <Select
                                id="tipo"
                                value={this.formEvent.onValueSelect('select', 'tipo', this.state.type)}
                                onChange={(e: any) => this.onChangeType(e)}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.type}
                                isMulti={false}
                                />
                            </Col>
                        </Row>
                        {this.state.showPF && <fieldset className="fieldset">
                            <legend>Pessoa Física</legend>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control
                                    id="cpf"
                                    maxLength={14}
                                    defaultValue={json['cpf'] ? this.formEvent.onCPFSelect('cpf') : ''}
                                    onChange={(e: any) => this.formEvent.onChange("cpf", e.target)}
                                    placeholder="Digite o CPF" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Sexo</Form.Label>
                                    <Select
                                    id="sexo"
                                    value={this.formEvent.onValueSelect('select', 'sexo', this.state.sex)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sexo")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.sex}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Data de nascimento</Form.Label>
                                    <Form.Control
                                    id="datanascimento"
                                    maxLength={10}
                                    defaultValue={json['datanascimento'] ? this.formEvent.onDateSelect('datanascimento') : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Digite a data de nascimento" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Data de óbito</Form.Label>
                                    <Form.Control
                                    id="dataobito"
                                    maxLength={10}
                                    defaultValue={json['dataobito'] ? this.formEvent.onDateSelect('dataobito') : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Digite a data de óbito" 
                                    />
                                </Col>
                            </Row>
                        </fieldset> }
                        {this.state.showPJ && <fieldset className="fieldset">
                            <legend>Pessoa Jurídica</legend>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">CNPJ</Form.Label>
                                    <Form.Control
                                    id="cnpj"
                                    maxLength={18}
                                    defaultValue={json['cnpj'] ? this.formEvent.onCNPJSelect('cnpj') : ''}
                                    onChange={(e: any) => this.formEvent.onChange("cnpj", e.target)}
                                    placeholder="Digite o CNPJ" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Razão Social</Form.Label>
                                    <Form.Control
                                    id="razaosocial"
                                    defaultValue={json['razaosocial'] ? json['razaosocial'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite a razão social" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Data de fundação</Form.Label>
                                    <Form.Control
                                    id="datafundacao"
                                    maxLength={10}
                                    defaultValue={json['datafundacao'] ? this.formEvent.onDateSelect('datafundacao') : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Digite a data de fundação" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Inscrição municipal</Form.Label>
                                    <Form.Control
                                    id="inscricaomunicipal"
                                    defaultValue={json['inscricaomunicipal'] ? json['inscricaomunicipal'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite a inscrição municipal" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Inscrição estadual</Form.Label>
                                    <Form.Control
                                    id="inscricaoestadual"
                                    defaultValue={json['inscricaoestadual'] ? json['inscricaoestadual'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite a inscrição social" 
                                    />
                                </Col>
                            </Row>
                        </fieldset> }
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                    <Image className="imageSave"
                                    src={salvar}/>
                                    Salvar
                                </Button>
                                <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                            </Col>
                        </Row>
                    </Form>
                    <ToastContainer />
                </Container>
            </div>
            <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
        </div>)
    }

    private save() {
        var json = this.state.json as any
        if(!this.validateFiedls(json)) {
            return
        }

        if(json["tipo"] == "PF") {
            delete json['cnpj']
            delete json['razaosocial']
            delete json['datafundacao']
            delete json['inscricaomunicipal']
            delete json['inscricaoestadual']
        } else {
            delete json['cpf']
            delete json['datanascimento']
            delete json['sexo']
            delete json['dataobito']
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private validateFiedls(json: any): boolean {
        if(json["nome"] == undefined || json["tipo"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return false
        }

        var validarCPFCNPJ = new ValidaCNPJCPF()
        if(json["tipo"] == "PF") {
            if(json["sexo"] == undefined) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return false
            }

            if(json["datanascimento"] == undefined || json["datanascimento"].length != 10) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return false
            }

            var date = moment(json["datanascimento"], "DD/MM/YYYY")
            if(!date.isValid()) {
                this.showFieldsEmpty("Data de nascimento inválido!")
                return false
            }

            if(json['dataobito']) {
                if(json["dataobito"].length != 10) {
                    this.showFieldsEmpty("Data de óbito inválido!")
                    return false
                }

                var dateDeath = moment(json["dataobito"], "DD/MM/YYYY")
                if(!dateDeath.isValid()) {
                    this.showFieldsEmpty("Data de óbito inválido!")
                    return false
                }
            }

            var years = moment().diff(date.toDate(), 'years', false)
            if(years > 8) {
                if(json["cpf"] == undefined || json["cpf"].length != 14) {
                    this.showFieldsEmpty("CPF é obrigatório!")
                    return false
                }

                if(!validarCPFCNPJ.validaCPF(json["cpf"])) {
                    this.showFieldsEmpty("CPF inválido!")
                    return false
                }
            }

        } else {
            if(json["cnpj"] == undefined || json["cnpj"].length != 18) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return false
            }

            if(!validarCPFCNPJ.validaCNPJ(json["cnpj"])) {
                this.showFieldsEmpty("CPF inválido!")
                return false
            }

            if(json['datafundacao']) {
                if(json["datafundacao"].length != 10) {
                    this.showFieldsEmpty("Data de fundação inválido!")
                    return false
                }

                var dateDeath = moment(json["datafundacao"], "DD/MM/YYYY")
                if(!dateDeath.isValid()) {
                    this.showFieldsEmpty("Data de fundação inválido!")
                    return false
                }
            }
        }

        return true
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadType() {
        var array = Array<any>()
        array.push({value: "PF", label: "Pessoa Física", check: false})
        array.push({value: "PJ", label: "Pessoa Jurídica", check: false})

        this.setState({
            type: array
        })
    }

    private loadSex() {
        var array = Array<any>()
        array.push({value: "M", label: "Masculino", check: false})
        array.push({value: "F", label: "Feminino", check: false})

        this.setState({
            sex: array
        })
    }

    private onChangeType(e: any) {
        this.setState({
            showPF: e.value == "PF",
            showPJ: e.value == "PJ"
        })

        this.formEvent.onChange("select", e, "tipo")
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgeppessoa'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    showPF: resultado[0]['tipo'] == "PF",
                    showPJ: resultado[0]['tipo'] == "PJ",
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}