import { PersonInteractor, PersonRequest, PersonStructRequest } from "../../Coordinator/Person/PersonInteractor";
import moment from 'moment';

export class PersonViewModel {
    private interactor: PersonInteractor;

	constructor() {
		this.interactor = new PersonInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PersonRequest(
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["tipo"] ? obj["tipo"] : "",
            obj["cpf"] ? String(obj["cpf"]).replace(/[^\d]+/g,'') : "",
            obj["datanascimento"] ? moment(obj["datanascimento"], "DD/MM/YYYY").format("YYYY-MM-DD") : "",
            obj["sexo"] ? obj["sexo"] : "",
            obj["dataobito"] ? moment(obj["dataobito"], "DD/MM/YYYY").format("YYYY-MM-DD") : "",
            obj["cnpj"] ? String(obj["cnpj"]).replace(/[^\d]+/g,'') : "",
            obj["razaosocial"] ? obj["razaosocial"] : "",
            obj["datafundacao"] ? moment(obj["datafundacao"], "DD/MM/YYYY").format("YYYY-MM-DD") : "",
            obj["inscricaomunicipal"] ? obj["inscricaomunicipal"] : "",
            obj["inscricaoestadual"] ? obj["inscricaoestadual"] : ""
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PersonStructRequest(
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

}