import { RuralPropertySituationInteractor, RuralPropertySituationRequest, RuralPropertySituationStructRequest } from "../../Coordinator/RuralPropertySituation/RuralPropertySituationInteractor";

export class RuralPropertySituationViewModel {
    private interactor: RuralPropertySituationInteractor;

	constructor() {
		this.interactor = new RuralPropertySituationInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new RuralPropertySituationRequest(
            obj["sgepsituacaoproprural"] ? parseInt(obj["sgepsituacaoproprural"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new RuralPropertySituationStructRequest(
            obj["sgepsituacaoproprural"] ? parseInt(obj["sgepsituacaoproprural"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}