import { Request } from "../../Servidor/Request";

export class AdminProcessTypeRequest {
	private sgeptipoprocesso: number;
	private nome: string;

	constructor($sgeptipoprocesso: number, $nome: string) {
		this.sgeptipoprocesso = $sgeptipoprocesso;
		this.nome = $nome;
	}

	/**
	 * Getter $sgepprocessoadministrativo
	 * @return {number}
	 */
	public get $sgeptipoprocesso(): number {
		return this.sgeptipoprocesso;
	}

	public get $nome(): number {
		return this.$nome;
	}
}

export class AdminProcessTypeStructRequest {
	private sgeptipoprocesso: number;

	constructor(sgeptipoprocesso: number) {
		this.sgeptipoprocesso = sgeptipoprocesso;
	}

	/**
	 * Getter sgepprocessoadministrativostatus
	 * @return {number}
	 */
	public getSgepprocessoadministrativo(): number {
		return this.sgeptipoprocesso;
	}
}

export class AdminProcessTypeInteractor {
	public fetchSave(request: AdminProcessTypeRequest): PromiseLike<Boolean> {
		return new Promise((res, rej) => {
			return new Request().requestPost("tipo-processo/salvar", request).then(
				(rs) => {
					if (rs == undefined) {
						return rej({
							Mensagem: "Erro ao conectar!",
						});
					}

					let response = rs.Status == "Sucesso!";
					res(response);
				},
				(e) => {
					rej(e);
				}
			);
		});
	}

	public fetchDataOne(
		request: AdminProcessTypeStructRequest
	): PromiseLike<AdminProcessTypeRequest> {
		return new Promise((res, rej) => {
			return new Request().requestPost("tipo-processo/buscar", request).then(
				(rs) => {
					if (rs == undefined) {
						return rej({
							Mensagem: "Erro ao conectar!",
						});
					}

					res(rs.Resultado);
				},
				(e) => {
					rej(e);
				}
			);
		});
	}
}
