import { Request } from "../../Servidor/Request"

export class CourseRequest {
    private cgepcurso: number;
    private nome: string;
    private cgepcursogrupo: number;
    private cesus: string;

    constructor (
        cgepcurso: number,
        nome: string,
        cgepcursogrupo: number,
        cesus: string,
    ) {
        this.cgepcurso = cgepcurso;
        this.nome = nome;
        this.cgepcursogrupo = cgepcursogrupo;
        this.cesus = cesus;
    }

    /**
     * Getter $cgepcurso
     * @return {number}
     */
	public get $cgepcurso(): number {
		return this.cgepcurso;
    }
    
    /**
     * Getter $nome
     * @return {string}
     */
	public get $nome(): string {
		return this.nome;
    }

    /**
     * Getter $cgepcursogrupo
     * @return {number}
     */
	public get $cgepcursogrupo(): number {
		return this.cgepcursogrupo;
    }

    /**
     * Getter $cesus
     * @return {string}
     */
	public get $cesus(): string {
		return this.cesus;
    }
}

export class CourseStructRequest {
    private cgepcurso: number;

    constructor(cgepcurso: number) {
        this.cgepcurso = cgepcurso;
    }
    
    /**
     * Getter $cgepcurso
     * @return {number}
     */
	public get $cgepcurso(): number {
		return this.cgepcurso;
    }
}

export class CourseInteractor {
    public fetchSave(request: CourseRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/curso/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: CourseStructRequest): PromiseLike<CourseRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/curso/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
