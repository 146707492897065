import { Request } from '../../Servidor/Request';

export class TypeHeritageRequest {
    private sgeptipopatrimonio: number;
    private nome: string;

	constructor(sgeptipopatrimonio: number, nome: string) {
		this.sgeptipopatrimonio = sgeptipopatrimonio;
		this.nome = nome;
	}


    /**
     * Getter sgeptipopatrimonio
     * @return {number}
     */
	public getSgeptipopatrimonio(): number {
		return this.sgeptipopatrimonio;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class TypeHeritageStructRequest {
    private sgeptipopatrimonio: number;

	constructor(sgeptipopatrimonio: number) {
        this.sgeptipopatrimonio = sgeptipopatrimonio;
	}


    /**
     * Getter sgeptipopatrimonio
     * @return {number}
     */
	public getSgeptipopatrimonio(): number {
		return this.sgeptipopatrimonio;
	}

}

export class TypeHeritageStructResponse {
    private sgeptipopatrimonio: number;
    private nome: string;

	constructor(sgeptipopatrimonio: number, nome: string) {
		this.sgeptipopatrimonio = sgeptipopatrimonio;
		this.nome = nome;
	}


    /**
     * Getter sgeptipopatrimonio
     * @return {number}
     */
	public getSgeptipopatrimonio(): number {
		return this.sgeptipopatrimonio;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class TypeHeritageInteractor {
    public fetchSave(request: TypeHeritageRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/tipo-patrimonio/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: TypeHeritageStructRequest): PromiseLike<TypeHeritageStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/tipo-patrimonio/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}