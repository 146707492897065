import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Loader from 'react-loader-spinner';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

interface MyProps {
    show: boolean
    hide: () => any
}

export class LoaderComponente extends React.Component<MyProps>{

    render() {

        return (
            <Modal show={this.props.show} onHide={() => this.props.hide()}>
                <Modal.Body>
                    <div style={{textAlign: "center"}}>
                        <Loader
                            type="ThreeDots"
                            color="#0080ce"
                            height={100}
                            width={100}
                        />
                        <Row>
                            <Col xs={12} style={{textAlign: "center"}}>   
                                <p>Processando...</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}