export class ValidaEmail {

    public valida(email: string): Boolean {
        if(email.length == 0) {
            return false
        }

        var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
        if(!er.test(email)) {
            return false
        }

        return true
    }

}