import { Request } from '../../Servidor/Request';

export class PersonRequest {
    private sgeppessoa: number;
    private nome: string;
    private tipo: string;
    private cpf: string;
    private datanascimento: string;
    private sexo: string;
    private dataobito: string;
    private cnpj: string;
    private razaosocial: string;
    private datafundacao: string;
    private inscricaomunicipal: string;
    private inscricaoestadual: string;


	constructor(sgeppessoa: number, nome: string, tipo: string, cpf: string, datanascimento: string, sexo: string, dataobito: string, cnpj: string, razaosocial: string, datafundacao: string, inscricaomunicipal: string, inscricaoestadual: string) {
		this.sgeppessoa = sgeppessoa;
		this.nome = nome;
		this.tipo = tipo;
		this.cpf = cpf;
		this.datanascimento = datanascimento;
		this.sexo = sexo;
		this.dataobito = dataobito;
		this.cnpj = cnpj;
		this.razaosocial = razaosocial;
		this.datafundacao = datafundacao;
		this.inscricaomunicipal = inscricaomunicipal;
		this.inscricaoestadual = inscricaoestadual;
	}


    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter tipo
     * @return {string}
     */
	public getTipo(): string {
		return this.tipo;
	}

    /**
     * Getter cpf
     * @return {string}
     */
	public getCpf(): string {
		return this.cpf;
	}

    /**
     * Getter datanascimento
     * @return {string}
     */
	public getDatanascimento(): string {
		return this.datanascimento;
	}

    /**
     * Getter sexo
     * @return {string}
     */
	public getSexo(): string {
		return this.sexo;
	}

    /**
     * Getter dataobito
     * @return {string}
     */
	public getDataobito(): string {
		return this.dataobito;
	}

    /**
     * Getter cnpj
     * @return {string}
     */
	public getCnpj(): string {
		return this.cnpj;
	}

    /**
     * Getter razaosocial
     * @return {string}
     */
	public getRazaosocial(): string {
		return this.razaosocial;
	}

    /**
     * Getter datafundacao
     * @return {string}
     */
	public getDatafundacao(): string {
		return this.datafundacao;
	}

    /**
     * Getter inscricaomunicipal
     * @return {string}
     */
	public getInscricaomunicipal(): string {
		return this.inscricaomunicipal;
	}

    /**
     * Getter inscricaoestadual
     * @return {string}
     */
	public getInscricaoestadual(): string {
		return this.inscricaoestadual;
	}

}

export class PersonResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success;
	}


    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}

}

export class PersonStructRequest {
    private sgeppessoa: number;

	constructor(sgeppessoa: number) {
        this.sgeppessoa = sgeppessoa;
	}


    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

}

export class PersonStructResponse {
    private sgeppessoa: number;
    private nome: string;
    private tipo: string;
    private cpf: string;
    private datanascimento: string;
    private sexo: string;
    private dataobito: string;
    private cnpj: string;
    private razaosocial: string;
    private datafundacao: string;
    private inscricaomunicipal: string;
    private inscricaoestadual: string;


	constructor(sgeppessoa: number, nome: string, tipo: string, cpf: string, datanascimento: string, sexo: string, dataobito: string, cnpj: string, razaosocial: string, datafundacao: string, inscricaomunicipal: string, inscricaoestadual: string) {
		this.sgeppessoa = sgeppessoa;
		this.nome = nome;
		this.tipo = tipo;
		this.cpf = cpf;
		this.datanascimento = datanascimento;
		this.sexo = sexo;
		this.dataobito = dataobito;
		this.cnpj = cnpj;
		this.razaosocial = razaosocial;
		this.datafundacao = datafundacao;
		this.inscricaomunicipal = inscricaomunicipal;
		this.inscricaoestadual = inscricaoestadual;
	}


    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter tipo
     * @return {string}
     */
	public getTipo(): string {
		return this.tipo;
	}

    /**
     * Getter cpf
     * @return {string}
     */
	public getCpf(): string {
		return this.cpf;
	}

    /**
     * Getter datanascimento
     * @return {string}
     */
	public getDatanascimento(): string {
		return this.datanascimento;
	}

    /**
     * Getter sexo
     * @return {string}
     */
	public getSexo(): string {
		return this.sexo;
	}

    /**
     * Getter dataobito
     * @return {string}
     */
	public getDataobito(): string {
		return this.dataobito;
	}

    /**
     * Getter cnpj
     * @return {string}
     */
	public getCnpj(): string {
		return this.cnpj;
	}

    /**
     * Getter razaosocial
     * @return {string}
     */
	public getRazaosocial(): string {
		return this.razaosocial;
	}

    /**
     * Getter datafundacao
     * @return {string}
     */
	public getDatafundacao(): string {
		return this.datafundacao;
	}

    /**
     * Getter inscricaomunicipal
     * @return {string}
     */
	public getInscricaomunicipal(): string {
		return this.inscricaomunicipal;
	}

    /**
     * Getter inscricaoestadual
     * @return {string}
     */
	public getInscricaoestadual(): string {
		return this.inscricaoestadual;
	}

}

export class PersonInteractor {
    public fetchSave(request: PersonRequest): PromiseLike<PersonResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/pessoas/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new PersonResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PersonStructRequest): PromiseLike<PersonStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/pessoas/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}