import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { PropertyFormObtainingViewControllerDelegate, PropertyFormObtainingViewController } from "../../Screen/PropertyFormObtaining/PropertyFormObtainingViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { PropertyFormObtainingViewModel } from '../../Screen/PropertyFormObtaining/PropertyFormObtainingViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PropertyFormObtainingCoordinator implements IndexCoordinatorDelegate, PropertyFormObtainingViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepformaobtencao")
        return (<ProtectedRoute requiredPermissions={['prop.forma_obtencao.*']} path="/:base/forma-obtencao" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'prop.forma_obtencao.adicionar',
                    edit: 'prop.forma_obtencao.editar',
                    delete: 'prop.forma_obtencao.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Forma de Obtenção da Propriedade"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new PropertyFormObtainingViewModel()
        return (<ProtectedRoute requiredPermissions={['prop.forma_obtencao.adicionar', 'prop.forma_obtencao.editar']} path="/:base/forma-obtencao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<PropertyFormObtainingViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepformaobtencao") {
            navigation.history.push(`forma-obtencao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepformaobtencao") {
            navigation.history.push(`forma-obtencao/cad/${idSelection}`);
            return;
        }
    }

}