import { Request } from '../../Servidor/Request';

export class PointTypeRequest {
    private sgeptipoponto: number;
    private nome: string;
    private itens: any[];

	constructor(sgeptipoponto: number, nome: string, itens: any[]) {
		this.sgeptipoponto = sgeptipoponto;
		this.nome = nome;
        this.itens = itens;
	}


    /**
     * Getter sgeptipoponto
     * @return {number}
     */
	public getSgeptipoponto(): number {
		return this.sgeptipoponto;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class PointTypeStructRequest {
    private sgeptipoponto: number;

	constructor(sgeptipoponto: number) {
        this.sgeptipoponto = sgeptipoponto;
	}


    /**
     * Getter sgeptipoponto
     * @return {number}
     */
	public getSgeptipoponto(): number {
		return this.sgeptipoponto;
	}

}

export class PointTypeStructResponse {
    private sgeptipoponto: number;
    private nome: string;

	constructor(sgeptipoponto: number, nome: string) {
		this.sgeptipoponto = sgeptipoponto;
		this.nome = nome;
	}


    /**
     * Getter sgeptipoponto
     * @return {number}
     */
	public getSgeptipoponto(): number {
		return this.sgeptipoponto;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class PointTypeInteractor {
    public fetchSave(request: PointTypeRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/tipo-ponto/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PointTypeStructRequest): PromiseLike<PointTypeStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/tipo-ponto/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}