import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { PropertySituationViewControllerDelegate, PropertySituationViewController } from "../../Screen/PropertySituation/PropertySituationViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { PropertySituationViewModel } from '../../Screen/PropertySituation/PropertySituationViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PropertySituationCoordinator implements IndexCoordinatorDelegate, PropertySituationViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepsituacaopropriedade")
        return (<ProtectedRoute requiredPermissions={['prop.sit_prop.*']} path="/:base/situacao-propriedade" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                permissions={{
                    add: 'prop.sit_prop.adicionar',
                    edit: 'prop.sit_prop.editar',
                    delete: 'prop.sit_prop.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Situação Propriedade"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new PropertySituationViewModel()
        return (<ProtectedRoute requiredPermissions={['prop.sit_prop.adicionar', 'prop.sit_prop.editar']} path="/:base/situacao-propriedade/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<PropertySituationViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepsituacaopropriedade") {
            navigation.history.push(`situacao-propriedade/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepsituacaopropriedade") {
            navigation.history.push(`situacao-propriedade/cad/${idSelection}`);
            return;
        }
    }

}