import React from 'react'
import { AddressViewModel } from './AddressViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 
import deletar from '../../Imagens/delete-16.png';

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';

interface MyProps {
    navigation: any
    delegate: AddressViewControllerDelegate
    viewModel: AddressViewModel
    idPrimary: number
    idPerson?: number
    idPoint?: number
}

export interface AddressViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class AddressViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        typeAddress: [],
        zone: [],
        blockFace: [],
        main: [],
        neighborhood: [],
        district: [],
        zoning: [],
        showButtonDelete: false
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadBlockFace()
        this.loadTypeAddress()
        this.loadZone()
        this.loadMain()
        this.loadSelect()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }

        if(this.props.idPoint) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Endereços</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="cgependereco"
                                    defaultValue={json['cgependereco'] ? json['cgependereco'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Logradouro</Form.Label>
                                    <Form.Control
                                    id="logradouro"
                                    defaultValue={json['logradouro'] ? json['logradouro'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Lado Logradouro</Form.Label>
                                    <Select
                                    id="ladologradouro"
                                    value={this.formEvent.onValueSelect('select', 'ladologradouro', this.state.blockFace)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "ladologradouro")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.blockFace}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Número</Form.Label>
                                    <Form.Control
                                    id="numero"
                                    defaultValue={json['numero'] ? json['numero'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Complemento</Form.Label>
                                    <Form.Control
                                    id="complemento"
                                    defaultValue={json['complemento'] ? json['complemento'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Bairro</Form.Label>
                                    <Select
                                    id="sgepbairros"
                                    value={this.formEvent.onValueSelect('select', 'sgepbairros', this.state.neighborhood)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepbairros")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.neighborhood}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <InputModalSelectComponent 
                                        id="Sequencial"
                                        keyValue="nome"
                                        label="Cidade"
                                        required={true}
                                        placeholder="Procure"
                                        showAddButton={false}
                                        navigation={this.props.navigation}
                                        titleModal="Cidades"
                                        table="cidade"
                                        valueDefault={json['municipio']}
                                        getValue={(id: number, value: string) => this.setCity(id, value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>CEP</Form.Label>
                                    <Form.Control
                                    id="cep"
                                    maxLength={9}
                                    defaultValue={json['cep'] ? json['cep'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("cep", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Tipo</Form.Label>
                                    <Select
                                    id="tipo"
                                    value={this.formEvent.onValueSelect('select', 'tipo', this.state.typeAddress)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "tipo")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.typeAddress}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Zona</Form.Label>
                                    <Select
                                    id="zona"
                                    value={this.formEvent.onValueSelect('select', 'zona', this.state.zone)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "zona")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.zone}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Distrito</Form.Label>
                                    <Select
                                    id="sgepdistrito"
                                    value={this.formEvent.onValueSelect('select', 'sgepdistrito', this.state.district)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepdistrito")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.district}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Zoneamento</Form.Label>
                                    <Select
                                    id="sgepzoneamento"
                                    value={this.formEvent.onValueSelect('select', 'sgepzoneamento', this.state.zoning)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepzoneamento")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.zoning}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Referência</Form.Label>
                                    <Form.Control
                                    id="referencia"
                                    defaultValue={json['referencia'] ? json['referencia'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Principal</Form.Label>
                                    <Select
                                    id="principal"
                                    value={this.formEvent.onValueSelect('select', 'principal', this.state.main)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "principal")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.main}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="bottom10">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                    id="descricao"
                                    as="textarea" 
                                    rows="3"
                                    defaultValue={json['descricao'] ? json['descricao'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite uma descrição" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    {!this.state.showButtonDelete &&
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>}
                                    {this.state.showButtonDelete &&
                                    <Button onClick={(e: any) => this.delete()} className="buttonCancel buttonDelete" variant="danger">
                                        <Image className="imageSave" 
                                        src={deletar}/>
                                        Remover
                                    </Button>}
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private save() {
        var json = this.state.json as any
        if(json["logradouro"] == undefined || json["ccidade"] == undefined || json["sgepbairros"] == undefined || json["principal"] == undefined || json["sgepdistrito"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["logradouro"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(this.props.idPerson) {
            json['sgeppessoa'] = this.props.idPerson
        }

        if(this.props.idPoint) {
            json['sgeppontos'] = this.props.idPoint
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadTypeAddress() {
        var array = Array<any>()
        array.push({value: "RE", label: "Residencial", check: false})
        array.push({value: "CO", label: "Comercial", check: false})
        array.push({value: "CR", label: "Correspondência", check: false})

        this.setState({
            typeAddress: array
        })
    }

    private loadZone() {
        var array = Array<any>()
        array.push({value: "R", label: "Rural", check: false})
        array.push({value: "U", label: "Urbano", check: false})

        this.setState({
            zone: array
        })
    }

    private loadBlockFace() {
        var array = Array<any>()
        array.push({value: "P", label: "Par", check: false})
        array.push({value: "I", label: "Ímpar", check: false})
        array.push({value: "N", label: "Nenhum", check: false})

        this.setState({
            blockFace: array
        })
    }

    private loadMain() {
        var array = Array<any>()
        array.push({value: "S", label: "Sim", check: false})
        array.push({value: "N", label: "Não", check: false})

        this.setState({
            main: array
        })
    }

    private loadSelect() {
        this.props.viewModel.fetchSelectProperty().then((rs) => {
            var json = rs.length > 0 ? rs[0] : {} as any
            var keys = Object.keys(json)

            for(var i = 0; i < keys.length; i++) {
                var array = Array<any>()
                var result = json[keys[i]] as Array<any>

                for(var a = 0; a < result.length; a++) {
                    var item = result[a]
                    array.push({value: item.id, label: item.value, check: false})
                }

                switch (keys[i]) {
                    case "gepbairros":
                        this.setState({neighborhood: array})
                        break;
                    case "gepdistrito":
                        this.setState({district: array})
                        break;
                    case "gepzoneamento":
                        this.setState({zoning: array})
                        break;
                }
            }
        })
    }

    private setCity(id: number, value: string) {
        var json = this.state.json as any 
        json['ccidade'] = id 
        json['municipio'] = value

        this.setState({
            json: json
        })
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['cgependereco'] = this.props.idPrimary

        if(this.props.idPerson) {
            json['sgeppessoa'] = this.props.idPerson
        }

        if(this.props.idPoint) {
            json['sgeppontos'] = this.props.idPoint
        }

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0],
                    showButtonDelete: this.props.idPoint ? true : false
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private delete() {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.setState({showLoading: true})
                    this.props.viewModel.fetchDelete(this.state.json).then(() => {
                        this.setState({showLoading: false})
                        toast.success("Registro deletado com sucesso!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                        setTimeout(() => {
                            this.props.delegate.tapDimiss(this.props.navigation);
                        }, 1000);
                    }, e => {
                        this.setState({showLoading: false})
                        toast.error(JSON.stringify(e), {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }
}