import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewControllerDelegate, TableGenericViewController, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import { PropertyBuildingViewModel } from '../../Screen/PropertyBuilding/PropertyBuildingViewModel';
import { PropertyBuildingViewController, PropertyBuildingViewControllerDelegate } from '../../Screen/PropertyBuilding/PropertyBuildingViewController';
import { RealEstateRegistrationViewModel } from '../../Screen/RealEstateRegistration/RealEstateRegistrationViewModel';
import { RealEstateRegistrationViewController } from '../../Screen/RealEstateRegistration/RealEstateRegistrationViewController';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PropertyBuildingCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, PropertyBuildingViewControllerDelegate {
    
    private pointType: string
    constructor() {
        this.pointType = ""
    } 

    getRoute(): JSX.Element[] {
        return [
            this.routeTableBuilding(),
            this.routeFormBuilding(),
            this.routeTableRealEstate(),
            this.routeFormRealEstate()
        ]
    }

    private routeTableBuilding(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocumentsBuilding(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Cadastro Técnico", 
            action: (navigation: any, item: any, component: React.Component) => this.routerRealEstateRegistration(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Processo Administrativo", 
            action: (navigation: any, item: any, component: React.Component) => this.routerAdminProcess(navigation, item),
            isSelection: true
        })
        arrayOptions.push({
            title: "Visitas", 
            action: (navigation: any, item: any, component: React.Component) => this.routerVisit(navigation, item),
            isSelection: true
        })
        
        var viewModel = new TableGenericViewModel("geppropedificacoes")
        return (<ProtectedRoute path="/:base/propriedade/tipo/:type/:idPropriedade/edificacoes" exact={true} component={(props: any) => {
            var idFk = props.match.params.idPropriedade
            var filter = new TableGenericFilter('Propriedade', idFk)
            var pointType = props.match.params.type
            this.pointType = pointType

            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Edificações"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormBuilding(): JSX.Element {
        var viewModel = new PropertyBuildingViewModel()
        return (<ProtectedRoute path="/:base/propriedade/tipo/:type/:idPropriedade/edificacoes/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            var idFk = props.match.params.idPropriedade
            var pointType = props.match.params.type
            this.pointType = pointType

            return (<PropertyBuildingViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idProperty={idFk}
                pointType={pointType}
            />)
        }}/>)
    }

    private routeTableRealEstate(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Arquivos Digitais (Documentos)", 
            action: (navigation: any, item: any, component: React.Component) => this.routerDocumentsRealEstateRegistration(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepcadastroimobiliario")
        return (<ProtectedRoute path="/:base/edificacao/tipo/:type/:idEdificacao/cadastro-imobiliario" exact={true} component={(props: any) => {
            var idFk = props.match.params.idEdificacao
            var filter = new TableGenericFilter('Edificação', idFk)
            var pointType = props.match.params.type
            this.pointType = pointType

            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Cadastro Técnico"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormRealEstate(): JSX.Element {
        var viewModel = new RealEstateRegistrationViewModel()
        return (<ProtectedRoute path="/:base/edificacao/tipo/:type/:idEdificacao/cadastro-imobiliario/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            var idFk = props.match.params.idEdificacao
            var pointType = props.match.params.type
            this.pointType = pointType

            return (<RealEstateRegistrationViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idRealEstate={idFk}
                pointType={pointType}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "geppropedificacoes" && filter) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/propriedade/tipo/${this.pointType}/${filter[0].getValue()}/edificacoes/cad/0`);
            return;
        } else if(viewModel.getTable() == "gepcadastroimobiliario" && filter) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/edificacao/tipo/${this.pointType}/${filter[0].getValue()}/cadastro-imobiliario/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "geppropedificacoes" && filter) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/propriedade/tipo/${this.pointType}/${filter[0].getValue()}/edificacoes/cad/${idSelection}`);
            return;
        } else if(viewModel.getTable() == "gepcadastroimobiliario" && filter) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/edificacao/tipo/${this.pointType}/${filter[0].getValue()}/cadastro-imobiliario/cad/${idSelection}`);
            return;
        }
    }

    private routerDocumentsBuilding(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/edificacao/${item}/arquivos`);
    }

    private routerRealEstateRegistration(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/edificacao/tipo/${this.pointType}/${item}/cadastro-imobiliario`);
    }

    private routerDocumentsRealEstateRegistration(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/cadastro-imobiliario/${item}/arquivos`);
    }

    private routerAdminProcess(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/edificacao/${item}/processo-administrativo`);
    }

    private routerVisit(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/edificacao/${item}/visita`);
    }
}