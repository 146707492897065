import { AddressInteractor, AddressRequest, AddressStructRequest, AddressStructRequestPoint } from "../../Coordinator/Address/AddressInteractor";

export class AddressViewModel {
    private interactor: AddressInteractor;

	constructor() {
		this.interactor = new AddressInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        console.log(obj)
        var request = new AddressRequest(
            obj["cgependereco"] ? parseInt(obj["cgependereco"]) : 0,
            obj["descricao"] ? obj["descricao"] : "",
            obj["logradouro"] ? obj["logradouro"] : "",
            obj["numero"] ? obj["numero"] : "",
            obj["complemento"] ? obj["complemento"] : "",
            obj["ccidade"] ? parseInt(obj["ccidade"]) : 0,
            obj["cep"] ? obj["cep"] : "",
            obj["tipo"] ? obj["tipo"] : "",
            obj["zona"] ? obj["zona"] : "",
            obj["referencia"] ? obj["referencia"] : "",
            obj["principal"] ? obj["principal"] : "",
            obj["sgeppontos"] ? parseInt(obj["sgeppontos"]) : 0,
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0,
            obj["ladologradouro"] ? obj["ladologradouro"] : "",
            obj["sgepbairros"] ? parseInt(obj["sgepbairros"]) : 0,
            obj["sgepdistrito"] ? parseInt(obj["sgepdistrito"]) : 0,
            obj["sgepzoneamento"] ? parseInt(obj["sgepzoneamento"]) : 0,
            obj["identificador"] ? obj["identificador"] : ""
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchSave(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDelete(obj: any): PromiseLike<any> {
        var request = new AddressStructRequest(
            obj["cgependereco"] ? parseInt(obj["cgependereco"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDelete(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        if(obj['sgeppontos']) {
            return this.fetchPoint(obj)
        }

        var request = new AddressStructRequest(
            obj["cgependereco"] ? parseInt(obj["cgependereco"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchPoint(obj: any): PromiseLike<any> {
        var request = new AddressStructRequestPoint(
            obj["sgeppontos"] ? parseInt(obj["sgeppontos"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchPoint(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectProperty(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchSelectAddress().then((result) => {
            return res(result)
            }, e => {
            return rej(e)
            });
        })
    }
}