import { Request } from "../../Servidor/Request";

export class EconActivityLinkRequest {
    private sgeppessoaatividadeseconomicas: number;
    private sgepatividadeseconomicas: number;
    private sgeppessoa: number;

    constructor (sgeppessoaatividadeseconomicas:number, sgepatividadeseconomicas: number, sgeppessoa: number) {
        this.sgeppessoaatividadeseconomicas = sgeppessoaatividadeseconomicas;
        this.sgepatividadeseconomicas = sgepatividadeseconomicas;
        this.sgeppessoa = sgeppessoa;
    }

    /**
     * Getter sgeppessoaatividadeseconomicas
     * @return {number}
     */
    public getSgeppessoaatividadeseconomicas(): number {
        return this.sgeppessoaatividadeseconomicas;
    }

    /**
     * Getter sgepatividadeseconomicas
     * @return {number}
     */
    public getsgepatividadeseconomicas(): number {
        return this.sgepatividadeseconomicas;
    }

    /**
     * Getter sgeppessoa
     * @return {number}
     */
     public getSgeppessoa(): number {
        return this.sgeppessoa;
    }
}

export class EconActivityLinkStructRequest {
    private sgeppessoaatividadeseconomicas: number;

    constructor (sgeppessoaatividadeseconomicas: number){
        this.sgeppessoaatividadeseconomicas = sgeppessoaatividadeseconomicas;
    }

    /**
     * Getter sgeppessoaatividadeseconomicas
     * @return {number}
     */
    public getSgeppessoaatividadeseconomicas(): number {
        return this.sgeppessoaatividadeseconomicas;
    }
}

export class EconActivitySelect {
    private idPessoa: number;

    constructor(idPessoa: number) {
        this.idPessoa = idPessoa;
	}

    /**
     * Getter idPessoa
     * @return {number}
     */
	public getIdPessoa(): number {
		return this.idPessoa;
	}
}

export class EconActivityLinkInteractor {
    public fetchSave(request: EconActivityLinkRequest): PromiseLike<boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("pessoas/atividades-economicas/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }
                res(true) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: EconActivityLinkStructRequest): PromiseLike<EconActivityLinkRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("pessoas/atividades-economicas/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchEconActivity(request: EconActivitySelect): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/atividades-economicas/buscar-select", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}