import { Request } from '../../Servidor/Request';

export class ImobiliaryRegisterRelatoryInteractor {
    public fetchImobiliaryRegisters(filters: Array<any>): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("relatorio/listagem-cadastro-imobiliario", { ...filters }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchImobiliaryRegistersRelatory(filters: Array<any>, pdfName: string): PromiseLike<any> {
        let name = pdfName === 'U' ? 'Urbanas': 'Rurais'
        return new Promise((res, rej) => {
            return new Request().requestPdf("relatorio/listagem-cadastro-imobiliario-pdf", { ...filters }, name).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}