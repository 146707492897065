import { GroupVisitReasonInteractor, GroupVisitReasonRequest, GroupVisitReasonStructRequest } from "../../Coordinator/GroupVisitReason/GroupVisitReasonInteractor";

export class GroupVisitReasonViewModel {
    private interactor: GroupVisitReasonInteractor;

	constructor() {
		this.interactor = new GroupVisitReasonInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new GroupVisitReasonRequest(
            obj["sgepgrupomotivovisita"] ? parseInt(obj["sgepgrupomotivovisita"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new GroupVisitReasonStructRequest(
            obj["sgepgrupomotivovisita"] ? parseInt(obj["sgepgrupomotivovisita"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}