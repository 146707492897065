import { TableGenericViewModel } from "./TableGenericViewModel";
import React from "react";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Col, Image, Form, Button, Table, NavDropdown, Navbar, Nav } from "react-bootstrap";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import fechar from '../../Imagens/close-24.png'; 
import baixo from '../../Imagens/arrow-down-blue-16.png';
import cima from '../../Imagens/arrow-up-blue-16.png';
import filtrar from '../../Imagens/filter-16.png'; 
import adicionar from '../../Imagens/add-16.png';
import editar from '../../Imagens/edit-16.png';
import deletar from '../../Imagens/delete-16.png';
import voltarInicio from '../../Imagens/arrow-left-first-16.png';
import voltar from '../../Imagens/arrow-left-16.png';
import proximo from '../../Imagens/arrow-right-16.png';
import proximoUltimo from '../../Imagens/arrow-right-last-16.png';

import Select from 'react-select';
import "../../Estilo/TableGeneric.css";
import { LoaderComponente } from "../../Components/LoaderComponente";
import { TableGenericFilter } from "../../Coordinator/TableGeneric/TableGenericInteractor";
import { Request } from "../../Servidor/Request";

export interface NavOptions {
    title: string
    action: (navigation: any, item: any, component: React.Component) => void
    isSelection: boolean
    uploadFile?: boolean
    multipleFile?: boolean
}

export type TypePagination = 'first' | 'back' | 'next' | 'last'
interface MyProps {
    navigation: any
    delegate: TableGenericViewControllerDelegate
    viewModel: TableGenericViewModel
    title: String
    options?: Array<NavOptions>
    actions?: Array<NavOptions>
    filter?: Array<TableGenericFilter>
    hiddenMenu?: boolean
    selectedLine?: (item: any) => any
    permissions?: {add?: string, edit?: string, delete?: string}
}

export interface TableGenericViewControllerDelegate {
    tapDimiss(navigation: any): void
    add(navigation: any, viewModel: TableGenericViewModel): void
    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void
}

export class TableGenericViewController extends React.Component<MyProps> {

    private formEvent: FormEvent
    private lineSelection: any
    state = {
        json: {},
        showLoading: false,
        showFilter: false,
        optionsFilter: [],
        headValues: [],
        bodyValues: [],
        filter: Array<TableGenericFilter>(),
        page: 1,
        countPage: 0,
        countLine: 0,
        selectionIndex: 0,
        permissions: {
            add: true,
            edit: true,
            delete: true
        }
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        if(this.props.filter) {
            this.props.viewModel.setFilter(this.props.filter)
        }
        if(this.props.permissions) {
            this.checkPermissions(this.props.permissions)
        }
        this.loadData(this.state.filter, this.state.page)
    }

    checkPermissions(permissions: {add?:string, edit?:string, delete?:string}) {
        let permissionsCheck = [];
        if(permissions.add && permissions.add !== '') {
            permissionsCheck.push(permissions.add);
        }
        if(permissions.edit && permissions.edit !== '') {
            permissionsCheck.push(permissions.edit);
        }
        if(permissions.delete && permissions.delete !== '') {
            permissionsCheck.push(permissions.delete);
        }
            new Request()
				.requestPost("permissoes/checar", {
					permissoes: permissionsCheck,
				})
				.then((rs) => {
                    this.setState({
                        ...this.state,
                        permissions: {
                            add: rs.permissions.hasOwnProperty(permissions.add) ? rs.permissions[permissions.add!!] : true,
                            edit: rs.permissions.hasOwnProperty(permissions.edit) ? rs.permissions[permissions.edit!!] : true,
                            delete: rs.permissions.hasOwnProperty(permissions.delete) ? rs.permissions[permissions.delete!!] : true,
                        }
                    })
				});
    }

    render() {
        var hiddenMenu = this.props.hiddenMenu == true
        return (
            <div>
                {!hiddenMenu &&
                <NavBarComponente propNav={this.props.navigation}/>}
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>{this.props.title}</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Row className="header">
                            {this.generateOptions()}
                            <Col xs="12" className="infoFilter">
                                <h3 onClick={() => this.setState({showFilter: !this.state.showFilter})}>
                                    <Image 
                                    className="iconFilter"
                                    src={this.state.showFilter ? cima : baixo}/>
                                    EXIBIR FILTROS
                                </h3>
                            </Col>
                            {this.state.showFilter &&
                            <Col xs="12" className="containerFilter">
                                <Form>
                                    <Row>
                                        <Col lg="4" xs="12">
                                            <Form.Label>Tipo</Form.Label>
                                            <Select
                                            id="selectFilter"
                                            onChange={(e: any) => this.formEvent.onChange('select', e, "selectFilter")}
                                            closeMenuOnSelect={true}
                                            placeholder={"Selecione"}
                                            options={this.state.optionsFilter}
                                            isMulti={false}
                                            />
                                        </Col>
                                        <Col lg="4" xs="12">
                                            <Form.Label>Pesquisar</Form.Label>
                                            <Form.Control 
                                            id="valueFilter"
                                            onChange={(e: any) => this.formEvent.onChange('varchar', e.target)}
                                            placeholder="Pesquisar"/>
                                        </Col>
                                        <Col lg="4" xs="12">
                                            <Button 
                                            onClick={() => this.filter()}
                                            variant="info" 
                                            type="button" 
                                            className="btnFilter">
                                                <Image
                                                className="iconBtnFilter"
                                                src={filtrar}/>
                                                Filtrar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>}
                            {this.props.viewModel.getTable() == "imagem" &&
                            <Col xs="12" className="containerActions">
                                <Button variant="danger" type="button" className="buttonGeneric colorRed"
                                onClick={() => this.remove()}>
                                    <Image 
                                    className="iconButtonGeneric"
                                    src={deletar}/>
                                    Excluir
                                </Button>
                            </Col>
                            }

                            {this.props.viewModel.getTable() != "imagem" && !hiddenMenu &&
                            <Col xs="12" className="containerActions">
                                {this.state.permissions.add && (
                                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" 
                                    onClick={() => this.props.delegate.add(this.props.navigation, this.props.viewModel)}>
                                        <Image 
                                        className="iconButtonGeneric"
                                        src={adicionar}/>
                                        Adicionar
                                    </Button>
                                )}
                                {this.state.permissions.edit && (
                                    <Button variant="info" type="button" className="buttonGeneric colorGeneric"
                                    onClick={() => this.edit()}>
                                        <Image 
                                        className="iconButtonGeneric"
                                        src={editar}/>
                                        Editar
                                    </Button>
                                )}
                                {this.state.permissions.delete && (
                                    <Button variant="danger" type="button" className="buttonGeneric colorRed"
                                    onClick={() => this.remove()}>
                                        <Image 
                                        className="iconButtonGeneric"
                                        src={deletar}/>
                                        Excluir
                                    </Button>
                                )}
                            </Col>}
                        </Row>
                    </Container>
                    <div className="tableData">
                        <Table responsive={true}>
                            <thead className="tableDataHead">
                                <tr>
                                    {this.state.headValues}
                                </tr>
                            </thead>
                            <tbody className="tableDataBody">
                                {this.state.bodyValues}
                            </tbody>
                        </Table>
                        <ToastContainer />
                    </div>
                    <div className="tableContainerNav">
                        <Container fluid={true}>
                            <Row>
                                <Col xs="12">
                                    <div style={{height: 30}}>
                                        <div style={{float: "right"}}>
                                            <label className="tableCountData">Número de Registros: {this.state.countLine}</label>
                                        </div>
                                        <div style={{float: "left"}}>
                                            <Image 
                                            onClick={() => this.pagination('first')}
                                            style={{marginLeft: 0, cursor: "pointer"}}
                                            src={voltarInicio}
                                            width={16}
                                            className="center"
                                            fluid  />
                                            <Image 
                                            onClick={() => this.pagination('back')}
                                            src={voltar}
                                            className="center imageNav"
                                            fluid  />
                                            <label className="tablePage">{this.state.page}/{this.state.countPage}</label>
                                            <Image
                                            onClick={() => this.pagination('next')}
                                            src={proximo}
                                            className="center imageNav"
                                            fluid  />
                                            <Image 
                                            onClick={() => this.pagination('last')}
                                            src={proximoUltimo}
                                            className="center imageNav"
                                            fluid  />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <LoaderComponente show={this.state.showLoading} hide={() => this.setState({showLoading: this.state.showLoading})}/>
            </div>
        )
    }

    private filter() {
        var filterArray = Array<TableGenericFilter>()
        var json = this.state.json as any 

        if(json['selectFilter'] && json['valueFilter']) {
            if(json['valueFilter'].length > 0) {
                var objFilter = new TableGenericFilter(json['selectFilter'], json['valueFilter'])
                filterArray.push(objFilter)
            }
        }

        this.setState({
            filter: filterArray,
            page: 1
        })

        this.loadData(filterArray, 1)
    }

    private loadData(filter: Array<TableGenericFilter>, page: number) {
        this.setState({showLoading: true})
        this.props.viewModel.fetchData(this.addFilter(filter), page).then((rs) => {
            var head = rs.columns as Array<string>
            var body = rs.data as Array<any>
            var columns = rs.columns as Array<string>
            var headValues = Array<any>()
            var bodyValues = Array<any>()
            var optionsFilter = Array<any>()

            for(var i = 0; i < head.length; i++) {
                headValues.push(<th>{head[i].replace(/"/g, '')}</th>)
            }

            body.forEach((item) => {
                var itens = Array<any>()
                var key = Object.keys(item)
                for(var a = 0; a < key.length; a++) {
                    itens.push(<td>{item[key[a]]}</td>)
                }

                bodyValues.push(<tr onClick={(e: any) => this.selectionItem(item, e)} className="mouseClick">{itens}</tr>)
            });

            for(var i = 0; i < columns.length; i++) {
                optionsFilter.push({value: columns[i], label: columns[i], check: false})
            }

            this.setState({
                showLoading: false,
                headValues: headValues,
                bodyValues: bodyValues,
                optionsFilter: optionsFilter,
                countLine: rs.count,
                countPage: Math.round(rs.count / 10)
            })
        }, e => {
            this.setState({showLoading: false})
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private selectionItem(item: any, line: any) {
        if(item['sequencial']) {
            if(this.lineSelection) {
                this.lineSelection.style.backgroundColor = '#ffffff'
            }

            line.currentTarget.style.backgroundColor = '#eeeeee'
            this.lineSelection = line.currentTarget
            
            if(this.props.selectedLine != undefined) {
                this.props.selectedLine(item)
            }
            
            this.setState({
                selectionIndex: item['sequencial']
            })
        }
    }

    private edit() {
        if(this.state.selectionIndex == 0) {
            return
        }

        this.props.delegate.edit(this.props.navigation, this.state.selectionIndex, this.props.viewModel)
    }

    private remove() {
        if(this.state.selectionIndex == 0) {
            return
        }

        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.setState({showLoading: true})
                    this.props.viewModel.fetchDelete(this.state.selectionIndex).then(() => {
                        if(this.lineSelection) {
                            this.lineSelection.remove()
                            this.lineSelection = null
                        }

                        this.setState({showLoading: false})
                        toast.success("Registro deletado com sucesso!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }, e => {
                        this.setState({showLoading: false})
                        toast.error(JSON.stringify(e), {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private pagination(type: TypePagination) {

        var page = 0
        switch (type) {
            case 'first':
                page = 1
                break;
            case 'back':
                page = this.state.page == 1 ? 1 : this.state.page - 1
                break;
            case 'next':
                page = this.state.page == this.state.countPage ? this.state.countPage : this.state.page + 1
                break;
            case 'last':
                page = this.state.countPage
                break;
        }

        this.setState({
            page: page
        })

        this.loadData(this.state.filter, page)
    }

    private generateOptions() {
        if(this.props.options == undefined && this.props.actions == undefined) {
            return
        }

        var arrayNavOptions = new Array<any>()
        if(this.props.options != undefined) {
            this.props.options!.forEach((item) => {
                arrayNavOptions.push(<NavDropdown.Item onClick={() => this.clickOption(item)}>{item.title}</NavDropdown.Item>);
            });
        }
        
        var arrayNavActions = new Array<any>()
        if(this.props.actions != undefined) {
            this.props.actions!.forEach((item) => {
                arrayNavActions.push(<NavDropdown.Item onClick={() => this.clickAction(item)}>{item.title}</NavDropdown.Item>);
            });
        }

        return (
            <Col xs="12" style={{borderWidth: 1, borderStyle: "solid", borderColor: "#cccccc", background: "linear-gradient(to bottom, #cccccc 0%, #d7d7d7 100%)"}} >
                <Navbar expand="lg">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {arrayNavOptions.length > 0 &&
                            <NavDropdown title="Opções" id="basic-nav-dropdown">
                                {arrayNavOptions}
                            </NavDropdown>}
                            {arrayNavActions.length > 0 &&
                            <NavDropdown title="Ações" id="basic-nav-dropdown">
                                {arrayNavActions}
                            </NavDropdown>}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Col>
            
        )
    }

    private clickOption(item: NavOptions) {
        if(this.state.selectionIndex == 0) {
            return
        }

        item.action(this.props.navigation, this.state.selectionIndex, this)
    }

    private clickAction(item: NavOptions) {
        if(this.state.selectionIndex == 0 && item.isSelection) {
            return
        }

        if(item.uploadFile == true) {
            var input = document.createElement('input');
            input.type = 'file';
            input.multiple = item.multipleFile!;
            input.click();
            input.onchange = (e: any) => {
                this.setState({showLoading: true})
                var json = {
                    id: this.state.selectionIndex
                }

                var formData = new FormData();
                let files    = e.target.files;
                for (var i = 0; i < files.length; i++) {
                    formData.append(`images[${i}]`, files[i])
                }

                var response = {
                    files: files,
                    json: json,
                    viewModel: this.props.viewModel
                }
                
                item.action(this.props.navigation, response, this)
            }
            return
        }

        var response = {
            id: this.state.selectionIndex,
            viewModel: this.props.viewModel
        }

        item.action(this.props.navigation, response, this)
    }

    private addFilter(filter: Array<TableGenericFilter>) {
        var arrayFilter = filter
        if(this.props.filter != undefined) {
            for(var i = 0; i < this.props.filter.length; i++) {
                arrayFilter.push(this.props.filter[i])
            }
        }

        return arrayFilter
    }

}