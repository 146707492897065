import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { BuildingPermitViewController, BuildingPermitViewControllerDelegate } from "../../Screen/BuildingPermit/BuildingPermitViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { BuildingPermitViewModel } from '../../Screen/BuildingPermit/BuildingPermitViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class BuildingPermitCoordinator implements IndexCoordinatorDelegate, BuildingPermitViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepalvaraconstrucao")
        return (<ProtectedRoute path="/:base/alvara-construcao" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Alvará de Construção"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new BuildingPermitViewModel()
        return (<ProtectedRoute path="/:base/alvara-construcao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<BuildingPermitViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepalvaraconstrucao") {
            navigation.history.push(`alvara-construcao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepalvaraconstrucao") {
            navigation.history.push(`alvara-construcao/cad/${idSelection}`);
            return;
        }
    }

}