import { Request } from '../../Servidor/Request';

export class NeighborhoodRequest {
    private sgepbairros: number;
    private nome: string;

	constructor(sgepbairros: number, nome: string) {
		this.sgepbairros = sgepbairros;
		this.nome = nome;
	}


    /**
     * Getter sgepbairros
     * @return {number}
     */
	public getSgepbairros(): number {
		return this.sgepbairros;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class NeighborhoodStructRequest {
    private sgepbairros: number;

	constructor(sgepbairros: number) {
        this.sgepbairros = sgepbairros;
	}


    /**
     * Getter sgepbairros
     * @return {number}
     */
	public getSgepbairros(): number {
		return this.sgepbairros;
	}

}

export class NeighborhoodStructResponse {
    private sgepbairros: number;
    private nome: string;

	constructor(sgepbairros: number, nome: string) {
		this.sgepbairros = sgepbairros;
		this.nome = nome;
	}


    /**
     * Getter sgepbairros
     * @return {number}
     */
	public getSgepbairros(): number {
		return this.sgepbairros;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class NeighborhoodInteractor {
    public fetchSave(request: NeighborhoodRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/bairros/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: NeighborhoodStructRequest): PromiseLike<NeighborhoodStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/bairros/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}