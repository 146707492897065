import React from 'react'
import { ConsolidatedReportViewModel } from './ConsolidatedReportViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Table } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';

interface MyProps {
    navigation: any
    delegate: ConsolidatedReportViewControllerDelegate
    viewModel: ConsolidatedReportViewModel
}

export interface ConsolidatedReportViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class ConsolidatedReportViewController extends React.Component<MyProps> {
    
    private formEvent: FormEvent
    state = {
        isLoading: true,
        json: {},
        layers: [],
        data: Array<any>(),
        total: 0
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadLayers()
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>{this.props.viewModel.getTitle()}</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Camada</Form.Label>
                                    <Select
                                    id="principal"
                                    value={this.formEvent.onValueSelect('select', 'camada', this.state.layers)}
                                    onChange={(e: any) => this.onChangeLayer(e)}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.layers}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Table responsive hover>
                                <thead style={{backgroundColor: "#eeeeee", fontSize: 14}}>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody style={{backgroundColor: "#ffffff", fontSize: 14}}>
                                    {this.state.data}
                                </tbody>
                            </Table>
                            <Row>
                                <Col lg="12" xs="12">
                                    <div style={{float: "right"}}>
                                        <label style={{marginLeft: 10, fontSize: 12, fontWeight: "bold"}}>Quantidade total: {this.state.total}</label>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private loadLayers() {
        this.props.viewModel.fetchSelectLayers().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item._id, label: item.nome, check: false})
            }

            this.setState({
                layers: array,
                isLoading: false 
            })
        }, e => {
            this.setState({
                isLoading: false 
            })
        });
    }

    private onChangeLayer(e: any) {
        this.setState({
            isLoading: true 
        })

        this.props.viewModel.fetchReport(e.value).then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()
            var total = 0

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push(
                    (<tr key={"tr-tabelaDados-" + i} >
                        <td>{item['name']}</td>
                        <td>{item['total']}</td>
                    </tr>)
                )

                total += item['total']
            }

            this.setState({
                isLoading: false,
                data: array,
                total: total
            })
        }, e => {
            this.setState({
                isLoading: false 
            })
        })

        this.formEvent.onChange("select", e, "camada")
    }
}