import React from 'react';
import L from 'leaflet';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

import editar from '../Imagens/edit-16.png';
import ponto from '../Imagens/point-white-16.png';
import linha from '../Imagens/polyline-white-16.png';
import add from '../Imagens/add-16.png';
import poligono from '../Imagens/polygon-white-16.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadowIcon from 'leaflet/dist/images/marker-shadow.png';
import markerRetina from 'leaflet/dist/images/marker-icon-2x.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-editable';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { MdCheck } from 'react-icons/md';

var iconDefault = L.Icon.Default.prototype as any;
delete iconDefault._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerRetina,
    iconUrl: markerIcon,
    shadowUrl: markerShadowIcon
});

interface MyProps {
    getMap: () => any
    startEdit: () => any
    saveEdit: () => any
    cancelEdit: () => any
    stopDraw: () => any
    startAddShape: () => any
}

type tipoDesenho = "ponto" | "linha" | "poligono" | "addShape";
export class DesenharMapaComponente extends React.Component<MyProps>{

    state = {
        isEdit         : false,
        isDesenhoAtivo : false
    }

    componentDidMount(){
        document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', 'margin-top: 20px;');
    }

    render(){
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <div style={{ 
                            minHeight: 10,
                            width: window.innerWidth,
                            backgroundColor: "#424545",
                            position: "relative", 
                            zIndex: 1000, 
                            float: "right"}}>
                                {this.state.isEdit &&
                                <Row>
                                    <Col xs={"12"}>
                                        <Button onClick={() => this.saveEdit()} variant="info" type="button" style={{float: "right", marginTop: 5, marginBottom: 10, marginRight: 5, backgroundColor: "#0080d1", width: 100, height: 30, fontSize: 12}}>
                                            Salvar
                                        </Button>
                                        <Button onClick={() => this.cancelEdit()} variant="info" type="button" style={{float: "right", marginTop: 5, marginBottom: 10, marginRight: 5, backgroundColor: "#424545", width: 140, height: 30, fontSize: 12, borderColor: "#424545"}}>
                                            Desabilitar Edição
                                        </Button>
                                    </Col>
                                </Row>}
                        </div>
                    </Col>
                </Row>
                
                {!this.state.isEdit &&
                <Row>
                    <Col xs={12}>
                        <div style={{ 
                        height: 35,
                        width: 35,
                        marginTop: 10,
                        marginRight: 10,
                        backgroundColor: "#424545",
                        position: "relative", 
                        zIndex: 1000, 
                        float: "right",
                        borderRadius: 5}}>
                            <Image 
                                title={"Habilitar Edição"}
                                onClick={() => this.startEdit()}
                                style={{marginLeft: 10, cursor: "pointer", marginTop: 8}}
                                src={editar}
                                width={16}/>
                        </div>
                    </Col>
                </Row>}
                {this.state.isEdit &&
                <div>
                    <Row>
                        <Col xs={12}>
                            <div style={{ 
                            height: 35,
                            width: 35,
                            marginTop: 10,
                            marginRight: 10,
                            backgroundColor: "#424545",
                            position: "relative", 
                            zIndex: 1000, 
                            float: "right",
                            borderRadius: 5}}>
                                <Image 
                                    onClick={() => this.ativarDesenho("addShape")}
                                    title={"Adicionar Shape"}
                                    style={{marginLeft: 10, cursor: "pointer", marginTop: 8}}
                                    src={add}
                                    width={16}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div style={{ 
                            height: 35,
                            width: 35,
                            marginTop: 10,
                            marginRight: 10,
                            backgroundColor: "#424545",
                            position: "relative", 
                            zIndex: 1000, 
                            float: "right",
                            borderRadius: 5}}>
                                <Image 
                                    onClick={() => this.ativarDesenho("ponto")}
                                    title={"Desenhar Ponto"}
                                    style={{marginLeft: 10, cursor: "pointer", marginTop: 8}}
                                    src={ponto}
                                    width={16}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div style={{ 
                            height: 35,
                            width: 35,
                            marginTop: 10,
                            marginRight: 10,
                            backgroundColor: "#424545",
                            position: "relative", 
                            zIndex: 1000, 
                            float: "right",
                            borderRadius: 5}}>
                                <Image 
                                    onClick={() => this.ativarDesenho("linha")}
                                    title={"Desenhar Linha"}
                                    style={{marginLeft: 10, cursor: "pointer", marginTop: 8}}
                                    src={linha}
                                    width={16}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div style={{ 
                            height: 35,
                            width: 35,
                            marginTop: 10,
                            marginRight: 10,
                            backgroundColor: "#424545",
                            position: "relative", 
                            zIndex: 1000, 
                            float: "right",
                            borderRadius: 5}}>
                                <Image 
                                    onClick={() => this.ativarDesenho("poligono")}
                                    title={"Desenhar Polígono"}
                                    style={{marginLeft: 10, cursor: "pointer", marginTop: 8}}
                                    src={poligono}
                                    width={16}/>
                            </div>
                        </Col>
                    </Row>
                </div>}
            </div>
        )

    }

    private ativarDesenho(tipoDesenho: tipoDesenho){

        if(!this.props.getMap()) return;

        if(this.state.isDesenhoAtivo){
            return;
        }

        // this.props.getMap().on('custom:disable-draw', (e: any) => {
        //     this.setState({
        //         isDesenhoAtivo: false
        //     });
        // });

        if(tipoDesenho == "ponto"){
            this.props.getMap().pm.enableDraw('Marker', {
                tooltips: false
            });
        } else if(tipoDesenho == "linha"){
            this.props.getMap().pm.enableDraw('Line', {
                tooltips: false
            });
        } else if(tipoDesenho == "poligono"){
            this.props.getMap().pm.enableDraw('Polygon', {
                snappable: true,
                tooltips: false
            });
        } else if(tipoDesenho == "addShape") {
            this.props.startAddShape()
            this.props.getMap().pm.enableDraw('Marker', {
                tooltips: false
            });
        }

        this.setState({
            isDesenhoAtivo: true
        });

    }

    private startEdit(){

        if(this.state.isEdit){
            return;
        }

        var styleControl = document.querySelector('.leaflet-left .leaflet-control')?.getAttribute('style');
        document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', `${styleControl} margin-top: 55px;`);
        document.querySelector('#camadas')?.setAttribute('class', `camada-draw`);
        document.querySelector('#procurar')?.setAttribute('class', `procurar-draw`);
        document.querySelector('#relatorio')?.setAttribute('class', `filtros-draw`);
        document.querySelector('#camada-hierarquia')?.setAttribute('class', `camada-hierarquia-draw`);
        this.setState({
            isEdit: !this.state.isEdit
        })

        this.props.startEdit()
    }

    private saveEdit() {
        var styleControl = document.querySelector('.leaflet-left .leaflet-control')?.getAttribute('style');
        document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', `${styleControl} margin-top: 20px;`);
        document.querySelector('#camadas')?.setAttribute('class', `camada`);
        document.querySelector('#procurar')?.setAttribute('class', `procurar`);
        document.querySelector('#relatorio')?.setAttribute('class', `filtros`);
        document.querySelector('#camada-hierarquia')?.setAttribute('class', `camada-hierarquia`);
        this.setState({
            isEdit: !this.state.isEdit
        })

        this.props.startEdit()
        this.props.saveEdit()
    }

    private cancelEdit(){
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja cancelar as modificações?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var styleControl = document.querySelector('.leaflet-left .leaflet-control')?.getAttribute('style');
                    document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', `${styleControl} margin-top: 20px;`);
                    document.querySelector('#camadas')?.setAttribute('class', `camada`);
                    document.querySelector('#camada-hierarquia')?.setAttribute('class', `camada-hierarquia`);
                    this.setState({
                        isEdit: !this.state.isEdit
                    })

                    this.props.cancelEdit()
                    this.props.startEdit()
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        });

    }

}