import { ContactInteractor, ContactRequest, ContactStructRequest} from "../../Coordinator/Contact/ContactInteractor";

export class ContactViewModel {
    private interactor: ContactInteractor;

	constructor() {
		this.interactor = new ContactInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new ContactRequest(
            obj["cgepcontato"] ? parseInt(obj["cgepcontato"]) : 0,
            obj["descricao"] ? obj["descricao"] : "",
            obj["email"] ? obj["email"] : "",
            obj["fone"] ? obj["fone"] : "",
            obj["siteblogfax"] ? obj["siteblogfax"] : "",
            obj["principal"] ? obj["principal"] : "",
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new ContactStructRequest(
            obj["cgepcontato"] ? parseInt(obj["cgepcontato"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}