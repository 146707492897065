import {SchoolYearRequest, SchoolYearStructRequest, SchoolYearInteractor} from "../../Coordinator/SchoolYear/SchoolYearInteractor";

export class SchoolYearViewModel {
    private interactor: SchoolYearInteractor;

    constructor() {
		this.interactor = new SchoolYearInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new SchoolYearRequest(
            obj["sgepanoletivo"] ? parseInt(obj["sgepanoletivo"]) : 0,
            obj["sgepaluno"] ? parseInt(obj["sgepaluno"]) : 0,
            obj["anocalendario"] ? obj["anocalendario"] : "",
            obj["numeromatricula"] ? obj["numeromatricula"] : "",
            obj["datamatricula"] ? obj["datamatricula"] : "",
            obj["sgepescola"] ? parseInt(obj["sgepescola"]) : 0,
            obj["cgepcurso"] ? parseInt(obj["cgepcurso"]) : 0,
            obj["cursoperiodo"] ? obj["cursoperiodo"] : "",
            obj["turno"] ? obj["turno"] : "",
            obj["turma"] ? obj["turma"] : "",
            obj["utilizatransporte"] ? obj["utilizatransporte"] : "",
            obj["cursomodalidade"] ? obj["cursomodalidade"] : "",
            obj["cursodescricao"] ? obj["cursodescricao"] : "",
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new SchoolYearStructRequest(
            obj["sgepanoletivo"] ? parseInt(obj["sgepanoletivo"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSchool(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSchool().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchCourse(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchCourse().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}