import React from "react";
import { DocumentTypeLinkViewModel } from "./DocumentTypeLinkViewModel";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from "../../Imagens/close-24.png"; 
import salvar from "../../Imagens/save-16.png"; 

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';

interface MyProps {
    navigation: any
    delegate: DocumentTypeLinkViewControllerDelegate
    viewModel: DocumentTypeLinkViewModel
    idPrimary: number
    idPerson: number
}

export interface DocumentTypeLinkViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class DocumentTypeLinkViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        docType: Array<any>(),
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadDocType()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Tipo de Documento - Pessoa</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgeppessoatpdoc"
                                    defaultValue={json['sgeppessoatpdoc'] ? json['sgeppessoatpdoc'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Tipo de Documento</Form.Label>
                                    <Select
                                    id="sgeptpdoc"
                                    value={this.formEvent.onValueSelect('select', 'sgeptpdoc', this.state.docType)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptpdoc")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione Tipo de Documento"}
                                    options={this.state.docType}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Valor</Form.Label>
                                    <Form.Control
                                    id="valor"
                                    defaultValue={json['valor'] ? json['valor'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Insira o valor" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private loadDocType() {
        this.props.viewModel.fetchDocType(this.props.idPerson).then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                docType: array
            })
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})
        }
        );
    }

    private save() {
        var json = this.state.json as any
        if(json["valor"] == undefined || json["sgeptpdoc"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["valor"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        json["sgeppessoa"] = this.props.idPerson

        this.setState({isLoading: true})

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgeppessoatpdoc'] = this.props.idPrimary

        this.setState({isLoading: true})

        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })
                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}