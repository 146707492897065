import { Request } from '../../Servidor/Request';

export class ConsolidatedReportInteractor {
    public fetchDistrict(camada: number): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("relatorio/consolidado/distrito", {camada: camada}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchNeighborhood(camada: number): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("relatorio/consolidado/bairro", {camada: camada}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectLayers(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("camada/buscar-camada-ponto", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}