import L from 'leaflet';

L.LatLngControl = L.Control.extend({
    onAdd: function (map) {
        var container = L.DomUtil.create('div', 'coordinates');
        this.latlng = L.DomUtil.create('p', '', container)        

        return container
    },
    update: function(props) {
        this.latlng.innerText = props['lat'] + " | " + props["lng"]
    }
});

L.latLngControl = function(options) {
    return new L.LatLngControl(options);
};