import { PoolInteractor, PoolRequest, PoolStructRequest} from "../../Coordinator/Pool/PoolInteractor";

export class PoolViewModel {
    private interactor: PoolInteractor;

	constructor() {
		this.interactor = new PoolInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PoolRequest(
            obj["sgeppiscina"] ? parseInt(obj["sgeppiscina"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PoolStructRequest(
            obj["sgeppiscina"] ? parseInt(obj["sgeppiscina"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}