import { Request } from "../../Servidor/Request"

export class SchoolYearRequest {
    private sgepanoletivo: number;
    private sgepaluno: number;
    private anocalendario: string;
    private numeromatricula: string;
    private datamatricula: string;
    private sgepescola: number;
    private cgepcurso: number;
    private cursoperiodo: string;
    private turno: string;
    private turma: string;
    private utilizatransporte: string;
    private cursomodalidade: string;
    private cursodescricao: string;

    constructor (sgepanoletivo: number, sgepaluno: number, anocalendario: string, numeromatricula: string, datamatricula: string, sgepescola: number, cgepcurso: number, cursoperiodo: string, turno: string, turma: string, utilizatransporte: string, cursomodalidade: string, cursodescricao: string){
        this.sgepanoletivo = sgepanoletivo;
        this.sgepaluno = sgepaluno;
        this.anocalendario = anocalendario;
        this.numeromatricula = numeromatricula;
        this.datamatricula = datamatricula;
        this.sgepescola = sgepescola;
        this.cgepcurso = cgepcurso;
        this.cursoperiodo = cursoperiodo;
        this.turno = turno;
        this.turma = turma;
        this.utilizatransporte = utilizatransporte;
        this.cursomodalidade = cursomodalidade;
        this.cursodescricao = cursodescricao;
    }

        /**
         * Getter sgepanoletivo
         * @return { number }
         */
        public get $sgepanoletivo(): number {
            return this.sgepanoletivo
        }

        /**
         * Getter sgepaluno
         * @return { number }
         */
        public get $sgepaluno(): number {
            return this.sgepaluno
        }

        /**
         * Getter anocalendario
         * @return { string }
         */
        public get $anocalendario(): string {
            return this.anocalendario
        }

        /**
         * Getter numeromatricula
         * @return { string }
         */
        public get $numeromatricula(): string {
            return this.numeromatricula
        }

        /**
         * Getter datamatricula
         * @return { string }
         */
        public get $datamatricula(): string {
            return this.datamatricula
        }

        /**
         * Getter sgepescola
         * @return { number }
         */
        public get $sgepescola(): number {
            return this.sgepescola
        }

        /**
         * Getter cgepcurso
         * @return { number }
         */
        public get $cgepcurso(): number {
            return this.cgepcurso
        }

        /**
         * Getter cursoperiodo
         * @return { string }
         */
        public get $cursoperiodo(): string {
            return this.cursoperiodo
        }

        /**
         * Getter turno
         * @return { string }
         */
        public get $turno(): string {
            return this.turno
        }

        /**
         * Getter turma
         * @return { string }
         */
        public get $turma(): string {
            return this.turma
        }

        /**
         * Getter utilizatransporte
         * @return { string }
         */
        public get $utilizatransporte(): string {
            return this.utilizatransporte
        }

        /**
         * Getter cursomodalidade
         * @return { string }
         */
        public get $cursomodalidade(): string {
            return this.cursomodalidade
        }

        /**
         * Getter cursodescricao
         * @return { string }
         */
        public get $cursodescricao(): string {
            return this.cursodescricao
        }

}

export class SchoolYearStructRequest {
    private sgepanoletivo: number;

    constructor (sgepanoletivo: number) {
        this.sgepanoletivo = sgepanoletivo;
    }

    /**
         * Getter sgepanoletivo
         * @return { number }
         */
        public get $sgepanoletivo(): number {
            return this.sgepanoletivo
        }
}

export class SchoolYearInteractor {
    public fetchSave(request: SchoolYearRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/anoletivo/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: SchoolYearStructRequest): PromiseLike<SchoolYearRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/anoletivo/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSchool(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/escola/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchCourse(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("aluno/curso/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}