import { Request } from "../../Servidor/Request";

export class FarmProductRequest {
    private sgepprodutosagropecuarios: number;
    private sgeptpprodagropecuario: number
    private nome: string;

    constructor (sgepprodutosagropecuarios:number, sgeptpprodagropecuario: number, nome: string) {
        this.sgepprodutosagropecuarios = sgepprodutosagropecuarios;
        this.sgeptpprodagropecuario = sgeptpprodagropecuario;
        this.nome = nome;
    }

    /**
     * Getter sgepprodutosagropecuarios
     * @return {number}
     */
    public getSgepprodutosagropecuarios(): number {
        return this.sgepprodutosagropecuarios;
    }

    /**
     * Getter sgeptpprodagropecuario
     * @return {number}
     */
    public getSgeptpprodagropecuario(): number {
        return this.sgeptpprodagropecuario;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }
}

export class FarmProductStructRequest {
    private sgepprodutosagropecuarios: number;

    constructor (sgepprodutosagropecuarios: number){
        this.sgepprodutosagropecuarios = sgepprodutosagropecuarios;
    }

    /**
     * Getter sgepprodutosagropecuarios
     * @return {number}
     */
    public getSgepprodutosagropecuarios(): number {
        return this.sgepprodutosagropecuarios;
    }
}

export class FarmProductInteractor {
    public fetchSave(request: FarmProductRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/produto-agropecuario/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: FarmProductStructRequest): PromiseLike<FarmProductRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("basico/produto-agropecuario/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectType(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/tipo-produto-agropecuario/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}

