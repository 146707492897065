import { PropertyBuildingInteractor, PropertyBuildRequest, PropertyBuildStructRequest } from "../../Coordinator/PropertyBuilding/PropertyBuildingInteractor";

export class PropertyBuildingViewModel {
    private interactor: PropertyBuildingInteractor;

	constructor() {
		this.interactor = new PropertyBuildingInteractor();
    }
    
    public fetchPerson(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchPerson().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
    
    public fetchKinship(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchKinship().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PropertyBuildStructRequest(
            obj["sgeppropedificacoes"] ? parseInt(obj["sgeppropedificacoes"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var objProperty = obj['proprietarios'] as Array<any> 
        var objResident = obj['residentes'] as Array<any> 

        var request = new PropertyBuildRequest(
            obj['sgeppropedificacoes'] ? parseInt(obj['sgeppropedificacoes']) : 0,
            obj['sgeppropriedade'] ? parseInt(obj['sgeppropriedade']) : 0,
            obj['descricao'] ? obj['descricao'] : '',
			obj['inscricao'] ? obj['inscricao'] : '',
			obj['sgeptipotumulo'] ? parseInt(obj['sgeptipotumulo']) : 0,
            objProperty,
            objResident
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchTypeOfGrave(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchTypeOfGrave().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}