import { Request } from '../../Servidor/Request';

export class UserGroupRequest {
    private cgrusuario: number;
    private grusuario: string;
    private permissions: Array<number>;

	constructor(cgrusuario: number, grusuario: string, permissions: Array<number>) {
		this.cgrusuario = cgrusuario;
		this.grusuario = grusuario;
        this.permissions = permissions;
	}


    /**
     * Getter cgrusuario
     * @return {number}
     */
	public getCgrusuario(): number {
		return this.cgrusuario;
	}

    /**
     * Getter grusuario
     * @return {string}
     */
	public getGrusuario(): string {
		return this.grusuario;
	}

    /**
     * Getter permissions
     * @return {Array<number>}
     */
	public getPermissions(): Array<number> {
		return this.permissions;
	}    
}

export class UserGroupResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class UserGroupStructRequest {
    private cgrusuario: number;

	constructor(cgrusuario: number) {
        this.cgrusuario = cgrusuario;
	}


    /**
     * Getter cgrusuario
     * @return {number}
     */
	public getCgrusuario(): number {
		return this.cgrusuario;
	}

}

export class UserGroupStructResponse {
    private cgrusuario: number;
    private grusuario: string;
    private permissions: Array<any>;

	constructor(cgrusuario: number, grusuario: string, permissions: Array<any>) {
		this.cgrusuario = cgrusuario;
		this.grusuario = grusuario;
        this.permissions = permissions;
	}


    /**
     * Getter cgrusuario
     * @return {number}
     */
	public getCgrusuario(): number {
		return this.cgrusuario;
	}

    /**
     * Getter grusuario
     * @return {string}
     */
	public getGrusuario(): string {
		return this.grusuario;
	}

    /**
     * Getter permissions
     * @return {Array<any>}
     */
	public getPermissions(): Array<any> {
		return this.permissions;
	}
}

export class PermissionsResponse {
    private sgeppermissao: number;
    private nome: string;

	constructor(sgeppermissao: number, nome: string) {
		this.sgeppermissao = sgeppermissao;
		this.nome = nome;
	}


    /**
     * Getter sgeppermissao
     * @return {number}
     */
	public getSgeppermissao(): number {
		return this.sgeppermissao;
	}

    /**
     * Getter grusuario
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class UserGroupInteractor {
    public fetchSave(request: UserGroupRequest): PromiseLike<UserGroupResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/grupo-usuario/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new UserGroupResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: UserGroupStructRequest): PromiseLike<UserGroupStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/grupo-usuario/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectPermissions(): PromiseLike<PermissionsResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/permissao/buscar-permissoes", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}