import { CourseInteractor, CourseRequest, CourseStructRequest } from "../../Coordinator/Course/CourseInteractor";

export class CourseViewModel {
    private interactor: CourseInteractor;
    
    constructor() {
        this.interactor = new CourseInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new CourseRequest(
            obj["cgepcurso"] ? parseInt(obj["cgepcurso"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["cgepcursogrupo"] ? parseInt(obj["cgepcursogrupo"]) : 0,
            obj["cesus"] ? obj["cesus"] : ""
        )

        return new Promise((res, rej) => {
            this.interactor.fetchSave(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new CourseStructRequest(
            obj["cgepcurso"] ? parseInt(obj["cgepcurso"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

}