import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { VisitReasonViewControllerDelegate, VisitReasonViewController } from "../../Screen/VisitReason/VisitReasonViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { VisitReasonViewModel } from '../../Screen/VisitReason/VisitReasonViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { GroupVisitReasonViewModel } from '../../Screen/GroupVisitReason/GroupVisitReasonViewModel';
import { GroupVisitReasonViewController } from '../../Screen/GroupVisitReason/GroupVisitReasonViewController';

export class GroupVisitReasonCoordinator implements IndexCoordinatorDelegate, VisitReasonViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTableGroupVisitReason(),
            this.routeFormGroupVisitReason()
        ]
    }

    private routeTableGroupVisitReason(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepgrupomotivovisita")
        return (<ProtectedRoute requiredPermissions={['info_bas.grp_mot_visita.*']} path="/:base/grupo-motivo-visita" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.grp_mot_visita.adicionar',
                    edit: 'info_bas.grp_mot_visita.editar',
                    delete: 'info_bas.grp_mot_visita.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Grupo Motivo Visita"}
            />)
        }}/>)
    }

    private routeFormGroupVisitReason(): JSX.Element {
        var viewModel = new GroupVisitReasonViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.grp_mot_visita.adicionar', 'info_bas.grp_mot_visita.editar']} path="/:base/grupo-motivo-visita/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<GroupVisitReasonViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepgrupomotivovisita") {
            navigation.history.push(`grupo-motivo-visita/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepgrupomotivovisita") {
            navigation.history.push(`grupo-motivo-visita/cad/${idSelection}`);
            return;
        }
    }

}