import { Request } from '../../Servidor/Request';

export class TypeOfGraveRequest {
    private sgeptipotumulo: number;
    private nome: string;

	constructor(sgeptipotumulo: number, nome: string) {
		this.sgeptipotumulo = sgeptipotumulo;
		this.nome = nome;
	}


    /**
     * Getter sgeptipotumulo
     * @return {number}
     */
	public getSgeptipotumulo(): number {
		return this.sgeptipotumulo;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class TypeOfGraveStructRequest {
    private sgeptipotumulo: number;

	constructor(sgeptipotumulo: number) {
        this.sgeptipotumulo = sgeptipotumulo;
	}


    /**
     * Getter sgeptipotumulo
     * @return {number}
     */
	public getSgeptipotumulo(): number {
		return this.sgeptipotumulo;
	}

}

export class TypeOfGraveStructResponse {
    private sgeptipotumulo: number;
    private nome: string;

	constructor(sgeptipotumulo: number, nome: string) {
		this.sgeptipotumulo = sgeptipotumulo;
		this.nome = nome;
	}


    /**
     * Getter sgeptipotumulo
     * @return {number}
     */
	public getSgeptipotumulo(): number {
		return this.sgeptipotumulo;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class TypeOfGraveInteractor {
    public fetchSave(request: TypeOfGraveRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("cemiterio/tipo-tumulo/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: TypeOfGraveStructRequest): PromiseLike<TypeOfGraveStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("cemiterio/tipo-tumulo/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}