import React from 'react';
import { Form } from 'react-bootstrap';

interface Option {
    value: string;
    label: string;
}

interface SelectFormProps {
    options: Option[];
    label: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value: string;
}

const SelectComponent: React.FC<SelectFormProps> = ({ options, label, onChange, value }) => {

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control as="select" onChange={onChange} value={value}>
                {!value && (
                    <option key={0} value=''>
                        Selecione...
                    </option>
                )}
                {options.map((option, key) => (
                    <option key={key} value={option.value} selected={option.value === value}>
                        {option.label}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default SelectComponent;
