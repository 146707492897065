import React from "react";
import { Route } from "react-router-dom";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { SpecialNeedsViewController, SpecialNeedsViewControllerDelegate } from "../../Screen/SpecialNeeds/SpecialNeedsViewController";
import { SpecialNeedsViewModel } from "../../Screen/SpecialNeeds/SpecialNeedsViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";


export class SpecialNeedsCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, SpecialNeedsViewControllerDelegate{
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepnecessidadesespecial")
        return (<ProtectedRoute requiredPermissions={['info_bas.necess_especial.*']} path="/:base/necessidade-especial" exact={true} component={(props:any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.necess_especial.adicionar',
                    edit: 'info_bas.necess_especial.editar',
                    delete: 'info_bas.necess_especial.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Necessidades Especiais"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new SpecialNeedsViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.necess_especial.adicionar', 'info_bas.necess_especial.editar']} path="/:base/necessidade-especial/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<SpecialNeedsViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepnecessidadesespecial"){
            navigation.history.push(`necessidade-especial/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepnecessidadesespecial"){
            navigation.history.push(`necessidade-especial/cad/${idSelection}`);
            return;
        }
    }

}