import { GlobalInteractor } from "../../Coordinator/Global/GlobalInteractor";

export class GlobalViewModel {
    private interactor: GlobalInteractor;

    constructor() {
        this.interactor = new GlobalInteractor();
    }

    public fetchDataToSelectInput(tabela: string, colunaPk: string): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchDataToSelectInput(tabela,colunaPk).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
}