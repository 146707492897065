import { Request } from '../../Servidor/Request';

export class LayerPermissionStructRequest {
    private grusuario: Array<number>;


	constructor(grusuario: Array<number>) {
		this.grusuario = grusuario;
	}


    /**
     * Getter grusuario
     * @return {Array<number>}
     */
	public getGrusuario(): Array<number> {
		return this.grusuario;
	}

}

export class UserGroupResponse {
    private cgrusuario: number;
    private grusuario: string;

	constructor(cgrusuario: number, grusuario: string) {
		this.cgrusuario = cgrusuario;
		this.grusuario = grusuario;
	}


    /**
     * Getter cgrusuario
     * @return {number}
     */
	public getCgrusuario(): number {
		return this.cgrusuario;
	}

    /**
     * Getter grusuario
     * @return {string}
     */
	public getGrusuario(): string {
		return this.grusuario;
	}
}

export class LayerPermissionRequest {
    private scamada: Array<number>;
    private susuariogrupo: Array<number>;


	constructor(scamada: Array<number>, susuariogrupo: Array<number>) {
        this.scamada = scamada;
        this.susuariogrupo = susuariogrupo;
	}


    /**
     * Getter scamada
     * @return {Array<number>}
     */
	public getScamada(): Array<number> {
		return this.scamada;
	}


    /**
     * Getter susuariogrupo
     * @return {Array<number>}
     */
	public getSusuariogrupo(): Array<number> {
		return this.susuariogrupo;
	}

}

export class LayerPermissionInteractor {
    public fetchLayers(request: LayerPermissionStructRequest): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost('permissoes/buscarCamadas', request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e)
            });
        });
    }

    public fetchSelectUserGroup(): PromiseLike<UserGroupResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/usuario/buscar-grupo-usuario", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSave(request: LayerPermissionRequest): PromiseLike<boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("permissoes/salvar-camada-permissoes", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(true)
            }, e => {
                rej(e);
            });
        });
    }
}