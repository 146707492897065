import { Request } from '../../Servidor/Request';

export class AddressRequest {
    private cgependereco: number;
    private descricao: string;
    private logradouro: string;
    private numero: string;
    private complemento: string;
    private ccidade: number;
    private cep: string;
    private tipo: string;
    private zona: string;
    private referencia: string;
    private principal: string;
    private sgeppontos: number;
    private sgeppessoa: number;
    private ladologradouro: string;
    private sgepbairros: number;
    private sgepdistrito: number;
    private sgepzoneamento: number;
    private identificador: string;

    constructor (
        cgependereco: number,
        descricao: string,
        logradouro: string,
        numero: string,
        complemento: string,
        ccidade: number,
        cep: string,
        tipo: string,
        zona: string,
        referencia: string,
        principal: string,
        sgeppontos: number,
        sgeppessoa: number,
        ladologradouro: string,
        sgepbairros: number,
        sgepdistrito: number,
        sgepzoneamento: number,
        identificador: string 
    ) {
        this.cgependereco = cgependereco;
        this.descricao = descricao;
        this.logradouro = logradouro;
        this.numero = numero;
        this.complemento = complemento;
        this.ccidade = ccidade;
        this.cep = cep;
        this.tipo = tipo;
        this.zona = zona;
        this.referencia = referencia;
        this.principal = principal;
        this.sgeppontos = sgeppontos;
        this.sgeppessoa = sgeppessoa;
        this.ladologradouro = ladologradouro;
        this.sgepbairros = sgepbairros;
        this.sgepdistrito = sgepdistrito;
        this.sgepzoneamento = sgepzoneamento;
        this.identificador = identificador;
    }


    /**
     * Getter $cgependereco
     * @return {number}
     */
	public get $cgependereco(): number {
		return this.cgependereco;
	}

    /**
     * Getter $descricao
     * @return {string}
     */
	public get $descricao(): string {
		return this.descricao;
	}

    /**
     * Getter $logradouro
     * @return {string}
     */
	public get $logradouro(): string {
		return this.logradouro;
	}

    /**
     * Getter $numero
     * @return {string}
     */
	public get $numero(): string {
		return this.numero;
	}

    /**
     * Getter $complemento
     * @return {string}
     */
	public get $complemento(): string {
		return this.complemento;
	}

    /**
     * Getter $ccidade
     * @return {number}
     */
	public get $ccidade(): number {
		return this.ccidade;
	}

    /**
     * Getter $cep
     * @return {string}
     */
	public get $cep(): string {
		return this.cep;
	}

    /**
     * Getter $tipo
     * @return {string}
     */
	public get $tipo(): string {
		return this.tipo;
	}

    /**
     * Getter $zona
     * @return {string}
     */
	public get $zona(): string {
		return this.zona;
	}

    /**
     * Getter $referencia
     * @return {string}
     */
	public get $referencia(): string {
		return this.referencia;
	}

    /**
     * Getter $principal
     * @return {string}
     */
	public get $principal(): string {
		return this.principal;
	}

    /**
     * Getter $sgeppontos
     * @return {number}
     */
	public get $sgeppontos(): number {
		return this.sgeppontos;
	}

    /**
     * Getter $sgeppessoa
     * @return {number}
     */
	public get $sgeppessoa(): number {
		return this.sgeppessoa;
	}

    /**
     * Getter $ladologradouro
     * @return {string}
     */
	public get $ladologradouro(): string {
		return this.ladologradouro;
	}

    /**
     * Getter $sgepbairros
     * @return {number}
     */
	public get $sgepbairros(): number {
		return this.sgepbairros;
	}

    /**
     * Getter $sgepdistrito
     * @return {number}
     */
	public get $sgepdistrito(): number {
		return this.sgepdistrito;
	}

    /**
     * Getter $sgepzoneamento
     * @return {number}
     */
	public get $sgepzoneamento(): number {
		return this.sgepzoneamento;
	}

    /**
     * Getter $identificador
     * @return {string}
     */
	public get $identificador(): string {
		return this.identificador;
	}
}

export class AddressStructRequest {
    private cgependereco: number;

	constructor(cgependereco: number) {
        this.cgependereco = cgependereco;
    }
    

    /**
     * Getter $cgependereco
     * @return {number}
     */
	public get $cgependereco(): number {
		return this.cgependereco;
	}
}

export class AddressStructRequestPoint {
    private sgeppontos: number;

    constructor(sgeppontos: number) {
        this.sgeppontos = sgeppontos;
    }


    /**
     * Getter $sgeppontos
     * @return {number}
     */
	public get $sgeppontos(): number {
		return this.sgeppontos;
	}
}

export class AddressSelectResponse {
    private id: number;
    private value: string;


	constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
	}


    /**
     * Getter id
     * @return {number}
     */
	public getId(): number {
		return this.id;
	}

    /**
     * Getter value
     * @return {string}
     */
	public getValue(): string {
		return this.value;
	}

}

export class AddressInteractor {
    public fetchSave(request: AddressRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/endereco/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDelete(request: AddressStructRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/endereco/remover", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: AddressStructRequest): PromiseLike<AddressRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/endereco/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchPoint(request: AddressStructRequestPoint): PromiseLike<AddressRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/endereco/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectAddress(): PromiseLike<Array<AddressSelectResponse>> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/endereco/select/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}