import { Request } from '../../Servidor/Request';

export class GroupVisitReasonRequest {
    private sgepgrupomotivovisita: number;
    private nome: string;

	constructor(sgepgrupomotivovisita: number, nome: string) {
		this.sgepgrupomotivovisita = sgepgrupomotivovisita;
		this.nome = nome;
	}



    /**
     * Getter sgepgrupomotivovisita
     * @return {number}
     */
	public getSgepgrupomotivovisita(): number {
		return this.sgepgrupomotivovisita;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
    
}

export class GroupVisitReasonResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class GroupVisitReasonStructRequest {
    private sgepgrupomotivovisita: number;

	constructor(sgepgrupomotivovisita: number) {
        this.sgepgrupomotivovisita = sgepgrupomotivovisita;
	}



    /**
     * Getter sgepgrupomotivovisita
     * @return {number}
     */
	public getSgepgrupomotivovisita(): number {
		return this.sgepgrupomotivovisita;
	}
}

export class GroupVisitReasonStructResponse {
    private sgepgrupomotivovisita: number;
    private nome: string;

	constructor(sgepgrupomotivovisita: number, nome: string) {
		this.sgepgrupomotivovisita = sgepgrupomotivovisita;
		this.nome = nome;
	}

    /**
     * Getter sgepgrupomotivovisita
     * @return {number}
     */
	public getSgepgrupomotivovisita(): number {
		return this.sgepgrupomotivovisita;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class GroupVisitReasonInteractor {
    public fetchSave(request: GroupVisitReasonRequest): PromiseLike<GroupVisitReasonResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/grupo-motivo-visita/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new GroupVisitReasonResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: GroupVisitReasonStructRequest): PromiseLike<GroupVisitReasonStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/grupo-motivo-visita/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}