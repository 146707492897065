import { Request } from '../../Servidor/Request';

export class ZoningRequest {
    private sgepzoneamento: number;
    private nome: string;

	constructor(sgepzoneamento: number, nome: string) {
		this.sgepzoneamento = sgepzoneamento;
		this.nome = nome;
	}


    /**
     * Getter sgepzoneamento
     * @return {number}
     */
	public getSgepzoneamento(): number {
		return this.sgepzoneamento;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class ZoningStructRequest {
    private sgepzoneamento: number;

	constructor(sgepzoneamento: number) {
        this.sgepzoneamento = sgepzoneamento;
	}


    /**
     * Getter sgepzoneamento
     * @return {number}
     */
	public getSgepzoneamento(): number {
		return this.sgepzoneamento;
	}

}

export class ZoningStructResponse {
    private sgepzoneamento: number;
    private nome: string;

	constructor(sgepzoneamento: number, nome: string) {
		this.sgepzoneamento = sgepzoneamento;
		this.nome = nome;
	}


    /**
     * Getter sgepzoneamento
     * @return {number}
     */
	public getSgepzoneamento(): number {
		return this.sgepzoneamento;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class ZoningInteractor {
    public fetchSave(request: ZoningRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/zoneamento/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: ZoningStructRequest): PromiseLike<ZoningStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/zoneamento/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}