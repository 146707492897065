import React from 'react';
import { Route} from 'react-router-dom'
import { LoginViewControllerDelegate, LoginViewController } from "../../Screen/Login/LoginViewController";
import { LoginViewModel } from '../../Screen/Login/LoginViewModel';
import { IndexCoordinatorDelegate } from '../IndexCoordinator';

export class LoginCoordinator implements IndexCoordinatorDelegate, LoginViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        var array = Array<JSX.Element>()
        var loginRoute = (<Route path="/:base/login" exact={true} component={(props: any) => {
            return (<LoginViewController navigation={props} viewModel={new LoginViewModel()} delegate={this}/>)
        }}/>)

        array.push(loginRoute)
        return array
    }


    tapMapInitial(navigation: any, token: String) {
        navigation.history.push('mapa-inicial')
    }
}