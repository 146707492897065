import { Request } from "../../Servidor/Request";
export class UploadFilesRequest {
    private tabela: string;
    private registro: number;

	constructor(tabela: string, registro: number) {
		this.tabela = tabela;
		this.registro = registro;
	}


    /**
     * Getter tabela
     * @return {string}
     */
	public getTabela(): string {
		return this.tabela;
	}

    /**
     * Getter registro
     * @return {number}
     */
	public getRegistro(): number {
		return this.registro;
	}
    
}

export class UploadStructRequest {
    private simagem: number;

	constructor(simagem: number) {
        this.simagem = simagem
	}


    /**
     * Getter simagem
     * @return {number}
     */
	public getSimagem(): number {
		return this.simagem;
	}

}

export class UploadMainStruct {
    private simagem: number;
    private tabela: string;
    private registro: number;

    constructor(simagem: number, tabela: string, registro: number) {
        this.simagem = simagem
        this.tabela = tabela
        this.registro = registro
    }

     /**
     * Getter simagem
     * @return {number}
     */
	public getSimagem(): number {
		return this.simagem;
	}

     /**
     * Getter tabela
     * @return {string}
     */
	public getTabela(): string {
		return this.tabela;
	}

    /**
     * Getter registro
     * @return {number}
     */
	public getRegistro(): number {
		return this.registro;
	}
}

export class UploadFilesInteractor {
    public fetchDownloadDocument(request: UploadFilesRequest): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("imagens/baixar-documento", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchImportDocuments(request: FormData): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestFile("imagens/importar-documentos", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Status == "Sucesso")
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: UploadStructRequest): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("imagens/buscar-imagem", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public saveMain(request: UploadMainStruct): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("imagens/update-principal", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(true)
            }, e => {
                rej(e);
            });
        });
    }
}