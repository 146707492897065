import { PropertyBuildingInteractor, RealEstateRegistrationRequest, RealEstateRegistrationStructRequest } from "../../Coordinator/PropertyBuilding/PropertyBuildingInteractor";
import moment from 'moment';

export class RealEstateRegistrationViewModel {
    private interactor: PropertyBuildingInteractor;

	constructor() {
		this.interactor = new PropertyBuildingInteractor();
    }

    public fetchBuildingPermit(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchBuildingPermit().then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchRealEstateListItens(tipoorigem: string): PromiseLike<Array<any>> {
        return new Promise((res, rej) => {
            this.interactor.fetchRealEstateListItens(tipoorigem).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchSave(obj: any): PromiseLike<Boolean> {
        var request = new RealEstateRegistrationRequest(
            obj['sgepcadastroimobiliario'] ? parseInt(obj['sgepcadastroimobiliario']) : 0,
            obj['sgeppropedificacoes'] ? parseInt(obj['sgeppropedificacoes']) : 0,
            obj['datainicial'] ? moment(obj["datainicial"], 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            obj['datafinal'] ? moment(obj["datafinal"], 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            obj['inscricaomunicipal'] ? obj['inscricaomunicipal'] : '',
            obj['valoredificacao'] ? parseFloat(obj['valoredificacao']) : 0.0,
            obj['numeroalvara'] ? obj['numeroalvara'] : '',
            obj['dataalvara'] ? moment(obj["dataalvara"], 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            obj['sgepalvaraconstrucao'] ? parseInt(obj['sgepalvaraconstrucao']) : 0,
            obj['descricao'] ? obj['descricao'] : '',
            obj['sgeptipoedificacao'] ? parseInt(obj['sgeptipoedificacao']) : 0,
            obj['observacao'] ? obj['observacao'] : '',
            obj['itens']
        )

        return new Promise((res, rej) => {
            this.interactor.fetchSaveRealEstateRegistration(request).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> { 
        var request = new RealEstateRegistrationStructRequest(
            obj["sgepcadastroimobiliario"] ? parseInt(obj["sgepcadastroimobiliario"]) : 0
        )

        return new Promise((res, rej) => {
			this.interactor.fetchRealEstateRegistrationDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchBuildingType(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchBuildingType().then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }
}