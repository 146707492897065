import { Request } from "../../Servidor/Request";

export class CustodyTypeRequest {
    private sgeptpresponsavel: number;
    private nome: string;
    private cesus: string;

    constructor (sgeptpresponsavel:number, nome: string, cesus: string){
        this.sgeptpresponsavel = sgeptpresponsavel;
        this.nome = nome;
        this.cesus = cesus;
    }

    /**
     * Getter sgeptpresponsavel
     * @return {number}
     */
    public getSgeptpresponsavel(): number {
        return this.sgeptpresponsavel;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }

    /**
     * Getter cesus
     * @return {string}
     */
    public getCesus(): string {
        return this.cesus;
    }
}
export class CustodyTypeStructRequest {
    private sgeptpresponsavel: number;

    constructor (sgeptpresponsavel:number){
        this.sgeptpresponsavel = sgeptpresponsavel;
    }

    /**
     * Getter sgeptpresponsavel
     * @return {number}
     */
    public getSgeptpresponsavel(): number {
        return this.sgeptpresponsavel;
    }
}

export class CustodyTypeInteractor {
    public fetchSave(request: CustodyTypeRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/tipo-responsavel/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: CustodyTypeStructRequest): PromiseLike<CustodyTypeRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("basico/tipo-responsavel/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
