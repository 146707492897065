import React from "react";
import ProtectedRoute from "../../Components/ProtectedRoute";
import {
	MapInitialViewControllerDelegate,
	MapInitialViewController,
} from "../../Screen/MapInitial/MapInitialViewController";
import { MapInitialViewModel } from "../../Screen/MapInitial/MapInitialViewModel";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";

export class MapInitialCoordinator
	implements IndexCoordinatorDelegate, MapInitialViewControllerDelegate
{
	public getRoute(): Array<JSX.Element> {
		var array = Array<JSX.Element>();
		var loginRoute = (
			<ProtectedRoute
				requiredPermissions={['gis.mapa_inicial.ver']}
				path="/:base/mapa-inicial"
				exact={true}
				component={(props: any) => {
					return (
						<MapInitialViewController
							navigation={props}
							viewModel={new MapInitialViewModel()}
							delegate={this}
						/>
					);
				}}
			/>
		);

		array.push(loginRoute);
		return array;
	}
}
