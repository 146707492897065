import { MarkerImageInteractor, MarkerImageRequest, MarkerImageStructRequest } from "../../Coordinator/MarkerImage/MarkerImageInteractor";

export class MarkerImageViewModel {
    private interactor: MarkerImageInteractor;

	constructor() {
		this.interactor = new MarkerImageInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new MarkerImageRequest(
            obj["smarcadorimagem"] ? parseInt(obj["smarcadorimagem"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["tipo"] ? obj["tipo"] : "",
            obj["smarcadorimagemicone"] ? parseInt(obj["smarcadorimagemicone"]) : 0,
            obj["smarcadorimagemiconedados"] ? parseInt(obj["smarcadorimagemiconedados"]) : 0,
            obj["smarcadorimagemiconeclick"] ? parseInt(obj["smarcadorimagemiconeclick"]) : 0,
            obj["smarcadorimagemiconefiltro"] ? parseInt(obj["smarcadorimagemiconefiltro"]) : 0,
            obj["preeenchimento"] ? obj["preeenchimento"] : "",
            obj["preeenchimentoclick"] ? obj["preeenchimentoclick"] : "",
            obj["preenchimentofiltro"] ? obj["preenchimentofiltro"] : "",
            obj["opacidade"] ? parseFloat(obj["opacidade"]) : 0,
            obj["opacidadeborda"] ? parseFloat(obj["opacidadeborda"]) : 0,
            obj["borda"] ? obj["borda"] : "",
            obj["bordaclick"] ? obj["bordaclick"] : "",
            obj["bordafiltro"] ? obj["bordafiltro"] : "",
            obj["largura"] ? parseFloat(obj["largura"]) : 0,
            obj["raio"] ? parseFloat(obj["raio"]) : 0
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new MarkerImageStructRequest(
            obj["smarcadorimagem"] ? parseInt(obj["smarcadorimagem"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectIcon(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectIcon().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}