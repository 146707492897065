import { Request } from "../../Servidor/Request";

export class DocumentTypeRequest {
    private sgeptpdoc: number;
    private tipo: boolean;
    private nome: string;

    constructor (sgeptpdoc:number, tipo: boolean, nome: string) {
        this.sgeptpdoc = sgeptpdoc;
        this.tipo = tipo;
        this.nome = nome;
    }

    /**
     * Getter sgeptpdoc
     * @return {number}
     */
    public getSgeptpdoc(): number {
        return this.sgeptpdoc;
    }

    /**
     * Getter tipo
     * @return {boolean}
     */
    public getTipo(): boolean {
        return this.tipo;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }
}

export class DocumentTypeStructRequest {
    private sgeptpdoc: number;

    constructor (sgeptpdoc: number){
        this.sgeptpdoc = sgeptpdoc;
    }

    /**
     * Getter sgeptpdoc
     * @return {number}
     */
    public getSgeptpdoc(): number {
        return this.sgeptpdoc;
    }
}

export class DocumentTypeInteractor {
    public fetchSave(request: DocumentTypeRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/tipo-documento/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: DocumentTypeStructRequest): PromiseLike<DocumentTypeRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("basico/tipo-documento/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}