import React from 'react';
import {MapInitialViewModel} from "./MapInitialViewModel"
import { MapaComponente } from '../../Components/MapaComponente';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { ShapeComponente, Action } from '../../Components/ShapeComponente';
import { confirmAlert } from 'react-confirm-alert';
import { ModalAttributesShapeComponente } from '../../Components/ModalAttributesShapeComponente';
import {GeoJSON, Icon} from 'leaflet';
import { ToastContainer, toast } from 'react-toastify';
import { AddShapeModalComponente } from '../../Components/AddShapeModalComponente';
import moment from 'moment';

interface MyProps {
    navigation: any
    delegate: MapInitialViewControllerDelegate,
    viewModel: MapInitialViewModel
}

export interface MapInitialViewControllerDelegate {
}

type Crud = "add" | "edit" | "delete"
export interface Shape {
    type: Crud
    geojson: any 
    sshape: number
    scamada?: number
    layer?: any 
}

export class MapInitialViewController extends React.Component<MyProps>{

    private layerClick:any;
    private layerCreated: any;
    private layerAfterEdit: any;
    private styleLayerClick: any;
    private startEditShape: boolean;
    private arrayDraw: Array<Shape>;
    private map: any;
    private mapComponent?: MapaComponente
    private drawShape: Boolean
    private addShapeModalComponent?: AddShapeModalComponente

    state = {
        showInfoShape: false,
        typeShape: "",
        atributesShape: {},
        startEdit: false,
        showModalAttributes: false,
        showAddShape: false
    }

    constructor(props: any) {
        super(props)
        this.startEditShape = false
        this.arrayDraw = new Array<Shape>()
        this.drawShape = false
    }

    componentDidMount() {
        this.eventKeyboard()
        if(localStorage.getItem('layers') != null && this.mapComponent != null) {
            let obj = JSON.parse(localStorage.getItem('layers')!)
            this.mapComponent.setLayersAtivos(obj['layers'])
        }   
    }

    private eventKeyboard() {
        document.addEventListener("keyup", (ev) => {
            if(this.map.pm.globalRemovalModeEnabled() && ev.keyCode == 13) {
                this.map.pm.disableGlobalDragMode()
            }

            if(this.startEditShape && ev.keyCode == 13) {
                this.setState({
                    showModalAttributes: true
                })
            }

            if(this.layerCreated && ev.keyCode  == 13) {
                this.setState({
                    showModalAttributes: true
                })
            }

            if(this.state.startEdit && ev.keyCode == 46) {
                if(this.startEditShape) {
                    this.layerClick.disableEdit()
                    this.startEditShape = false
                }

                this.deleteShape()
            }
        });
    }

    private deleteShape() {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse shape?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var geojson = this.layerClick.feature
                    if(geojson['properties']['sshape']) {
                        this.arrayDraw.push({
                            type: "delete",
                            geojson: {},
                            sshape: geojson['properties']['sshape']
                        })
                    } else {
                        for(var i = 0; i < this.arrayDraw.length; i++) {
                            var item = this.arrayDraw[i]
                            if(item.geojson == geojson) {
                                this.arrayDraw.splice(i, 1)
                                break
                            }
                        }
                    }
                    

                    this.layerClick.remove()
                    this.layerClick = null
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    render() {
        var attributes = this.state.atributesShape as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                {this.state.showInfoShape && 
                <ShapeComponente 
                    sshape={attributes && attributes['sshape'] ? attributes['sshape'] : 0}
                    typeShape={this.state.typeShape}
                    attributes={attributes} 
                    startEdit={this.state.startEdit}
                    clickAction={(action, sshape) => this.clickActionInfoShape(action, sshape)}
                    clickLink={(link) => this.openLink(link)}
                    close={() => this.setState({showInfoShape: false})} 
                />}
                {this.state.showModalAttributes &&
                <ModalAttributesShapeComponente 
                show={this.state.showModalAttributes} 
                layer={this.layerCreated ? this.layerCreated : this.layerClick}
                clickHide={(isSave: boolean, obj: any) => this.clickActionsModal(isSave, obj)} />
                }
                <MapaComponente 
                    ref={(e: any) => this.mapComponent = e}
                    height={window.innerHeight - 80} 
                    isDraw={true} 
                    isCamada={true} 
                    isSearch={true}
                    isFilter={true}
                    getClickShape={(e: any) => this.clickShape(e)}
                    getMap={(map: any) => this.setMap(map)}
                    saveEdit={() => this.saveEdit()}
                    startEdit={() => this.startEdit()}
                    cancelEdit={() => this.cancelEdit()}
                    stopDraw={() => this.stopDraw()}
                    startDrawShape={() => this.drawShape = true}
                    clickRight={(e: any) => this.clickRight(e)} />
                <AddShapeModalComponente 
                ref={(ref: any) => this.addShapeModalComponent = ref}
                show={this.state.showAddShape} 
                clickHide={(isShow: boolean, json: any) => this.saveCancelDrawVector(isShow, json)}/>
                <ToastContainer />
            </div>
        )
    }

    private saveCancelDrawVector(isSave: boolean, json: any) {
        this.drawShape = false
        this.map.fire('custom:disable-draw')
        this.map.pm.disableDraw()

        if(this.layerCreated) {
            this.layerCreated.remove()
        }
        
        if(!isSave) {
            this.setState({
                showAddShape: false
            })

            return
        }

        let layer = this.mapComponent?.getLayerJSONSingle(json) as any 
        if(this.layerClick) {
            json['properties'] = this.layerClick.feature.properties
            layer.feature = json
        }
        
        this.saveByVertex(layer)
        this.setState({
            showAddShape: false
        })
    }

    private saveByVertex(layer: any) {
        if(this.layerCreated) {
            this.layerCreated = layer 
            this.setState({
                showModalAttributes: true
            })

            return
        }

        this.layerClick.remove()
        this.map.addLayer(layer)

        var obj = layer.toGeoJSON()['features'][0]['properties']
        if(obj['sshapetemp']) {
            for(var i = 0; i < this.arrayDraw.length; i++) {
                var item = this.arrayDraw[i].geojson as any 
                if(item['properties']) {
                    if(item['properties']['sshapetemp'] == obj['sshapetemp']) {
                        this.arrayDraw.splice(i, 1)
                        break
                    }
                }
            }
        }

        this.arrayDraw.push({
            type: "edit",
            geojson: layer.toGeoJSON()['features'][0],
            sshape: obj['sshape'] ? obj['sshape'] : 0,
            scamada: obj.scamada
        })

        this.startEditShape = false
    }

    private openEditDrawVector() {
        var coordinates = new Array<any>()
        var geom = this.layerClick.feature.geometry as any
        var typeGeom = ""

        if(geom['type'] == 'Polygon') {
            typeGeom = 'P'
            var geomCoordinates = geom['coordinates'][0] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        } else if(geom['type'] == 'MultiPolygon') {
            typeGeom = 'P'
            var geomCoordinates = geom['coordinates'][0][0] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        } else if(geom['type'] == 'MultiLineString') {
            typeGeom = 'L'
            var geomCoordinates = geom['coordinates'][0] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        } else if(geom['type'] == 'LineString') {
            typeGeom = 'L'
            var geomCoordinates = geom['coordinates'] as Array<any>
            geomCoordinates.forEach((item) => {
                coordinates.push({
                    lat: item[1],
                    lng: item[0]
                })
            })
        }

        if(this.addShapeModalComponent) {
            this.addShapeModalComponent.refreshCoordinator(coordinates, typeGeom)
        }

        this.setState({
            showAddShape: true
        })
    }

    private stopDraw() {
        if(this.startEditShape) {
            this.setState({showModalAttributes: true})
        }
    }

    private clickRight(e: any) {
        if(this.state.startEdit) {
            return
        }
        
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja criar um ponto aqui?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var latlng = e.latlng
                    this.props.navigation.history.push(`ponto/latitude/${latlng.lat}/longitude/${latlng.lng}`)
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private clickActionsModal(isSave: boolean, obj: any) {
        this.map.fire('custom:disable-draw')
        
        if(this.layerClick) {
            this.map.pm.disableDraw()
        }

        if(!isSave && this.layerClick) {
            this.layerClick.remove()
            var layerCancel = this.mapComponent?.getLayerJSONSingle(this.layerAfterEdit) as any
            this.map.addLayer(layerCancel)

            this.setState({
                showInfoShape: false
            })
        }

        if(this.layerCreated) {
            this.map.pm.disableDraw()
        }

        if(!isSave && this.layerCreated) {
            this.layerCreated.remove()
        }

        if(isSave && this.layerClick) {
            this.layerClick.feature.properties = obj
            this.layerClick.redraw()

            if(obj['sshapetemp']) {
                for(var i = 0; i < this.arrayDraw.length; i++) {
                    var item = this.arrayDraw[i].geojson as any 
                    if(item['properties']) {
                        if(item['properties']['sshapetemp'] == obj['sshapetemp']) {
                            this.arrayDraw.splice(i, 1)
                            break
                        }
                    }
                }
            }

            let layer = this.layerClick.toGeoJSON()
            layer['properties'] = obj

            this.layerClick.remove()
            var layerUpdate = this.mapComponent?.getLayerJSONSingle(layer) as any
            this.map.addLayer(layerUpdate)

            this.arrayDraw.push({
                type: "edit",
                geojson: layer,
                sshape: obj['sshape'] ? obj['sshape'] : 0,
                scamada: obj.scamada
            })
        }

        if(isSave && this.layerCreated) {
            this.layerCreated.remove()
            var geojson = this.layerCreated.toGeoJSON()
            if(geojson['properties'] == undefined) {
                geojson = geojson['features'][0]
            }
            
            geojson['properties'] = obj
            geojson['properties']['sshapetemp'] = `id-${new Date().getTime()}` 

            var layer = new GeoJSON(geojson, {
                onEachFeature: (feature, layer) => {
                    layer.on({
                        click: this.clickShape.bind(this)
                    })
                }
            });

            this.map.addLayer(layer)
            this.arrayDraw.push({
                type: "add",
                geojson: geojson,
                sshape: 0,
                scamada: obj.scamada,
                layer: layer
            })

            this.layerCreated = null
        }

        this.startEditShape = false
        this.setState({
            showModalAttributes: false
        })
    }

    private clickShape(e: any){
        if(this.startEditShape) {
            return
        }

        var layer = e.target as any;
        if(this.layerClick && this.styleLayerClick){
            this.layerClick.disableEdit()
            if(this.styleLayerClick instanceof Icon) {
                this.layerClick.setIcon(this.styleLayerClick)
            } else {
                this.layerClick.setStyle(this.styleLayerClick);
            }
        }

        this.layerClick = layer;
        this.layerAfterEdit = layer.toGeoJSON()
        if(e.target.options.style) {
            this.styleLayerClick = e.target.options.style;
            var estilo = {
                fillColor : e.target.options.style.fillColorClick,
                color     : e.target.options.style.colorClick
            }

            layer.setStyle(estilo);
        }

        if(e.target.options.icon) {
            this.styleLayerClick = e.target.options.icon
            let geometryStyle = e.target.feature.style
            if(geometryStyle['iconeClick']) {
                let icon = new Icon({
                    iconUrl: geometryStyle['iconeClick']
                })

                layer.setIcon(icon)
            }
        }

        this.storageInfoMap()
        this.setState({
            showInfoShape: true,
            typeShape: layer.feature.geometry.type,
            atributesShape: layer.feature.properties
        })
    }

    private storageInfoMap() {
        var latlng = this.map._lastCenter
        let zoom = this.map._zoom

        if(latlng && zoom) {
            localStorage.setItem('latitude', latlng.lat)
            localStorage.setItem('longitude', latlng.lng)
            localStorage.setItem('zoom', zoom)
        }

        if(this.mapComponent) {
            var layers = this.mapComponent?.getLayersAtivos()
            var keys = Object.keys(layers)
            localStorage.setItem('layers', JSON.stringify({layers: keys}))
        }

        
    }

    private setMap(map: any){
        map.on('pm:create', (e: any) => {
            this.layerCreated = e.layer
            if(this.drawShape) {
                var coordinates = [{
                    lat: this.layerCreated._latlng['lat'],
                    lng: this.layerCreated._latlng['lng']
                }]

                if(this.addShapeModalComponent) {
                    this.addShapeModalComponent.refreshCoordinator(coordinates, '')
                }

                this.setState({
                    showAddShape: true
                })
                return
            }
            
            this.setState({
                showModalAttributes: true
            })
        })

        map.on('pm:cut', (e: any) => {
            this.layerClick = e.layer 
            this.layerClick['feature']['properties'] = e.originalLayer['feature']['properties']
            this.setState({
                showModalAttributes: true
            })
        })

        this.map = map
    }

    private startEdit() {
        this.setState({
            startEdit: !this.state.startEdit
        })
    }

    private clickActionInfoShape(action: Action, sshape: number) {
        var database = localStorage.getItem('database')
        let attributes = this.state.atributesShape as any 
        switch (action) {
            case "addPoint":
                if(attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/ponto/cad/${attributes['sshape']}`)
                }
                break;
            case "editPoint":
                if(attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/ponto/cad/${attributes['sshape']}`)
                }
                break;
            case "deletePoint":
                if(attributes['sshape']) {
                    this.deletePoint(attributes['sshape'])
                }
                break;
            case "editShape":
                this.startEditShape = true;
                this.layerClick.pm.enable();
                break;
            case "editShapeCoordinator":
                this.openEditDrawVector()
                break;
            case "cutShape":
                this.startEditShape = true;
                this.map.pm.enableDraw('Cut', {
                    tooltips: false
                });
                break;
            case "moveShape":
                this.startEditShape = true;
                this.map.pm.toggleGlobalDragMode();
                break;
            case "attributesShape":
                this.setState({
                    showModalAttributes: true
                })
                break;
            case "deleteShape":
                this.deleteShape()
                break;
            case "visitPoint":
                if(attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/visita`)
                } else if(attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/shape/${attributes['sshape']}/visita`)
                }
                break;
            case "property":
                if(attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/propriedade/tipo/${attributes['tipo']}`)
                }
                break;
            case "address":
                if(attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/endereco`)
                }
                break;
            case "adminProcess":
                if(attributes['sshape']) {
                    this.props.navigation.history.push(`/${database}/shape/${attributes['sshape']}/processo-administrativo`)
                }
                break;
            case "imagem":
                if(attributes['sgeppontos']) {
                    this.props.navigation.history.push(`/${database}/ponto/${attributes['sgeppontos']}/arquivos`)
                }
                break;
            case "export":
                this.exportPDF(attributes['sshape'])
                break;
            default:
                break;
        }
    }

    private openLink(link: string) {
        let database = localStorage.getItem('database')
        this.props.navigation.history.push(`/${database}/${link}`)
    }

    private deletePoint(sshape: number) {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.props.viewModel.fetchRemovePoint({sshape: sshape}).then(() => {
                        this.layerClick.remove()
                        this.layerClick = null

                        toast.success('Registro deletado com sucesso!', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                        this.setState({
                            showInfoShape: false
                        })
                    }, e => {
                        toast.error(JSON.stringify(e), {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private cancelEdit() {
        if(this.layerClick) {
            this.layerClick.disableEdit()
        }

        if(this.layerCreated) {
            this.layerCreated.disableEdit()
        }

        
        this.startEditShape = false
        window.location.reload()
    }

    private saveEdit() {
        this.props.viewModel.fetchSaveDraw(this.arrayDraw).then(() => {
            window.location.reload()
        }, e => {
            this.arrayDraw.forEach((item) => {
                if(item.layer) {
                    item.layer!.remove()
                }
            })

            this.arrayDraw  = []
            this.mapComponent?.reloadShapesCamadas(false)

            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private exportPDF(sshape: number) {
        this.mapComponent?.setLoading(true)
        this.props.viewModel.fetchMapPDF(sshape).then((rs) => {
            let property = rs.filter((x: any) => x['key'] == 'propriedade')[0]['values'] as Array<any>
            let building = rs.filter((x: any) => x['key'] == 'edificacoes')[0]['values'] as Array<any>
            let person = rs.filter((x: any) => x['key'] == 'pessoas')[0]['values'] as Array<any>
            let address = rs.filter((x: any) => x['key'] == 'endereco')[0]['values'] as Array<any>
            let shape = rs.filter((x: any) => x['key'] == 'shape')[0]['values'] as Array<any>
            let attributes = rs.filter((x: any) => x['key'] == 'atributos')[0]['values'] as Array<any>
            let visit = rs.filter((x: any) => x['key'] == 'visitas')[0]['values'] as Array<any>
            let processAdm = rs.filter((x: any) => x['key'] == 'processoAdm')[0]['values'] as Array<any>

            var footerPDF = this.getAtributesShapePDF(true, attributes, visit, processAdm)
            if(property.length > 0) {
                footerPDF = this.getFooterPDF(property, building, person, address, attributes, visit, processAdm)
            }

            this.mapComponent?.setShapeLegend(shape)
            this.mapComponent?.exportPDF('Relatório em Mapa', footerPDF)
        }, e => {
            this.mapComponent?.setLoading(false)
            toast.error("Erro interno!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private getFooterPDF(property: Array<any>, building: Array<any>, person: Array<any>, address: Array<any>, attributes: Array<any>, visit: Array<any>, processAdm: Array<any>): string {
        var htmlContentProperty = ``
        var propertyItens = property[0]['itens'] as Array<any>
        if(propertyItens.length > 0) {
            htmlContentProperty = this.getTableItemValue(propertyItens)
        }

        var htmlContentBuilding = ``
        if(building.length > 0) {
            htmlContentBuilding = `<h3 style="margin-bottom: 20px; margin-top: 50px;">Dados das Edificações</h3>`
            building.forEach((item) => {
                let buildingItens = item['itens'] as Array<any>
                var htmlContentBuildingItens = ``
                if(buildingItens.length > 0) {
                    htmlContentBuildingItens = this.getTableItemValue(buildingItens)
                }

                htmlContentBuilding += `
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col-2">Data Cadastro</th>
                                    <th scope="col-6">Inscrição Imobiliária</th>
                                    <th scope="col-2">Total</th>
                                    <th scope="col-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>${this.getFormatData(item['anoinicial'])} - ${this.getFormatData(item['anofinal'])}</td>
                                    <td>${item['inscricao'] == null ? 'Sem informação' : item['inscricao']}</td>
                                    <td>${property[0]['total']}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                ${htmlContentBuildingItens}
                ${this.getTablePerson(person, item['id'], 'edificacao', 'P')}
                ${this.getTablePerson(person, item['id'], 'edificacao', 'R')}
                ${this.getVisitShapePDF('Visitas', 'edificacao', visit)}
                ${this.getVisitShapePDF('Processos Administrativo', 'edificacao', processAdm)}
                `
            })
        }

        let footer = `
        <div class="container-fluid" style="margin-top: 50px;">
            ${this.getAtributesShapePDF(true, attributes, visit, processAdm)}
            ${this.getTableAddress(address)}
            ${this.getVisitShapePDF('Visitas', 'ponto', visit)}
            ${this.getVisitShapePDF('Processos Administrativo', 'ponto', processAdm)}
            <h3 style="margin-bottom: 20px; margin-top: 50px;">Dados da Propriedade</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-2">Data Cadastro</th>
                                <th scope="col-6">Inscrição Imobiliária</th>
                                <th scope="col-2">Total</th>
                                <th scope="col-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${this.getFormatData(property[0]['anoinicial'])} - ${this.getFormatData(property[0]['anofinal'])}</td>
                                <td>${property[0]['inscricao'] == null ? 'Sem informação' : property[0]['inscricao']}</td>
                                <td>${property[0]['total']}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            ${htmlContentProperty}
            ${this.getTablePerson(person, property[0]['id'], 'propriedade', 'P')}
            ${this.getTablePerson(person, property[0]['id'], 'propriedade', 'R')}
            ${this.getVisitShapePDF('Visitas', 'propriedade', visit)}
            ${this.getVisitShapePDF('Processos Administrativo', 'propriedade', processAdm)}
            ${htmlContentBuilding}
        </div>        
        `

        return footer
    }

    private getAtributesShapePDF(hasProperty: boolean, list: Array<any>, visit: Array<any>, processAdm: Array<any>): string {
        let layerName = list.filter((x: any) => x['key'] == 'Nome da Camada Geográfica')
        let layerType =  list.filter((x: any) => x['key'] == 'Tipo da Camada Geográfica')
        let layerFilter = list.filter((x: any) => x['key'] != 'Nome da Camada Geográfica' && x['key'] != 'Tipo da Camada Geográfica')

        var outputItens = ``
        layerFilter.forEach((item) => {
            outputItens += `<tr>
                                <td>${item['key']}</td>
                                <td>${item['value']}</td>
                            </tr>`
        })

        let output = `
            <h3 style="margin-bottom: 20px; margin-top: 50px;">Dados Geográfico</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${layerName[0]['key']}</td>
                                <td>${layerName[0]['value']}</td>
                            </tr>
                             <tr>
                                <td>${layerType[0]['key']}</td>
                                <td>${layerType[0]['value']}</td>
                            </tr>
                            ${outputItens}
                        </tbody>
                    </table>
                </div>
            </div>
        `

        if(!hasProperty) {
            return `
                <div class="container-fluid" style="margin-top: 50px;">
                    ${output}
                    ${this.getVisitShapePDF('Visitas', 'ponto', visit)}
                    ${this.getVisitShapePDF('Processos Administrativo', 'ponto', processAdm)}
                </div>
            `
        }
        
        return output
    }

    private getFormatData(data: string): string {
        return moment(data).format('DD/MM/YYYY')
    }

    private getTableItemValue(list: Array<any>): string {
        var itensProperty = ``
        list.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td scope="row">${index + 1}</td>
                                    <td>${item['item']}</td>
                                    <td>${item['valor']}</td>
                                </tr>`
        })

        return `
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-2">#</th>
                                <th scope="col-4">Item</th>
                                <th scope="col-6">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private getTablePerson(list: Array<any>, id: number, table: string, grpType: string): string {
        let listFilter = list.filter((x: any) => x['id'] == id && x['tabela'] == table && x['tipo'] == grpType)
        if(listFilter.length == 0) {
            return ''
        }

        var itensProperty = ``
        listFilter.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td scope="row">${index + 1}</td>
                                    <td>${item['nome']}</td>
                                    <td>${item['parentesco'] == null ? 'Não informado' : item['parentesco']}</td>
                                    <td>${item['principal']}</td>
                                </tr>`
        })

        return `
            <h3 style="margin-bottom: 20px;">${grpType == 'P' ? 'Proprietários' : 'Residentes'}</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-3">#</th>
                                <th scope="col-3">Nome</th>
                                <th scope="col-3">Parentesco</th>
                                <th scope="col-3">Principal</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private getTableAddress(list: Array<any>): string {
        if(list.length == 0) {
            return ''
        }

        var itensProperty = ``
        list.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td>${item['logradouro']}</td>
                                    <td>${item['numero'] == null ? '' : item['numero']}</td>
                                    <td>${item['cep'] == null ? '' : item['cep']}</td>
                                    <td>${item['bairro'] == null ? '' : item['bairro']}</td>
                                    <td>${item['distrito'] == null ? '' : item['distrito']}</td>
                                </tr>`
        })

        return `
            <h3 style="margin-bottom: 20px;">Endereço</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Logradouro</th>
                                <th scope="col">Número</th>
                                <th scope="col">CEP</th>
                                <th scope="col">Bairro</th>
                                <th scope="col">Distrito</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    private getVisitShapePDF(title: string, type: string, visit: Array<any>): string {
        let listFilter = visit.filter((x) => x['tabela'] == type)
        if(listFilter.length == 0) {
            return ''
        }

        var itensProperty = ``
        listFilter.forEach((item: any, index: number) => {
            itensProperty += `  <tr>
                                    <td scope="row">${index + 1}</td>
                                    <td>${item['data']}</td>
                                    <td>${item['profissional']}</td>
                                </tr>`
        })

        return `
            <h3 style="margin-bottom: 20px;">${title}</h3>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-3">#</th>
                                <th scope="col-3">Data</th>
                                <th scope="col-3">Profissional</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itensProperty}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }
}
