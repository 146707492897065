import React from 'react';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { LocationInitialViewControllerDelegate, LocationInitialViewController } from "../../Screen/LocationInitial/LocationInitialViewController";
import { LocationInitialViewModel } from '../../Screen/LocationInitial/LocationInitialViewModel';
import { IndexCoordinatorDelegate } from '../IndexCoordinator';

export class LocationInitialCoordinator implements IndexCoordinatorDelegate, LocationInitialViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        return [
            this.routeForm()
        ]
    }

    private routeForm(): JSX.Element {
        var viewModel = new LocationInitialViewModel()
        return (<ProtectedRoute requiredPermissions={['config.localizacao_inicial.ver']} path="/:base/localizacao-inicial" exact={true} component={(props: any) => {
            return (<LocationInitialViewController navigation={props} viewModel={viewModel} delegate={this}/>)
        }}/>)
    } 

    tapDimiss(navigation: any): void {
        navigation.history.goBack()
    }
}