import React from 'react';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { MdSearch, MdAdd } from 'react-icons/md';

import "../Estilo/InputModalSelect.css"
import { TableGenericViewController, TableGenericViewControllerDelegate } from '../Screen/TableGeneric/TableGenericViewController';
import { TableGenericViewModel } from '../Screen/TableGeneric/TableGenericViewModel';

interface MyProps {
    id: string
    keyValue: string
    label: string
    required: Boolean
    placeholder: string
    showAddButton: Boolean
    navigation: any
    titleModal: string
    table: string
    valueDefault?: string
    getValue: (id: number, value: string) => any
    tapAdd?: () => any
    clearValue?: boolean
    setClearValue?: () => void
}

export class InputModalSelectComponent extends React.Component<MyProps> implements TableGenericViewControllerDelegate {

    state = {
        showModal: false,
        value: ''
    }

    render() {
        let viewModel = new TableGenericViewModel(this.props.table)
        let className = this.props.required ? 'fieldRequired' : ''
        let value = this.state.value.length == 0 && this.props.valueDefault != undefined ? this.props.valueDefault : this.state.value

        if (this.props.clearValue && this.props.clearValue === true) {
            this.state.value = ''
            value = ''
            this.props.setClearValue!()
        }

        return (
            <div>
                <Row>
                    <Col xs="10">
                        <Form.Label className={className}>
                            {this.props.label}
                            {this.props.showAddButton &&
                                <MdAdd size={15} className="buttonAdd" onClick={() => this.clickAdd()} />}
                        </Form.Label>
                        <Form.Control
                            id={this.props.id}
                            disabled={true}
                            defaultValue={value}
                            placeholder={this.props.placeholder}>
                        </Form.Control>
                    </Col>
                    <Col xs="1">
                        <Button className="buttonSearch" onClick={() => this.setState({ showModal: true })}>
                            <MdSearch size={25} />
                        </Button>
                    </Col>
                </Row>
                <Modal show={this.state.showModal} dialogClassName="modal-full-screen">
                    <Modal.Body>
                        <TableGenericViewController
                            navigation={this.props.navigation}
                            viewModel={viewModel}
                            delegate={this}
                            title={this.props.titleModal}
                            hiddenMenu={true}
                            selectedLine={(item: any) => this.selectLine(item)}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    private clickAdd() {
        if (this.props.tapAdd != undefined) {
            this.props.tapAdd()
        }
    }

    private selectLine(item: any) {
        if (item[this.props.keyValue]) {
            this.props.getValue(item['sequencial'], item[this.props.keyValue])
            this.setState({
                value: item[this.props.keyValue],
                showModal: false
            })
        }
    }

    tapDimiss(navigation: any): void {
        this.setState({
            showModal: false
        })
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
    }

}