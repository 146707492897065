import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { DistrictViewControllerDelegate, DistrictViewController } from "../../Screen/District/DistrictViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { DistrictViewModel } from '../../Screen/District/DistrictViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class DistrictCoordinator implements IndexCoordinatorDelegate, DistrictViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepdistrito")
        return (<ProtectedRoute requiredPermissions={['prop.distrito.*']} path="/:base/distrito" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'prop.distrito.adicionar',
                    edit: 'prop.distrito.editar',
                    delete: 'prop.distrito.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Distrito"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new DistrictViewModel()
        return (<ProtectedRoute requiredPermissions={['prop.distrito.adicionar', 'prop.distrito.editar']} path="/:base/distrito/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<DistrictViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepdistrito") {
            navigation.history.push(`distrito/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepdistrito") {
            navigation.history.push(`distrito/cad/${idSelection}`);
            return;
        }
    }

}