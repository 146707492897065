import React from 'react'
import { PropertyBuildingViewModel } from './PropertyBuildingViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Tabs, Tab, Table, Modal } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 
import adicionar from '../../Imagens/add-16.png';
import editar from '../../Imagens/edit-16.png';
import deletar from '../../Imagens/delete-16.png';

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { PersonViewControllerDelegate, PersonViewController } from '../Person/PersonViewController';
import { PersonViewModel } from '../Person/PersonViewModel';
import { confirmAlert } from 'react-confirm-alert';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';

interface MyProps {
    navigation: any
    delegate: PropertyBuildingViewControllerDelegate
    viewModel: PropertyBuildingViewModel
    idPrimary: number
    idProperty: number
    pointType: string
}

export interface PropertyBuildingViewControllerDelegate {
    tapDimiss(navigation: any): void
}

type form = 'property' | 'resident'
export class PropertyBuildingViewController extends React.Component<MyProps> implements PersonViewControllerDelegate {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        tabKey: "main",
        person: new Array<any>(),
        main: new Array<any>(),
        kinship: new Array<any>(),
        typeGrave: new Array<any>(),
        showFormProperty: false,
        showFormResident: false,
        showAddPerson: false,
        arrayProperty: new Array<any>(),
        arrayResident: new Array<any>(),
        indexSelectProperty: -1,
        indexSelectResident: -1,
        personEdit: null
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadMain()
        this.loadTypeOfGrave()
        this.setKeyFK()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Edificação</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col xs="12">
                                    <Tabs 
                                    id="controlled-tabs"
                                    activeKey={this.state.tabKey}
                                    onSelect={(key: any) => this.setTab(key)}>
                                        <Tab eventKey="main" title="Edificação">
                                            <Row className="tab-item-container">
                                                <Col lg="4" xs="12" className="bottom10 hide">
                                                    <Form.Label>Sequencial</Form.Label>
                                                    <Form.Control
                                                    id="sgeppropedificacoes"
                                                    defaultValue={json['sgeppropedificacoes'] ? json['sgeppropedificacoes'] : ''}
                                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                                    />
                                                </Col>
                                            </Row>
                                            {this.props.pointType == 'C' &&
                                            <Row>
                                                <Col lg="4" xs="12" className="bottom10">
                                                    <Form.Label>Inscrição</Form.Label>
                                                    <Form.Control
                                                    id="inscricao"
                                                    defaultValue={json['inscricao'] ? json['inscricao'] : ''}
                                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                    placeholder="Digite a inscrição" 
                                                    />
                                                </Col>
                                                <Col lg="4" xs="12" className="bottom10">
                                                    <Form.Label className="isRequired">Tipo</Form.Label>
                                                    <Select
                                                    id="sgeptipotumulo"
                                                    value={this.formEvent.onValueSelect('select', 'sgeptipotumulo', this.state.typeGrave)}
                                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptipotumulo")}
                                                    closeMenuOnSelect={true}
                                                    placeholder={"Selecione"}
                                                    options={this.state.typeGrave}
                                                    isMulti={false}
                                                    />
                                                </Col>    
                                            </Row>}
                                            <Row>
                                                <Col xs="12" className="bottom10">
                                                    <Form.Label>Descrição</Form.Label>
                                                    <Form.Control
                                                    id="descricao"
                                                    as="textarea" 
                                                    rows="3"
                                                    defaultValue={json['descricao'] ? json['descricao'] : ''}
                                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                    placeholder="Digite uma descrição" 
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="property" title="Proprietários">
                                            {this.getTable('property')}
                                        </Tab>
                                        <Tab eventKey="resident" title={this.props.pointType == 'C' ? 'Sepultados' : 'Residentes'}>
                                            {this.getTable('resident')}
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        {this.getModalProperty()}
                        {this.getModalResident()}
                        {this.getModalAddPerson()}
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setKeyFK() {
        var json = this.state.json as any
        if(this.props.idProperty) {
            json['sgeppropriedade'] = this.props.idProperty
        }

        this.setState({
            json: json
        })
    }

    private setTab(key: string) {
        this.setState({
            tabKey: key
        })
    }

    private getTable(form: form) {
        return (
        <Row className="tab-item-container">
            <Col xs="12" className="containerActions">
                <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setShow(form, true)}>
                    <Image 
                    className="iconButtonGeneric"
                    src={adicionar}/>
                    Adicionar
                </Button>
                <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setEdit(form)}>
                    <Image 
                    className="iconButtonGeneric"
                    src={editar}/>
                    Editar
                </Button>
                <Button variant="danger" type="button" className="buttonGeneric colorRed" onClick={() => this.setDelete(form)}>
                    <Image 
                    className="iconButtonGeneric"
                    src={deletar}/>
                    Excluir
                </Button>
            </Col>
            <Col xs="12">
                <div className="tableData">
                    <Table responsive={true}>
                        <thead className="tableDataHead">
                            <tr>
                                <th>Nome</th>
                                <th>Principal</th>
                            </tr>
                        </thead>
                        <tbody className="tableDataBody">
                            {this.getContainerTable(form)}
                        </tbody>
                    </Table>
                </div>
                <div className="tableContainerNav">
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <div style={{height: 10}}></div>
                            </Col> 
                        </Row> 
                    </Container>
                </div>
            </Col>
        </Row>)
    }

    private getContainerTable(form: form) {
        var container = new Array<any>()
        var array = this.state.arrayProperty
        if(form == 'resident') {
            array = this.state.arrayResident
        }

        array.forEach((item, index) => {
            container.push((
                <tr 
                key={`${form}-${index}`} 
                style={{backgroundColor: this.changeColorLine(form, index)}}
                onClick={() => this.selectLine(form, index)}>
                    <td>{item.nome}</td>
                    <td>{item.principal}</td>
                </tr>
            ))
        })

        return container
    }

    private selectLine(form: form, index: number) {
        if(form == 'property') {
            this.setState({
                indexSelectProperty: index
            })
        } else {
            this.setState({
                indexSelectResident: index
            })
        }
    }

    private changeColorLine(form: form, index: number): string {
        if(form == 'property') {
            return this.state.indexSelectProperty == index ? '#eeeeee' : '#ffffff'
        } else {
            return this.state.indexSelectResident == index ? '#eeeeee' : '#ffffff'
        }
    }

    private setShow(form: form, isClearJSON: Boolean) {
        if(isClearJSON) {
            this.clearJSON()
        }
        
        if(form == 'property') {
            var show = this.state.showFormProperty
            this.setState({
                showFormProperty: !show
            })
        } else {
            var show = this.state.showFormResident
            this.setState({
                showFormResident: !show
            })
        }
    }

    private setEdit(form: form) {
        var index = form == 'property' ? this.state.indexSelectProperty : this.state.indexSelectResident
        var array = form == 'property' ? this.state.arrayProperty : this.state.arrayResident
        if(index == -1) {
            return
        }

        if(array[index]) {
            var json = this.state.json as any 
            json = Object.assign(json, array[index])
            json['indexSelected'] = index 

            this.setState({json: json, personEdit: array[index].sgeppessoa})
            this.setShow(form, false)
        }
    }

    private setDelete(form: form) {
        var index = form == 'property' ? this.state.indexSelectProperty : this.state.indexSelectResident
        var array = form == 'property' ? this.state.arrayProperty : this.state.arrayResident
        if(index == -1) {
            return
        }

        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    array.splice(index, 1)
                    if(form == 'property') {
                        this.setState({
                            arrayProperty: array,
                            indexSelectProperty: -1
                        })
                    } else {
                        this.setState({
                            arrayResident: array,
                            indexSelectResident: -1
                        })
                    }
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private showModal(form: form) {
        if(form == 'resident') {
            this.loadKinship()
        }
    }

    private getModalProperty() {
        var json = this.state.json as any
        return (
            <Modal show={this.state.showFormProperty} onShow={() => this.showModal('property')}>
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{fontSize: "20px", fontWeight: "bold"}}>Cadastro Proprietário</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                id="sgepgrupopessoaparente"
                                defaultValue={json['sgepgrupopessoaparente'] ? json['sgepgrupopessoaparente'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <InputModalSelectComponent 
                                id="sgeppessoa"
                                keyValue="nome"
                                label="Pessoa"
                                required={true}
                                placeholder="Procure"
                                showAddButton={true}
                                navigation={this.props.navigation}
                                titleModal="Pessoas"
                                table="geppessoas"
                                valueDefault={json['nome']}
                                getValue={(id: number, value: string) => this.setIdPerson(id, value)}
                                tapAdd={() => this.setState({showAddPerson: true})} />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Principal</Form.Label>
                                <Select
                                id="principal"
                                value={this.formEvent.onValueSelect('select', 'principal', this.state.main)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, "principal")}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.main}
                                isMulti={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.saveProperty()}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.setShow('property', false)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                    <ToastContainer />
                </Modal.Body>
            </Modal>
        )
    }

    private getModalResident() {
        var json = this.state.json as any
        return (
            <Modal show={this.state.showFormResident} onShow={() => this.showModal('resident')}>
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{fontSize: "20px", fontWeight: "bold"}}>Cadastro Residente</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                id="sgepgrupopessoaparente"
                                defaultValue={json['sgepgrupopessoaparente'] ? json['sgepgrupopessoaparente'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <InputModalSelectComponent 
                                id="sgeppessoa"
                                keyValue="nome"
                                label="Pessoa"
                                required={true}
                                placeholder="Procure"
                                showAddButton={true}
                                navigation={this.props.navigation}
                                titleModal="Pessoas"
                                table="geppessoas"
                                getValue={(id: number, value: string) => this.setIdPerson(id, value)}
                                tapAdd={() => this.setState({showAddPerson: true})} />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Principal</Form.Label>
                                <Select
                                id="principal"
                                value={this.formEvent.onValueSelect('select', 'principal', this.state.main)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, "principal")}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.main}
                                isMulti={false}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Parentesco com o principal</Form.Label>
                                <Select
                                id="cgepgrauparentesco"
                                value={this.formEvent.onValueSelect('select', 'cgepgrauparentesco', this.state.kinship)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, "cgepgrauparentesco")}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.kinship}
                                isMulti={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.saveResident()}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.setShow('resident', false)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    private setIdPerson(id: number, value: string) {
        var json = this.state.json as any 
        json['sgeppessoa'] = id 
        json['nome'] = value

        this.setState({
            json: json
        })
    }

    private getModalAddPerson() {
        let viewModel = new PersonViewModel()
        return (
            <Modal show={this.state.showAddPerson} dialogClassName="modal-full-screen">
                <Modal.Body>
                    <PersonViewController 
                    navigation={this.props.navigation} 
                    delegate={this} 
                    idPrimary={0} 
                    viewModel={viewModel}
                    isShowHeader={false}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    private loadMain() {
        var array = Array<any>()
        array.push({value: 'S', label: 'Sim', check: false})
        array.push({value: 'N', label: 'Não', check: false})

        this.setState({
            main: array
        })
    }

    private loadKinship() {
        this.props.viewModel.fetchKinship().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                kinship: array
            })
        });
    }

    private saveProperty() {
        var json = this.state.json as any 
        var array = this.state.arrayProperty as Array<any>

        if(!json['sgeppessoa'] || !json['principal']) {
            this.showFieldsEmpty('Todos os campos devem ser preenchidos!')
            return
        }

        if(this.state.personEdit != json['sgeppessoa'] && array.filter((x) => x['sgeppessoa'] == json['sgeppessoa']).length > 0) {
            this.showFieldsEmpty('Pessoa já vinculada!')
            return
        }

        var copyJSON = Object.assign({}, json)
        if(json['indexSelected'] >= 0) {
            array[json['indexSelected']] = copyJSON
        } else {
            array.push(copyJSON)
        }

        this.setState({
            arrayProperty: array,
            showFormProperty: false,
            personEdit: null
        })
    }

    private saveResident() {
        var json = this.state.json as any 
        var array = this.state.arrayResident as Array<any>

        if(!json['sgeppessoa'] || !json['principal']) {
            this.showFieldsEmpty('Os campos pessoa e principal devem ser preenchidos!')
            return
        }

        if(json['principal'] == 'N' && !json['cgepgrauparentesco']) {
            this.showFieldsEmpty('O campo grau parentesco é obrigatório!')
            return
        }

        if(this.state.personEdit != json['sgeppessoa'] && array.filter((x) => x['sgeppessoa'] == json['sgeppessoa']).length > 0) {
            this.showFieldsEmpty('Pessoa já vinculada!')
            return
        }

        var filterPerson = this.state.person.filter((x) => {
            return x['value'] == json['sgeppessoa']
        })

        json['nome'] = filterPerson.length == 0 ? '' : filterPerson[0].label
        var copyJSON = Object.assign({}, json)
        if(json['indexSelected'] >= 0) {
            array[json['indexSelected']] = copyJSON
        } else {
            array.push(copyJSON)
        }

        this.setState({
            arrayResident: array,
            showFormResident: false,
            personEdit: null
        })
    }

    private save() {
        var json = this.state.json as any 
        var arrayProperty = this.state.arrayProperty
        var arrayResident = this.state.arrayResident

        json['proprietarios'] = arrayProperty
        json['residentes'] = arrayResident

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgeppropedificacoes'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any> 
            if(resultado.length > 0) {
                json['descricao'] = resultado[0]['descricao']
                json['inscricao'] = resultado[0]['inscricao']
                json['sgeptipotumulo'] = resultado[0]['sgeptipotumulo']
                this.setState({
                    json: json,
                    arrayProperty: resultado[0]['proprietarios'],
                    arrayResident: resultado[0]['residentes'],
                    isLoading: false
                })
                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private loadTypeOfGrave() {
        this.props.viewModel.fetchTypeOfGrave().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                typeGrave: array
            })
        });
    }

    tapDimiss(navigation: any) {
        this.setState({
            showAddPerson: false
        })
    }

    private clearJSON() {
        var json = this.state.json as any 
        delete json['sgepgrupopessoaparente']
        delete json['nome']
        delete json['sgeppessoa']
        delete json['principal']
        delete json['cgepgrauparentesco']
        delete json['indexSelected']

        this.setState({
            json: json
        })
    }
}