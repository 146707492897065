import { Request } from "../../Servidor/Request";

export class AdminProcessStatusRequest {
    private sgepprocessoadministrativostatus: number;
    private nome: string;

    constructor (sgepprocessoadministrativostatus:number, nome: string){
        this.sgepprocessoadministrativostatus = sgepprocessoadministrativostatus;
        this.nome = nome;
    }

    /**
     * Getter sgepprocessostatusadministrativo
     * @return {number}
     */
    public getSgepprocessoadministrativostatus(): number {
        return this.sgepprocessoadministrativostatus;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }
}

export class AdminProcessStatusStructRequest {
    private sgepprocessoadministrativostatus: number;

    constructor (sgepprocessoadministrativostatus:number){
        this.sgepprocessoadministrativostatus = sgepprocessoadministrativostatus;
    }

    /**
     * Getter sgepprocessoadministrativostatus
     * @return {number}
     */
    public getSgepprocessoadministrativostatus(): number {
        return this.sgepprocessoadministrativostatus;
    }
}

export class AdminProcessStatusInteractor {
    public fetchSave(request: AdminProcessStatusRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("processo-administrativo/status/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: AdminProcessStatusStructRequest): PromiseLike<AdminProcessStatusRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("processo-administrativo/status/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
