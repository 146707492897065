import { Request } from '../../Servidor/Request';

export class MarkerIconRequest {
    private smarcadorimagemicone: number;
    private nome: string;


	constructor(smarcadorimagemicone: number, nome: string) {
		this.smarcadorimagemicone = smarcadorimagemicone;
		this.nome = nome;
	}


    /**
     * Getter smarcadorimagemicon
     * @return {number}
     */
	public getSmarcadorimagemicone(): number {
		return this.smarcadorimagemicone;
	}


    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class MarkerIconResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success;
	}


    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}

}

export class MarkerIconStructRequest {
    private smarcadorimagemicone: number;

	constructor(smarcadorimagemicone: number) {
        this.smarcadorimagemicone = smarcadorimagemicone;
	}



    /**
     * Getter smarcadorimagemicone
     * @return {number}
     */
	public getSmarcadorimagemicone(): number {
		return this.smarcadorimagemicone;
	}
    
}

export class MarkerIconStructResponse {
    private smarcadorimagemicone: number;
    private nome: string;


	constructor(smarcadorimagemicone: number, nome: string) {
		this.smarcadorimagemicone = smarcadorimagemicone;
		this.nome = nome;
	}


    /**
     * Getter smarcadorimagemicon
     * @return {number}
     */
	public getSmarcadorimagemicone(): number {
		return this.smarcadorimagemicone;
	}


    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class ImportIconResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success;
	}


    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}

}

export class MarkerIconInteractor {
    public fetchSave(request: MarkerIconRequest): PromiseLike<MarkerIconResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-icone/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new MarkerIconResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: MarkerIconStructRequest): PromiseLike<MarkerIconStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-icone/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchImportIcon(request: FormData): PromiseLike<ImportIconResponse> {
        return new Promise((res, rej) => {
            return new Request().requestFile("camada/importar-icone", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new ImportIconResponse(true)
                res(response)
            }, e => {
                rej(e);
            });
        });
    }
}