import { ConsolidatedReportInteractor } from "../../Coordinator/ConsolidatedReport/ConsolidatedReportInteractor";

export enum ConsolidatedReportType {
    district, neighborhood
}

export class ConsolidatedReportViewModel {

    private interactor: ConsolidatedReportInteractor
    private type: ConsolidatedReportType
    constructor(type: ConsolidatedReportType) {
        this.interactor = new ConsolidatedReportInteractor()
        this.type = type
    }

    public fetchReport(camada: number): PromiseLike<any> {
        return new Promise((res, rej) => {
            switch(this.type) {
                case ConsolidatedReportType.district:
                    return this.interactor.fetchDistrict(camada).then((result) => {
                        return res(result)
                    }, e => {
                        return rej(e)
                    })
                case ConsolidatedReportType.neighborhood:
                    this.interactor.fetchNeighborhood(camada).then((result) => {
                        return res(result)
                    }, e => {
                        return rej(e)
                    })
            }
		})
    }

    public fetchSelectLayers(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectLayers().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public getTitle(): string {
        switch(this.type) {
            case ConsolidatedReportType.district:
                return "Relatório consolidado de distritos"
            case ConsolidatedReportType.neighborhood:
                return "Relatório consolidado de bairros"
        }
    }
}