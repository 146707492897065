import React from "react";
import { AdminProcessTypeViewModel } from "./AdminProcessTypeViewModel";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Image, Col, Form, Button } from "react-bootstrap";
import fechar from "../../Imagens/close-24.png";
import salvar from "../../Imagens/save-16.png";

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";

interface MyProps {
	navigation: any;
	delegate: AdminProcessTypeViewControllerDelegate;
	viewModel: AdminProcessTypeViewModel;
	idPrimary: number;
}

export interface AdminProcessTypeViewControllerDelegate {
	tapDimiss(navigation: any): void;
}

export class AdminProcessTypeViewController
	extends React.Component<MyProps>
	implements AdminProcessTypeViewControllerDelegate
{
	private formEvent: FormEvent;
	state = {
		isLoading: false,
		json: {},
	};

	constructor(props: any) {
		super(props);
		this.formEvent = new FormEvent(this);
	}

	tapDimiss(navigation: any): void {}

	componentDidMount() {
		this.setKeyFK();
		if (this.props.idPrimary > 0) {
			this.loadStruct();
		}
	}

	render() {
		var json = this.state.json as any;
		return (
			<div>
				<NavBarComponente propNav={this.props.navigation} />
				<div>
					<Container fluid={true}>
						<Row>
							<Col>
								<div className="title">
									<h3>Tipo de Processo Administrativo</h3>
								</div>
								<div className="buttonClose">
									<Image
										className="imageClose"
										onClick={(e: any) =>
											this.props.delegate.tapDimiss(this.props.navigation)
										}
										src={fechar}
									/>
								</div>
							</Col>
						</Row>
						<Form className="containerForm">
							<Row>
								<Col lg="4" xs="12" className="bottom10 hide">
									<Form.Label>Sequencial</Form.Label>
									<Form.Control
										id="sgeptipoprocesso"
										defaultValue={
											json["sgeptipoprocesso"] ? json["sgeptipoprocesso"] : ""
										}
										onChange={(e: any) =>
											this.formEvent.onChange("inteiro", e.target)
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Nome</Form.Label>
									<Form.Control
										id="nome"
										maxLength={100}
										required
										defaultValue={json["nome"] ? json["nome"] : ""}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Digite o nome do tipo de processo"
									/>
								</Col>
							</Row>
							<Row>
								<Col xs="12" style={{ marginTop: 20 }}>
									<Button
										className="buttonSave"
										type="button"
										onClick={(e: any) => this.save()}
										variant="primary"
									>
										<Image className="imageSave" src={salvar} />
										Salvar
									</Button>
									<Button
										onClick={(e: any) =>
											this.props.delegate.tapDimiss(this.props.navigation)
										}
										className="buttonCancel"
										variant="link"
									>
										Cancelar
									</Button>
								</Col>
							</Row>
						</Form>
						<ToastContainer />
					</Container>
				</div>
				<LoaderComponente
					show={this.state.isLoading}
					hide={() => this.setState({ isLoading: this.state.isLoading })}
				/>
			</div>
		);
	}

	private setKeyFK() {
		var json = this.state.json as any;

		this.setState({
			json: json,
		});
	}

	private save() {
		var json = this.state.json as any;
		this.props.viewModel.fetchSave(json).then(
			() => {
				toast.success("Registro incluído com sucesso!", {
					position: toast.POSITION.BOTTOM_RIGHT,
				});

				setTimeout(() => {
					this.props.delegate.tapDimiss(this.props.navigation);
				}, 1000);
			},
			(e) => {
				toast.error(JSON.stringify(e), {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		);
	}

	private loadStruct() {
		var json = this.state.json as any;
		json["sgeptipoprocesso"] = this.props.idPrimary;

		this.setState({ isLoading: true });
		this.props.viewModel.fetchDataOne(json).then(
			(rs) => {
				var resultado = rs as Array<any>;
				if (resultado.length > 0) {
					var result = resultado[0];
					this.setState({
						isLoading: false,
						json: result,
					});

					return;
				}

				this.setState({ isLoading: false });
			},
			(e) => {
				toast.error(JSON.stringify(e), {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		);
	}
}
