import { Request } from '../../Servidor/Request';

export class ContactRequest {
    private cgepcontato: number;
    private descricao: string;
    private email: string;
    private fone: string;
    private siteblogfax: string;
    private principal: string;
    private sgeppessoa: number;

	constructor($cgepcontato: number, $descricao: string, $email: string, $fone: string, $siteblogfax: string, $principal: string, $sgeppessoa: number) {
		this.cgepcontato = $cgepcontato;
		this.descricao = $descricao;
		this.email = $email;
		this.fone = $fone;
		this.siteblogfax = $siteblogfax;
		this.principal = $principal;
		this.sgeppessoa = $sgeppessoa;
	}


    /**
     * Getter $cgepcontato
     * @return {number}
     */
	public get $cgepcontato(): number {
		return this.cgepcontato;
	}

    /**
     * Getter $descricao
     * @return {string}
     */
	public get $descricao(): string {
		return this.descricao;
	}

    /**
     * Getter $email
     * @return {string}
     */
	public get $email(): string {
		return this.email;
	}

    /**
     * Getter $fone
     * @return {string}
     */
	public get $fone(): string {
		return this.fone;
	}

    /**
     * Getter $siteblogfax
     * @return {string}
     */
	public get $siteblogfax(): string {
		return this.siteblogfax;
	}

    /**
     * Getter $principal
     * @return {string}
     */
	public get $principal(): string {
		return this.principal;
	}

    /**
     * Getter $sgeppessoa
     * @return {number}
     */
	public get $sgeppessoa(): number {
		return this.sgeppessoa;
	}
}

export class ContactStructRequest {
    private cgepcontato: number;

	constructor(cgepcontato: number) {
        this.cgepcontato = cgepcontato;
	}


    /**
     * Getter $cgepcontato
     * @return {number}
     */
	public get $cgepcontato(): number {
		return this.cgepcontato;
	}
}

export class ContactInteractor {
    public fetchSave(request: ContactRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/contato/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: ContactStructRequest): PromiseLike<ContactRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/contato/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}