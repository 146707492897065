import React from 'react';
import { Modal, Row, Col, Container, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { area, lineDistance } from 'turf';
import { FormEvent } from '../ScreenGeneric/Models/FormEvent';
import { Request } from '../Servidor/Request';
import { LoaderComponente } from './LoaderComponente';

interface MyProps {
    layer: any
    show: boolean
    clickHide: (isSave: boolean, json: any) => void
}

export class ModalAttributesShapeComponente extends React.Component<MyProps> {
    private formEvent: FormEvent
    state = {
        layers: [],
        json: {},
        isLoading: false,
        layerAttributes: []
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    render() {
        var json = this.state.json as any
        return (
            <Modal show={this.props.show} onShow={() => this.loadLayers()} onHide={() => this.props.clickHide(false, json)}>
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{fontSize: "20px", fontWeight: "bold"}}>Atributos</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Camada</Form.Label>
                                <Select
                                id="scamada"
                                value={this.formEvent.onValueSelect('select', 'scamada', this.state.layers)}
                                onChange={(e: any) => this.changeLayerAttributes(e)}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={this.state.layers}
                                isMulti={false}
                                />
                            </Col>
                        </Row>
                        {this.getAttributes()}
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.props.clickHide(true, json)}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.props.clickHide(false, json)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </Modal>
        )
    }

    private loadLayers() {
        this.setState({isLoading: true})
        this.loadAttributes()

        var json = this.state.json as any
        if(json['scamada']) {
            this.loadServerAttributes()
        }
        
        new Request().requestPost('camada/buscar-camadas-select', {}).then((rs) => {
            var resultado = rs.Resultado as Array<any>
            var arrayLayers = new Array<any>()

            for(var i = 0; i < resultado.length; i++) {
                var item = resultado[i] as any
                arrayLayers.push({value: item['id'], label: item['name'], check: false})
            }

            this.setState({
                layers: arrayLayers,
                isLoading: false
            })
        }, e => {

        })
    }

    private changeLayerAttributes(e: any) {
        this.loadAttributes()
        this.formEvent.onChange("select", e, "scamada") 
        this.loadServerAttributes()
    }

    private loadServerAttributes() {
        var json = this.state.json as any
        new Request().requestPost('camada/buscar-camada-atributos-select', {scamada: json['scamada']}).then((rs) => {
            var resultado = rs.Resultado as Array<any>   
            this.setState({
                isLoading : false,
                layerAttributes : resultado
            })
        }, e => {

        })
    }

    private loadAttributes() {
        var layer = this.props.layer as any 
        if(layer && layer['feature']) {
            var json = this.state.json as any 
            var scamada = layer['feature']['properties']['scamada']
            var sshape = layer['feature']['properties']['sshape']

            json['sshape'] = sshape
            json['scamada'] = scamada
            this.setState({isLoading: true, json: json})
            new Request().requestPost('formulario/ponto/buscar', {sshape: sshape}).then((rs) => {
                var resultado = rs['Atributos'].Resultado as Array<any> 
                resultado.forEach((item) => {
                    json[item.key] = layer['feature']['properties'][item.key] ? layer['feature']['properties'][item.key] : item.value
                })
                
                this.setState({
                    isLoading: false,
                    json: json
                })
            }, e => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    private getAttributes() {
        var json = this.addCalcule(this.state.json) as any 
        var layersAtributes = this.state.layerAttributes
        var fieldsAutomatic = new Array<any>()

        Object.keys(json).forEach((item) => {
            if(layersAtributes.filter((x: any) => x['name'].toLowerCase() == item.toLocaleLowerCase()).length == 0 && item != 'scamada') {
                fieldsAutomatic.push({name: item})
            }
        })

        var array = new Array<any>()
        for(var i = 0; i < layersAtributes.length; i++) {
            var item = layersAtributes[i] as any
            array.push((
                <Row className="bottom10">
                    <Col xs="12">
                        <Form.Label className="bold">{item['name']}</Form.Label>
                        <Form.Control 
                        id={item['name']}
                        defaultValue={json[item['name']] ? json[item['name']] : ''}
                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                        placeholder="Digite"/>
                    </Col>
                </Row>
            ))
        }

        for(var i = 0; i < fieldsAutomatic.length; i++) {
            var item = fieldsAutomatic[i] as any
            array.push((
                <Row className="bottom10">
                    <Col xs="12">
                        <Form.Label className="bold">{item['name']}</Form.Label>
                        <Form.Control 
                        id={item['name']}
                        defaultValue={json[item['name']] ? json[item['name']] : ''}
                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                        placeholder="Digite"/>
                    </Col>
                </Row>
            ))
        }

        return array
    }

    private addCalcule(json: any): any {
        var geojson = this.props.layer.toGeoJSON()
        var geojsonType = ""
        if(geojson['geometry']) {
            geojsonType = geojson['geometry']['type'] as string
        } else {
            geojsonType = geojson['features'][0]['geometry']['type'] as string
        }

        if(geojsonType.toLowerCase().indexOf('polygon') != -1) {
            json["Área em metros quadrados"] = area(this.props.layer.toGeoJSON()).toFixed(2)
        }

        if(geojsonType.toLowerCase().indexOf('line') != -1) {
            json["Distância"] = lineDistance(this.props.layer.toGeoJSON(), 'kilometers').toFixed(2)
        }

        if(geojsonType.toLowerCase().indexOf('point') != -1) {
            let longitude = geojson['geometry']['coordinates'][0]
            let latitude = geojson['geometry']['coordinates'][1]

            json["Latitude"] = latitude
            json["Longitude"] = longitude
        }

        return json
    }
}