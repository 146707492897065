import { StudentInteractor, StudentRequest, StudentStructRequest} from "../../Coordinator/Student/StudentInteractor";

export class StudentViewModel {
    private interactor: StudentInteractor;

	constructor() {
		this.interactor = new StudentInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new StudentRequest(
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0,
            obj["cgeptiporesponsavel"] ? parseInt(obj["cgeptiporesponsavel"]) : 0,
            obj["cgeppessoaresp"] ? parseInt(obj["cgeppessoaresp"]) : 0,
            obj["cgepnecessidadesespecial"] ? parseInt(obj["cgepnecessidadesespecial"]) : 0,
            obj["idmec"] ? obj["idmec"] : "",
			obj["ativo"] ? obj["ativo"] : "",
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new StudentStructRequest(
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchCustody(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchCustody().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSpecialNeeds(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSpecialNeeds().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
	}
	
	public fetchDelete(obj: any): PromiseLike<any> {
		var request = new StudentStructRequest(
			obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0
		)
        return new Promise((res, rej) => {
			this.interactor.fetchDelete(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

}