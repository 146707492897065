import { Request } from '../../Servidor/Request';

export class AcessTypePropertyRequest {
    private sgepproptpacesso: number;
    private nome: string;

	constructor(sgepproptpacesso: number, nome: string) {
		this.sgepproptpacesso = sgepproptpacesso;
		this.nome = nome;
	}


    /**
     * Getter sgepproptpacesso
     * @return {number}
     */
	public getSgepproptpacesso(): number {
		return this.sgepproptpacesso;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class AcessTypePropertyResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class AcessTypePropertyStructRequest {
    private sgepproptpacesso: number;

	constructor(sgepproptpacesso: number) {
        this.sgepproptpacesso = sgepproptpacesso;
	}


    /**
     * Getter sgepproptpacesso
     * @return {number}
     */
	public getSgepproptpacesso(): number {
		return this.sgepproptpacesso;
	}

}

export class AcessTypePropertyStructResponse {
    private sgepproptpacesso: number;
    private nome: string;

	constructor(sgepproptpacesso: number, nome: string) {
		this.sgepproptpacesso = sgepproptpacesso;
		this.nome = nome;
	}


    /**
     * Getter sgepproptpacesso
     * @return {number}
     */
	public getSgepproptpacesso(): number {
		return this.sgepproptpacesso;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class AcessTypePropertyInteractor {
    public fetchSave(request: AcessTypePropertyRequest): PromiseLike<AcessTypePropertyResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/tipo-acesso-propriedade/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new AcessTypePropertyResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: AcessTypePropertyStructRequest): PromiseLike<AcessTypePropertyStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/tipo-acesso-propriedade/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}