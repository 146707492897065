import { PavingInteractor, PavingRequest, PavingStructRequest} from "../../Coordinator/Paving/PavingInteractor";

export class PavingViewModel {
    private interactor: PavingInteractor;

	constructor() {
		this.interactor = new PavingInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PavingRequest(
            obj["sgeppavimentacao"] ? parseInt(obj["sgeppavimentacao"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PavingStructRequest(
            obj["sgeppavimentacao"] ? parseInt(obj["sgeppavimentacao"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}