import React from 'react'
import { SchoolYearViewModel } from './SchoolYearViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';
import moment from 'moment';

interface MyProps {
    navigation: any
    delegate: SchoolYearViewControllerDelegate
    viewModel: SchoolYearViewModel
    idPrimary: number
    idStudent: number
}

export interface SchoolYearViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class SchoolYearViewController extends React.Component<MyProps>{

    private formEvent: FormEvent 
    state = {
        isLoading: false,
        json: {},
        school: Array<any>(),
        course: Array<any>(),
        turn: [],
        period: [],
        transport: [],
        mode: [],
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        var json = this.state.json as any
        json["sgepaluno"] = this.props.idStudent
        this.loadSchool()
        this.loadCourse()
        this.loadTurn()
        this.loadPeriod()
        this.loadTransport()
        this.loadMode()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Ano Letivo</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgepanoletivo"
                                    defaultValue={json['sgepanoletivo'] ? json['sgepanoletivo'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Ano</Form.Label>
                                    <Form.Control
                                    id="anocalendario"
                                    defaultValue={json['anocalendario'] ? json['anocalendario'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("year", e.target)}
                                    placeholder="Insira o Ano" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Número da Matrícula</Form.Label>
                                    <Form.Control
                                    id="numeromatricula"
                                    defaultValue={json['numeromatricula'] ? json['numeromatricula'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Insira Matrícula" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Data de Matrícula</Form.Label>
                                    <Form.Control
                                    id="datamatricula"
                                    defaultValue={json['datamatricula'] ? json['datamatricula'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Insira Data de Matrícula" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Escola</Form.Label>
                                    <Select
                                    id="sgepescola"
                                    value={this.formEvent.onValueSelect('select', 'sgepescola', this.state.school)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepescola")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.school}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Curso</Form.Label>
                                    <Select
                                    id="cgepcurso"
                                    value={this.formEvent.onValueSelect('select', 'cgepcurso', this.state.course)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "cgepcurso")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.course}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Período do Curso</Form.Label>
                                    <Select
                                    id="cursoperiodo"
                                    value={this.formEvent.onValueSelect('select', 'cursoperiodo', this.state.period)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "cursoperiodo")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.period}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Turno</Form.Label>
                                    <Select
                                    id="turno"
                                    value={this.formEvent.onValueSelect('select', 'turno', this.state.turn)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "turno")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.turn}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Turma</Form.Label>
                                    <Form.Control
                                    id="turma"
                                    defaultValue={json['turma'] ? json['turma'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Insira a Turma" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Situação do Transporte</Form.Label>
                                    <Select
                                    id="utilizatransporte"
                                    value={this.formEvent.onValueSelect('select', 'utilizatransporte', this.state.transport)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "utilizatransporte")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.transport}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Modalidade</Form.Label>
                                    <Select
                                    id="cursomodalidade"
                                    value={this.formEvent.onValueSelect('select', 'cursomodalidade', this.state.mode)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "cursomodalidade")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.mode}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="8" xs="12" className="bottom10">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                    as="textarea"
                                    rows={4}
                                    id="cursodescricao"
                                    defaultValue={json['cursodescricao'] ? json['cursodescricao'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Descrição" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private loadSchool() {
        this.props.viewModel.fetchSchool().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                school: array
            })
        });
    }

    private loadCourse() {
        this.props.viewModel.fetchCourse().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                course: array
            })
        });
    }

    private loadTurn() {
        var array = Array<any>()
        array.push({ value: "M", label: "Manhã", check: false})
        array.push({ value: "T", label: "Tarde", check: false })
        array.push({ value: "N", label: "Noite", check: false })

        this.setState({
            turn: array
        })
    }

    private loadPeriod() {
        var array = Array<any>()
        array.push({ value: "T1", label: "Teste1", check: false})
        array.push({ value: "T2", label: "Teste2", check: false })

        this.setState({
            period: array
        })
    }

    private loadTransport() {
        var array = Array<any>()
        array.push({ value: "T1", label: "Teste1", check: false})
        array.push({ value: "T2", label: "Teste2", check: false })

        this.setState({
            transport: array
        })
    }

    private loadMode() {
        var array = Array<any>()
        array.push({ value: "T1", label: "Teste1", check: false})
        array.push({ value: "T2", label: "Teste2", check: false })

        this.setState({
            mode: array
        })
    }

    private save() {
        var json = this.state.json as any
        if(json["anocalendario"] == undefined || json["numeromatricula"] == undefined || json["datamatricula"] == undefined || json["sgepescola"] == undefined || json["cgepcurso"] == undefined || json["cursoperiodo"] == undefined || json["turno"] == undefined || json["turma"] == undefined || json["utilizatransporte"] == undefined || json["cursomodalidade"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["numeromatricula"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["turma"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        var date = moment(json["datamatricula"], "DD/MM/YYYY")
        if(!date.isValid()) {
            this.showFieldsEmpty("Data inválida!")
            return
        }

        if(date.diff(new Date()) > 0) {
            this.showFieldsEmpty("Data inválida!")
            return
        }

        this.setState({isLoading: true})
        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgepanoletivo'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                var result = resultado[0]
                result['datamatricula'] = result['datamatricula'] ? moment(result['datamatricula']).format('DD/MM/YYYY') : ""
                this.setState({
                    isLoading: false,
                    json: result
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}