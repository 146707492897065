import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { RuralPropertySituationViewController, RuralPropertySituationViewControllerDelegate } from '../../Screen/RuralPropertySituation/RuralPropertySituationViewController';
import { RuralPropertySituationViewModel } from '../../Screen/RuralPropertySituation/RuralPropertySituationViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class RuralPropertySituationCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, RuralPropertySituationViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepsituacaoproprural")
        return (<ProtectedRoute requiredPermissions={['info_bas.sit_prop_rural.*']} path="/:base/situacao-propriedade-rural" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                permissions={{
                    add: 'info_bas.sit_prop_rural.adicionar',
                    edit: 'info_bas.sit_prop_rural.editar',
                    delete: 'info_bas.sit_prop_rural.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Situação Propriedade Rural"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new RuralPropertySituationViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.sit_prop_rural.adicionar', 'info_bas.sit_prop_rural.editar']} path="/:base/situacao-propriedade-rural/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<RuralPropertySituationViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepsituacaoproprural") {
            navigation.history.push(`situacao-propriedade-rural/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepsituacaoproprural") {
            navigation.history.push(`situacao-propriedade-rural/cad/${idSelection}`);
            return;
        }
    }

}