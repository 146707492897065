import { Request } from '../../Servidor/Request';

export class RuralLocationRequest {
    private sgeplocalizacaorural: number;
    private nome: string;

	constructor(sgeplocalizacaorural: number, nome: string) {
		this.sgeplocalizacaorural = sgeplocalizacaorural;
		this.nome = nome;
	}


    /**
     * Getter sgeplocalizacaorural
     * @return {number}
     */
	public getSgeplocalizacaorural(): number {
		return this.sgeplocalizacaorural;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class RuralLocationStructRequest {
    private sgeplocalizacaorural: number;

	constructor(sgeplocalizacaorural: number) {
        this.sgeplocalizacaorural = sgeplocalizacaorural;
	}


    /**
     * Getter sgeplocalizacaorural
     * @return {number}
     */
	public getSgeplocalizacaorural(): number {
		return this.sgeplocalizacaorural;
	}

}

export class RuralLocationStructResponse {
    private sgeplocalizacaorural: number;
    private nome: string;

	constructor(sgeplocalizacaorural: number, nome: string) {
		this.sgeplocalizacaorural = sgeplocalizacaorural;
		this.nome = nome;
	}


    /**
     * Getter sgeplocalizacaorural
     * @return {number}
     */
	public getSgeplocalizacaorural(): number {
		return this.sgeplocalizacaorural;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class RuralLocationInteractor {
    public fetchSave(request: RuralLocationRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/localizacao-rural/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: RuralLocationStructRequest): PromiseLike<RuralLocationStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/localizacao-rural/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}