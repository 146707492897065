import { VisitInteractor, VisitRequest, VisitStructRequest } from "../../Coordinator/Visit/VisitInteractor";
import moment from 'moment';

export class VisitViewModel {
    private interactor: VisitInteractor;

	constructor() {
		this.interactor = new VisitInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new VisitRequest(
            obj["sgepvisita"] ? parseInt(obj["sgepvisita"]) : 0,
            obj["data"] ? moment(obj["data"], 'DD/MM/YYYY').format('YYYY-MM-DD') : "",
            obj["hora"] ? moment(obj["hora"], 'HH:mm').format('HH:mm:ss') : "",
            obj["sgepusuariocargo"] ? parseInt(obj["sgepusuariocargo"]) : 0,
            obj["sgepmotivovisita"] ? parseInt(obj["sgepmotivovisita"]) : 0,
            obj["obs"] ? obj["obs"] : "",
            obj["sgeppontos"] ? parseInt(obj["sgeppontos"]) : 0,
            obj["sshape"] ? parseInt(obj["sshape"]) : 0,
            obj["sgepprocessoadministrativo"] ? parseInt(obj["sgepprocessoadministrativo"]) : 0,
            obj["sgeppropriedade"] ? parseInt(obj["sgeppropriedade"]) : 0,
            obj["sgeppropedificacoes"] ? parseInt(obj["sgeppropedificacoes"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new VisitStructRequest(
            obj["sgepvisita"] ? parseInt(obj["sgepvisita"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchProfessional(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchProfessional().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchVisitReason(id: number): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchVisitReason(id).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchGroupVisitReason(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchGroupVisitReason().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}