import { Request } from '../../Servidor/Request';

export class PropertyConfigItem {
    private sgeppropriedadeconfiguracaoitem: number;
    private item: string;
    private valor: number;


	constructor(sgeppropriedadeconfiguracaoitem: number, item: string, valor: number) {
		this.sgeppropriedadeconfiguracaoitem = sgeppropriedadeconfiguracaoitem;
		this.item = item;
		this.valor = valor;
	}


    /**
     * Getter sgeppropriedadeconfiguracaoitem
     * @return {number}
     */
	public getSgeppropriedadeconfiguracaoitem(): number {
		return this.sgeppropriedadeconfiguracaoitem;
	}

    /**
     * Getter item
     * @return {string}
     */
	public getItem(): string {
		return this.item;
	}

    /**
     * Getter valor
     * @return {number}
     */
	public getValor(): number {
		return this.valor;
	}
    
}

export class PropertyConfigRequest {
    private sgeppropriedadeconfiguracao: number;
    private nome: string;
    private tipo: string;
    private obrigatorio: string;
    private iscadastroimobiliario: boolean;
    private ordem: number;
    private itens: Array<PropertyConfigItem>;
    private tipoorigem: string;


	constructor(sgeppropriedadeconfiguracao: number, nome: string, tipo: string, obrigatorio: string, iscadastroimobiliario: boolean, ordem: number, itens: Array<PropertyConfigItem>, tipoorigem: string) {
        this.sgeppropriedadeconfiguracao = sgeppropriedadeconfiguracao
        this.nome = nome
        this.tipo = tipo
        this.obrigatorio = obrigatorio
        this.iscadastroimobiliario = iscadastroimobiliario
        this.ordem = ordem 
        this.itens = itens
        this.tipoorigem = tipoorigem
	}


    /**
     * Getter sgeppropriedadeconfiguracao
     * @return {number}
     */
	public getSgeppropriedadeconfiguracao(): number {
		return this.sgeppropriedadeconfiguracao;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
    }
    
    /**
     * Getter iscadastroimobiliario
     * @return {Boolean}
     */
	public getIscadastroimobiliario(): Boolean {
		return this.iscadastroimobiliario;
    }
    

    /**
     * Getter $tipo
     * @return {string}
     */
	public get $tipo(): string {
		return this.tipo;
    }
    

    /**
     * Getter $obrigatorio
     * @return {string}
     */
	public get $obrigatorio(): string {
		return this.obrigatorio;
	}
    

    /**
     * Getter ordem
     * @return {number}
     */
	public getOrdem(): number {
		return this.ordem;
	}

    /**
     * Getter itens
     * @return {Array<PropertyConfigItem>}
     */
	public getItens(): Array<PropertyConfigItem> {
		return this.itens;
	}

    /**
     * Getter $tipoorigem
     * @return {string}
     */
	public get $tipoorigem(): string {
		return this.tipoorigem;
	}
}

export class PropertyConfigStructRequest {
    private sgeppropriedadeconfiguracao: number;

	constructor(sgeppropriedadeconfiguracao: number) {
        this.sgeppropriedadeconfiguracao = sgeppropriedadeconfiguracao;
	}



    /**
     * Getter sgeppropriedadeconfiguracao
     * @return {number}
     */
	public getSgeppropriedadeconfiguracao(): number {
		return this.sgeppropriedadeconfiguracao;
	}
    

}

export class PropertyConfigInteractor {
    public fetchSave(request: PropertyConfigRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/configuracao-propriedade/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Status == "Sucesso")
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PropertyConfigStructRequest): PromiseLike<PropertyConfigRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/configuracao-propriedade/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}