import { Request } from '../../Servidor/Request';

export class PropertyConfigItem {
    private sgeppropriedadeconfiguracaoitem: number;
    private sgeppropriedadeconfiguracao: number;
    private item: string;
    private valor: number;

    constructor(sgeppropriedadeconfiguracaoitem: number, sgeppropriedadeconfiguracao: number, item: string, valor: number) {
        this.sgeppropriedadeconfiguracaoitem = sgeppropriedadeconfiguracaoitem
        this.sgeppropriedadeconfiguracao = sgeppropriedadeconfiguracao
        this.item = item
        this.valor = valor
    }


    /**
     * Getter sgeppropriedadeconfiguracaoitem
     * @return {number}
     */
    public getSgeppropriedadeconfiguracaoitem(): number {
        return this.sgeppropriedadeconfiguracaoitem;
    }

    /**
     * Getter sgeppropriedadeconfiguracao
     * @return {number}
     */
    public getSgeppropriedadeconfiguracao(): number {
        return this.sgeppropriedadeconfiguracao;
    }

    /**
     * Getter item
     * @return {string}
     */
    public getItem(): string {
        return this.item;
    }

    /**
     * Getter valor
     * @return {number}
     */
    public getValor(): number {
        return this.valor;
    }
}

export class PropertyConfig {
    private sgeppropriedadeconfiguracao: number;
    private nome: string;
    private iscadastroimobiliario: Boolean;
    private ordem: number;
    private itens: Array<PropertyConfigItem>;

    constructor(sgeppropriedadeconfiguracao: number, nome: string, iscadastroimobiliario: Boolean, ordem: number, itens: Array<PropertyConfigItem>) {
        this.sgeppropriedadeconfiguracao = sgeppropriedadeconfiguracao
        this.nome = nome
        this.iscadastroimobiliario = iscadastroimobiliario
        this.ordem = ordem
        this.itens = itens
    }


    /**
     * Getter sgeppropriedadeconfiguracao
     * @return {number}
     */
    public getSgeppropriedadeconfiguracao(): number {
        return this.sgeppropriedadeconfiguracao;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }

    /**
     * Getter iscadastroimobiliario
     * @return {string}
     */
    public getIscadastroimobiliario(): Boolean {
        return this.iscadastroimobiliario;
    }

    /**
     * Getter ordem
     * @return {number}
     */
    public getOrdem(): number {
        return this.ordem;
    }


    /**
     * Getter itens
     * @return {Array<PropertyConfigItem>}
     */
    public getItens(): Array<PropertyConfigItem> {
        return this.itens;
    }
}

export class Property {
    private sgepgrupopessoaparente: number;
    private sgeppessoa: number;
    private principal: string;

    constructor(sgepgrupopessoaparente: number, sgeppessoa: number, principal: string) {
        this.sgepgrupopessoaparente = sgepgrupopessoaparente;
        this.sgeppessoa = sgeppessoa;
        this.principal = principal;
    }


    /**
     * Getter sgepgrupopessoaparente
     * @return {number}
     */
    public getSgepgrupopessoaparente(): number {
        return this.sgepgrupopessoaparente;
    }

    /**
     * Getter sgeppessoa
     * @return {number}
     */
    public getSgeppessoa(): number {
        return this.sgeppessoa;
    }

    /**
     * Getter principal
     * @return {string}
     */
    public getPrincipal(): string {
        return this.principal;
    }

}

export class PropertyItens {
    private key: number;
    private value: string;

    constructor(key: number, value: string) {
        this.key = key;
        this.value = value;
    }

    /**
     * Getter $key
     * @return {number}
     */
    public get $key(): number {
        return this.key;
    }


    /**
     * Getter $value
     * @return {string}
     */
    public get $value(): string {
        return this.value;
    }

}

export class PropertyRequest {
    private sgeppropriedade: number;
    private anoinicial: string;
    private anofinal: string;
    private tpprop: string;
    private sgeppontos: number;
    private entradaagua: string;
    private entradaenergiaeletrica: string;
    private sgepsituacaoproprural: number;
    private sgepproptpacesso: number;
    private inscricaomunicipal: string;
    private cin: string;
    private numeromatricula: string;
    private registromatricula: string;
    private datamatricula: string;
    private cnm: string;
    private numeroquadra: string;
    private numerolote: string;
    private nomeloteamento: string;
    private anolotamento: string;
    private facequadra: string;
    private matriculaorigem: string;
    private sgepdistrito: number;
    private sgepzoneamento: number;
    private sgepformaobtencao: number;
    private sgeptipoedificacao: number;
    private sgeptipopatrimonio: number;
    private livro: string;
    private oficio: string;
    private cnscartorio: string;
    private ccidade: number;
    private sgepsituacaopropriedade: number;
    private sgepsituacaoquadra: number;
    private sgepocupacaolote: number;
    private sgeppavimentacao: number;
    private sgeptopografia: number;
    private sgeppedologia: number;
    private sgeplimitacaolote: number;
    private sgeppasseiopublico: number;
    private sgeppiscina: number;
    private area: number;
    private perimetro: number;
    private testada: number;
    private fundos: number;
    private frentefundosesquerdo: number;
    private frentefundosdireito: number;
    private profundidademedia: number;
    private afastamento: number;
    private pavimentos: number;
    private valortotal: number;
    private valorbenfeitorias: number;
    private valorterrenovazio: number;
    private valorfiscal: number;
    private itens: Array<PropertyItens>;
    private proprietarios: Array<Property>;
    private sgeplitigio: Array<number>;
    private sgeptipoponto: number;
    private copiarpropriedade: boolean;
    private copiarsgeppropriedade: number;


    constructor(
        sgeppropriedade: number,
        anoinicial: string,
        anofinal: string,
        tpprop: string,
        sgeppontos: number,
        entradaagua: string,
        entradaenergiaeletrica: string,
        sgepsituacaoproprural: number,
        sgepproptpacesso: number,
        inscricaomunicipal: string,
        cin: string,
        numeromatricula: string,
        registromatricula: string,
        datamatricula: string,
        cnm: string,
        numeroquadra: string,
        numerolote: string,
        nomeloteamento: string,
        anolotamento: string,
        facequadra: string,
        matriculaorigem: string,
        sgepdistrito: number,
        sgepzoneamento: number,
        sgepformaobtencao: number,
        sgeptipoedificacao: number,
        sgeptipopatrimonio: number,
        livro: string,
        oficio: string,
        cnscartorio: string,
        ccidade: number,
        sgepsituacaopropriedade: number,
        sgepsituacaoquadra: number,
        sgepocupacaolote: number,
        sgeppavimentacao: number,
        sgeptopografia: number,
        sgeppedologia: number,
        sgeplimitacaolote: number,
        sgeppasseiopublico: number,
        sgeppiscina: number,
        area: number,
        perimetro: number,
        testada: number,
        fundos: number,
        frentefundosesquerdo: number,
        frentefundosdireito: number,
        profundidademedia: number,
        afastamento: number,
        pavimentos: number,
        valortotal: number,
        valorbenfeitorias: number,
        valorterrenovazio: number,
        valorfiscal: number,
        sgeptipoponto: number,
        sgeplitigio: Array<number>,
        itens: Array<PropertyItens>,
        proprietarios: Array<Property>,
        copiarpropriedade: boolean,
        copiarsgeppropriedade: number
    ) {
        this.sgeppropriedade = sgeppropriedade;
        this.anoinicial = anoinicial;
        this.anofinal = anofinal;
        this.tpprop = tpprop;
        this.sgeppontos = sgeppontos;
        this.entradaagua = entradaagua;
        this.entradaenergiaeletrica = entradaenergiaeletrica;
        this.sgepsituacaoproprural = sgepsituacaoproprural;
        this.sgepproptpacesso = sgepproptpacesso;
        this.inscricaomunicipal = inscricaomunicipal;
        this.cin = cin;
        this.numeromatricula = numeromatricula;
        this.registromatricula = registromatricula;
        this.datamatricula = datamatricula;
        this.cnm = cnm;
        this.numeroquadra = numeroquadra;
        this.numerolote = numerolote;
        this.nomeloteamento = nomeloteamento;
        this.anolotamento = anolotamento;
        this.facequadra = facequadra;
        this.matriculaorigem = matriculaorigem;
        this.sgepdistrito = sgepdistrito;
        this.sgepzoneamento = sgepzoneamento;
        this.sgepformaobtencao = sgepformaobtencao;
        this.sgeptipoedificacao = sgeptipoedificacao;
        this.sgeptipopatrimonio = sgeptipopatrimonio;
        this.livro = livro;
        this.oficio = oficio;
        this.cnscartorio = cnscartorio;
        this.ccidade = ccidade;
        this.sgepsituacaopropriedade = sgepsituacaopropriedade;
        this.sgepsituacaoquadra = sgepsituacaoquadra;
        this.sgepocupacaolote = sgepocupacaolote;
        this.sgeppavimentacao = sgeppavimentacao;
        this.sgeptopografia = sgeptopografia;
        this.sgeppedologia = sgeppedologia;
        this.sgeplimitacaolote = sgeplimitacaolote;
        this.sgeppasseiopublico = sgeppasseiopublico;
        this.sgeppiscina = sgeppiscina;
        this.area = area;
        this.perimetro = perimetro;
        this.testada = testada;
        this.fundos = fundos;
        this.frentefundosesquerdo = frentefundosesquerdo;
        this.frentefundosdireito = frentefundosdireito;
        this.profundidademedia = profundidademedia;
        this.afastamento = afastamento;
        this.pavimentos = pavimentos;
        this.valortotal = valortotal;
        this.valorbenfeitorias = valorbenfeitorias;
        this.valorterrenovazio = valorterrenovazio;
        this.valorfiscal = valorfiscal;
        this.sgeptipoponto = sgeptipoponto;
        this.sgeplitigio = sgeplitigio;
        this.itens = itens;
        this.proprietarios = proprietarios;
        this.copiarpropriedade = copiarpropriedade;
        this.copiarsgeppropriedade = copiarsgeppropriedade;
    }

    /**
     * Getter sgeppropriedade
     * @return {number}
     */
    public getSgeppropriedade(): number {
        return this.sgeppropriedade;
    }

    /**
     * Getter anoinicial
     * @return {number}
     */
    public getAnoinicial(): string {
        return this.anoinicial;
    }

    /**
     * Getter anofinal
     * @return {number}
     */
    public getAnofinal(): string {
        return this.anofinal;
    }

    /**
     * Getter tpprop
     * @return {string}
     */
    public getTpprop(): string {
        return this.tpprop;
    }

    /**
     * Getter sgeppontos
     * @return {number}
     */
    public getSgeppontos(): number {
        return this.sgeppontos;
    }

    /**
     * Getter entradaagua
     * @return {string}
     */
    public getEntradaagua(): string {
        return this.entradaagua;
    }

    /**
     * Getter entradaenergiaeletrica
     * @return {string}
     */
    public getEntradaenergiaeletrica(): string {
        return this.entradaenergiaeletrica;
    }

    /**
     * Getter sgepsituacaoproprural
     * @return {number}
     */
    public getSgepsituacaoproprural(): number {
        return this.sgepsituacaoproprural;
    }

    /**
     * Getter sgepproptpacesso
     * @return {number}
     */
    public getSgepproptpacesso(): number {
        return this.sgepproptpacesso;
    }

    /**
     * Getter inscricaomunicipal
     * @return {string}
     */
    public getInscricaomunicipal(): string {
        return this.inscricaomunicipal;
    }

    /**
     * Getter cin
     * @return {string}
     */
    public getCin(): string {
        return this.cin;
    }

    /**
     * Getter numeromatricula
     * @return {string}
     */
    public getNumeromatricula(): string {
        return this.numeromatricula;
    }

    /**
     * Getter registromatricula
     * @return {string}
     */
    public getRegistromatricula(): string {
        return this.registromatricula;
    }

    /**
     * Getter datamatricula
     * @return {string}
     */
    public getDatamatricula(): string {
        return this.datamatricula;
    }

    /**
     * Getter cnm
     * @return {string}
     */
    public getCnm(): string {
        return this.cnm;
    }

    /**
     * Getter numeroquadra
     * @return {string}
     */
    public getNumeroquadra(): string {
        return this.numeroquadra;
    }

    /**
     * Getter numerolote
     * @return {string}
     */
    public getNumerolote(): string {
        return this.numerolote;
    }

    /**
     * Getter nomeloteamento
     * @return {string}
     */
    public getNomeloteamento(): string {
        return this.nomeloteamento;
    }

    /**
     * Getter anolotamento
     * @return {string}
     */
    public getAnolotamento(): string {
        return this.anolotamento;
    }


    /**
     * Getter facequadra
     * @return {string}
     */
    public getFacequadra(): string {
        return this.facequadra;
    }


    /**
     * Getter matriculaorigem
     * @return {string}
     */
    public getMatriculaorigem(): string {
        return this.matriculaorigem;
    }


    /**
     * Getter sgepdistrito
     * @return {number}
     */
    public getSgepdistrito(): number {
        return this.sgepdistrito;
    }

    /**
     * Getter sgepzoneamento
     * @return {number}
     */
    public getSgepzoneamento(): number {
        return this.sgepzoneamento;
    }

    /**
     * Getter sgepformaobtencao
     * @return {number}
     */
    public getSgepformaobtencao(): number {
        return this.sgepformaobtencao;
    }

    /**
     * Getter sgeptipoedificacao
     * @return {number}
     */
    public getSgeptipoedificacao(): number {
        return this.sgeptipoedificacao;
    }

    /**
     * Getter sgeptipopatrimonio
     * @return {number}
     */
    public getSgeptipopatrimonio(): number {
        return this.sgeptipopatrimonio;
    }

    /**
     * Getter livro
     * @return {string}
     */
    public getLivro(): string {
        return this.livro;
    }

    /**
     * Getter oficio
     * @return {string}
     */
    public getOficio(): string {
        return this.oficio;
    }

    /**
     * Getter cnscartorio
     * @return {string}
     */
    public getCnscartorio(): string {
        return this.cnscartorio;
    }

    /**
     * Getter ccidade
     * @return {number}
     */
    public getCcidade(): number {
        return this.ccidade;
    }

    /**
     * Getter sgepsituacaopropriedade
     * @return {number}
     */
    public getSgepsituacaopropriedade(): number {
        return this.sgepsituacaopropriedade;
    }

    /**
     * Getter sgepsituacaoquadra
     * @return {number}
     */
    public getSgepsituacaoquadra(): number {
        return this.sgepsituacaoquadra;
    }

    /**
     * Getter sgepocupacaolote
     * @return {number}
     */
    public getSgepocupacaolote(): number {
        return this.sgepocupacaolote;
    }

    /**
     * Getter sgeptopografia
     * @return {number}
     */
    public getSgeptopografia(): number {
        return this.sgeptopografia;
    }

    /**
     * Getter sgeppedologia
     * @return {number}
     */
    public getSgeppedologia(): number {
        return this.sgeppedologia;
    }

    /**
     * Getter sgeplimitacaolote
     * @return {number}
     */
    public getSgeplimitacaolote(): number {
        return this.sgeplimitacaolote;
    }

    /**
     * Getter sgeppasseiopublico
     * @return {number}
     */
    public getSgeppasseiopublico(): number {
        return this.sgeppasseiopublico;
    }

    /**
     * Getter sgeppiscina
     * @return {number}
     */
    public getSgeppiscina(): number {
        return this.sgeppiscina;
    }

    /**
     * Getter sgeppavimentacao
     * @return {number}
     */
    public getSgeppavimentacao(): number {
        return this.sgeppavimentacao;
    }

    /**
     * Getter area
     * @return {number}
     */
    public getArea(): number {
        return this.area;
    }

    /**
     * Getter perimetro
     * @return {number}
     */
    public getPerimetro(): number {
        return this.perimetro;
    }

    /**
     * Getter testada
     * @return {number}
     */
    public getTestada(): number {
        return this.testada;
    }

    /**
     * Getter fundos
     * @return {number}
     */
    public getFundos(): number {
        return this.fundos;
    }

    /**
     * Getter frentefundosesquerdo
     * @return {number}
     */
    public getFrentefundosesquerdo(): number {
        return this.frentefundosesquerdo;
    }

    /**
     * Getter frentefundosdireito
     * @return {number}
     */
    public getFrentefundosdireito(): number {
        return this.frentefundosdireito;
    }

    /**
     * Getter profundidademedia
     * @return {number}
     */
    public getProfundidademedia(): number {
        return this.profundidademedia;
    }

    /**
     * Getter valortotal
     * @return {number}
     */
    public getValortotal(): number {
        return this.valortotal;
    }

    /**
     * Getter valorbenfeitorias
     * @return {number}
     */
    public getValorbenfeitorias(): number {
        return this.valorbenfeitorias;
    }

    /**
     * Getter valorterrenovazio
     * @return {number}
     */
    public getValorterrenovazio(): number {
        return this.valorterrenovazio;
    }

    /**
     * Getter valorfiscal
     * @return {number}
     */
    public getValorfiscal(): number {
        return this.valorfiscal;
    }

    /**
     * Getter sgeptipoponto
     * @return {number}
     */
    public getSgeptipoponto(): number {
        return this.sgeptipoponto;
    }

    /**
     * Getter sgeplitigio
     * @return {Array<number>}
     */
    public getSgeplitigio(): Array<number> {
        return this.sgeplitigio;
    }

    /**
     * Getter itens
     * @return {Array<PropertyItens>}
     */
    public getItens(): Array<PropertyItens> {
        return this.itens;
    }

    /**
     * Getter proprietarios
     * @return {Array<Property>}
     */
    public getProprietarios(): Array<Property> {
        return this.proprietarios;
    }


    /**
     * Getter $afastamento
     * @return {number}
     */
    public get $afastamento(): number {
        return this.afastamento;
    }


    /**
     * Getter $pavimentos
     * @return {number}
     */
    public get $pavimentos(): number {
        return this.pavimentos;
    }

    /**
     * Getter $copiarpropriedade
     * @return {boolean}
     */
    public get $copiarpropriedade(): boolean {
        return this.copiarpropriedade;
    }

    /**
     * Getter $copiarsgeppropriedade
     * @return {boolean}
     */
    public get $copiarsgeppropriedade(): number {
        return this.copiarsgeppropriedade;
    }
}

export class PropertyResponse {
    private sgeppropriedade: number;

    constructor(sgeppropriedade: number) {
        this.sgeppropriedade = sgeppropriedade
    }


    /**
     * Getter sgeppropriedade
     * @return {number}
     */
    public getSgeppropriedade(): number {
        return this.sgeppropriedade;
    }
}

export class PropertyStructRequest {
    private sgeppropriedade: number;

    constructor(sgeppropriedade: number) {
        this.sgeppropriedade = sgeppropriedade
    }


    /**
     * Getter sgeppropriedade
     * @return {number}
     */
    public getSgeppropriedade(): number {
        return this.sgeppropriedade;
    }
}

export class PropertySelectResponse {
    private id: number;
    private value: string;


    constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
    }


    /**
     * Getter id
     * @return {number}
     */
    public getId(): number {
        return this.id;
    }

    /**
     * Getter value
     * @return {string}
     */
    public getValue(): string {
        return this.value;
    }

}

export class PropertyCityRequest {
    private suf: string;

    constructor(suf: string) {
        this.suf = suf
    }

    /**
     * Getter suf
     * @return {string}
     */
    public getUf(): string {
        return this.suf;
    }
}

export class PropertyInteractor {
    public fetchSave(request: PropertyRequest): PromiseLike<PropertyResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/propriedade/salvar", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let result = rs.Resultado
                var response = new PropertyResponse(0)
                if (result.length > 0) {
                    response = new PropertyResponse(result[0]['sgeppropriedade'])
                }

                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDelete(request: PropertyStructRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/propriedade/remover", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PropertyStructRequest): PromiseLike<PropertyRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/propriedade/buscar", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectState(): PromiseLike<PropertySelectResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("cidades/estados/buscar-select", {}).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectCity(request: PropertyCityRequest): PromiseLike<PropertySelectResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("cidades/buscar-select", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchListItens(tipoorigem: string, sgeppontos?: number): PromiseLike<Array<PropertyConfig>> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/lista-itens/buscar", { tipoorigem: tipoorigem, sgeppontos: sgeppontos }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectProperty(): PromiseLike<Array<PropertySelectResponse>> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/select/buscar-select", {}).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}

