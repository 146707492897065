import { PropertyFormObtainingInteractor, PropertyFormObtainingRequest, PropertyFormObtainingStructRequest} from "../../Coordinator/PropertyFormObtaining/PropertyFormObtainingInteractor";

export class PropertyFormObtainingViewModel {
    private interactor: PropertyFormObtainingInteractor;

	constructor() {
		this.interactor = new PropertyFormObtainingInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PropertyFormObtainingRequest(
            obj["sgepformaobtencao"] ? parseInt(obj["sgepformaobtencao"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PropertyFormObtainingStructRequest(
            obj["sgepformaobtencao"] ? parseInt(obj["sgepformaobtencao"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}