import { UploadFilesInteractor, UploadFilesRequest, UploadMainStruct, UploadStructRequest } from "../../Coordinator/UploadFiles/UploadFilesInteractor";

export class UploadFilesViewModel {
    private interactor: UploadFilesInteractor;

	constructor() {
		this.interactor = new UploadFilesInteractor();
    }

    public fetchDownloadDocument(json: any): PromiseLike<any> {
        let request = new UploadFilesRequest(json["tabela"], json["registro"])
        return new Promise((res, rej) => {
            return this.interactor.fetchDownloadDocument(request).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }
    
    public fetchImportDocuments(request: FormData): PromiseLike<any> {
        return new Promise((res, rej) => {
            return this.interactor.fetchImportDocuments(request).then(() => {
                return res(true)
            }, e => {
                return rej(e)
            })
        })
    }

    public fetchDataOne(json: any): PromiseLike<any> {
        let request = new UploadStructRequest(json['simagem'])
        return new Promise((res, rej) => {
            return this.interactor.fetchDataOne(request).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }

    public saveMain(json: any): PromiseLike<any> {
        let request = new UploadMainStruct(json['simagem'], json['tabela'], json['registro'])
        console.log(request)
        return new Promise((res, rej) => {
            return this.interactor.saveMain(request).then((rs) => {
                return res(rs)
            }, e => {
                return rej(e)
            })
        })
    }
}