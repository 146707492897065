import React from 'react';
import { MarkerIconViewModel } from './MarkerIconViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import { LoaderComponente } from '../../Components/LoaderComponente';
import { ToastContainer, toast } from 'react-toastify';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';

import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

interface MyProps {
    navigation: any
    delegate: MarkerIconViewControllerDelegate
    viewModel: MarkerIconViewModel
    idPrimary: number
}

export interface MarkerIconViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class MarkerIconViewController extends React.Component<MyProps> {
    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {}
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }
    
    componentDidMount() {
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
        <div>
            <NavBarComponente propNav={this.props.navigation}/>
            <div>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <div className="title">
                                <h3>Ícones</h3>
                            </div>
                            <div className="buttonClose">
                                <Image className="imageClose"
                                onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                src={fechar}/>
                            </div>
                        </Col>
                    </Row>
                    <Form className="containerForm">
                        <Row>
                            <Col lg="4" xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                id="smarcadorimagemicone"
                                defaultValue={json['smarcadorimagemicone'] ? json['smarcadorimagemicone'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col lg="4" xs="12" className="bottom10">
                                <Form.Label className="isRequired">Nome</Form.Label>
                                <Form.Control
                                id="nome"
                                defaultValue={json['nome'] ? json['nome'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                placeholder="Digite o nome" 
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                    <Image className="imageSave"
                                    src={salvar}/>
                                    Salvar
                                </Button>
                                <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                            </Col>
                        </Row>
                    </Form>
                    <ToastContainer />
                </Container>
            </div>
            <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
        </div>)
    }

    private save() {
        var json = this.state.json as any
        if(json["nome"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["nome"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['smarcadorimagemicone'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}