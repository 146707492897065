import React from "react";
import { AgriculturalRegistrationViewModel } from "./AgriculturalRegistrationViewModel";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Image, Col, Form, Button } from "react-bootstrap";
import fechar from "../../Imagens/close-24.png";
import salvar from "../../Imagens/save-16.png";

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from "react-select";

interface MyProps {
	navigation: any;
	delegate: AgriculturalRegistrationViewControllerDelegate;
	viewModel: AgriculturalRegistrationViewModel;
	idPrimary: number;
	idPropriedade?: number;
}

export interface AgriculturalRegistrationViewControllerDelegate {
	tapDimiss(navigation: any): void;
}

export class AgriculturalRegistrationViewController extends React.Component<MyProps> {
	private formEvent: FormEvent;
	state = {
		isLoading: false,
		isDisabled: false,
		productType: new Array<any>(),
		product: new Array<any>(),
		aptidao: new Array<any>(),
		json: {},
	};

	constructor(props: any) {
		super(props);
		this.formEvent = new FormEvent(this);
	}

	componentDidMount() {
		this.loadProductType();
		this.loadAptidao();
		if (this.props.idPrimary > 0) {
			this.loadStruct();
		}
		
	}

	render() {
		var json = this.state.json as any;
		return (
			<div>
				<NavBarComponente propNav={this.props.navigation} />
				<div>
					<Container fluid={true}>
						<Row>
							<Col>
								<div className="title">
									<h3>Cadastro Agropecuário</h3>
								</div>
								<div className="buttonClose">
									<Image
										className="imageClose"
										onClick={(e: any) =>
											this.props.delegate.tapDimiss(this.props.navigation)
										}
										src={fechar}
									/>
								</div>
							</Col>
						</Row>
						<Form className="containerForm">
							<Row>
								<Col lg="4" xs="12" className="bottom10 hide">
									<Form.Label>Sequencial</Form.Label>
									<Form.Control
										id="sgepprodutosagropecuarios"
										defaultValue={
											json["sgepprodutosagropecuarios"]
												? json["sgepprodutosagropecuarios"]
												: ""
										}
										onChange={(e: any) =>
											this.formEvent.onChange("inteiro", e.target)
										}
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">
										Tipo de Produto
									</Form.Label>
									<Select
										id="sgeptpprodagropecuario"
										isDisabled={this.state.isDisabled}
										value={this.formEvent.onValueSelect(
											"select",
											"sgeptpprodagropecuario",
											this.state.productType
										)}
										onChange={(e: any) => {
											this.formEvent.onChange(
												"select",
												e,
												"sgeptpprodagropecuario"
											);
											this.loadProduct(parseInt(e.value));
										}}
										closeMenuOnSelect={true}
										placeholder={"Selecione"}
										options={this.state.productType}
										isMulti={false}
									/>
								</Col>
								{this.state.product && (
									<Col lg="4" xs="12" className="bottom10">
										<Form.Label className="isRequired">Produto</Form.Label>
										<Select
											id="sgepprodutosagropecuarios"
											isDisabled={this.state.isDisabled}
											value={this.formEvent.onValueSelect(
												"select",
												"sgepprodutosagropecuarios",
												this.state.product
											)}
											onChange={(e: any) =>
												this.formEvent.onChange(
													"select",
													e,
													"sgepprodutosagropecuarios"
												)
											}
											closeMenuOnSelect={true}
											placeholder={"Selecione"}
											options={this.state.product}
											isMulti={false}
										/>
									</Col>
								)}

								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Quantidade</Form.Label>
									<Form.Control
										id="quantidade"
										type="number"
										defaultValue={json["quantidade"] ? json["quantidade"] : ""}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Insira a Quantidade"
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Área Plantada</Form.Label>
									<Form.Control
										id="areaplantada"
										type="number"
										defaultValue={
											json["areaplantada"] ? json["areaplantada"] : ""
										}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Insira a Área Plantada"
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Aptidão de outros produtos</Form.Label>
									<Select
											id="aptidaooutrosprodutos"
											isDisabled={this.state.isDisabled}
											value={this.formEvent.onValueSelect(
												"select",
												"aptidaooutrosprodutos",
												this.state.aptidao
											)}
											onChange={(e: any) =>
												this.formEvent.onChange(
													"select",
													e,
													"aptidaooutrosprodutos"
												)
											}
											defaultValue={json["aptidaooutrosprodutos"] ? 1 : 0}
											closeMenuOnSelect={true}
											placeholder={"Selecione"}
											options={this.state.aptidao}
											isMulti={false}
										/>
								</Col>
							</Row>
							<Row>
								<Col xs="12" style={{ marginTop: 20 }}>
									<Button
										className="buttonSave"
										type="button"
										onClick={(e: any) => this.save()}
										variant="primary"
									>
										<Image className="imageSave" src={salvar} />
										Salvar
									</Button>
									<Button
										onClick={(e: any) =>
											this.props.delegate.tapDimiss(this.props.navigation)
										}
										className="buttonCancel"
										variant="link"
									>
										Cancelar
									</Button>
								</Col>
							</Row>
						</Form>
						<ToastContainer />
					</Container>
				</div>
				<LoaderComponente
					show={this.state.isLoading}
					hide={() => this.setState({ isLoading: this.state.isLoading })}
				/>
			</div>
		);
	}

	private loadProductType() {
		this.setState({ isLoading: true });
		this.props.viewModel.fetchSelectType().then((rs) => {
			var result = rs as Array<any>;
			var array = Array<any>();

			for (var i = 0; i < result.length; i++) {
				var item = result[i];
				array.push({ value: item.id, label: item.value, check: false });
			}

			this.setState({
				productType: array,
				isLoading: false,
			});
		});
	}

	private loadAptidao() {
		this.setState({ aptidao: [{ value: 1, label: "Sim", check: true }, { value: 0, label: "Não", check: true }] });
	}

	private loadProduct(sgeptpprodagropecuario: number) {
		this.setState({ isLoading: true });
		this.props.viewModel
			.fetchSelectProduct(sgeptpprodagropecuario)
			.then((rs) => {
				var result = rs as Array<any>;
				var array = Array<any>();

				for (var i = 0; i < result.length; i++) {
					var item = result[i];
					array.push({ value: item.id, label: item.value, check: false });
				}

				this.setState({
					product: array,
					isLoading: false,
				}, () => {
					this.setState({
						json: Object.assign(this.state.json, {
							sgepprodutosagropecuarios: null
						})
					})
				});
			});
	}

	private save() {
		var json = this.state.json as any;
		if (json["quantidade"] == undefined) {
			this.showFieldsEmpty("Campos obrigatórios não preenchidos!");
			return;
		}
		if (json["areaplantada"] == undefined) {
			this.showFieldsEmpty("Campos obrigatórios não preenchidos!");
			return;
		}
		if (json["aptidaooutrosprodutos"] == undefined) {
			this.showFieldsEmpty("Campos obrigatórios não preenchidos!");
			return;
		}
        json["sgeppropriedade"] = this.props.idPropriedade

		this.setState({ isLoading: true });

		this.props.viewModel.fetchSave(json).then(
			() => {
				toast.success("Registro incluído com sucesso!", {
					position: toast.POSITION.BOTTOM_RIGHT,
				});

				this.setState({ isLoading: false });

				setTimeout(() => {
					this.props.delegate.tapDimiss(this.props.navigation);
				}, 1000);
			},
			(e) => {
				toast.error(JSON.stringify(e), {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		);
	}

	private showFieldsEmpty(message: string) {
		toast.error(message, {
			position: toast.POSITION.BOTTOM_RIGHT,
		});
	}

	private loadStruct() {
		var json = this.state.json as any;
		json["sgepcadastroagropecuario"] = this.props.idPrimary;

		this.setState({ isLoading: true });
		this.props.viewModel.fetchDataOne(json).then(
			(rs) => {
				var resultado = rs as Array<any>;
				if (resultado.length > 0) {
					this.setState({
						isLoading: false,
						json: resultado[0],
					});
					return;
				}

				this.setState({ isLoading: false });
			},
			(e) => {
				toast.error(JSON.stringify(e), {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
		);
	}
}
