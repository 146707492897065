import React from 'react';
import { Row, Col, Container, Form, Image, Button } from 'react-bootstrap';
import "../Estilo/Mapa.css";
import "../Estilo/Form.css";
import baixo from '../Imagens/arrow-down-blue-16.png';
import cima from '../Imagens/arrow-up-blue-16.png';
import filtrar from '../Imagens/filter-16.png';
import direito from '../Imagens/arrow-right.png';
import markerAzul from '../Imagens/marker-azul.png';
import clearFilter from '../Imagens/clear-filter.png';
import fechar from '../Imagens/close-24.png';
import { Request } from '../Servidor/Request';
import { LoaderComponente } from './LoaderComponente';
import NumberFormat from 'react-number-format';
import { FormEvent } from '../ScreenGeneric/Models/FormEvent';
import { toast } from 'react-toastify';
import { center } from 'turf';

interface MyProps {
    isOpenCamada: Boolean
    tapLocation: (location: any) => void,
    shapesFilter: (shapes: Array<number>) => void,
    close: () => void
    isOpenFilter: Boolean
}

export class ModalSearchMapComponent extends React.Component<MyProps> {

    private formEvent: FormEvent
    state = {
        showFiltros: true,
        showLoading: false,
        tipo: Array<any>(),
        camada: Array<any>(),
        atributos: Array<any>(),
        json: {},
        result: Array<any>()
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadFilter()
    }

    render() {
        let json = this.state.json as any
        return (
            <Container className="container-procurar" style={{
                width: window.innerWidth > 400 ? "400px" : `${window.innerWidth}px`,
                height: window.innerHeight > 500 ? "500px" : `${window.innerHeight}px`,
                marginLeft: this.props.isOpenCamada ? 300 : this.props.isOpenFilter ? 500 : 0
            }}
            >
                <Row>
                    <Col xs="12">
                        <Form.Label className="container-procura-titulo">Pesquisar</Form.Label>
                        <Image
                            onClick={() => this.props.close()}
                            className="float-right"
                            style={{ marginTop: 15, cursor: "pointer" }}
                            src={fechar}
                            width={16} />
                    </Col>
                </Row>
                <div className="container-procurar-itens">
                    <Row style={{ marginTop: 10 }}>
                        <Col xs="12">
                            <h3 className="container-procura-titulo-filtro" onClick={() => this.setState({ showFiltros: !this.state.showFiltros })}>
                                <Image
                                    style={{ marginRight: 20, cursor: "pointer" }}
                                    src={this.state.showFiltros ? cima : baixo}
                                    width={16} />
                                EXIBIR FILTROS
                            </h3>
                        </Col>
                    </Row>
                    {this.state.showFiltros &&
                        <div>
                            <Row style={{ marginBottom: 10 }}>
                                <Col xs="12">
                                    <Form.Label className="isRequired">Tipo</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.changeValue('tipo', e.currentTarget.value)}>
                                        <option value={0}>{"Selecione"}</option>
                                        {this.state.tipo}
                                    </Form.Control>
                                </Col>
                            </Row>
                            {(json['tipo'] == 'PP' || json['tipo'] == 'PR' || json['tipo'] == 'SH') &&
                            <Row style={{ marginBottom: 10 }}>
                                <Col xs="12">
                                    <Form.Label>Camada</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.changeValue('camada', e.currentTarget.value)}>
                                        <option value={0}>{"Selecione"}</option>
                                        {this.state.camada}
                                    </Form.Control>
                                </Col>
                            </Row>}
                            {(json['tipo'] == 'PP' || json['tipo'] == 'SH') &&
                                <Row style={{ marginBottom: 10 }}>
                                    <Col xs="12">
                                        <Form.Label className="isRequired">Atributo</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => this.changeValue('atributo', e.currentTarget.value)}>
                                            <option value={0}>{"Selecione"}</option>
                                            {this.state.atributos}
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {(json['tipo'] == 'PP' || json['tipo'] == 'PR' || json['tipo'] == 'SH') && 
                            <Row>
                                <Col xs="12">
                                    <Form.Label className="isRequired">Pesquisar</Form.Label>
                                    {json['atributo'] == 'valortotal' &&
                                        <NumberFormat
                                            id="pesquisar"
                                            className="form-control"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            prefix="R$"
                                            value={json['pesquisar'] ? json['pesquisar'] : ''}
                                            onValueChange={(values) => this.formEvent.onChange("money", values, 'pesquisar')}
                                        />}
                                    {json['atributo'] != 'valortotal' &&
                                        <Form.Control
                                            id="pesquisar"
                                            placeholder="Pesquisar"
                                            defaultValue={json['pesquisar'] ? json['pesquisar'] : ''}
                                            onChange={(e: any) => this.formEvent.onChange(json['atributo'] == "area" ? "double" : "varchar", e.target)}
                                        />}
                                </Col>
                            </Row>}
                            {(json['tipo'] == 'L') &&
                            <Row>
                                <Col xs="12">
                                    <Form.Label className="isRequired">Latitude</Form.Label>
                                    <Form.Control
                                        id="latitude"
                                        placeholder="Latitude"
                                        defaultValue={json['latitude'] ? json['latitude'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    />
                                </Col>
                                <Col xs="12">
                                    <Form.Label className="isRequired">Longitude</Form.Label>
                                    <Form.Control
                                        id="longitude"
                                        placeholder="Longitude"
                                        defaultValue={json['longitude'] ? json['longitude'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    />
                                </Col>
                            </Row>
                            }
                            <Row style={{ marginBottom: 10, marginTop: -5 }}>
                                <Col xs="12">
                                    <Button
                                        variant="info"
                                        type="button"
                                        onClick={() => this.filtrar()}
                                        style={{ backgroundColor: "#0080d1", marginTop: 30, width: 150, marginRight: 10 }}>
                                        <Image
                                            style={{ marginRight: 20, cursor: "pointer", marginTop: -5 }}
                                            src={filtrar}
                                            width={16} />
                                        Filtrar
                                    </Button>
                                    <Button
                                        variant="danger"
                                        type="button"
                                        onClick={() => this.clearFilter() }
                                        style={{ marginTop: 30, width: 150 }}>
                                        <Image
                                            style={{ marginRight: 20, cursor: "pointer", marginTop: -5 }}
                                            src={clearFilter}
                                            width={16} />
                                        Limpar
                                    </Button>
                                </Col>
                            </Row>
                        </div>}
                </div>
                <div style={{ padding: 15 }}>
                    {this.getLineFilter(this.state.result)}
                </div>
                <LoaderComponente show={this.state.showLoading} hide={() => this.setState({ showLoading: this.state.showLoading })} />
            </Container>
        )
    }

    private changeValue(id: string, value: any) {
        var json = this.state.json as any
        json[id] = value

        this.setState({
            json: json
        })

        if (value == 0) {
            return
        }

        if (json['tipo'] == 'SH') {
            if (json['camada'] != undefined && json['camada'] != 0) {
                this.loadAtributo(json['tipo'], json['camada'])
                return
            }
        } else if (json['tipo'] == 'PP') {
            this.loadAtributo(json['tipo'])
            return
        }
    }

    private loadAtributo(tipo: string, camada?: number) {
        var request = {
            tipo: tipo
        } as any

        if (camada != undefined) {
            request['camada'] = camada
        }

        this.setState({ showLoading: true })
        new Request().requestPost('relatorio/procurar-mapa/filtro-atributos', request).then((rs) => {
            this.setState({
                showLoading: false,
                atributos: this.getOptions(rs)
            })
        }, e => {
            this.setState({ showLoading: false })
            this.showFieldsEmpty("Erro interno, entre em contato com o suporte!")
        });
    }

    private loadFilter() {
        this.setState({ showLoading: true })
        new Request().requestPost('relatorio/procurar-mapa/filtro', {}).then((rs) => {
            var tipo = rs['tipo'] as Array<any>
            var camada = rs['camada'] as Array<any>

            this.setState({
                showLoading: false,
                tipo: this.getOptions(tipo),
                camada: this.getOptions(camada)
            })
        }, e => {
            this.setState({ showLoading: false })
            this.showFieldsEmpty("Erro interno, entre em contato com o suporte!")
        });
    }

    private getOptions(itens: Array<any>): Array<any> {
        var array = new Array<any>()
        array.push(<option value='L'>Localização</option>)
        for (var index = 0; index < itens.length; index++) {
            let item = itens[index] as any
            array.push(<option value={item['id']}>{item['name']}</option>)
        }

        return array
    }

    private getLineFilter(itens: Array<any>): Array<any> {
        var array = new Array<any>()
        var arrayShapes = new Array<number>()
        itens.forEach((item) => {
            let info = this.getInfoLocation(item['geojson'])
            array.push(
                <Row className="container-procurar-linha" onClick={() => this.props.tapLocation(info)}>
                    <Col xs="10" style={{ marginTop: 10 }}>
                        <Col xs="12" style={{ marginLeft: -20 }}>
                            <Image
                                src={item['iconefiltro'] != undefined ? item['iconefiltro'] : markerAzul}
                                width={16} />
                            <label style={{ marginLeft: 10, fontWeight: "bold" }}>
                                {info['tipo']}
                            </label>
                        </Col>
                        <Col xs="12" style={{ marginLeft: 5, marginTop: -10 }}>
                            Latitude: {info['latitude']}
                        </Col>
                        <Col xs="12" style={{ marginLeft: 5, marginBottom: 10 }}>
                            Longitude: {info['longitude']}
                        </Col>
                    </Col>
                    <Col xs="2" style={{ marginTop: 15 }}>
                        <Image
                            className="float-right"
                            src={direito}
                            width={16} />
                    </Col>
                </Row>
            )

            arrayShapes.push(item['id'])
        })

        this.props.shapesFilter(arrayShapes)
        return array
    }

    private getInfoLocation(geojson: any): any {
        var type = geojson['type'] as string
        if (type == 'Point') {
            return {
                tipo: "Ponto",
                latitude: geojson['coordinates'][1],
                longitude: geojson['coordinates'][0]
            }
        } else if (type == 'Polygon' || type == 'MultiPolygon') {
            let transformGeoJson = center(geojson).geometry
            return {
                tipo: "Polígono",
                latitude: transformGeoJson['coordinates'][1],
                longitude: transformGeoJson['coordinates'][0]
            }
        } else {
            let transformGeoJson = center(geojson).geometry
            return {
                tipo: "Linha",
                latitude: transformGeoJson['coordinates'][1],
                longitude: transformGeoJson['coordinates'][0]
            }
        }
    }

    private filtrar() {
        var json = this.state.json as any
        if (json['tipo'] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if (json['tipo'] == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json['tipo'] != 'L') {
            if (json['pesquisar'].length == 0) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }
        }

        if(json['tipo'] == 'L') {
            if(json['latitude'] == undefined || json['longitude'] == undefined) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }

            if (json['latitude'].length == 0 || json['longitude'].length == 0) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }
        }

        if (json['tipo'] == 'SH') {
            if (json['camada'] == undefined || json['atributo'] == undefined) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }

            if (json['camada'] == 0 || json['atributo'] == 0) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }
        }

        if (json['tipo'] == 'PP') {
            if (json['atributo'] == undefined) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }

            if (json['atributo'] == 0) {
                this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                return
            }
        }

        if(json['tipo'] == 'L') {
            let result = {
                tipo: "Ponto",
                latitude: json['latitude'],
                longitude: json['longitude']
            }

            this.props.tapLocation(result)
            return 
        }

        var request = {
            tipo: json['tipo'],
            pesquisar: json['pesquisar']
        } as any

        if (json['camada'] != undefined) {
            request['camada'] = json['camada']
        }

        if (json['atributo'] != undefined) {
            request['atributo'] = json['atributo']
        }

        this.setState({ showLoading: true })
        new Request().requestPost('relatorio/procurar-mapa/filtrar', request).then((rs) => {
            console.log(rs)

            this.setState({
                showLoading: false,
                result: rs,
                showFiltros: false
            })
        }, e => {
            this.setState({ showLoading: false })
            this.showFieldsEmpty("Erro interno, entre em contato com o suporte!")
        });
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private clearFilter() {
        this.setState({result: new Array<any>()})
        this.props.shapesFilter(new Array<any>())
    }
}