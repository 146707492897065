import { LocationInitialInteractor, LocationInitialRequest } from "../../Coordinator/LocationInitial/LocationInitialInteractor";

export class LocationInitialViewModel {
    private interactor: LocationInitialInteractor; 

	constructor() {
		this.interactor = new LocationInitialInteractor();
	}

	public fetchSave(obj: any): PromiseLike<any> {
		var request = new LocationInitialRequest(
            obj["latitude"] ? obj["latitude"] : 0,
            obj["longitude"] ? obj["longitude"] : "",
			obj["srid"] ? obj["srid"] : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
	}

	public fetchDataOne(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

	public fetchSelectSRID(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectSRID().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}