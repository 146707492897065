import {DocumentTypeLinkRequest, DocumentTypeLinkStructRequest, DocumentTypeLinkInteractor, DocTypeSelect} from "../../Coordinator/DocumentTypeLink/DocumentTypeLinkInteractor";

export class DocumentTypeLinkViewModel {
    private interactor: DocumentTypeLinkInteractor;

    constructor() {
        this.interactor = new DocumentTypeLinkInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new DocumentTypeLinkRequest(
            obj["sgeppessoatpdoc"] ? parseInt(obj["sgeppessoatpdoc"]) : 0,
            obj["sgeptpdoc"] ? parseInt(obj["sgeptpdoc"]) : 0,
            obj["valor"] ? obj["valor"] : "",
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0,
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(true)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new DocumentTypeLinkStructRequest(
            obj["sgeppessoatpdoc"] ? parseInt(obj["sgeppessoatpdoc"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDocType(idPerson: number): PromiseLike<any> {
        let request = new DocTypeSelect(idPerson)

        return new Promise((res, rej) => {
			this.interactor.fetchDocType(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
	}
}