import React from 'react'
import { VisitViewModel } from './VisitViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import moment from 'moment';

interface MyProps {
    navigation: any
    delegate: VisitViewControllerDelegate
    viewModel: VisitViewModel
    idPrimary: number
    idPoint?: number
    idShape?: number
    idAdminProcess?: number
    idProperty?: number
    idPropertyBuilding?: number
}

export interface VisitViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class VisitViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        professional: Array<any>(),
        visitReason: Array<any>(),
        groupVisitReason: Array<any>()
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.setKeyFK()
        this.loadProfessional()
        this.loadGroupVisitReason()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Visita</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgepvisita"
                                    defaultValue={json['sgepvisita'] ? json['sgepvisita'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Data</Form.Label>
                                    <Form.Control
                                    id="data"
                                    maxLength={10}
                                    defaultValue={json['data'] ? json['data'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Digite a data" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Hora</Form.Label>
                                    <Form.Control
                                    id="hora"
                                    maxLength={5}
                                    defaultValue={json['hora'] ? json['hora'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("hour", e.target)}
                                    placeholder="Digite a hora" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Profissional</Form.Label>
                                    <Select
                                    id="sgepusuariocargo"
                                    value={this.formEvent.onValueSelect('select', 'sgepusuariocargo', this.state.professional)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepusuariocargo")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.professional}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Grupo Motivo Visita</Form.Label>
                                    <Select
                                    id="sgepgrupomotivovisita"
                                    value={this.formEvent.onValueSelect('select', 'sgepgrupomotivovisita', this.state.groupVisitReason)}
                                    onChange={(e: any) => this.onChangeGroupVisitReason(e)}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.groupVisitReason}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Motivo Visita</Form.Label>
                                    <Select
                                    id="sgepmotivovisita"
                                    value={this.formEvent.onValueSelect('select', 'sgepmotivovisita', this.state.visitReason)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepmotivovisita")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.visitReason}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="bottom10">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                    id="obs"
                                    as="textarea" 
                                    rows={3}
                                    defaultValue={json['obs'] ? json['obs'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite uma observação" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setKeyFK() {
        var json = this.state.json as any
        json['data'] = moment(new Date()).format('DD/MM/YYYY')
        json['hora'] = moment(new Date()).format('HH:mm')

        if(this.props.idPoint) {
            json['sgeppontos'] = this.props.idPoint
        }

        if(this.props.idShape) {
            json['sshape'] = this.props.idShape
        }

        if(this.props.idAdminProcess) {
            json['sgepprocessoadministrativo'] = this.props.idAdminProcess
        }

        if(this.props.idProperty) {
            json['sgeppropriedade'] = this.props.idProperty
        }

        if(this.props.idPropertyBuilding) {
            json['sgeppropedificacoes'] = this.props.idPropertyBuilding
        }

        this.setState({
            json: json
        })
    }

    private loadProfessional() {
        this.props.viewModel.fetchProfessional().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                professional: array
            })
        });
    }

    private onChangeGroupVisitReason(e: any) {
        this.loadVisitReason(e.value)
        this.formEvent.onChange("select", e, "sgepgrupomotivovisita")
    }

    private loadVisitReason(id: number) {
        this.props.viewModel.fetchVisitReason(id).then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                visitReason: array
            })
        });
    }

    private save() {
        var json = this.state.json as any
        if(json["data"] == undefined || json["hora"] == undefined || json["sgepusuariocargo"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        var date = moment(json["data"], "DD/MM/YYYY")
        if(!date.isValid()) {
            this.showFieldsEmpty("Data inválida!")
            return
        }

        var hour = moment(json["hora"], "HH:mm")
        if(!hour.isValid()) {
            this.showFieldsEmpty("Hora inválida!")
            return false
        }

        if(date.diff(new Date()) > 0) {
            this.showFieldsEmpty("Data inválida!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgepvisita'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                var result = resultado[0]
                result['data'] = result['data'] ? moment(result['data']).format('DD/MM/YYYY') : ""
                result['hora'] = result['hora'] ? moment(result['hora'], 'HH:mm:ss').format('HH:mm') : ""

                if(result['sgepgrupomotivovisita'] != undefined) {
                    this.loadVisitReason(result['sgepgrupomotivovisita'])
                }

                this.setState({
                    isLoading: false,
                    json: result
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private loadGroupVisitReason() {
        this.props.viewModel.fetchGroupVisitReason().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                groupVisitReason: array
            })
        });
    }
}