import { Request } from "../../Servidor/Request"

export class SchoolRequest {
    private sgepescola: number;
    private nome: string;
    private idgoverno: string;
    private cre: string;
    private dependenciaadm: string;
    private idt: string;

    constructor ($sgepescola:number, $nome:string, $idgoverno:string, $cre:string, $dependenciaadm:string, $idt:string){
        this.sgepescola = $sgepescola;
        this.nome = $nome;
        this.idgoverno = $idgoverno;
        this.cre = $cre;
        this.dependenciaadm = $dependenciaadm;
        this.idt = $idt;
    } 

    /**
     * Getter $sgepescola
     * @return { number }
     */
    public get $sgepescola(): number {
        return this.sgepescola;
    }

    /**
     * Getter nome
     * @return { nome }
     */
    public get $nome(): string {
        return this.nome;
    }

    /**
     * Getter $idgoverno
     * @return { idgoverno }
     */
    public get $idgoverno(): string {
        return this.idgoverno;
    }

    /**
     * Getter $cre
     * @return { cre }
     */
    public get $cre(): string {
        return this.cre;
    }

    /**
     * Getter $dependenciaadm
     * @return { dependenciaadm }
     */
    public get $dependenciaadm(): string {
        return this.dependenciaadm;
    }

    /**
     * Getter $idt
     * @return { idt }
     */
    public get $idt(): string {
        return this.idt;
    }
    
}

export class SchoolStructRequest {
    private sgepescola: number;

    constructor (sgepescola:number){
        this.sgepescola = sgepescola;
    }

    /**
     * Getter sgepescola
     * @return { sgepescola }
     */
    public getSgepescola(): number {
        return this.sgepescola;
    }
}

export class SchoolInteractor {
    public fetchSave(request: SchoolRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("aluno/escola/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: SchoolStructRequest): PromiseLike<SchoolRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("aluno/escola/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}