import { BuildingTypeInteractor, BuildingTypeRequest, BuildingTypeStructRequest } from "../../Coordinator/BuildingType/BuildingTypeInteractor";

export class BuildingTypeViewModel {
    private interactor: BuildingTypeInteractor;

	constructor() {
		this.interactor = new BuildingTypeInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new BuildingTypeRequest(
            obj["sgeptipoedificacao"] ? parseInt(obj["sgeptipoedificacao"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new BuildingTypeStructRequest(
            obj["sgeptipoedificacao"] ? parseInt(obj["sgeptipoedificacao"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}