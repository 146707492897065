import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { StudentViewController, StudentViewControllerDelegate } from "../../Screen/Student/StudentViewController";
import { StudentViewModel } from "../../Screen/Student/StudentViewModel";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericFilter } from "../TableGeneric/TableGenericInteractor";
import { SchoolYearViewController } from "../../Screen/SchoolYear/SchoolYearViewController";
import { SchoolYearViewModel } from "../../Screen/SchoolYear/SchoolYearViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";

export class StudentCoordinator implements IndexCoordinatorDelegate, StudentViewControllerDelegate, TableGenericViewControllerDelegate{
    
    getRoute(): JSX.Element[]{
        return[
            this.routeForm(),
            this.routeTableSchoolYear(),
            this.routeFormSchoolYear()
        ]
    }

    private routeForm(): JSX.Element {
        var viewModel = new StudentViewModel()
        return (<ProtectedRoute path="/:base/pessoa/:idpessoa/aluno" exact={true} component={(props:any) => {
            var idPerson = props.match.params.idpessoa
            var idPk = props.match.params.id
            return (<StudentViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPerson={idPerson}
                idPrimary={idPk}
            />)
        }}/>)
    }

    private routeTableSchoolYear(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepanoletivo")
        return (<ProtectedRoute path="/:base/aluno/:id/ano-letivo" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Aluno', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Ano Letivo"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormSchoolYear(): JSX.Element {
        var viewModel = new SchoolYearViewModel()
        return (<ProtectedRoute path="/:base/aluno/:idaluno/ano-letivo/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            var idaluno = props.match.params.idaluno
            return (<SchoolYearViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idStudent={idaluno}
                />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    tapSchoolYear(navigation: any, id: number): void {
        if(id > 0) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/aluno/${id}/ano-letivo`);
        }
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepanoletivo" && filter){
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/aluno/${filter[0].getValue()}/ano-letivo/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepanoletivo"){
            navigation.history.push(`ano-letivo/cad/${idSelection}`)
            return;
        }
    }

}