import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { PointTypeViewControllerDelegate, PointTypeViewController } from "../../Screen/PointType/PointTypeViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { PointTypeViewModel } from '../../Screen/PointType/PointTypeViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PointTypeCoordinator implements IndexCoordinatorDelegate, PointTypeViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geptipoponto")
        return (<ProtectedRoute requiredPermissions={['gis.tipo_ponto.*']} path="/:base/tipo-ponto" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'gis.tipo_ponto.adicionar',
                    edit: 'gis.tipo_ponto.editar',
                    delete: 'gis.tipo_ponto.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Tipo Ponto"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new PointTypeViewModel()
        return (<ProtectedRoute requiredPermissions={['gis.tipo_ponto.adicionar', 'gis.tipo_ponto.editar']} path="/:base/tipo-ponto/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<PointTypeViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geptipoponto") {
            navigation.history.push(`tipo-ponto/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geptipoponto") {
            navigation.history.push(`tipo-ponto/cad/${idSelection}`);
            return;
        }
    }

}