import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { AcessTypePropertyViewController, AcessTypePropertyViewControllerDelegate } from '../../Screen/AcessTypeProperty/AcessTypePropertyViewController';
import { AcessTypePropertyViewModel } from '../../Screen/AcessTypeProperty/AcessTypePropertyViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class AcessTypePropertyCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, AcessTypePropertyViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepproptpacesso")
        return (<ProtectedRoute requiredPermissions={["info_bas.tipo_acesso_prop.*"]} path="/:base/tipo-acesso-propriedade" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                navigation={props} 
                permissions={{
                    add: 'info_bas.tipo_acesso_prop.adicionar',
                    edit: 'info_bas.tipo_acesso_prop.editar',
                    delete: 'info_bas.tipo_acesso_prop.excluir',
                }}
                viewModel={viewModel} 
                delegate={this} 
                title={"Tipo Acesso Propriedade"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new AcessTypePropertyViewModel()
        return (<ProtectedRoute requiredPermissions={["info_bas.tipo_acesso_prop.adicionar", "info_bas.tipo_acesso_prop.editar"]}  path="/:base/tipo-acesso-propriedade/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<AcessTypePropertyViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepproptpacesso") {
            navigation.history.push(`tipo-acesso-propriedade/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepproptpacesso") {
            navigation.history.push(`tipo-acesso-propriedade/cad/${idSelection}`);
            return;
        }
    }

}