import { PublicRideInteractor, PublicRideRequest, PublicRideStructRequest} from "../../Coordinator/PublicRide/PublicRideInteractor";

export class PublicRideViewModel {
    private interactor: PublicRideInteractor;

	constructor() {
		this.interactor = new PublicRideInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PublicRideRequest(
            obj["sgeppasseiopublico"] ? parseInt(obj["sgeppasseiopublico"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PublicRideStructRequest(
            obj["sgeppasseiopublico"] ? parseInt(obj["sgeppasseiopublico"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}