import React from 'react'
import { LayerPermissionViewModel } from './LayerPermissionViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';

import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 
import pastaAbertaAmarela from "../../Imagens/pasta-aberta-amarela.jpg";
import pastaFechadaAmarela from "../../Imagens/pasta-fechada-amarela.jpg";
import arquivoBranco from "../../Imagens/arquivo-branco.png";

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';

interface MyProps {
    navigation: any
    delegate: LayerPermissionViewControllerDelegate
    viewModel: LayerPermissionViewModel
}

export interface LayerPermissionViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class LayerPermissionViewController extends React.Component<MyProps> {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        userGroup: [],
        layers: new Array<any>(),
        isLoadingLayer: false,
        checkPasta: {},
        checkCamada: {}
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadUserGroup()
    }
    
    render() {
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Camadas</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Grupo de Usuário</Form.Label>
                                    <Select
                                    id="grusuario"
                                    value={this.formEvent.onValueSelect('selectMultiple', 'grusuario', this.state.userGroup)}
                                    onChange={(e: any) => this.onChangeType(e)}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.userGroup}
                                    isMulti={true}
                                    />
                                </Col>
                            </Row>
                            {this.state.layers.length > 0 &&
                            <Row>
                                {this.gerarMenuCamada()}
                            </Row>}
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>  
        )
    }

    private onChangeType(e: any) {
        if(!this.state.isLoadingLayer) {
            var array = e as Array<any> 
            if(array.length > 0) {
                this.getLayers(array[0].value)
            }
        }

        this.formEvent.onChange("selectMultiple", e, "grusuario")
    }

    private getLayers(grUsuario: number) {
        this.setState({isLoading: true})

        this.props.viewModel.fetchLayers([grUsuario]).then((rs) => {
            this.getLayersCheck(rs['hierarquia'].Resultado, rs['permissoes'].Resultado)
            this.setState({
                isLoading: false,
                layers: rs['hierarquia'].Resultado,
                isLoadingLayer: true
            })
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private getLayersCheck(listCamadas: Array<any>, layers: Array<any>) {
        var checkPasta = {} as any; 
        var checkCamada = {} as any;
        
        let callback = (camadas: Array<any>, camadaPai: any) => {
            camadas.forEach((item) => {
                var camadasFilhos = item['camadaFilhos'] as Array<any>
                let layerFilter = layers.filter((x) => {
                    return x['scamada'] == item['scamada']
                })

                if(layerFilter.length > 0) {
                    var keys = Object.keys(camadaPai)
                    keys.forEach((key) => {
                        checkPasta[key] = true
                    })

                    if(checkCamada[item['scamadapai']] == undefined) {
                        checkCamada[item['scamadapai']] = {} as any
                    }

                    checkCamada[item['scamadapai']][item['scamada']] = true
                } else if(camadasFilhos.length > 0) {
                    camadaPai[item['scamada']] = true
                    callback(camadasFilhos, camadaPai)
                }
            });
        }

        listCamadas.forEach((item) => {
            var camadasFilhos = item['camadaFilhos'] as Array<any>
            var camadaPai = {} as any
            camadaPai[item['scamada']] = true
            if(camadasFilhos.length > 0) {
                callback(camadasFilhos, camadaPai)
            }

        })

        this.setState({
            checkPasta: checkPasta,
            checkCamada: checkCamada
        })
    }

    private gerarMenuCamada(){

        var arrayCamadas    = this.state.layers;
        var arrayHierarquia = new Array<any>();
        var checkPasta      = this.state.checkPasta as any;

        arrayCamadas.forEach((valor, index) => {
            var itensSub = valor.camadaFilhos as Array<any>;
            var check    = checkPasta[valor.scamada] != undefined;
            arrayHierarquia.push(
                <div>
                    <Row key={`item-menu-${index}`}>
                        <Col xs="12" style={{marginTop: 20, marginLeft: 10}}>
                            <Image
                            style={{marginTop: -3, float: "left"}}
                            src={check ? pastaAbertaAmarela : pastaFechadaAmarela}
                            width={20}
                            className="center"
                            fluid  />
                            <Form.Check
                                style={{fontSize: 12, marginLeft: 10, marginTop: -5, float: "left"}}
                                type={"checkbox"}>
                                <Form.Check.Input 
                                onChange={() => this.setChecked(true, 0, valor)}
                                checked={check}
                                type={"checkbox"}/>
                                <Form.Check.Label style={{marginTop: 3}}>{valor.camada}</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    {check && itensSub.length > 0 && this.gerarMenuCamadaFilhos(valor.scamada, itensSub, 25)}
                </div>
            )
        });

        return (
            <div id="camada-hierarquia" style={{marginLeft: 5}}>
                {arrayHierarquia}
            </div>
        )

    }

    private gerarMenuCamadaFilhos(idCamadaPai: any, arrayCamadas: Array<any>, marginLeft: number){

        var arrayHierarquia = new Array<any>();
        marginLeft         += 5;
        arrayCamadas.forEach((valor, index) => {
            var itensSub = valor.camadaFilhos as Array<any>;
            var tipoImg  = itensSub.length == 0 ? 'arquivo' : 'pasta';
            var check    = false;
            if(tipoImg == 'arquivo'){
                var checkCamada = this.state.checkCamada as any;
                check           = checkCamada[idCamadaPai] != undefined ? checkCamada[idCamadaPai][valor.scamada] != undefined ? true : false : false;
            } else {
                var checkPasta  = this.state.checkPasta as any;
                check           = checkPasta[valor.scamada] != undefined;
            }

            arrayHierarquia.push(
                <div>
                    <Row>
                        <Col xs="12" style={{marginTop: 10, marginLeft: marginLeft}} key={`item-menu-${index}`}>
                            <Image
                            style={{marginTop: -3, float: "left"}}
                            src={tipoImg == 'arquivo' ? arquivoBranco : check ? pastaAbertaAmarela : pastaFechadaAmarela}
                            width={20}
                            className="center"
                            fluid  />
                            <Form.Check
                                style={{fontSize: 12, marginLeft: 10, marginTop: -5, float: "left"}}
                                type={"checkbox"}>
                                <Form.Check.Input
                                onChange={() => this.setChecked(tipoImg == 'pasta', idCamadaPai, valor)} 
                                checked={check}
                                type={"checkbox"}/>
                                <Form.Check.Label style={{marginTop: 3}}>{valor.camada}</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    {check && itensSub.length > 0 && this.gerarMenuCamadaFilhos(valor.scamada, itensSub, marginLeft)}
                </div>
                
            )

        });

        return arrayHierarquia;

    }

    private setChecked(isPasta: boolean, idCamadaPai: any, obj: any){

        if(isPasta){
            var checked       = this.state.checkPasta as any;
            var checkedCamada = this.state.checkCamada as any;
            if(checked[obj.scamada]){
                delete checked[obj.scamada];
                delete checkedCamada[obj.scamada];
            } else {
                checked[obj.scamada] = true;
            }

            this.setState({
                checkPasta  : checked,
                checkCamada : checkedCamada
            });
            return;
        } 

        var checked = this.state.checkCamada as any;
        if(!checked[idCamadaPai]){
            checked[idCamadaPai] = {} as any;
            checked[idCamadaPai][obj.scamada] = true;
        } else {
            if(checked[idCamadaPai][obj.scamada]){
                delete checked[idCamadaPai][obj.scamada];
            } else {
                checked[idCamadaPai][obj.scamada] = true;
            }
        }

        this.setState({
            checkCamada: checked
        });

    }

    private save() {
        var json = this.state.json as any 
        if(!json['grusuario']) {
            this.showFieldsEmpty("Nenhum grupo de usuário selecionado!")
            return
        }

        if(json['grusuario'].length == 0) {
            this.showFieldsEmpty("Nenhum grupo de usuário selecionado!")
            return
        }

        let checkCamada = this.state.checkCamada as any 
        let keys = Object.keys(checkCamada)
        let layers = Array<number>() 

        keys.forEach((item) => {
            var keysChildreen = Object.keys(checkCamada[item])
            keysChildreen.forEach((itemChildreen) => {
                layers.push(parseInt(itemChildreen))
            })
        })

        this.props.viewModel.fetchSave(json['grusuario'], layers).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadUserGroup() {
        this.setState({isLoading: true})
        this.props.viewModel.fetchSelectUserGroup().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                userGroup: array,
                isLoading: false
            })
        });
    }
}