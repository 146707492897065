import { Request } from '../../Servidor/Request';

export class MarkerImageRequest {
    private smarcadorimagem: number;
    private nome: string;
    private tipo: string;
    private smarcadorimagemicone: number;
    private smarcadorimagemiconedados: number;
    private smarcadorimagemiconeclick: number;
    private smarcadorimagemiconefiltro: number;
    private preeenchimento: string;
    private preeenchimentoclick: string;
    private preenchimentofiltro: string;
    private opacidade: number;
    private opacidadeborda: number;
    private borda: string;
    private bordaclick: string;
    private bordafiltro: string;
    private largura: number;
    private raio: number;


	constructor(smarcadorimagem: number, nome: string, tipo: string, smarcadorimagemicone: number, smarcadorimagemiconedados: number, smarcadorimagemiconeclick: number,  smarcadorimagemiconefiltro: number, preeenchimento: string, preeenchimentoclick: string, preenchimentofiltro: string, opacidade: number, opacidadeborda: number, borda: string, bordaclick: string, bordafiltro: string, largura: number, raio: number) {
		this.smarcadorimagem = smarcadorimagem;
        this.nome = nome;
        this.tipo = tipo;
		this.smarcadorimagemicone = smarcadorimagemicone;
		this.smarcadorimagemiconedados = smarcadorimagemiconedados;
		this.smarcadorimagemiconeclick = smarcadorimagemiconeclick;
        this.smarcadorimagemiconefiltro = smarcadorimagemiconefiltro;
		this.preeenchimento = preeenchimento;
		this.preeenchimentoclick = preeenchimentoclick;
        this.preenchimentofiltro = preenchimentofiltro;
		this.opacidade = opacidade;
		this.opacidadeborda = opacidadeborda;
		this.borda = borda;
		this.bordaclick = bordaclick;
        this.bordafiltro = bordafiltro;
		this.largura = largura;
		this.raio = raio;
	}


    /**
     * Getter smarcadorimagem
     * @return {number}
     */
	public getSmarcadorimagem(): number {
		return this.smarcadorimagem;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter smarcadorimagemicone
     * @return {number}
     */
	public getSmarcadorimagemicone(): number {
		return this.smarcadorimagemicone;
	}

    /**
     * Getter smarcadorimagemiconedados
     * @return {number}
     */
	public getSmarcadorimagemiconedados(): number {
		return this.smarcadorimagemiconedados;
	}

    /**
     * Getter smarcadorimagemiconeclick
     * @return {number}
     */
	public getSmarcadorimagemiconeclick(): number {
		return this.smarcadorimagemiconeclick;
	}

    /**
     * Getter smarcadorimagemiconefiltro
     * @return {number}
     */
	public getSmarcadorimagemiconefiltro(): number {
		return this.smarcadorimagemiconefiltro;
	}

    /**
     * Getter preenchimento
     * @return {string}
     */
	public getPreeenchimento(): string {
		return this.preeenchimento;
	}

    /**
     * Getter preenchimentoclick
     * @return {string}
     */
	public getPreeenchimentoClick(): string {
		return this.preeenchimentoclick;
	}

    /**
     * Getter preenchimentofiltro
     * @return {string}
     */
	public getPreeenchimentofiltro(): string {
		return this.preenchimentofiltro;
	}

    /**
     * Getter opacidade
     * @return {number}
     */
	public getOpacidade(): number {
		return this.opacidade;
	}

    /**
     * Getter opacidadeclick
     * @return {number}
     */
	public getOpacidadeborda(): number {
		return this.opacidadeborda;
	}

    /**
     * Getter borda
     * @return {string}
     */
	public getBorda(): string {
		return this.borda;
	}

    /**
     * Getter bordaclick
     * @return {string}
     */
	public getBordaclick(): string {
		return this.bordaclick;
	}

    /**
     * Getter bordafiltro
     * @return {string}
     */
	public getBordafiltro(): string {
		return this.bordafiltro;
	}

    /**
     * Getter largura
     * @return {number}
     */
	public getLargura(): number {
		return this.largura;
	}

    /**
     * Getter raio
     * @return {number}
     */
	public getRaio(): number {
		return this.raio;
    }
    

    /**
     * Getter tipo
     * @return {string}
     */
	public getTipo(): string {
		return this.tipo;
	}

}

export class MarkerImageResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success;
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}

}

export class MarkerImageStructRequest {
    private smarcadorimagem: number;

	constructor(smarcadorimagem: number) {
        this.smarcadorimagem = smarcadorimagem;
	}



    /**
     * Getter smarcadorimagemicone
     * @return {number}
     */
	public getSmarcadorimagem(): number {
		return this.smarcadorimagem;
	}
    
}

export class MarkerImageStructResponse {
    private smarcadorimagem: number;
    private nome: string;
    private tipo: string;
    private smarcadorimagemicone: number;
    private smarcadorimagemiconedados: number;
    private smarcadorimagemiconeclick: number;
    private smarcadorimagemiconefiltro: number;
    private preeenchimento: string;
    private preeenchimentoclick: string;
    private preeenchimentofiltro: string;
    private opacidade: number;
    private opacidadeborda: number;
    private borda: string;
    private bordaclick: string;
    private bordafiltro: string;
    private largura: number;
    private raio: number;


	constructor(smarcadorimagem: number, nome: string, tipo: string, smarcadorimagemicone: number, smarcadorimagemiconedados: number, smarcadorimagemiconeclick: number, smarcadorimagemiconefiltro: number, preeenchimento: string, preeenchimentoclick: string, preenchimentofiltro: string, opacidade: number, opacidadeborda: number, borda: string, bordaclick: string, bordafiltro: string, largura: number, raio: number) {
		this.smarcadorimagem = smarcadorimagem;
        this.nome = nome;
        this.tipo = tipo;
		this.smarcadorimagemicone = smarcadorimagemicone;
		this.smarcadorimagemiconedados = smarcadorimagemiconedados;
		this.smarcadorimagemiconeclick = smarcadorimagemiconeclick;
        this.smarcadorimagemiconefiltro = smarcadorimagemiconefiltro;
		this.preeenchimento = preeenchimento;
		this.preeenchimentoclick = preeenchimentoclick;
        this.preeenchimentofiltro = preenchimentofiltro;
		this.opacidade = opacidade;
		this.opacidadeborda = opacidadeborda;
		this.borda = borda;
		this.bordaclick = bordaclick;
        this.bordafiltro = bordafiltro;
		this.largura = largura;
		this.raio = raio;
	}


    /**
     * Getter smarcadorimagem
     * @return {number}
     */
	public getSmarcadorimagem(): number {
		return this.smarcadorimagem;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter smarcadorimagemicone
     * @return {number}
     */
	public getSmarcadorimagemicone(): number {
		return this.smarcadorimagemicone;
	}

    /**
     * Getter smarcadorimagemiconedados
     * @return {number}
     */
	public getSmarcadorimagemiconedados(): number {
		return this.smarcadorimagemiconedados;
	}

    /**
     * Getter smarcadorimagemiconeclick
     * @return {number}
     */
	public getSmarcadorimagemiconeclick(): number {
		return this.smarcadorimagemiconeclick;
	}

    /**
     * Getter smarcadorimagemiconefiltro
     * @return {number}
     */
	public getSmarcadorimagemiconefiltro(): number {
		return this.smarcadorimagemiconefiltro;
	}

    /**
     * Getter preenchimento
     * @return {string}
     */
	public getPreeenchimento(): string {
		return this.preeenchimento;
	}

    /**
     * Getter preenchimentoclick
     * @return {string}
     */
	public getPreeenchimentoClick(): string {
		return this.preeenchimentoclick;
	}

    /**
     * Getter preeenchimentofiltro
     * @return {string}
     */
	public getPreeenchimentofiltro(): string {
		return this.preeenchimentofiltro;
	}

    /**
     * Getter opacidade
     * @return {number}
     */
	public getOpacidade(): number {
		return this.opacidade;
	}

    /**
     * Getter opacidadeclick
     * @return {number}
     */
	public getOpacidadeborda(): number {
		return this.opacidadeborda;
	}

    /**
     * Getter borda
     * @return {string}
     */
	public getBorda(): string {
		return this.borda;
	}

    /**
     * Getter bordaclick
     * @return {string}
     */
	public getBordaclick(): string {
		return this.bordaclick;
	}

    /**
     * Getter bordafiltro
     * @return {string}
     */
	public getBordafiltro(): string {
		return this.bordafiltro;
	}

    /**
     * Getter largura
     * @return {number}
     */
	public getLargura(): number {
		return this.largura;
	}

    /**
     * Getter raio
     * @return {number}
     */
	public getRaio(): number {
		return this.raio;
    }
    

    /**
     * Getter tipo
     * @return {string}
     */
	public getTipo(): string {
		return this.tipo;
	}

}

export class MarkerImageIconResponse {
    private id: number;
    private value: string;


	constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
	}


    /**
     * Getter id
     * @return {number}
     */
	public getId(): number {
		return this.id;
	}

    /**
     * Getter value
     * @return {string}
     */
	public getValue(): string {
		return this.value;
	}

}

export class MarkerImageInteractor {
    public fetchSave(request: MarkerImageRequest): PromiseLike<MarkerImageResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-marcador-imagem/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new MarkerImageResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: MarkerImageStructRequest): PromiseLike<MarkerImageStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-marcador-imagem/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectIcon(): PromiseLike<MarkerImageIconResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-marcador-imagem/buscar-icone", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}