import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { CustodyTypeViewController, CustodyTypeViewControllerDelegate } from "../../Screen/CustodyType/CustodyTypeViewController";
import { CustodyTypeViewModel } from "../../Screen/CustodyType/CustodyTypeViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";


export class CustodyTypeCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, CustodyTypeViewControllerDelegate{
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geptpresponsavel")
        return (<ProtectedRoute path="/:base/tipo-responsavel" requiredPermissions={['info_bas.tipo_resp.*']} exact={true} component={(props:any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.tipo_resp.adicionar',
                    edit: 'info_bas.tipo_resp.editar',
                    delete: 'info_bas.tipo_resp.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Tipo de Responsável"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new CustodyTypeViewModel()
        return (<ProtectedRoute path="/:base/tipo-responsavel/cad/:id" requiredPermissions={['info_bas.tipo_resp.adicionar', 'info_bas.tipo_resp.editar']} exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<CustodyTypeViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "geptpresponsavel"){
            navigation.history.push(`tipo-responsavel/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "geptpresponsavel"){
            navigation.history.push(`tipo-responsavel/cad/${idSelection}`);
            return;
        }
    }

}
