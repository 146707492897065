import { Request } from '../../Servidor/Request';

export class TopographyRequest {
    private sgeptopografia: number;
    private nome: string;

	constructor(sgeptopografia: number, nome: string) {
		this.sgeptopografia = sgeptopografia;
		this.nome = nome;
	}


    /**
     * Getter sgeptopografia
     * @return {number}
     */
	public getSgeptopografia(): number {
		return this.sgeptopografia;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class TopographyStructRequest {
    private sgeptopografia: number;

	constructor(sgeptopografia: number) {
        this.sgeptopografia = sgeptopografia;
	}


    /**
     * Getter sgeptopografia
     * @return {number}
     */
	public getSgeptopografia(): number {
		return this.sgeptopografia;
	}

}

export class TopographyStructResponse {
    private sgepsituacaopropriedade: number;
    private nome: string;

	constructor(sgepsituacaopropriedade: number, nome: string) {
		this.sgepsituacaopropriedade = sgepsituacaopropriedade;
		this.nome = nome;
	}


    /**
     * Getter sgepsituacaopropriedade
     * @return {number}
     */
	public getSgepsituacaopropriedade(): number {
		return this.sgepsituacaopropriedade;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class TopographyInteractor {
    public fetchSave(request: TopographyRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/topografia/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: TopographyStructRequest): PromiseLike<TopographyStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/topografia/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}