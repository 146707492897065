import React from 'react';
import { Button, Container, Row, Form, Image, Col } from 'react-bootstrap';
import logo from "../../Imagens/logo-login.png";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { LoginViewModel } from './LoginViewModel';

interface MyProps {
    navigation: any
    delegate: LoginViewControllerDelegate,
    viewModel: LoginViewModel
}

export interface LoginViewControllerDelegate {
    tapMapInitial(navigation: any, token: String): void
}

export class LoginViewController extends React.Component<MyProps>{

    state = {
        user: "",
        password: ""
    }

    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <Container style={{marginTop: 30}}>
                <Row>
                    <Col md={6} sm={12}>
                        <Image 
                        src={logo} 
                        height={200}
                        width={500} 
                        className="center"
                        fluid  />
                    </Col>
                    <Col md={6} sm={12} style={{marginTop: 50}}>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Usuário</Form.Label>
                                <Form.Control type="text" placeholder="Digite seu usuário" onKeyUp={(e: any) => this.onChange("user", e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control type="password" placeholder="Digite sua senha" onKeyUp={(e: any) => this.onChange("password", e.target.value)} />
                            </Form.Group>
                            <Button variant="primary" type="button" style={{float: "right"}} onClick={() => this.autentica()}>
                                Confirmar
                            </Button>
                            <ToastContainer />
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }

    private onChange(key: string, value: String) {
        let state = this.state as any
        state[key] = value

        this.setState(state)
    }

    private autentica() {
        this.props.viewModel.fetchLogin(this.state.user, this.state.password).then(() => {
            var viewModel = this.props.viewModel.$state
            if(viewModel.status == null) {
                return
            }

            if(viewModel.status.$success == false) {
                toast.error(viewModel.status.$message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return
            }

            if(viewModel.status.$token == undefined){
                return
            }

            this.props.delegate.tapMapInitial(this.props.navigation, viewModel.status.$token)
        }, e => {
            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

}