import React from 'react';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { LayerPermissionViewControllerDelegate, LayerPermissionViewController } from "../../Screen/LayerPermission/LayerPermissionViewController";
import { LayerPermissionViewModel } from '../../Screen/LayerPermission/LayerPermissionViewModel';
import { IndexCoordinatorDelegate } from '../IndexCoordinator';

export class LayerPermissionCoordinator implements IndexCoordinatorDelegate, LayerPermissionViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        return [
            this.routeForm()
        ]
    }

    private routeForm(): JSX.Element {
        var viewModel = new LayerPermissionViewModel()
        return (<ProtectedRoute requiredPermissions={['gis.camadas.adicionar', 'gis.camadas.editar']} path="/:base/grupo-camadas" exact={true} component={(props: any) => {
            return (<LayerPermissionViewController navigation={props} viewModel={viewModel} delegate={this}/>)
        }}/>)
    } 

    tapDimiss(navigation: any): void {
        navigation.history.goBack()
    }
}