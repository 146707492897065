import React from 'react'
import { AdminProcessViewModel } from './AdminProcessViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Modal } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import moment from 'moment';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import { PersonViewModel } from '../Person/PersonViewModel';
import { PersonViewController, PersonViewControllerDelegate } from '../Person/PersonViewController';

interface MyProps {
    navigation: any
    delegate: AdminProcessViewControllerDelegate
    viewModel: AdminProcessViewModel
    idPrimary: number
    idProcessAdmin?: number
    idShape?: number
    idProperty?: number
    idPropertyBuilding?: number
}

export interface AdminProcessViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class AdminProcessViewController extends React.Component<MyProps> implements AdminProcessViewControllerDelegate {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        professional: Array<any>(),
        status: Array<any>(),
        tipos: Array<any>(),
        showAddPerson: false
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }
    
    tapDimiss(navigation: any): void {
        this.setState({
            showAddPerson: false
        })
    }

    componentDidMount() {
        this.setKeyFK()
        this.loadProfessional()
        this.loadStatus()
        this.loadTiposProcessos();
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Processo Administrativo</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgepprocessoadministrativo"
                                    defaultValue={json['sgepprocessoadministrativo'] ? json['sgepprocessoadministrativo'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Número do Protocolo</Form.Label>
                                    <Form.Control
                                    id="numeroprotocolo"
                                    maxLength={10}
                                    defaultValue={json['numeroprotocolo'] ? json['numeroprotocolo'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite o número do protocolo" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Data abertura</Form.Label>
                                    <Form.Control
                                    id="dataabertura"
                                    maxLength={10}
                                    defaultValue={json['dataabertura'] ? json['dataabertura'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Digite a data de abertura" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Data conclusão</Form.Label>
                                    <Form.Control
                                    id="dataconclusao"
                                    maxLength={10}
                                    defaultValue={json['dataconclusao'] ? json['dataconclusao'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                    placeholder="Digite a data de conclusão" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <InputModalSelectComponent 
                                    id="sgeppessoa"
                                    keyValue="nome"
                                    label="Solicitante"
                                    required={true}
                                    placeholder="Procure"
                                    showAddButton={true}
                                    navigation={this.props.navigation}
                                    titleModal="Pessoas"
                                    table="geppessoas"
                                    valueDefault={json['nome']}
                                    getValue={(id: number, value: string) => this.setIdPerson(id, value)}
                                    tapAdd={() => this.setState({showAddPerson: true})} />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Responsável pela abertura</Form.Label>
                                    <Select
                                    id="responsavel"
                                    value={this.formEvent.onValueSelect('select', 'responsavel', this.state.professional)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "responsavel")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.professional}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Status</Form.Label>
                                    <Select
                                    id="sgepprocessoadministrativostatus"
                                    value={this.formEvent.onValueSelect('select', 'sgepprocessoadministrativostatus', this.state.status)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepprocessoadministrativostatus")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.status}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Tipo</Form.Label>
                                    <Select
                                    id="sgeptipoprocesso"
                                    value={this.formEvent.onValueSelect('select', 'sgeptipoprocesso', this.state.tipos)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptipoprocesso")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.tipos}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="bottom10">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                    id="descricao"
                                    as="textarea" 
                                    rows="3"
                                    defaultValue={json['descricao'] ? json['descricao'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite uma descrição" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="bottom10">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                    id="observacao"
                                    as="textarea" 
                                    rows="3"
                                    defaultValue={json['observacao'] ? json['observacao'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite uma observação" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                {this.getModalAddPerson()}
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setKeyFK() {
        var json = this.state.json as any
        var dateNow = new Date();

        json['dataabertura'] = moment(dateNow).format('DD/MM/YYYY')
        json['numeroprotocolo'] = ("0" + dateNow.getDate()).substr(-2)+("0" + (dateNow.getMonth() + 1)).substr(-2)+dateNow.getFullYear()+Math.floor(1000 + Math.random() * 9000)

        if(this.props.idShape) {
            json['sshape'] = this.props.idShape
        }

        if(this.props.idProcessAdmin) {
            json['sgepprocessoadministrativopai'] = this.props.idProcessAdmin
        }

        if(this.props.idProperty) {
            json['sgeppropriedade'] = this.props.idProperty
        }

        if(this.props.idPropertyBuilding) {
            json['sgeppropedificacoes'] = this.props.idPropertyBuilding
        }

        this.setState({
            json: json
        })
    }

    private setIdPerson(id: number, value: string) {
        var json = this.state.json as any 
        json['solicitante'] = id 

        this.setState({
            json: json
        })
    }

    private getModalAddPerson() {
        let viewModel = new PersonViewModel()
        return (
            <Modal show={this.state.showAddPerson} dialogClassName="modal-full-screen">
                <Modal.Body>
                    <PersonViewController
                    navigation={this.props.navigation} 
                    delegate={this} 
                    idPrimary={0} 
                    viewModel={viewModel}
                    isShowHeader={false}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    private loadProfessional() {
        this.props.viewModel.fetchProfessional().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                professional: array
            })
        });
    }

    private loadStatus() {
        this.props.viewModel.fetchStatus().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                status: array
            })
        });
    }

    private loadTiposProcessos() {
        this.props.viewModel.fetchTipos().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                tipos: array
            })
        });
    }

    private save() {
        var json = this.state.json as any
        if(json["numeroprotocolo"] == undefined || json["dataabertura"] == undefined || json["responsavel"] == undefined || json["solicitante"] == undefined || json["sgepprocessoadministrativostatus"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["numeroprotocolo"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        var date = moment(json["dataabertura"], "DD/MM/YYYY")
        if(!date.isValid()) {
            this.showFieldsEmpty("Data de abertura inválida!")
            return
        }

        if(date.diff(new Date()) > 0) {
            this.showFieldsEmpty("Data de abertura inválida!")
            return
        }

        if(json["dataconclusao"] != undefined && json["dataconclusao"].length > 0) {
            var dateConclusion = moment(json["dataconclusao"], "DD/MM/YYYY")
            if(!dateConclusion.isValid()) {
                this.showFieldsEmpty("Data de conclusão inválida!")
                return
            }

            if(dateConclusion.diff(new Date()) > 0) {
                this.showFieldsEmpty("Data de conclusão inválida!")
                return
            }
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgepprocessoadministrativo'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                var result = resultado[0]
                result['dataabertura'] = result['dataabertura'] ? moment(result['dataabertura']).format('DD/MM/YYYY') : ""
                result['dataconclusao'] = result['dataconclusao'] ? moment(result['dataconclusao']).format('DD/MM/YYYY') : ""
                this.setState({
                    isLoading: false,
                    json: result
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}