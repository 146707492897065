import { Request } from '../../Servidor/Request';

export class UserRequest {
    private cusuario: number;
    private nome: string;
    private nomecompleto: string;
    private email: string;
    private ativo: string;
    private grusuario: Array<number>;
    private permissions: Array<number>;

	constructor(cusuario: number, nome: string, nomecompleto: string, email: string, ativo: string, grusuario: Array<number>, permissions: Array<number>) {
		this.cusuario = cusuario;
		this.nome = nome;
		this.nomecompleto = nomecompleto;
		this.email = email;
		this.ativo = ativo;
		this.grusuario = grusuario;
        this.permissions = permissions;
    }
    

    /**
     * Getter cusuario
     * @return {number}
     */
	public getCusuario(): number {
		return this.cusuario;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter nomecompleto
     * @return {string}
     */
	public getNomecompleto(): string {
		return this.nomecompleto;
	}

    /**
     * Getter email
     * @return {string}
     */
	public getEmail(): string {
		return this.email;
	}

    /**
     * Getter ativo
     * @return {string}
     */
	public getAtivo(): string {
		return this.ativo;
	}

    /**
     * Getter grusuario
     * @return {Array<number>}
     */
	public getGrusuario(): Array<number> {
		return this.grusuario;
	}
	
}

export class UserResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class UserStructRequest {
    private cusuario: number;

	constructor(cusuario: number) {
        this.cusuario = cusuario;
	}


    /**
     * Getter cusuario
     * @return {number}
     */
	public getCusuario(): number {
		return this.cusuario;
	}

}

export class UserStructResponse {
    private cusuario: number;
    private nome: string;
    private nomecompleto: string;
    private email: string;
    private ativo: string;
    private grusuario: Array<any>;
    private permissions: Array<any>;

	constructor(cusuario: number, nome: string, nomecompleto: string, email: string, ativo: string, grusuario: Array<any>, permissions: Array<any>) {
		this.cusuario = cusuario;
		this.nome = nome;
		this.nomecompleto = nomecompleto;
		this.email = email;
		this.ativo = ativo;
		this.grusuario = grusuario;
        this.permissions = permissions;
    }
    

    /**
     * Setter grusuario
     * @param {Array<any>} value
     */
	public setGrusuario(value: Array<any>) {
		this.grusuario = value;
	}

    /**
     * Setter permissions
     * @param {Array<any>} value
     */
    public setPermissions(value: Array<any>) {
        this.permissions = value;
    }


    /**
     * Getter cusuario
     * @return {number}
     */
	public getCusuario(): number {
		return this.cusuario;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter nomecompleto
     * @return {string}
     */
	public getNomecompleto(): string {
		return this.nomecompleto;
	}

    /**
     * Getter email
     * @return {string}
     */
	public getEmail(): string {
		return this.email;
	}

    /**
     * Getter ativo
     * @return {string}
     */
	public getAtivo(): string {
		return this.ativo;
	}

    /**
     * Getter grusuario
     * @return {Array<any>}
     */
	public getGrusuario(): Array<any> {
		return this.grusuario;
	}

     /**
     * Getter permissions
     * @return {Array<any>}
     */
	public getPermissions(): Array<any> {
		return this.permissions;
	}
}

export class UserGroupResponse {
    private cgrusuario: number;
    private grusuario: string;

	constructor(cgrusuario: number, grusuario: string) {
		this.cgrusuario = cgrusuario;
		this.grusuario = grusuario;
	}


    /**
     * Getter cgrusuario
     * @return {number}
     */
	public getCgrusuario(): number {
		return this.cgrusuario;
	}

    /**
     * Getter grusuario
     * @return {string}
     */
	public getGrusuario(): string {
		return this.grusuario;
	}
}

export class ResetPasswordRequest {
    private cusuario: number;
    private senha: string;

	constructor(cusuario: number, senha: string) {
        this.cusuario = cusuario 
        this.senha = senha
    }
    

    /**
     * Getter cusuario
     * @return {number}
     */
	public getCusuario(): number {
		return this.cusuario;
	}

    /**
     * Getter senha
     * @return {string}
     */
	public getSenha(): string {
		return this.senha;
	}

}

export class PermissionsResponse {
    private sgeppermissao: number;
    private nome: string;

	constructor(sgeppermissao: number, nome: string) {
		this.sgeppermissao = sgeppermissao;
		this.nome = nome;
	}


    /**
     * Getter sgeppermissao
     * @return {number}
     */
	public getSgeppermissao(): number {
		return this.sgeppermissao;
	}

    /**
     * Getter grusuario
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class UserInteractor {
    public fetchSave(request: UserRequest): PromiseLike<UserResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/usuario/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new UserResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: UserStructRequest): PromiseLike<[UserStructResponse]> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/usuario/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectUserGroup(): PromiseLike<UserGroupResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/usuario/buscar-grupo-usuario", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectPermissions(): PromiseLike<PermissionsResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/permissao/buscar-permissoes", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchResetPassword(request: ResetPasswordRequest): PromiseLike<void> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/usuario/resetar-senha", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res()
            }, e => {
                rej(e);
            });
        });
    }
}