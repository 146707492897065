import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { DocumentTypeLinkViewController, DocumentTypeLinkViewControllerDelegate } from "../../Screen/DocumentTypeLink/DocumentTypeLinkViewController";
import { DocumentTypeLinkViewModel } from "../../Screen/DocumentTypeLink/DocumentTypeLinkViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from "../../Components/ProtectedRoute";

export class DocumentTypeLinkCoordinator implements DocumentTypeLinkViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[]{
        return[
            this.routeForm(),
            this.routeTable()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geppessoatpdoc")
        return (<ProtectedRoute requiredPermissions={['info_bas.tipo_doc.*']} path="/:base/pessoa/:id/tipo-documento" exact={true} component={(props:any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Pessoa', idFk)
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.tipo_doc.adicionar',
                    edit: 'info_bas.tipo_doc.editar',
                    delete: 'info_bas.tipo_doc.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Tipo de Documento - Pessoa"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new DocumentTypeLinkViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.tipo_doc.adicionar', 'info_bas.tipo_doc.editar']} path="/:base/pessoa/:idpessoa/tipo-documento/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            var idPerson = props.match.params.idpessoa
            return (<DocumentTypeLinkViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idPerson={idPerson}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    tapDocumentType(navigation: any, id: number): void {
        if(id > 0) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/pessoas/${id}/tipo-documento`);
        }
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "geppessoatpdoc"){
            navigation.history.push(`tipo-documento/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "geppessoatpdoc"){
            navigation.history.push(`tipo-documento/cad/${idSelection}`);
            return;
        }
    }
}