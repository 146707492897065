import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import arrowDown from '../../Imagens/arrow-down-16.png'
import arrowRight from '../../Imagens/arrow-right.png'

interface FiltroCategoriaProps {
    openedFilter: string
    openedFilterName: string
    setOpenedFilter: (value: React.SetStateAction<string>) => void
    clearMapFilters: () => void
    labelName: string
}
export const FiltroCategoriaComponent: React.FC<FiltroCategoriaProps> = ({ clearMapFilters, openedFilter, setOpenedFilter, labelName, openedFilterName }) => {
    return (
        <Row className='filtro-categoria' onClick={() => { clearMapFilters(); setOpenedFilter(openedFilter === openedFilterName ? "" : openedFilterName) }}>
            <Col className='col-auto col-lg-1'>
                <Image
                    style={{ margin: 5, padding: 1, cursor: "pointer", alignItems: 'center' }}
                    src={openedFilter === openedFilterName ? arrowDown : arrowRight}
                    width={16}
                />
            </Col>
            <Col className='col-auto col-lg-11'>
                <span>{labelName}</span>
            </Col>
        </Row>
    )
}