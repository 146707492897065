import React from 'react'
import { PointTypeViewModel } from './PointTypeViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 
import adicionar from '../../Imagens/add-16.png';
import editar from '../../Imagens/edit-16.png';
import deletar from '../../Imagens/delete-16.png';

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { confirmAlert } from 'react-confirm-alert';

interface MyProps {
    navigation: any
    delegate: PointTypeViewControllerDelegate
    viewModel: PointTypeViewModel
    idPrimary: number
}

export interface PointTypeViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class PointTypeViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        arrayItens: new Array<any>(),
        indexSelectItem: -1,
        showFormItem: false,
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    private changeColorLine(index: number): string {
        return this.state.indexSelectItem === index ? '#eeeeee' : '#ffffff'
    }

    private setShow(isClearJSON: Boolean) {

        if(isClearJSON) {
            this.clearJSON()
        }

        var showFormItem = this.state.showFormItem
        this.setState({
            showFormItem: !showFormItem
        })
    }

    private setEdit() {
        var index = this.state.indexSelectItem
        var array = this.state.arrayItens
        if(index === -1) {
            return
        }

        if(array[index]) {
            var json = this.state.json as any 
            json['indexSelected'] = index 
            json['item_id'] = array[index]["sgeppontodiversos"] 
            json['item_nome'] = array[index]["nome"] 
            json['item_ordem'] = array[index]["ordem"]  
            json['item_obrigatorio'] = array[index]["obrigatorio"]  

            this.setState({json: json})
            this.setShow(false)
        }
    }

    private setDelete() {
        var index = this.state.indexSelectItem
        var array = this.state.arrayItens
        if(index === -1) {
            return
        }

        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    array.splice(index, 1)
                    this.setState({
                        arrayItens: array,
                        indexSelectItem: -1
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private clearJSON() {
        var json = this.state.json as any 
        delete json['item_id']
        delete json['item_nome']
        delete json['item_obrigatorio']
        delete json['item_ordem']
        this.setState({
            json: json
        })
    }

    private getContainerTable() {
        var container = new Array<any>()
        var array = this.state.arrayItens

        array.forEach((item, index) => {
            container.push((
                <tr 
                key={`${index}`} 
                style={{backgroundColor: this.changeColorLine(index)}}
                onClick={() => this.selectLine(index)}>
                    <td>{item.nome}</td>
                    <td>{item.ordem}</td>
                    <td>{item.obrigatorio ? "Sim" : "Não"}</td>
                </tr>
            ))
        })

        return container
    }

    private selectLine(index: number) {
        this.setState({
            indexSelectItem: index
        })
    }

    private saveItem() {
        var json = this.state.json as any
        var array = this.state.arrayItens as Array<any>

        if(json["item_nome"] === undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }
        if(json["item_ordem"] === undefined) {
            json["item_ordem"] = 1
        }
        if(json["item_obrigatorio"] === undefined) {
            json["item_obrigatorio"] = false
        }
        var copyJSON = Object.assign({}, json)
        var jsonFinal = {} as any
        jsonFinal['sgeppontodiversos'] = copyJSON['item_id']
        jsonFinal['nome'] = copyJSON['item_nome']
        jsonFinal['ordem'] = copyJSON['item_ordem']
        jsonFinal['obrigatorio'] = copyJSON['item_obrigatorio'] === "1"

        if(json['indexSelected'] >= 0) {
            array[json['indexSelected']] = jsonFinal
        } else {
            array.push(jsonFinal)
        }

        this.setState({
            arrayItens: array,
            showFormItem: false
        })
    }

    private getModal() {
        var json = this.state.json as any
        return (
            <Modal show={this.state.showFormItem}>
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{fontSize: "20px", fontWeight: "bold"}}>Cadastro Item</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                id="sgeppontodiversos"
                                defaultValue={json['item_id'] ? json['item_id'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Nome</Form.Label>
                                <Form.Control
                                id="item_nome"
                                defaultValue={json['item_nome'] ? json['item_nome'] : ''}
                                onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Ordem</Form.Label>
                                <Form.Control
                                id="item_ordem"
                                type="number"
                                defaultValue={json['item_ordem'] ? json['item_ordem'] : 1}
                                onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">É Obrigatório</Form.Label>
                                <Form.Control
                                 as="select"
                                id="item_obrigatorio"
                                defaultValue={json['item_obrigatorio'] ? '1' : '0'}
                                onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                >
                                     <option value={1}>Sim</option>
                                     <option value={0}>Não</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{marginTop: 20}}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.saveItem()}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.setShow(false)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Tipo de Ponto</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgeppiscina"
                                    defaultValue={json['sgeptipoponto'] ? json['sgeptipoponto'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                    id="nome"
                                    defaultValue={json['nome'] ? json['nome'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite o nome" 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="containerActions">
                                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setShow(true)}>
                                        <Image 
                                        className="iconButtonGeneric"
                                        src={adicionar}/>
                                        Adicionar
                                    </Button>
                                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setEdit()}>
                                        <Image 
                                        className="iconButtonGeneric"
                                        src={editar}/>
                                        Editar
                                    </Button>
                                    <Button variant="danger" type="button" className="buttonGeneric colorRed" onClick={() => this.setDelete()}>
                                        <Image 
                                        className="iconButtonGeneric"
                                        src={deletar}/>
                                        Excluir
                                    </Button>
                                </Col>
                                <Col xs="12">
                                    <div className="tableData">
                                        <Table responsive={true}>
                                            <thead className="tableDataHead">
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Ordem</th>
                                                    <th>É Obrigatório</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableDataBody">
                                                {this.getContainerTable()}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="tableContainerNav">
                                        <Container fluid={true}>
                                            <Row>
                                                <Col xs="12">
                                                    <div style={{height: 10}}></div>
                                                </Col> 
                                            </Row> 
                                        </Container>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                    {this.getModal()}
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private save() {
        var json = this.state.json as any
        if(json["nome"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["nome"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }
        json["itens"] = this.state.arrayItens

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgeptipoponto'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0],
                    arrayItens: resultado[0].itens
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}