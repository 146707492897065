import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { ImportRealStateRegistrationViewController, ImportRealStateRegistrationViewControllerDelegate } from '../../Screen/ImportRealStateRegistration/ImportRealStateRegistrationViewController';
import { ImportRealStateRegistrationViewModel } from '../../Screen/ImportRealStateRegistration/ImportRealStateRegistrationViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class ImportRealStateRegistrationCoordinator implements IndexCoordinatorDelegate, ImportRealStateRegistrationViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeForm()
        ]
    }

    private routeForm(): JSX.Element {
        var viewModel = new ImportRealStateRegistrationViewModel()
        return (<ProtectedRoute requiredPermissions={['config.importacao_cad_imob.ver']} path="/:base/importar-cadastro-imobiliario" exact={true} component={(props: any) => {
            return (<ImportRealStateRegistrationViewController navigation={props} viewModel={viewModel} delegate={this} />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
}