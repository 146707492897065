import { Request } from '../../Servidor/Request';
import Cookie from "js-cookie";

export class LoginRequest {
    private user: String;
    private password: String;
    private path: String;

	constructor($user: String, $password: String) {
		this.user = $user;
        this.password = $password;
        this.path = "login/autenticar"
    }
    
    /**
     * Getter $user
     * @return {String}
     */
	public get $user(): String {
		return this.user;
	}

    /**
     * Getter $password
     * @return {String}
     */
	public get $password(): String {
		return this.password;
    }
    
    /**
     * Getter $password
     * @return {String}
     */
	public get $path(): String {
		return this.path;
	}
    
}

export class LoginResponse {
    private success: boolean
    private message: String
    private token?: String

    constructor($success: boolean, $message: String, $token?: String) {
        this.success = $success
        this.message = $message
        this.token = $token
    }
    
     /**
     * Getter $success
     * @return {boolean}
     */
	public get $success(): boolean {
		return this.success
    }
    
     /**
     * Getter $message
     * @return {String}
     */
	public get $message(): String {
		return this.message
    }
    
     /**
     * Getter $message
     * @return {String}
     */
	public get $token(): String {
		return this.token!
	}
}

export class LoginInteractor {
    public fetchLogin(request: LoginRequest): PromiseLike<LoginResponse> {
        var json = {
            usuario: request.$user,
            senha: request.$password
        }

        return new Promise((res, rej) => {
            return new Request().requestPost(request.$path, json).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new LoginResponse(rs.Status == "Sucesso", rs.Mensagem, rs.token)
                Cookie.set('token', rs.token)
                res(response)
            }, e => {
                rej(e);
            });
        });
        
    }
}