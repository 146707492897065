import React from 'react';
import moment from 'moment';

export type TypeComponent = "inteiro" | "select" | "varchar" | "selectMultiple" | "color" | "double" | "cpf" | "cnpj" | "date" | "hour" | "user" | "money" | "cep" | "phone" | "year" | "checkbox"
export class FormEvent {
    private component: React.Component

    constructor(component: React.Component) {
        this.component = component
    }

    public onChange(typeComponent: TypeComponent, target: any, idHtml?: string) {
        var id = idHtml == undefined ? target.id : idHtml
        var value = target == null ? null : target.value

        var state = this.component.state as any
        var json = state.json as any
        switch (typeComponent) {
            case "inteiro":
                var char = String(value).charAt(value.length - 1)
                var regex = /[\d]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }
        
                target.value = value
                break;
            case "double":
                var char = String(value).charAt(value.length - 1)
                var regex = /^(-?\d*)((\.(\d{0,2})?)?)$/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }

                target.value = value
                break;
            case "date":
                var char = String(value).charAt(value.length - 1)
                var regex = /[\d]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }

                if(value.length == 2 || value.length == 5) {
                    value += "/"
                }

                target.value = value
                break;
            case "hour":
                    var char = String(value).charAt(value.length - 1)
                    var regex = /[\d]/
    
                    if(!regex.test(char)) {
                        value = String(value).substring(0, value.length - 1)
                    }
    
                    if(value.length == 2) {
                        value += ":"
                    }
    
                    target.value = value
                    break;
            case "cpf":
                var char = String(value).charAt(value.length - 1)
                var regex = /[\d]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }

                if(value.length == 3 || value.length == 7) {
                    value += "."
                }

                if(value.length == 11) {
                    value += "-"
                }
                
                target.value = value
                break;
            case "cnpj":
                var char = String(value).charAt(value.length - 1)
                var regex = /[\d]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }
    
                if(value.length == 2 || value.length == 6) {
                    value += "."
                }

                if(value.length == 10) {
                    value += "/"
                }

                if(value.length == 15) {
                    value += "-"
                }
                
                target.value = value
                break;
            case "color":
                value = target
                break;
            case "selectMultiple":
                var result = new Array<any>() 
                var arrayTarget = target == null ? [] : target as Array<any>
                for(var i = 0; i < arrayTarget.length; i++) {
                    result.push(arrayTarget[i]['value'])
                }

                value = result
                break;
            case "checkbox":
                    if (target.target.checked) {
                        if (json[id]) {
                            json[id] = [...json[id], parseInt(target.target.value)];
                        } else {
                            json[id] = [parseInt(target.target.value)];
                        }
                    } else {
                        if (json[id]) {
                            json[id] = json[id].filter((val: any) => val !== parseInt(target.target.value));
                        }
                    }
                    this.component.setState({ json });
                    return;
            case "user":
                var char = String(value).charAt(value.length - 1)
                var regex = /[a-zA-Z_.-]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }
        
                target.value = String(value).toUpperCase()
                break;
            case "money":
                value = target.floatValue
                break;
            case "cep":
                var char = String(value).charAt(value.length - 1)
                var regex = /[\d]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }

                if(value.length == 5) {
                    value += "-"
                }
                
                target.value = value
                break;
            case "phone":
                value = target.floatValue
                break;
            case "year":
                var char = String(value).charAt(value.length - 1)
                var regex = /[\d]/

                if(!regex.test(char)) {
                    value = String(value).substring(0, value.length - 1)
                }

                if(value.length == 5) {
                    value = String(value).substring(0, value.length - 1)
                }
                
                target.value = value
                break;
            default:
                json[id] = target.value
                break;
        }

        json[id] = value
        this.component.setState({
            json: json
        })
    }

    public onValueSelect(typeComponent: TypeComponent, key: string, array: Array<any>): any {
        var state = this.component.state as any
        var json = state.json as any 

        if(typeComponent == 'select') {
            if(json[key]) {
                var filter = array.filter((x) => {
                    return x.value == json[key]
                })

                if(filter.length > 0) {
                    return filter[0]
                }
            }
        } else if(typeComponent == 'selectMultiple') {
            if(json[key]) {
                var filter = array.filter((x) => {
                    return json[key].indexOf(x.value) != -1
                })

                return filter
            }
        }
    }

    public onDateSelect(key: string): any {
        var state = this.component.state as any
        var json = state.json as any

        if(json[key]) {
            return moment.utc(json[key]).format('DD/MM/YYYY')
        }

        return ''
    }

    public isChecked(key: string, value: any): boolean {
        var state = this.component.state as any;
        var json = state.json as any;
      
        if (json[key]) {
          return json[key].includes(parseInt(value));
        }
        
        return false;
      }

    public onCPFSelect(key: string): any {
        var state = this.component.state as any
        var json = state.json as any

        if(json[key]) {
            var cpf = ''
            var value = String(json[key])
            for(var i = 0; i < value.length; i++) {
                cpf += value[i]
                if(cpf.length == 3 || cpf.length == 7) {
                    cpf += '.'
                }

                if(cpf.length == 11) {
                    cpf += '-'
                }
            }

            return cpf
        }

        return ''
    }

    public onCNPJSelect(key: string): any {
        var state = this.component.state as any
        var json = state.json as any

        if(json[key]) {
            var cnpj = ''
            var value = String(json[key])
            for(var i = 0; i < value.length; i++) {
                cnpj += value[i]
                if(cnpj.length == 2 || cnpj.length == 6) {
                    cnpj += '.'
                }

                if(cnpj.length == 10) {
                    cnpj += '/'
                }

                if(cnpj.length == 15) {
                    cnpj += '-'
                }
            }

            return cnpj
        }

        return ''
    }
}