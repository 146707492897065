import { DistrictInteractor, DistrictRequest, DistrictStructRequest } from "../../Coordinator/District/DistrictInteractor";

export class DistrictViewModel {
    private interactor: DistrictInteractor;

	constructor() {
		this.interactor = new DistrictInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new DistrictRequest(
            obj["sgepdistrito"] ? parseInt(obj["sgepdistrito"]) : 0,
            obj["nome"] ? obj["nome"] : "",
			obj["sigla"] ? obj["sigla"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new DistrictStructRequest(
            obj["sgepdistrito"] ? parseInt(obj["sgepdistrito"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}