import { LoginInteractor, LoginRequest } from "../../Coordinator/Login/LoginInteractor";
import { LoginState, StatusLogin } from "./LoginState";

export class LoginViewModel {
	private interactor: LoginInteractor
	private state: LoginState

	constructor() {
		this.interactor = new LoginInteractor()
		this.state = new LoginState()
	}

	public get $state(): LoginState {
		return this.state
	}

	public fetchLogin(user: String, password: String): PromiseLike<any> {
		let request = new LoginRequest(user, password)
		return new Promise((res, rej) => {
			this.interactor.fetchLogin(request).then((result) => {
				this.state.status = new StatusLogin(result.$success, result.$message, result.$token)
				return res()
			}, e => {
				return rej(e)
			});
		})
	}
}