import React from 'react';
import { MarkerIconViewControllerDelegate,  MarkerIconViewController} from "../../Screen/MarkerIcon/MarkerIconViewController";
import { MarkerIconViewModel } from '../../Screen/MarkerIcon/MarkerIconViewModel';
import { IndexCoordinatorDelegate } from '../IndexCoordinator';
import { NavOptions, TableGenericViewController, TableGenericViewControllerDelegate } from '../../Screen/TableGeneric/TableGenericViewController';
import { TableGenericViewModel } from '../../Screen/TableGeneric/TableGenericViewModel';
import { ToastContainer, toast } from 'react-toastify';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class MarkerIconCoordinator implements IndexCoordinatorDelegate, MarkerIconViewControllerDelegate, TableGenericViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        return [
            this.routeTableMarkerIcon(),
            this.routeFormMarkerIcon()
        ]
    }

    private routeTableMarkerIcon(): JSX.Element {
        var arrayActions = new Array<NavOptions>()
        arrayActions.push({
            title: "Importar imagem", 
            action: (navigation: any, item: any, component: React.Component) => this.importarIcon(navigation, item, component), 
            isSelection: true,
            uploadFile: true,
            multipleFile: false
        })

        var viewModel = new TableGenericViewModel("marcadorimagemicone")
        return (<ProtectedRoute requiredPermissions={['gis.icone.*']} path="/:base/camada-icone" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'gis.icone.adicionar',
                    edit: 'gis.icone.editar',
                    delete: 'gis.icone.excluir',
                }}  
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Ícones"}
                actions={arrayActions}
            />)
        }}/>)
    }

    private routeFormMarkerIcon(): JSX.Element {
        var viewModel = new MarkerIconViewModel()
        return (<ProtectedRoute requiredPermissions={['gis.icone.adicionar', 'gis.icone.editar']} path="/:base/camada-icone/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<MarkerIconViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    private importarIcon(navigation: any, item: any, component: React.Component) {
        var files = item.files as Array<any>
        var json = {
            smarcadorimagemicone: item.json['id']
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(json));
        component.setState({
            showLoading: true
        })

        var viewModelLayer = new MarkerIconViewModel()
        viewModelLayer.fetchImportIcon(formData).then((rs) => {
            component.setState({
                showLoading: false
            })

            toast.success("Arquivos importados com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    public tapDimiss(navigation: any) {
        navigation.history.goBack();
    }

    public add(navigation: any, viewModel: TableGenericViewModel) {
        if(viewModel.getTable() == "marcadorimagemicone") {
            navigation.history.push(`camada-icone/cad/0`);
            return;
        }
    }
    
    public edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel) {
        if(viewModel.getTable() == "marcadorimagemicone") {
            navigation.history.push(`camada-icone/cad/${idSelection}`);
            return;
        }
    }
}