import { Request } from '../../Servidor/Request';

export class LotOccupationRequest {
    private sgepocupacaolote: number;
    private nome: string;

	constructor(sgepocupacaolote: number, nome: string) {
		this.sgepocupacaolote = sgepocupacaolote;
		this.nome = nome;
	}


    /**
     * Getter sgepocupacaolote
     * @return {number}
     */
	public getSgepocupacaolote(): number {
		return this.sgepocupacaolote;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class LotOccupationStructRequest {
    private sgepocupacaolote: number;

	constructor(sgepocupacaolote: number) {
        this.sgepocupacaolote = sgepocupacaolote;
	}


    /**
     * Getter sgepocupacaolote
     * @return {number}
     */
	public getSgepocupacaolote(): number {
		return this.sgepocupacaolote;
	}

}

export class LotOccupationStructResponse {
    private sgepocupacaolote: number;
    private nome: string;

	constructor(sgepocupacaolote: number, nome: string) {
		this.sgepocupacaolote = sgepocupacaolote;
		this.nome = nome;
	}


    /**
     * Getter sgepocupacaolote
     * @return {number}
     */
	public getSgepocupacaolote(): number {
		return this.sgepocupacaolote;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class LotOccupationInteractor {
    public fetchSave(request: LotOccupationRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/ocupacao-lote/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: LotOccupationStructRequest): PromiseLike<LotOccupationStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/ocupacao-lote/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}