import {AdminProcessStatusRequest, AdminProcessStatusStructRequest, AdminProcessStatusInteractor} from "../../Coordinator/AdminProcessStatus/AdminProcessStatusInteractor";

export class AdminProcessStatusViewModel {
    private interactor: AdminProcessStatusInteractor;

    constructor() {
		this.interactor = new AdminProcessStatusInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new AdminProcessStatusRequest(
            obj["sgepprocessoadministrativostatus"] ? parseInt(obj["sgepprocessoadministrativostatus"]) : 0,
            obj["nome"] ? obj["nome"] : "",
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new AdminProcessStatusStructRequest(
            obj["sgepprocessoadministrativostatus"] ? parseInt(obj["sgepprocessoadministrativostatus"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}
