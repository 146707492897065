import React from 'react'
import { StudentViewModel } from './StudentViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 
import deletar from '../../Imagens/delete-16.png';

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import { confirmAlert } from 'react-confirm-alert';

interface MyProps {
    navigation: any
    delegate: StudentViewControllerDelegate
    viewModel: StudentViewModel
    idPerson: number
    idPrimary: number
}

export interface StudentViewControllerDelegate {
    tapDimiss(navigation: any): void
    tapSchoolYear(navigation: any, id: number): void
}

type ScreenType = 'schoolyear'
export class StudentViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        active: Array<any>(),
        custody: Array<any>(),
        sneeds: Array<any>(),
        arraySchoolYear: new Array<any>(),
        showButtonDelete: false,
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadActive()
        this.loadCustody()
        this.loadSneeds()
        if(this.props.idPerson > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Aluno</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Row className="header">
                            {this.generateOptions()}
                            <Col xs="12">
                                <Form className="containerForm">
                                    <Row>
                                        <Col lg="4" md="6" xs="12" className="bottom10 hide">
                                            <Form.Label>Sequencial</Form.Label>
                                            <Form.Control
                                            id="sgeppessoa"
                                            defaultValue={json['sgeppessoa'] ? json['sgeppessoa'] : ''}
                                            onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                            />
                                        </Col>
                                        <Col lg="4" md="6" xs="12" className="bottom10">
                                            <Form.Label className="isRequired">Tipo de Responsável</Form.Label>
                                            <Select
                                            id="cgeptiporesponsavel"
                                            value={this.formEvent.onValueSelect('select', 'cgeptiporesponsavel', this.state.custody)}
                                            onChange={(e: any) => this.formEvent.onChange("select", e, "cgeptiporesponsavel")}
                                            closeMenuOnSelect={true}
                                            placeholder={"Selecione"}
                                            options={this.state.custody}
                                            isMulti={false}
                                            />
                                        </Col>
                                        <Col lg="4" md="6" xs="12" className="bottom10">
                                            <InputModalSelectComponent 
                                            id="cgeppessoaresp"
                                            keyValue="nome"
                                            label="Responsável"
                                            required={true}
                                            placeholder="Procure"
                                            showAddButton={false}
                                            navigation={this.props.navigation}
                                            titleModal="Pessoas"
                                            table="geppessoas"
                                            valueDefault={json['pessoa']}
                                            getValue={(id: number, value: string) => this.setIdPerson(id, value)}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4" md="6" xs="12" className="bottom10">
                                            <Form.Label className="isRequired">Necessidades Especiais</Form.Label>
                                            <Select
                                            id="cgepnecessidadesespecial"
                                            value={this.formEvent.onValueSelect('select', 'cgepnecessidadesespecial', this.state.sneeds)}
                                            onChange={(e: any) => this.formEvent.onChange("select", e, "cgepnecessidadesespecial")}
                                            closeMenuOnSelect={true}
                                            placeholder={"Selecione"}
                                            options={this.state.sneeds}
                                            isMulti={false}
                                            />
                                        </Col>
                                        <Col lg="4" md="6" xs="12" className="bottom10">
                                            <Form.Label>ID MEC</Form.Label>
                                            <Form.Control
                                            id="idmec"
                                            defaultValue={json['idmec'] ? json['idmec'] : ''}
                                            onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                            placeholder="ID MEC" 
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4" md="6" xs="12" className="bottom10">
                                            <Form.Label className="isRequired">Ativo</Form.Label>
                                            <Select
                                            id="ativo"
                                            value={this.formEvent.onValueSelect('select', 'ativo', this.state.active)}
                                            onChange={(e: any) => this.formEvent.onChange("select", e, "ativo")}
                                            closeMenuOnSelect={true}
                                            placeholder={"Selecione"}
                                            options={this.state.active}
                                            isMulti={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={{marginTop: 20}}>
                                            <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                                <Image className="imageSave"
                                                src={salvar}/>
                                                Salvar
                                            </Button>
                                            <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                            {this.state.showButtonDelete &&
                                                <Button onClick={(e: any) => this.delete()} className="buttonCancel buttonDelete" variant="danger">
                                                    <Image className="imageSave" 
                                                        src={deletar}/>
                                                        Remover
                                                </Button>}
                                        </Col>
                                    </Row>
                                </Form>
                                <ToastContainer />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setIdPerson(id: number, value: string) {
        var json = this.state.json as any 
        json['cgeppessoaresp'] = id 
        
        this.setState({
            json: json
        })
    }

    private loadCustody() {
        this.props.viewModel.fetchCustody().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                custody: array
            })
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})
        }
        );
    }

    private loadSneeds() {
        this.props.viewModel.fetchSpecialNeeds().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                sneeds: array
            })
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})
        }

        );
    }

    private loadActive() {
        var array = Array<any>()
        array.push({value: "S", label: "Sim", check: false})
        array.push({value: "N", label: "Não", check: false})

        this.setState({
            active: array
        })
    }

    private generateOptions() {
        var json = this.state.json as any
        var arrayNavOptions = new Array<any>()
        arrayNavOptions.push(
        <NavDropdown.Item onClick={() => this.openNewScreen('schoolyear')}>
            Ano Letivo
        </NavDropdown.Item>);
        
        return (
            <Col xs="12" style={{borderWidth: 1, borderStyle: "solid", borderColor: "#cccccc", background: "linear-gradient(to bottom, #cccccc 0%, #d7d7d7 100%)"}} >
                <Navbar expand="lg">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {arrayNavOptions.length > 0 &&
                            <NavDropdown title="Opções" id="basic-nav-dropdown">
                                {arrayNavOptions}
                            </NavDropdown>}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Col> 
        )
    }

    private openNewScreen(screenType: ScreenType) {
        var json = this.state.json as any
        if(json["sgepaluno"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        switch(screenType) {
            case 'schoolyear':
                this.props.delegate.tapSchoolYear(this.props.navigation, json['sgepaluno'])
                break 
            }
    }

    private save() {
        var json = this.state.json as any
        if(json["cgeptiporesponsavel"] == undefined || json["cgeppessoaresp"] == undefined || json["cgepnecessidadesespecial"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.setState({isLoading: true})
        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private delete() {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.setState({isLoading: true})
                    this.props.viewModel.fetchDelete(this.state.json).then(() => {
                        this.setState({isLoading: false})
                        toast.success("Registro deletado com sucesso!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                        setTimeout(() => {
                            this.props.delegate.tapDimiss(this.props.navigation);
                        }, 1000);
                    }, e => {
                        this.setState({isLoading: false})
                        toast.error(JSON.stringify(e), {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgeppessoa'] = this.props.idPerson

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0],
                    showButtonDelete: true
                })

                return
            }

            this.setState({isLoading: false})

        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})

        });
    }
}