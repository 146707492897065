import { Request } from '../../Servidor/Request';

export class VisitReasonRequest {
    private sgepmotivovisita: number;
    private gepmotivovisita: string;
    private sgepgrupomotivovisita: number;

	constructor(sgepmotivovisita: number, gepmotivovisita: string, sgepgrupomotivovisita: number) {
		this.sgepmotivovisita = sgepmotivovisita;
		this.gepmotivovisita = gepmotivovisita;
        this.sgepgrupomotivovisita = sgepgrupomotivovisita;
	}

    /**
     * Getter sgepmotivovisita
     * @return {number}
     */
	public getSgepmotivovisita(): number {
		return this.sgepmotivovisita;
	}

    /**
     * Getter gepmotivovisita
     * @return {string}
     */
	public getGepmotivovisita(): string {
		return this.gepmotivovisita;
	}

    /**
     * Getter sgepgrupomotivovisita
     * @return {number}
     */
	public getSgepgrupomotivovisita(): number {
		return this.sgepgrupomotivovisita;
	}
    
}

export class VisitReasonResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class VisitReasonStructRequest {
    private sgepmotivovisita: number;

	constructor(sgepmotivovisita: number) {
        this.sgepmotivovisita = sgepmotivovisita;
	}



    /**
     * Getter sgepmotivovisita
     * @return {number}
     */
	public getSgepmotivovisita(): number {
		return this.sgepmotivovisita;
	}
}

export class VisitReasonStructResponse {
    private sgepmotivovisita: number;
    private gepmotivovisita: string;
    private sgepgrupomotivovisita: number;

	constructor(sgepmotivovisita: number, gepmotivovisita: string, sgepgrupomotivovisita: number) {
		this.sgepmotivovisita = sgepmotivovisita;
		this.gepmotivovisita = gepmotivovisita;
        this.sgepgrupomotivovisita = sgepgrupomotivovisita;
	}

    /**
     * Getter sgepmotivovisita
     * @return {number}
     */
	public getSgepmotivovisita(): number {
		return this.sgepmotivovisita;
	}

    /**
     * Getter gepmotivovisita
     * @return {string}
     */
	public getGepmotivovisita(): string {
		return this.gepmotivovisita;
	}

    /**
     * Getter sgepgrupomotivovisita
     * @return {number}
     */
	public getSgepgrupomotivovisita(): number {
		return this.sgepgrupomotivovisita;
	}
}

export class VisitReasonInteractor {
    public fetchSave(request: VisitReasonRequest): PromiseLike<VisitReasonResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/motivo-visita/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new VisitReasonResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: VisitReasonStructRequest): PromiseLike<VisitReasonStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/motivo-visita/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchGroupVisitReason(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/grupo-motivo-visita/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}