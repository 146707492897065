import React from 'react'
import { VisitReasonViewModel } from './VisitReasonViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";

interface MyProps {
    navigation: any
    delegate: VisitReasonViewControllerDelegate
    viewModel: VisitReasonViewModel
    idPrimary: number
}

export interface VisitReasonViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class VisitReasonViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        groupVisitReason: []
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadGroupVisitReason()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Motivo Visita</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgepmotivovisita"
                                    defaultValue={json['sgepmotivovisita'] ? json['sgepmotivovisita'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                    id="gepmotivovisita"
                                    defaultValue={json['gepmotivovisita'] ? json['gepmotivovisita'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite o nome" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Grupo Motivo Visita</Form.Label>
                                    <Select
                                    id="sgepgrupomotivovisita"
                                    value={this.formEvent.onValueSelect('select', 'sgepgrupomotivovisita', this.state.groupVisitReason)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "sgepgrupomotivovisita")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.groupVisitReason}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private save() {
        var json = this.state.json as any
        if(json["gepmotivovisita"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["gepmotivovisita"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["sgepgrupomotivovisita"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgepmotivovisita'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private loadGroupVisitReason() {
        this.props.viewModel.fetchGroupVisitReason().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                groupVisitReason: array
            })
        });
    }
}