import React from 'react';
import { Route} from 'react-router-dom'
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { ConsolidatedReportViewControllerDelegate, ConsolidatedReportViewController } from "../../Screen/ConsolidatedReport/ConsolidatedReportViewController";
import { ConsolidatedReportType, ConsolidatedReportViewModel } from '../../Screen/ConsolidatedReport/ConsolidatedReportViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class ConsolidatedReportCoordinator implements IndexCoordinatorDelegate, ConsolidatedReportViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeDistrict(),
            this.routeNeighborhood()
        ]
    }

    private routeDistrict(): JSX.Element {
        var viewModel = new ConsolidatedReportViewModel(ConsolidatedReportType.district)
        return (<ProtectedRoute requiredPermissions={['relatorio.distritos_consolidado.*']} path="/:base/relatorio-distritos-consolidados" exact={true} component={(props: any) => {
            return (<ConsolidatedReportViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
            />)
        }}/>)
    }

    private routeNeighborhood(): JSX.Element {
        var viewModel = new ConsolidatedReportViewModel(ConsolidatedReportType.neighborhood)
        return (<ProtectedRoute requiredPermissions={['relatorio.bairros_consolidado.*']} path="/:base/relatorio-bairros-consolidados" exact={true} component={(props: any) => {
            return (<ConsolidatedReportViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
}