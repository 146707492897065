import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';


interface LabelArrayInterface {
    value: Array<boolean>
    labelName: Array<string>
    valueName: Array<string>


}

interface MyProps {
    labelArray: LabelArrayInterface
    classname?: string
    filledButton?: boolean
    filled?: boolean
    filledText?: string
    filters: any
    setState: (filters: any) => void
    setFilled?: (filled: any) => void
}



export class FilterCheckboxLabelsComponent extends React.Component<MyProps>{
    render() {
        return (
            <>
                <Row className='filtro-itens text-center mx-2 mt-3' >
                    {this.props.labelArray.labelName.map((name, key) => {
                        return (
                            <Col className='col-sm-6 col-lg-4' key={key}>
                                <Form.Group className="filtro-form-group" key={key}>
                                    <Form.Check
                                        type="checkbox"
                                        checked={this.props.labelArray.value[key]}
                                        onChange={(e: any) => {
                                            const { labelArray } = this.props;
                                            this.props.setState(
                                                {
                                                    ...this.props.filters,
                                                    [labelArray.valueName[key]]: !labelArray.value[key]
                                                });
                                        }}
                                        className={this.props.classname}
                                    />
                                    <Form.Label className='filtro-label'>{name}</Form.Label>
                                </Form.Group>
                            </Col>
                        )
                    })
                    }
                </Row>
                {this.props.filledButton &&
                    <Row className='filtro-itens justify-content-center text-center mx-2 mt-3' >
                        <Col className='col-auto col-lg-4'>
                            <Form.Group className="filtro-form-group">
                                <Form.Check
                                    type="checkbox"
                                    checked={this.props.filled}
                                    onChange={(e: any) => {
                                        this.props.setFilled && this.props.setFilled(!this.props.filled);
                                    }}
                                />
                                <Form.Label className='filtro-label bold '>{this.props.filledText ? this.props.filledText : 'Preenchido'}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                }
            </>
        )
    }
}