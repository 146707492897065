import { RealEstateRegistrationConfigInteractor, RealEstateRegistrationConfigRequest, RealEstateRegistrationConfigStructRequest } from "../../Coordinator/RealEstateRegistrationConfig/RealEstateRegistrationConfigInteractor";

export class RealEstateRegistrationConfigViewModel {
    private interactor: RealEstateRegistrationConfigInteractor;

	constructor() {
		this.interactor = new RealEstateRegistrationConfigInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new RealEstateRegistrationConfigRequest(
            obj["sgepcadastroimobiliarioconfiguracao"] ? parseInt(obj["sgepcadastroimobiliarioconfiguracao"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["tipo"] ? obj["tipo"] : "",
            obj["obrigatorio"] ? obj["obrigatorio"] : "",
            obj["ordem"] ? parseInt(obj["ordem"]) : 0,
            obj["itens"],
            obj["tipoorigem"] ? obj["tipoorigem"] : ""
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> { 
        var request = new RealEstateRegistrationConfigStructRequest(
            obj["sgepcadastroimobiliarioconfiguracao"] ? parseInt(obj["sgepcadastroimobiliarioconfiguracao"]) : 0
        )

        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}