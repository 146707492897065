import React from 'react';
import { Route} from 'react-router-dom'
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewControllerDelegate, TableGenericViewController, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import { UploadFilesViewModel } from '../../Screen/UploadFiles/UploadFilesViewModel';
import { ToastContainer, toast } from 'react-toastify';
import { UploadFilesViewController } from '../../Screen/UploadFiles/UploadFilesViewController';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class UploadFilesCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate {

    private arrayActions: Array<NavOptions>

    constructor() {
        this.arrayActions = new Array<NavOptions>()
        this.arrayActions.push({
            title: "Anexar Documentos", 
            action: (navigation: any, item: any, component: React.Component) => this.changeUploadDocuments(item, component), 
            isSelection: false, 
            uploadFile: true,
            multipleFile: true
        })

        this.arrayActions.push({
            title: "Baixar Documento", 
            action: (navigation: any, item: any, component: React.Component) => this.downloadFile(item, component), 
            isSelection: true
        })

        this.arrayActions.push({
            title: "Visualizar Documento", 
            action: (navigation: any, item: any, component: React.Component) => this.viewFile(navigation, item, component), 
            isSelection: true
        })
    }
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTableProperty(),
            this.routeTableVisit(),
            this.routeViewDocument(),
            this.routeTablePropertyBuilding(),
            this.routeTableRealEstateRegistration(),
            this.routeTableAdminProcess(),
            this.routeTablePerson(),
            this.routeTablePoint()
        ]
    }

    private routeTableProperty(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        return (<ProtectedRoute path="/:base/propriedade/:idPropriedade/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idPropriedade
            var filterTable = new TableGenericFilter('Tabela', "geppropriedade")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={this.arrayActions}
            />)
        }}/>)
    }

    private routeTableVisit(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        return (<ProtectedRoute path="/:base/visita/:idVisita/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idVisita
            var filterTable = new TableGenericFilter('Tabela', "gepvisita")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={this.arrayActions}
            />)
        }}/>)
    }

    private routeTablePropertyBuilding(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        return (<ProtectedRoute path="/:base/edificacao/:idEdificacao/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idEdificacao
            var filterTable = new TableGenericFilter('Tabela', "geppropedificacoes")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={this.arrayActions}
            />)
        }}/>)
    }

    private routeTableRealEstateRegistration(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        return (<ProtectedRoute path="/:base/cadastro-imobiliario/:idCadastroImobiliario/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idCadastroImobiliario
            var filterTable = new TableGenericFilter('Tabela', "gepcadastroimobiliario")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={this.arrayActions}
            />)
        }}/>)
    }

    private routeTableAdminProcess(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        return (<ProtectedRoute path="/:base/processo-administrativo/:idAdminProcess/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idAdminProcess
            var filterTable = new TableGenericFilter('Tabela', "gepprocessoadministrativo")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={this.arrayActions}
            />)
        }}/>)
    }

    private routeTablePerson(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        return (<ProtectedRoute path="/:base/pessoa/:idPessoa/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idPessoa
            var filterTable = new TableGenericFilter('Tabela', "geppessoas")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={this.arrayActions}
            />)
        }}/>)
    }

    private routeTablePoint(): JSX.Element {
        var viewModel = new TableGenericViewModel("imagem")
        let arrayActions = new Array<NavOptions>()
        arrayActions.push({
            title: "Anexar Documentos", 
            action: (navigation: any, item: any, component: React.Component) => this.changeUploadDocuments(item, component), 
            isSelection: false, 
            uploadFile: true,
            multipleFile: true
        })

        arrayActions.push({
            title: "Baixar Documento", 
            action: (navigation: any, item: any, component: React.Component) => this.downloadFile(item, component), 
            isSelection: true
        })

        arrayActions.push({
            title: "Visualizar Documento", 
            action: (navigation: any, item: any, component: React.Component) => this.viewFile(navigation, item, component), 
            isSelection: true
        })

        arrayActions.push({
            title: "Definir como principal", 
            action: (navigation: any, item: any, component: React.Component) => this.setMain(item, component), 
            isSelection: true
        })

        return (<ProtectedRoute path="/:base/ponto/:idPonto/arquivos" exact={true} component={(props: any) => {
            var idFk = props.match.params.idPonto
            var filterTable = new TableGenericFilter('Tabela', "geppontos")
            var filterData = new TableGenericFilter('Registro', idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Upload arquivos"}
                filter={[filterTable, filterData]}
                actions={arrayActions}
            />)
        }}/>)
    }

    private routeViewDocument(): JSX.Element {
        let viewModel = new UploadFilesViewModel()
        return (<ProtectedRoute path="/:base/visualizar-arquivo/:id" exact={true} component={(props: any) => {
            var id = props.match.params.id
            return (<UploadFilesViewController delegate={this} viewModel={viewModel} navigation={props} idPrimary={id}/>)
        }}/>)
    }

    private changeUploadDocuments(item: any, component: React.Component) {
        var files = item.files as Array<any>
        var json = {
            tabela: item.viewModel.filter[0].value,
            registro: item.viewModel.filter[1].value
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(json));
        component.setState({
            showLoading: true
        })

        var viewModelLayer = new UploadFilesViewModel()
        viewModelLayer.fetchImportDocuments(formData).then((rs) => {
            component.setState({
                showLoading: false
            })

            toast.success("Arquivos anexados com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                window.location.reload()
            }, 3000);
        });
    }

    private downloadFile(item: any, component: React.Component) {
        var json = {
            tabela: item.viewModel.filter[0].value,
            registro: item.viewModel.filter[1].value
        }

        component.setState({
            showLoading: true
        })

        var viewModelLayer = new UploadFilesViewModel()
        viewModelLayer.fetchDownloadDocument(json).then((rs) => {
            component.setState({
                showLoading: false
            })

            var resultado = rs.Resultado as Array<any> 
            if(resultado.length == 0) {
                toast.error("Nenhum arquivo encontrado!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return
            }

            window.location.assign(resultado[0].caminhodownload)
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private viewFile(navigation: any, item: any, component: React.Component) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/visualizar-arquivo/${item.id}`);
    }

    private setMain(item: any, component: React.Component) {
        var json = {
            simagem: item.id,
            tabela: item.viewModel.filter[0].value,
            registro: item.viewModel.filter[1].value
        }

        component.setState({
            showLoading: true
        })

        var viewModelLayer = new UploadFilesViewModel()
        viewModelLayer.saveMain(json).then((rs) => {
            component.setState({
                showLoading: false
            })

            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        
    }

}