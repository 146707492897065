import { Request } from '../../Servidor/Request';

export class KinshipDegreeRequest {
    private cgepgrauparentesco: number;
    private nome: string;

	constructor(cgepgrauparentesco: number, nome: string) {
		this.cgepgrauparentesco = cgepgrauparentesco;
		this.nome = nome;
	}


    /**
     * Getter cgepgrauparentesco
     * @return {number}
     */
	public getCgepgrauparentesco(): number {
		return this.cgepgrauparentesco;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class KinshipDegreeResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class KinshipDegreeStructRequest {
    private cgepgrauparentesco: number;

	constructor(cgepgrauparentesco: number) {
        this.cgepgrauparentesco = cgepgrauparentesco;
	}


    /**
     * Getter cgepgrauparentesco
     * @return {number}
     */
	public getCgepgrauparentesco(): number {
		return this.cgepgrauparentesco;
	}

}

export class KinshipDegreeInteractor {
    public fetchSave(request: KinshipDegreeRequest): PromiseLike<KinshipDegreeResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/grau-parentesco/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new KinshipDegreeResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: KinshipDegreeStructRequest): PromiseLike<KinshipDegreeRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/grau-parentesco/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}