import { PointInteractor, PointRequest, PointStructRequest } from "../../Coordinator/Point/PointInteractor";

export class PointViewModel {
    private interactor: PointInteractor

    constructor() {
        this.interactor = new PointInteractor()
    }

    public fetchSalvar(obj: any): PromiseLike<any> {
        var request = new PointRequest(
            obj["sshape"] ? parseInt(obj["sshape"]) : 0,
            obj["scamada"] ? parseInt(obj["scamada"]) : 0,
            obj['tipo'] ? obj['tipo'] : "",
            obj["cgepsituacoesshape"] ? parseInt(obj["cgepsituacoesshape"]) : 0,
            obj["origem"] ? obj["origem"] : "",
            obj["latitude"] ? obj["latitude"] : "",
            obj["longitude"] ? obj["longitude"] : "",
            obj["sgeptipoponto"] ? obj["sgeptipoponto"] : null
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PointStructRequest(
            obj["sshape"] ? parseInt(obj["sshape"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchImmobileSituation(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchImmobileSituation().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectLayers(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectLayers().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchPointTypes(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchPointTypes().then((result) => {
                return res(result)
            }
            , e => {
                return rej(e)
            });
        })
    }
}