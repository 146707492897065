import { UserGroupInteractor, UserGroupRequest, UserGroupStructRequest } from "../../Coordinator/UserGroup/UserGroupInteractor";

export class UserGroupViewModel {
    private interactor: UserGroupInteractor;

	constructor() {
		this.interactor = new UserGroupInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new UserGroupRequest(
            obj["cgrusuario"] ? parseInt(obj["cgrusuario"]) : 0,
            obj["grusuario"] ? obj["grusuario"] : "",
			obj["permissions"] ? obj["permissions"] : []
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new UserGroupStructRequest(
            obj["cgrusuario"] ? parseInt(obj["cgrusuario"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

	public fetchSelectPermissions(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchSelectPermissions().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
        })
    }
}