import { Request } from '../../Servidor/Request';

export class PoolRequest{
    private sgeppiscina: number;
    private nome: string;

	constructor(sgeppiscina: number, nome: string) {
		this.sgeppiscina = sgeppiscina;
		this.nome = nome;
	}


    /**
     * Getter sgeppiscina
     * @return {number}
     */
	public getSgeppiscina(): number {
		return this.sgeppiscina;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class PoolStructRequest {
    private sgeppiscina: number;

	constructor(sgeppiscina: number) {
        this.sgeppiscina = sgeppiscina;
	}


    /**
     * Getter sgeppiscina
     * @return {number}
     */
	public getSgeppiscina(): number {
		return this.sgeppiscina;
	}

}

export class PoolStructResponse {
    private sgeppiscina: number;
    private nome: string;

	constructor(sgeppiscina: number, nome: string) {
		this.sgeppiscina = sgeppiscina;
		this.nome = nome;
	}


    /**
     * Getter sgeppiscina
     * @return {number}
     */
	public getSgeppiscina(): number {
		return this.sgeppiscina;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class PoolInteractor {
    public fetchSave(request: PoolRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/piscina/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PoolStructRequest): PromiseLike<PoolStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/piscina/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}