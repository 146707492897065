import { Request } from "../../Servidor/Request";

export class SpecialNeedsRequest {
    private cgepnecessidadesespecial: number;
    private nome: string;
    private idgoverno: string;

    constructor (cgepnecessidadesespecial:number, nome: string, idgoverno: string){
        this.cgepnecessidadesespecial = cgepnecessidadesespecial;
        this.nome = nome;
        this.idgoverno = idgoverno;
    }

    /**
     * Getter cgepnecessidadesespecial
     * @return {number}
     */
    public getCgepnecessidadesespecial(): number {
        return this.cgepnecessidadesespecial;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }

    /**
     * Getter idgoverno
     * @return {string}
     */
    public getIdgoverno(): string {
        return this.idgoverno;
    }
}
export class SpecialNeedsStructRequest {
    private cgepnecessidadesespecial: number;

    constructor (cgepnecessidadesespecial:number){
        this.cgepnecessidadesespecial = cgepnecessidadesespecial;
    }

    /**
     * Getter cgepnecessidadesespecial
     * @return {number}
     */
    public getCgepnecessidadesespecial(): number {
        return this.cgepnecessidadesespecial;
    }
}

export class SpecialNeedsInteractor {
    public fetchSave(request: SpecialNeedsRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/necessidade-especial/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: SpecialNeedsStructRequest): PromiseLike<SpecialNeedsRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("basico/ncessidade-especial/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
