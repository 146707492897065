import { MarkerIconInteractor, MarkerIconRequest, MarkerIconStructRequest} from "../../Coordinator/MarkerIcon/MarkerIconInteractor"

export class MarkerIconViewModel {
    private interactor: MarkerIconInteractor;

	constructor() {
		this.interactor = new MarkerIconInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new MarkerIconRequest(
            obj["smarcadorimagemicone"] ? parseInt(obj["smarcadorimagemicone"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new MarkerIconStructRequest(
            obj["smarcadorimagemicone"] ? parseInt(obj["smarcadorimagemicone"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchImportIcon(obj: any): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchImportIcon(obj).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}