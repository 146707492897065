import {SchoolRequest, SchoolStructRequest, SchoolInteractor} from "../../Coordinator/School/SchoolInteractor";

export class SchoolViewModel {
    private interactor: SchoolInteractor;

    constructor() {
		this.interactor = new SchoolInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new SchoolRequest(
            obj["sgepescola"] ? parseInt(obj["sgepescola"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["idgoverno"] ? obj["idgoverno"] : "",
            obj["cre"] ? obj["cre"] : "",
            obj["dependenciaadm"] ? obj["dependenciaadm"] : 0,
            obj["idt"] ? obj["idt"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new SchoolStructRequest(
            obj["sgepescola"] ? parseInt(obj["sgepescola"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}