import { Request } from '../../Servidor/Request';

export class LayerInteractorRequest {
    private scamada: number;
    private camada: String;
    private ordem: number;
    private visivel: String;
    private displayonload: String;
    private tipoCamada: String;
    private smarcadorimagem: number; 
    private scamadapai: number;

    constructor(scamada: number, camada: String, ordem: number, visivel: String, displayonload: String, tipoCamada: String, smarcadorimagem: number, scamadapai: number) {
        this.scamada = scamada 
        this.camada = camada
        this.ordem = ordem 
        this.visivel = visivel 
        this.displayonload = displayonload 
        this.tipoCamada = tipoCamada 
        this.smarcadorimagem = smarcadorimagem 
        this.scamadapai = scamadapai
    }

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

    /**
     * Getter camada
     * @return {String}
     */
	public getCamada(): String {
		return this.camada;
	}

    /**
     * Getter ordem
     * @return {number}
     */
	public getOrdem(): number {
		return this.ordem;
	}

    /**
     * Getter visivel
     * @return {String}
     */
	public getVisivel(): String {
		return this.visivel;
	}

    /**
     * Getter displayonload
     * @return {String}
     */
	public getDisplayonload(): String {
		return this.displayonload;
	}

    /**
     * Getter tipoCamada
     * @return {String}
     */
	public getTipoCamada(): String {
		return this.tipoCamada;
	}

    /**
     * Getter smarcadorimagem
     * @return {number}
     */
	public getSmarcadorimagem(): number {
		return this.smarcadorimagem;
	}

    /**
     * Getter scamadapai
     * @return {number}
     */
	public getScamadapai(): number {
		return this.scamadapai;
    }

}

export class LayerInteractorResponse {
    private sucess: boolean;

	constructor(sucess: boolean) {
        this.sucess = sucess
    }
    

    /**
     * Getter sucess
     * @return {boolean}
     */
	public getSucess(): boolean {
		return this.sucess;
	}

}

export class LayerStructRequest {
    private scamada: number;

	constructor(scamada: number) {
        this.scamada = scamada;
	}


    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

}

export class LayerStructResponse {
    private scamada: number;
    private camada: String;
    private ordem: number;
    private visivel: String;
    private displayonload: String;
    private tipoCamada: String;
    private smarcadorimagem: number; 
    private scamadapai: number;

    constructor(scamada: number, camada: String, ordem: number, visivel: String, displayonload: String, tipoCamada: String, smarcadorimagem: number, scamadapai: number) {
        this.scamada = scamada 
        this.camada = camada
        this.ordem = ordem 
        this.visivel = visivel 
        this.displayonload = displayonload 
        this.tipoCamada = tipoCamada 
        this.smarcadorimagem = smarcadorimagem 
        this.scamadapai = scamadapai
    }

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

    /**
     * Getter camada
     * @return {String}
     */
	public getCamada(): String {
		return this.camada;
	}

    /**
     * Getter ordem
     * @return {number}
     */
	public getOrdem(): number {
		return this.ordem;
	}

    /**
     * Getter visivel
     * @return {String}
     */
	public getVisivel(): String {
		return this.visivel;
	}

    /**
     * Getter displayonload
     * @return {String}
     */
	public getDisplayonload(): String {
		return this.displayonload;
	}

    /**
     * Getter tipoCamada
     * @return {String}
     */
	public getTipoCamada(): String {
		return this.tipoCamada;
	}

    /**
     * Getter smarcadorimagem
     * @return {number}
     */
	public getSmarcadorimagem(): number {
		return this.smarcadorimagem;
	}

    /**
     * Getter scamadapai
     * @return {number}
     */
	public getScamadapai(): number {
		return this.scamadapai;
    }
}

export class LayerAttributeRequest {
    private scamadaatributo: number;
    private scamada: number;
    private nome: string;


	constructor(scamadaatributo: number, scamada: number, nome: string) {
        this.scamadaatributo = scamadaatributo;
        this.scamada = scamada;
        this.nome = nome;
    }   
    

    /**
     * Getter scamadaatributo
     * @return {number}
     */
	public getScamadaatributo(): number {
		return this.scamadaatributo;
	}

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}


}

export class LayerAttributeResponse {
    private sucess: boolean;

	constructor(sucess: boolean) {
        this.sucess = sucess
    }
    

    /**
     * Getter sucess
     * @return {boolean}
     */
	public getSucess(): boolean {
		return this.sucess;
	}

}

export class LayerAttributeStructRequest {
    private scamada: number;

	constructor(scamada: number) {
        this.scamada = scamada;
	}


    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

}

export class LayerAttributeStructResponse {
    private scamadaatributo: number;
    private scamada: number;
    private nome: string;


	constructor(scamadaatributo: number, scamada: number, nome: string) {
        this.scamadaatributo = scamadaatributo;
        this.scamada = scamada;
        this.nome = nome;
    }   
    

    /**
     * Getter scamadaatributo
     * @return {number}
     */
	public getScamadaatributo(): number {
		return this.scamadaatributo;
	}

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

}

export class ImportShapeResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success;
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class MarkerImageResponse {
    private id: number;
    private value: string;


	constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
	}


    /**
     * Getter id
     * @return {number}
     */
	public getId(): number {
		return this.id;
	}

    /**
     * Getter value
     * @return {string}
     */
	public getValue(): string {
		return this.value;
	}

}

export class LayersResponse {
    private id: number;
    private value: string;


	constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
	}


    /**
     * Getter id
     * @return {number}
     */
	public getId(): number {
		return this.id;
	}

    /**
     * Getter value
     * @return {string}
     */
	public getValue(): string {
		return this.value;
	}

}

export class LayerInteractor {
    public fetchSave(request: LayerInteractorRequest): PromiseLike<LayerInteractorResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new LayerInteractorResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: LayerStructRequest): PromiseLike<LayerStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchAttributeSave(request: LayerAttributeRequest): PromiseLike<LayerAttributeResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-atributo/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new LayerAttributeResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchAttributeDataOne(request: LayerAttributeStructRequest): PromiseLike<LayerAttributeStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada-atributo/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchImportShape(request: FormData): PromiseLike<ImportShapeResponse> {
        return new Promise((res, rej) => {
            return new Request().requestFile("camada/importar-shape", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new ImportShapeResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchImportDXF(request: FormData): PromiseLike<ImportShapeResponse> {
        return new Promise((res, rej) => {
            return new Request().requestFile("camada/importar-dxf", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new ImportShapeResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectMarkerImage(): PromiseLike<MarkerImageResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada/buscar-marcador-imagem", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectLayers(): PromiseLike<LayersResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/camada/buscar-camadas", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchRemoveShape(request: LayerStructRequest): PromiseLike<LayerInteractorResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("camada/remover-shapes", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchImportTiff(request: FormData): PromiseLike<boolean> {
        return new Promise((res, rej) => {
            return new Request().requestFile("camada/importar-ortofoto", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(true)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchRemoveTiff(request: LayerStructRequest): PromiseLike<LayerInteractorResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("camada/deletar-ortofoto", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs)
            }, e => {
                rej(e);
            });
        });
    }
}