import { LayerPermissionInteractor, LayerPermissionStructRequest, LayerPermissionRequest } from "../../Coordinator/LayerPermission/LayerPermissionInteractor";

export class LayerPermissionViewModel {
    private interactor: LayerPermissionInteractor;

	constructor() {
		this.interactor = new LayerPermissionInteractor();
    }

    fetchLayers(grUsers: Array<number>): PromiseLike<any> {
        return new Promise((res, rej) => {
			let request = new LayerPermissionStructRequest(grUsers)
			this.interactor.fetchLayers(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

	public fetchSelectUserGroup(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectUserGroup().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
	}
	
	public fetchSave(susuariogrupo: Array<number>, scamada: Array<number>): PromiseLike<any> {
		return new Promise((res, rej) => {
			let request = new LayerPermissionRequest(scamada, susuariogrupo)
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
	}
}