import React from 'react'
import { SchoolViewModel } from './SchoolViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Modal } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';

interface MyProps {
    navigation: any
    delegate: SchoolViewControllerDelegate
    viewModel: SchoolViewModel
    idPrimary: number
}

export interface SchoolViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class SchoolViewController extends React.Component<MyProps> {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        dependenciaAdm: Array<any>()
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadDependenciaAdm()

        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Escola</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgepescola"
                                    defaultValue={json['sgepescola'] ? json['sgepescola'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                    id="nome"
                                    defaultValue={json['nome'] ? json['nome'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Insira o nome" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Id Governo</Form.Label>
                                    <Form.Control
                                    id="idgoverno"
                                    defaultValue={json['idgoverno'] ? json['idgoverno'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Id Governo"
                                    />
                                </Col>
                            </Row>
                            <Row>
                            <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">CRE</Form.Label>
                                    <Form.Control
                                    id="cre"
                                    defaultValue={json['cre'] ? json['cre'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="CRE"
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">IDT</Form.Label>
                                    <Form.Control
                                    id="idt"
                                    defaultValue={json['idt'] ? json['idt'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="IDT"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Dependência Administrativa</Form.Label>
                                    <Select
                                    id="dependenciaadm"
                                    value={this.formEvent.onValueSelect('select', 'dependenciaadm', this.state.dependenciaAdm)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "dependenciaadm")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione Dependência"}
                                    options={this.state.dependenciaAdm}
                                    isMulti={false}
                                    />
                                </Col>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" variant="primary" onClick={(e: any) => this.save()}>
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private save() {
        var json = this.state.json as any
        if(json["nome"] == undefined || json["idgoverno"] == undefined || json["cre"] == undefined || json["idt"] == undefined || json["dependenciaadm"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["nome"].length == 0 || json["idgoverno"].length == 0 || json["cre"].length == 0 || json["idt"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadDependenciaAdm() {
        var array = Array<any>()
        array.push({value: "E", label: "Estado", check: false})
        array.push({value: "M", label: "Município", check: false})
        array.push({value: "P", label: "Privado", check: false})

        this.setState({
            dependenciaAdm: array
        })
    }

    private loadStruct() {
        var json = this.state.json as any
        json['sgepescola'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}