export class StatusLogin {
    private success: boolean
    private message: String
    private token?: String

    constructor($success: boolean, $message: String, $token?: String) {
        this.success = $success
        this.message = $message
        this.token = $token
    }
    
     /**
     * Getter $success
     * @return {boolean}
     */
	public get $success(): boolean {
		return this.success
    }
    
     /**
     * Getter $message
     * @return {String}
     */
	public get $message(): String {
		return this.message
    }
    
     /**
     * Getter $token
     * @return {String}
     */
	public get $token(): String {
		return this.token!
	}
}

export class LoginState {
    public status?: StatusLogin
}