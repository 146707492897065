import { Request } from "../../Servidor/Request";

export class AdminProcessMovementInteractor {
    public fetchSave(request: any): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("processo-administrativo/movimentacao/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: any): PromiseLike<any>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("processo-administrativo/movimentacao/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchProfessional(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/profissionais/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}