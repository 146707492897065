import React from 'react';
import logoMenu from "../Imagens/logo-geo-menu.png";
import { Navbar, Image} from 'react-bootstrap';
import { push as Menu } from 'react-burger-menu';
import logo from "../Imagens/logo-login.png";
import flechaDireita from "../Imagens/arrow-right.png";
import flechaBaixo from "../Imagens/arrow-down-16.png";
import sairPreto from "../Imagens/sair-preto-16.png";
import { confirmAlert } from 'react-confirm-alert';
import Cookie from "js-cookie";

import "../Estilo/SideBar.css";
import "../Estilo/Menu.css";
import { Request } from '../Servidor/Request';

interface MyProps {
    propNav : any,
}

export class NavBarComponente extends React.Component<MyProps>{

    state = {
        dataRequest : {},
        itensOpen : {},
        dataBase: "",
        userName: ""
    }

    componentDidMount(){
        this.getMenu();
    }

    render(){
        return (
            <Navbar expand="lg" className="navBackgroundColor">
                <Navbar.Brand>
                    <Menu>
                        <div className="infoContainer">
                            <Image 
                                src={logo}
                                className="center iconGeoEfficace"
                                fluid  />
                            <p className="infoName">
                                {this.state.userName}
                            </p>
                        </div>
                        {this.generateMenu(this.state.dataRequest, false)}
                    </Menu>
                </Navbar.Brand>
                <Navbar.Brand>
                    <Image
                    src={logoMenu}
                    className="center iconMenu"
                    fluid  />
                </Navbar.Brand>
            </Navbar>
        )
    }

    private getMenu(){
        new Request().requestPost('menu', {}).then((rs) => {
            localStorage.setItem('database', rs.baseDados)
            this.setState({
                dataRequest: rs.menu,
                dataBase: rs.baseDados,
                userName: rs.nomeUsuario
            }); 
        });
    }

    private generateMenu(data: any, isChildreen: boolean) {

        var array = new Array<any>();
        if(isChildreen) {
            var dataChildreen = data as Array<any>
            dataChildreen.forEach((item, index) => {
                array.push(
                    <a key={`menu-${index}`} onClick={() => this.clickRoute(item['url'])} className="item" style={{color: "#000000"}}>
                        {item && item.length > 0 &&
                            <Image 
                                src={flechaDireita}
                                className="center iconArrow"
                                fluid  />
                        }  
    
                        <span style={{marginLeft: item.menuFilhos && item.menuFilhos.length > 0 ? 10 : 25}}>{item['name']}</span>
                    </a>
                );
            });

            return array
        }

        var itensOpen = this.state.itensOpen as any
        var keys = Object.keys(data)
        keys.forEach((key, index) => {
            var item = data[key] as any
            array.push(
                <a key={`menu-${index}`} onClick={() => this.clickOpenMenu(key)} className="item" style={{color: "#000000"}}>
                    {item && item.length > 0 &&
                        <Image 
                            src={itensOpen[key] && item && item.length > 0 ? flechaBaixo : flechaDireita}
                            className="center iconArrow"
                            fluid  />
                    }  

                    <span style={{marginLeft: item.menuFilhos && item.menuFilhos.length > 0 ? 10 : 25}}>{key}</span>
                </a>
            );
            
            if(itensOpen[key] && item && item.length > 0) {
                var arrayChildreen = this.generateMenu(item, true)
                for(var i = 0; i < arrayChildreen.length; i++){
                    array.push(arrayChildreen[i]);
                }
            }
        });

        array.push(
            <a key={'exit'} className="item" onClick={() => this.clickExit()} style={{color: "#000000"}}>
                <Image 
                    src={sairPreto}
                    className="center iconExit"
                    fluid  />
                Sair
            </a>
        );

        return array
    }

    private clickOpenMenu(id: string) {
        var itens = this.state.itensOpen as any 
        if(itens[id]) {
            delete itens[id]
        } else {
            itens[id] = true
        }

        this.setState({
            itensOpen: itens
        })
    }

    private clickRoute(path: string) {
        var nav = this.props.propNav as any
        nav.history.push(`/${this.state.dataBase}/${path}`)
    }

    private clickExit() {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja realmente sair do sistema?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    let database = localStorage.getItem('database')
                    window.location.href = `/${database}/login`
                    Cookie.remove('token')
                    localStorage.clear()
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

}