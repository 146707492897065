import React from 'react'
import { UploadFilesViewModel } from './UploadFilesViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png';

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
var FileViewer = require("react-file-viewer");

interface MyProps {
    navigation: any
    delegate: UploadFilesViewControllerDelegate
    viewModel: UploadFilesViewModel
    idPrimary: number
}

export interface UploadFilesViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class UploadFilesViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        extension: '',
        json: {}
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Visualizar arquivo</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Row className="header" style={{ height: '500px' }}>
                            <Col xs="12">
                                {json['caminhodownload'] &&
                                <FileViewer
                                fileType={json['caminhodownload'] != undefined ? this.state.extension : null}
                                filePath={json['caminhodownload'] != undefined ? json['caminhodownload'] : null}
                                />}
                            </Col>
                        </Row>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private loadStruct() {
        let json = {
            simagem: this.props.idPrimary
        }

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                var extension = String(resultado[0].caminhoaws).substring(String(resultado[0].caminhoaws).lastIndexOf('.') + 1, String(resultado[0].caminhoaws).length)
                this.setState({
                    isLoading: false,
                    extension: extension,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}