import { Request } from "../../Servidor/Request";

export class PropertyBuildProperty {
    private sgepgrupopessoaparente: number;
    private sgeppessoa: number;
    private principal: string;

	constructor(sgepgrupopessoaparente: number, sgeppessoa: number, principal: string) {
		this.sgepgrupopessoaparente = sgepgrupopessoaparente;
		this.sgeppessoa = sgeppessoa;
		this.principal = principal;
	}


    /**
     * Getter sgepgrupopessoaparente
     * @return {number}
     */
	public getSgepgrupopessoaparente(): number {
		return this.sgepgrupopessoaparente;
	}

    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

    /**
     * Getter principal
     * @return {string}
     */
	public getPrincipal(): string {
		return this.principal;
	}

}

export class PropertyBuildResident {
    private sgepgrupopessoaparente: number;
    private sgeppessoa: number;
    private principal: string;
    private cgepgrauparentesco: number;

	constructor(sgepgrupopessoaparente: number, sgeppessoa: number, principal: string, cgepgrauparentesco: number) {
		this.sgepgrupopessoaparente = sgepgrupopessoaparente;
		this.sgeppessoa = sgeppessoa;
        this.principal = principal;
        this.cgepgrauparentesco = cgepgrauparentesco;
	}


    /**
     * Getter sgepgrupopessoaparente
     * @return {number}
     */
	public getSgepgrupopessoaparente(): number {
		return this.sgepgrupopessoaparente;
	}

    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

    /**
     * Getter principal
     * @return {string}
     */
	public getPrincipal(): string {
		return this.principal;
    }
    

    /**
     * Getter cgepgrauparentesco
     * @return {number}
     */
	public getCgepgrauparentesco(): number {
		return this.cgepgrauparentesco;
	}

}

export class PropertyBuildRequest {
    private sgeppropedificacoes: number;
    private sgeppropriedade: number;
    private descricao: string;
    private inscricao: string;
    private sgeptipotumulo: number;
    private proprietarios: Array<PropertyBuildProperty>;
    private residentes: Array<PropertyBuildResident>;


	constructor(sgeppropedificacoes: number, sgeppropriedade: number, descricao: string, inscricao: string, sgeptipotumulo: number, proprietarios: Array<PropertyBuildProperty>, residentes: Array<PropertyBuildResident>) {
        this.sgeppropedificacoes = sgeppropedificacoes;
        this.sgeppropriedade = sgeppropriedade;
        this.descricao = descricao;
        this.inscricao = inscricao;
        this.sgeptipotumulo = sgeptipotumulo;
        this.proprietarios = proprietarios;
        this.residentes = residentes;
    }
    

    /**
     * Getter sgeppropedificacoes
     * @return {number}
     */
	public getSgeppropedificacoes(): number {
		return this.sgeppropedificacoes;
	}

    /**
     * Getter sgeppropriedade
     * @return {number}
     */
	public getSgeppropriedade(): number {
		return this.sgeppropriedade;
	}

    /**
     * Getter descricao
     * @return {string}
     */
	public getDescricao(): string {
		return this.descricao;
	}

    /**
     * Getter inscricao
     * @return {string}
     */
	public getInscricao(): string {
		return this.inscricao;
	}

    /**
     * Getter sgeptipotumulo
     * @return {number}
     */
	public getSgeptipotumulo(): number {
		return this.sgeptipotumulo;
	}

    /**
     * Getter proprietarios
     * @return {Array<PropertyBuildProperty>}
     */
	public getProprietarios(): Array<PropertyBuildProperty> {
		return this.proprietarios;
	}

    /**
     * Getter residentes
     * @return {Array<PropertyBuildResident>}
     */
	public getResidentes(): Array<PropertyBuildResident> {
		return this.residentes;
	}

}

export class PropertyBuildStructRequest {
    private sgeppropedificacoes: number;

	constructor(sgeppropedificacoes: number) {
        this.sgeppropedificacoes = sgeppropedificacoes
    }
    

    /**
     * Getter sgeppropedificacoes
     * @return {number}
     */
	public getSgeppropedificacoes(): number {
		return this.sgeppropedificacoes;
	}

}

export class RealEstateConfigItem {
    private sgepcadastroimobiliarioconfiguracaoitem: number;
    private sgepcadastroimobiliarioconfiguracao: number;
    private item: string;
    private valor: number;
    
    constructor(sgepcadastroimobiliarioconfiguracaoitem: number, sgepcadastroimobiliarioconfiguracao: number, item: string, valor: number) {
        this.sgepcadastroimobiliarioconfiguracaoitem = sgepcadastroimobiliarioconfiguracaoitem
        this.sgepcadastroimobiliarioconfiguracao = sgepcadastroimobiliarioconfiguracao
        this.item = item
        this.valor = valor
    }


    /**
     * Getter sgepcadastroimobiliarioconfiguracaoitem
     * @return {number}
     */
	public getSgepcadastroimobiliarioconfiguracaoitem(): number {
		return this.sgepcadastroimobiliarioconfiguracaoitem;
	}

    /**
     * Getter sgepcadastroimobiliarioconfiguracao
     * @return {number}
     */
	public getSgepcadastroimobiliarioconfiguracao(): number {
		return this.sgepcadastroimobiliarioconfiguracao;
	}

    /**
     * Getter item
     * @return {string}
     */
	public getItem(): string {
		return this.item;
	}

    /**
     * Getter valor
     * @return {number}
     */
	public getValor(): number {
		return this.valor;
	}
}

export class RealEstateConfig {
    private sgepcadastroimobiliarioconfiguracao: number;
    private nome: string;
    private ordem: number;
    private itens: Array<RealEstateConfigItem>;

    constructor(sgepcadastroimobiliarioconfiguracao: number, nome: string, ordem: number, itens: Array<RealEstateConfigItem>) {
        this.sgepcadastroimobiliarioconfiguracao = sgepcadastroimobiliarioconfiguracao
        this.nome = nome
        this.ordem = ordem 
        this.itens = itens
    }


    /**
     * Getter sgepcadastroimobiliarioconfiguracao
     * @return {number}
     */
	public getSgepcadastroimobiliarioconfiguracao(): number {
		return this.sgepcadastroimobiliarioconfiguracao;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter ordem
     * @return {number}
     */
	public getOrdem(): number {
		return this.ordem;
    }
    

    /**
     * Getter itens
     * @return {Array<RealEstateConfigItem>}
     */
	public getItens(): Array<RealEstateConfigItem> {
		return this.itens;
	}
}

export class RealStateItens {
    private key: number;
    private value: string;

	constructor(key: number, value: string) {
		this.key = key;
		this.value = value;
	}

    /**
     * Getter $key
     * @return {number}
     */
	public get $key(): number {
		return this.key;
	}
    

    /**
     * Getter $value
     * @return {string}
     */
	public get $value(): string {
		return this.value;
	}

}

export class RealEstateRegistrationRequest {
    private sgepcadastroimobiliario: number;
    private sgeppropedificacoes: number;
    private datainicial: string;
    private datafinal: string;
    private inscricaomunicipal: string;
    private valoredificacao: number;
    private numeroalvara: string;
    private dataalvara: string;
    private sgepalvaraconstrucao: number;
    private descricao: string;
    private sgeptipoedificacao: number;
    private observacao: string;
    private itens: Array<RealStateItens>;

    constructor(sgepcadastroimobiliario: number, sgeppropedificacoes: number, datainicial: string, datafinal: string, inscricaomunicipal: string, valoredificacao: number, numeroalvara: string, dataalvara: string, sgepalvaraconstrucao: number, descricao: string, sgeptipoedificacao: number, observacao: string, itens: Array<RealStateItens>) {
        this.sgepcadastroimobiliario = sgepcadastroimobiliario
        this.sgeppropedificacoes = sgeppropedificacoes
        this.datainicial = datainicial 
        this.datafinal = datafinal 
        this.inscricaomunicipal = inscricaomunicipal
        this.valoredificacao = valoredificacao
        this.numeroalvara = numeroalvara
        this.dataalvara = dataalvara
        this.sgepalvaraconstrucao = sgepalvaraconstrucao
        this.descricao = descricao
        this.sgeptipoedificacao = sgeptipoedificacao;
        this.observacao = observacao
        this.itens = itens
    }


    /**
     * Getter sgepcadastroimobiliario
     * @return {number}
     */
	public getSgepcadastroimobiliario(): number {
		return this.sgepcadastroimobiliario;
	}

    /**
     * Getter sgeppropedificacoes
     * @return {number}
     */
	public getSgeppropedificacoes(): number {
		return this.sgeppropedificacoes;
	}


    /**
     * Getter datainicial
     * @return {string}
     */
	public getDatainicial(): string {
		return this.datainicial;
	}

    /**
     * Getter datafinal
     * @return {string}
     */
	public getDatafinal(): string {
		return this.datafinal;
	}

    /**
     * Getter inscricaomunicipal
     * @return {string}
     */
	public getInscricaomunicipal(): string {
		return this.inscricaomunicipal;
	}

    /**
     * Getter valoredificacao
     * @return {number}
     */
	public getValoredificacao(): number {
		return this.valoredificacao;
	}

    /**
     * Getter numeroalvara
     * @return {string}
     */
	public getNumeroalvara(): string {
		return this.numeroalvara;
	}

    /**
     * Getter dataalvara
     * @return {string}
     */
	public getDataalvara(): string {
		return this.dataalvara;
	}

    /**
     * Getter sgepalvaraconstrucao
     * @return {number}
     */
	public getSgepalvaraconstrucao(): number {
		return this.sgepalvaraconstrucao;
	}

    /**
     * Getter descricao
     * @return {string}
     */
	public getDescricao(): string {
		return this.descricao;
	}

    /**
     * Getter sgeptipoedificacao
     * @return {number}
     */
	public getSgeptipoedificacao(): number {
		return this.sgeptipoedificacao;
	}

    /**
     * Getter observacao
     * @return {string}
     */
	public getObservacao(): string {
		return this.observacao;
    }
    

    /**
     * Getter itens
     * @return {Array<RealStateItens>}
     */
	public getItens(): Array<RealStateItens> {
		return this.itens;
	}

}

export class RealEstateRegistrationStructRequest {
    private sgepcadastroimobiliario: number;

    constructor(sgepcadastroimobiliario: number) {
        this.sgepcadastroimobiliario = sgepcadastroimobiliario
    }


    /**
     * Getter sgepcadastroimobiliario
     * @return {number}
     */
	public getSgepcadastroimobiliario(): number {
		return this.sgepcadastroimobiliario;
	}
}

export class PropertyBuildingInteractor {
    public fetchPerson(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/pessoas/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchKinship(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/grau-parentesco/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PropertyBuildStructRequest): PromiseLike<PropertyBuildRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/edificacao/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSave(request: PropertyBuildRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/edificacao/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(true)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchBuildingPermit(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/alvara-construcao/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchRealEstateListItens(tipoorigem: string): PromiseLike<Array<RealEstateConfig>> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/cadastro-imobiliario/lista-itens/buscar", {tipoorigem: tipoorigem}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSaveRealEstateRegistration(request: RealEstateRegistrationRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/cadastro-imobiliario/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(true)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchRealEstateRegistrationDataOne(request: RealEstateRegistrationStructRequest): PromiseLike<RealEstateRegistrationRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/cadastro-imobiliario/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchTypeOfGrave(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("cemiterio/tipo-tumulo/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchBuildingType(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/tipo-edificacao/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}