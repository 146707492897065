import { Request } from '../../Servidor/Request';

export class BatchLimitationRequest {
    private sgeplimitacaolote: number;
    private nome: string;

	constructor(sgeplimitacaolote: number, nome: string) {
		this.sgeplimitacaolote = sgeplimitacaolote;
		this.nome = nome;
	}


    /**
     * Getter sgeplimitacaolote
     * @return {number}
     */
	public getSgeplimitacaolote(): number {
		return this.sgeplimitacaolote;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class BatchLimitationStructRequest {
    private sgeplimitacaolote: number;

	constructor(sgeplimitacaolote: number) {
        this.sgeplimitacaolote = sgeplimitacaolote;
	}


    /**
     * Getter sgeplimitacaolote
     * @return {number}
     */
	public getSgeplimitacaolote(): number {
		return this.sgeplimitacaolote;
	}

}

export class BatchLimitationStructResponse {
    private sgeplimitacaolote: number;
    private nome: string;

	constructor(sgeplimitacaolote: number, nome: string) {
		this.sgeplimitacaolote = sgeplimitacaolote;
		this.nome = nome;
	}


    /**
     * Getter sgeplimitacaolote
     * @return {number}
     */
	public getSgeplimitacaolote(): number {
		return this.sgeplimitacaolote;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class BatchLimitationInteractor {
    public fetchSave(request: BatchLimitationRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/limitacao-lote/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: BatchLimitationStructRequest): PromiseLike<BatchLimitationStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/limitacao-lote/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}