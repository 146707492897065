import { LayerInteractor, LayerAttributeRequest, LayerAttributeStructRequest } from "../../Coordinator/Layer/LayerInteractor";

export class LayerAttributeViewModel {
    private interactor: LayerInteractor

    constructor() {
        this.interactor = new LayerInteractor()
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new LayerAttributeRequest(
            obj["scamadaatributo"] ? parseInt(obj["scamadaatributo"]) : 0,
            obj["scamada"] ? parseInt(obj["scamada"]) : 0,
            obj["nome"] ? obj["nome"] : "",
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchAttributeSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new LayerAttributeStructRequest(
            obj["scamadaatributo"] ? parseInt(obj["scamadaatributo"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchAttributeDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}