import { PropertyInteractor, PropertyRequest, PropertyStructRequest, PropertyCityRequest } from "../../Coordinator/Property/PropertyInteractor";
import moment from 'moment';

export class PropertyViewModel {
  private interactor: PropertyInteractor;

  constructor() {
    this.interactor = new PropertyInteractor();
  }

  public fetchSave(obj: any): PromiseLike<any> {
    var objProperty = obj['proprietarios'] as Array<any>

    var request = new PropertyRequest(
      obj["sgeppropriedade"] ? parseInt(obj["sgeppropriedade"]) : 0,
      obj["anoinicial"] ? moment(obj["anoinicial"], 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      obj["anofinal"] ? moment(obj["anofinal"], 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      obj["tpprop"] ? obj["tpprop"] : "",
      obj["sgeppontos"] ? parseInt(obj["sgeppontos"]) : 0,
      obj["entradaagua"] ? obj["entradaagua"] : "",
      obj["entradaenergiaeletrica"] ? obj["entradaenergiaeletrica"] : "",
      obj["sgepsituacaoproprural"] ? parseInt(obj["sgepsituacaoproprural"]) : 0,
      obj["sgepproptpacesso"] ? parseInt(obj["sgepproptpacesso"]) : 0,
      obj["inscricaomunicipal"] ? obj["inscricaomunicipal"] : "",
      obj["cin"] ? obj["cin"] : "",
      obj["numeromatricula"] ? obj["numeromatricula"] : "",
      obj["registromatricula"] ? obj["registromatricula"] : "",
      obj["datamatricula"] ? obj["datamatricula"] : "",
      obj["cnm"] ? obj["cnm"] : "",
      obj["numeroquadra"] ? obj["numeroquadra"] : "",
      obj["numerolote"] ? obj["numerolote"] : "",
      obj["nomeloteamento"] ? obj["nomeloteamento"] : "",
      obj["anolotamento"] ? obj["anolotamento"] : "",
      obj["facequadra"] ? obj["facequadra"] : "",
      obj["matriculaorigem"] ? obj["matriculaorigem"] : "",
      obj["sgepdistrito"] ? parseInt(obj["sgepdistrito"]) : 0,
      obj["sgepzoneamento"] ? parseInt(obj["sgepzoneamento"]) : 0,
      obj["sgepformaobtencao"] ? parseInt(obj["sgepformaobtencao"]) : 0,
      obj["sgeptipoedificacao"] ? parseInt(obj["sgeptipoedificacao"]) : 0,
      obj["sgeptipopatrimonio"] ? parseInt(obj["sgeptipopatrimonio"]) : 0,
      obj["livro"] ? obj["livro"] : "",
      obj["oficio"] ? obj["oficio"] : "",
      obj["cnscartorio"] ? obj["cnscartorio"] : "",
      obj["ccidade"] ? parseInt(obj["ccidade"]) : 0,
      obj["sgepsituacaopropriedade"] ? parseInt(obj["sgepsituacaopropriedade"]) : 0,
      obj["sgepsituacaoquadra"] ? parseInt(obj["sgepsituacaoquadra"]) : 0,
      obj["sgepocupacaolote"] ? parseInt(obj["sgepocupacaolote"]) : 0,
      obj["sgeppavimentacao"] ? parseInt(obj["sgeppavimentacao"]) : 0,
      obj["sgeptopografia"] ? parseInt(obj["sgeptopografia"]) : 0,
      obj["sgeppedologia"] ? parseInt(obj["sgeppedologia"]) : 0,
      obj["sgeplimitacaolote"] ? parseInt(obj["sgeplimitacaolote"]) : 0,
      obj["sgeppasseiopublico"] ? parseInt(obj["sgeppasseiopublico"]) : 0,
      obj["sgeppiscina"] ? parseInt(obj["sgeppiscina"]) : 0,
      obj["area"] ? parseFloat(obj["area"]) : 0,
      obj["perimetro"] ? parseFloat(obj["perimetro"]) : 0,
      obj["testada"] ? parseFloat(obj["testada"]) : 0,
      obj["fundos"] ? parseFloat(obj["fundos"]) : 0,
      obj["frentefundosesquerdo"] ? parseFloat(obj["frentefundosesquerdo"]) : 0,
      obj["frentefundosdireito"] ? parseFloat(obj["frentefundosdireito"]) : 0,
      obj["profundidademedia"] ? parseFloat(obj["profundidademedia"]) : 0,
      obj["afastamento"] ? parseFloat(obj["afastamento"]) : 0,
      obj["pavimentos"] ? parseFloat(obj["pavimentos"]) : 0,
      obj["valortotal"] ? parseFloat(obj["valortotal"]) : 0,
      obj["valorbenfeitorias"] ? parseFloat(obj["valorbenfeitorias"]) : 0,
      obj["valorterrenovazio"] ? parseFloat(obj["valorterrenovazio"]) : 0,
      obj["valorfiscal"] ? parseFloat(obj["valorfiscal"]) : 0,
      obj["sgeptipoponto"] ? parseInt(obj["sgeptipoponto"]) : 0,
      obj['sgeplitigio'],
      obj['itens'],
      objProperty,
      obj['copiarpropriedade'] ? obj['copiarpropriedade'] : false,
      obj['copiarsgeppropriedade'] ? obj['copiarsgeppropriedade'] : 0,
    )

    return new Promise((res, rej) => {
      this.interactor.fetchSave(request).then((result) => {
        return res(result)
      }, e => {
        return rej(e)
      });
    })
  }

  public fetchDelete(obj: any): PromiseLike<any> {
    var request = new PropertyStructRequest(
      obj["sgeppontos"] ? parseInt(obj["sgeppontos"]) : 0
    )

    return new Promise((res, rej) => {
      this.interactor.fetchDelete(request).then((result) => {
        return res(true)
      }, e => {
        return rej(e)
      });
    })
  }

  public fetchDataOne(obj: any): PromiseLike<any> {
    var request = new PropertyStructRequest(
      obj["sgeppropriedade"] ? parseInt(obj["sgeppropriedade"]) : 0
    )

    return new Promise((res, rej) => {
      this.interactor.fetchDataOne(request).then((result) => {
        return res(result)
      }, e => {
        return rej(e)
      });
    })
  }

  public fetchSelectState(): PromiseLike<any> {
    return new Promise((res, rej) => {
      this.interactor.fetchSelectState().then((result) => {
        return res(result)
      }, e => {
        return rej(e)
      });
    })
  }

  public fetchSelectCity(uf: string): PromiseLike<any> {
    return new Promise((res, rej) => {
      let request = new PropertyCityRequest(uf)
      this.interactor.fetchSelectCity(request).then((result) => {
        return res(result)
      }, e => {
        return rej(e)
      });
    })
  }

  public fetchListItens(tipoorigem: string, sgeppontos?: number): PromiseLike<any> {
    return new Promise((res, rej) => {
      this.interactor.fetchListItens(tipoorigem, sgeppontos).then((result) => {
        return res(result)
      }, e => {
        return rej(e)
      });
    })
  }

  public fetchSelectProperty(): PromiseLike<any> {
    return new Promise((res, rej) => {
      this.interactor.fetchSelectProperty().then((result) => {
        return res(result)
      }, e => {
        return rej(e)
      });
    })
  }
}