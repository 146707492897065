import {EconActivityRequest, EconActivityStructRequest, EconActivityInteractor} from "../../Coordinator/EconActivity/EconActivityInteractor";

export class EconActivityViewModel {
    private interactor: EconActivityInteractor;

    constructor() {
        this.interactor = new EconActivityInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new EconActivityRequest(
            obj["sgepatividadeseconomicas"] ? parseInt(obj["sgepatividadeseconomicas"]) : 0,
            obj["versao"] ? obj["versao"] : "",
            obj["secao"] ? obj["secao"] : "",
            obj["divisao"] ? obj["divisao"] : "",
            obj["grupo"] ? obj["grupo"] : "",
            obj["classe"] ? obj["classe"] : "",
            obj["subclasse"] ? obj["subclasse"] : "",
            obj["denominacao"] ? obj["denominacao"] : ""
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(true)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new EconActivityStructRequest(
            obj["sgepatividadeseconomicas"] ? parseInt(obj["sgepatividadeseconomicas"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }
}