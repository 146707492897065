import { Request } from "../../Servidor/Request";

export class AgriculturalRegistrationRequest {
	private sgepcadastroagropecuario: number;
	private sgepprodutosagropecuarios: number;
	private quantidade: number;
	private areaplantada: number;
	private aptidaooutrosprodutos: boolean;
	private sgeppropriedade: number;

	constructor(
		sgepcadastroagropecuario: number,
		sgepprodutosagropecuarios: number,
		quantidade: number,
		areaplantada: number,
		aptidaooutrosprodutos: boolean,
		sgeppropriedade: number
	) {
		this.sgepcadastroagropecuario = sgepcadastroagropecuario;
		this.sgepprodutosagropecuarios = sgepprodutosagropecuarios;
		this.quantidade = quantidade;
		this.areaplantada = areaplantada;
		this.aptidaooutrosprodutos = aptidaooutrosprodutos;
		this.sgeppropriedade = sgeppropriedade;
	}

	/**
	 * Getter sgepcadastroagropecuario
	 * @return {number}
	 */
	public getSgepcadastroagropecuario(): number {
		return this.sgepcadastroagropecuario;
	}

	/**
	 * Getter sgepprodutosagropecuarios
	 * @return {number}
	 */
	public getSgepprodutosagropecuarios(): number {
		return this.sgepprodutosagropecuarios;
	}

	/**
	 * Getter quantidade
	 * @return {number}
	 */
	public getQuantidade(): number {
		return this.quantidade;
	}

	/**
	 * Getter areaplantada
	 * @return {number}
	 */
	public getAreaplantada(): number {
		return this.areaplantada;
	}

	/**
	 * Getter aptidaooutrosprodutos
	 * @return {boolean}
	 */
	public getAptidaooutrosprodutos(): boolean {
		return this.aptidaooutrosprodutos;
	}

	/**
	 * Getter sgeppropriedade
	 * @return {number}
	 */
	public getSgeppropriedade(): number {
		return this.sgeppropriedade;
	}
}
export class AgriculturalRegistrationStructRequest {
	private sgepcadastroagropecuario: number;

	constructor(sgepcadastroagropecuario: number) {
		this.sgepcadastroagropecuario = sgepcadastroagropecuario;
	}

	/**
	 * Getter sgepcadastroagropecuario
	 * @return {number}
	 */
	public getSgepcadastroagropecuario(): number {
		return this.sgepcadastroagropecuario;
	}
}

export class AgriculturalRegistrationInteractor {
	public fetchSave(
		request: AgriculturalRegistrationRequest
	): PromiseLike<Boolean> {
		return new Promise((res, rej) => {
			return new Request()
				.requestPost("basico/cadastro-agropecuario/salvar", request)
				.then(
					(rs) => {
						if (rs == undefined) {
							return rej({
								Mensagem: "Erro ao conectar!",
							});
						}

						let response = rs.Status == "Sucesso!";
						res(response);
					},
					(e) => {
						rej(e);
					}
				);
		});
	}

	public fetchDataOne(
		request: AgriculturalRegistrationStructRequest
	): PromiseLike<AgriculturalRegistrationRequest> {
		return new Promise((res, rej) => {
			return new Request()
				.requestPost("basico/cadastro-agropecuario/buscar", request)
				.then(
					(rs) => {
						if (rs == undefined) {
							return rej({
								Mensagem: "Erro ao conectar!",
							});
						}

						res(rs.Resultado);
					},
					(e) => {
						rej(e);
					}
				);
		});
	}

	public fetchSelectType(): PromiseLike<any> {
		return new Promise((res, rej) => {
			return new Request()
				.requestPost("basico/tipo-produto-agropecuario/buscar-select", {})
				.then(
					(rs) => {
						if (rs == undefined) {
							return rej({
								Mensagem: "Erro ao conectar",
							});
						}

						res(rs.Resultado);
					},
					(e) => {
						rej(e);
					}
				);
		});
	}

	public fetchSelectProduct(sgeptpprodagropecuario: number): PromiseLike<any> {
		return new Promise((res, rej) => {
			return new Request()
				.requestPost("basico/produto-agropecuario/buscar-select", {
					sgeptpprodagropecuario,
				})
				.then(
					(rs) => {
						if (rs == undefined) {
							return rej({
								Mensagem: "Erro ao conectar",
							});
						}

						res(rs.Resultado);
					},
					(e) => {
						rej(e);
					}
				);
		});
	}
}
