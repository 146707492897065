import React from 'react';
import { IndexCoordinatorDelegate } from '../IndexCoordinator';
import { NavOptions, TableGenericViewController, TableGenericViewControllerDelegate } from '../../Screen/TableGeneric/TableGenericViewController';
import { TableGenericViewModel } from '../../Screen/TableGeneric/TableGenericViewModel';
import { UserViewModel } from '../../Screen/User/UserViewModel';
import { UserViewController, UserViewControllerDelegate } from '../../Screen/User/UserViewController';
import { ResetPasswordViewModel } from '../../Screen/ResetPassword/ResetPasswordViewModel';
import { ResetPasswordViewController, ResetPasswordViewControllerDelegate } from '../../Screen/ResetPassword/ResetPasswordViewController';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class UserCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, UserViewControllerDelegate, ResetPasswordViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        return [
            this.routeTableUser(),
            this.routeFormUserGroup(),
            this.routeFormResetPassword()
        ]
    }

    private routeTableUser(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Trocar senha", 
            action: (navigation: any, item: any, component: React.Component) => this.resetPassword(navigation, item, component),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("usuario")
        return (<ProtectedRoute requiredPermissions={['config.usuarios.*']} path="/:base/usuarios" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'config.usuarios.adicionar',
                    edit: 'config.usuarios.editar',
                    delete: 'config.usuarios.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Usuários"}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormUserGroup(): JSX.Element {
        var viewModel = new UserViewModel()
        return (<ProtectedRoute requiredPermissions={['config.usuarios.adicionar', 'config.usuarios.editar']} path="/:base/usuarios/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<UserViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    private routeFormResetPassword(): JSX.Element {
        var viewModel = new ResetPasswordViewModel()
        return (<ProtectedRoute path="/:base/usuario/:id/resetar-senha" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<ResetPasswordViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    public tapDimiss(navigation: any) {
        navigation.history.goBack();
    }

    public add(navigation: any, viewModel: TableGenericViewModel) {
        if(viewModel.getTable() == "usuario") {
            navigation.history.push(`usuarios/cad/0`);
            return;
        }
    }
    
    public edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel) {
        if(viewModel.getTable() == "usuario") {
            navigation.history.push(`usuarios/cad/${idSelection}`);
            return;
        }
    }

    public resetPassword(navigation: any, item: any, component: React.Component) {
        navigation.history.push(`usuario/${item}/resetar-senha`);
    }
}