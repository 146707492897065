import {
	AdminProcessTypeInteractor,
	AdminProcessTypeRequest,
	AdminProcessTypeStructRequest,
} from "../../Coordinator/AdminProcessType/AdminProcessTypeInteractor";

export class AdminProcessTypeViewModel {
	private interactor: AdminProcessTypeInteractor;

	constructor() {
		this.interactor = new AdminProcessTypeInteractor();
	}

	public fetchSave(obj: any): PromiseLike<any> {
		var request = new AdminProcessTypeRequest(
			obj["sgeptipoprocesso"] ? parseInt(obj["sgeptipoprocesso"]) : 0,
			obj["nome"] ? obj["nome"] : ""
		);

		return new Promise<void>((res, rej) => {
			this.interactor.fetchSave(request).then(
				(result) => {
					return res();
				},
				(e) => {
					return rej(e);
				}
			);
		});
	}

	public fetchDataOne(obj: any): PromiseLike<any> {
		var request = new AdminProcessTypeStructRequest(
			obj["sgeptipoprocesso"] ? parseInt(obj["sgeptipoprocesso"]) : 0
		);

		return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then(
				(result) => {
					return res(result);
				},
				(e) => {
					return rej(e);
				}
			);
		});
	}
}
