import React from 'react';
import { Col, Row, Image, Container} from 'react-bootstrap';
import logo from "../Imagens/logo-login.png";

export class Pagina404Componente extends React.Component{

    render(){
        return (
            <Container>
                <Row style={{textAlign: "center"}}>
                    <Col xs={12}>
                        <Image 
                        style={{marginTop: 100}}
                        src={logo} 
                        height={200}
                        width={500} 
                        className="center"
                        fluid  />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h1 style={{marginTop: 50, textAlign: "center"}}>
                            Página não encontrada!
                        </h1>
                    </Col>
                </Row>
            </Container>
        )
    }

}