import { PropertySituationInteractor, PropertySituationRequest, PropertySituationStructRequest} from "../../Coordinator/PropertySituation/PropertySituationInteractor";

export class PropertySituationViewModel {
    private interactor: PropertySituationInteractor;

	constructor() {
		this.interactor = new PropertySituationInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new PropertySituationRequest(
            obj["sgepsituacaopropriedade"] ? parseInt(obj["sgepsituacaopropriedade"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new PropertySituationStructRequest(
            obj["sgepsituacaopropriedade"] ? parseInt(obj["sgepsituacaopropriedade"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}