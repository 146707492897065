import { Request } from '../../Servidor/Request';

export class ImmobileSituationRequest {
    private cgepsituacoesshape: number;
    private nome: string;

	constructor(cgepsituacoesshape: number, nome: string) {
		this.cgepsituacoesshape = cgepsituacoesshape;
		this.nome = nome;
	}


    /**
     * Getter cgepsituacoesshape
     * @return {number}
     */
	public getCgepsituacoesshape(): number {
		return this.cgepsituacoesshape;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class ImmobileSituationResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class ImmobileSituationStructRequest {
    private cgepsituacoesshape: number;

	constructor(cgepsituacoesshape: number) {
        this.cgepsituacoesshape = cgepsituacoesshape;
	}


    /**
     * Getter cgepsituacoesshape
     * @return {number}
     */
	public getCgepsituacoesshape(): number {
		return this.cgepsituacoesshape;
	}

}

export class ImmobileSituationStructResponse {
    private cgepsituacoesshape: number;
    private nome: string;

	constructor(cgepsituacoesshape: number, nome: string) {
		this.cgepsituacoesshape = cgepsituacoesshape;
		this.nome = nome;
	}


    /**
     * Getter cgepsituacoesshape
     * @return {number}
     */
	public getCgepsituacoesshape(): number {
		return this.cgepsituacoesshape;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class ImmobileSituationInteractor {
    public fetchSave(request: ImmobileSituationRequest): PromiseLike<ImmobileSituationResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/situacao-imovel/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new ImmobileSituationResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: ImmobileSituationStructRequest): PromiseLike<ImmobileSituationStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/situacao-imovel/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}