import { NeighborhoodInteractor, NeighborhoodRequest, NeighborhoodStructRequest} from "../../Coordinator/Neighborhood/NeighborhoodInteractor";

export class NeighborhoodViewModel {
    private interactor: NeighborhoodInteractor;

	constructor() {
		this.interactor = new NeighborhoodInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new NeighborhoodRequest(
            obj["sgepbairros"] ? parseInt(obj["sgepbairros"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new NeighborhoodStructRequest(
            obj["sgepbairros"] ? parseInt(obj["sgepbairros"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}