import { AdminProcessMovementInteractor } from "../../Coordinator/AdminProcessMovement/AdminProcessMovementInteractor";
import moment from 'moment';

export class AdminProcessMovementViewModel {
    private interactor: AdminProcessMovementInteractor;

    constructor() {
		this.interactor = new AdminProcessMovementInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            obj["datamovimentacao"] = obj["datamovimentacao"] ? moment(obj["datamovimentacao"], 'DD/MM/YYYY').format('YYYY-MM-DD') : ""
			this.interactor.fetchSave(obj).then(() => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(obj).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchProfessional(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchProfessional().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}