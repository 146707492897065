import React from "react";
import { EconActivityLinkViewModel } from "./EconActivityLinkViewModel";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Image, Col, Form, Button, Modal } from 'react-bootstrap';
import fechar from "../../Imagens/close-24.png"; 
import salvar from "../../Imagens/save-16.png"; 

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import { EconActivityViewModel } from '../EconActivity/EconActivityViewModel';
import { EconActivityViewController, EconActivityViewControllerDelegate } from '../EconActivity/EconActivityViewController';

interface MyProps {
    navigation: any
    delegate: EconActivityLinkViewControllerDelegate
    viewModel: EconActivityLinkViewModel
    idPrimary: number
    idPerson: number
}

export interface EconActivityLinkViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class EconActivityLinkViewController extends React.Component<MyProps> implements EconActivityViewControllerDelegate {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        EconActivity: Array<any>(),
        showEconActivity: false
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    tapDimiss(navigation: any): void {
        this.setState({
            showEconActivity: false
        })
    }

    componentDidMount() {
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Atividades Economicas - Pessoa</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="sgeppessoaatividadeseconomicas"
                                    defaultValue={json['sgeppessoaatividadeseconomicas'] ? json['sgeppessoaatividadeseconomicas'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <InputModalSelectComponent 
                                    id="sgepatividadeseconomicas"
                                    keyValue="sgepatividadeseconomicas"
                                    label="Atividades Economicas"
                                    required={true}
                                    placeholder="Procure"
                                    showAddButton={true}
                                    navigation={this.props.navigation}
                                    titleModal="Selecione Atividade"
                                    table="gepatividadeseconomicas"
                                    valueDefault={json['sgepatividadeseconomicas']}
                                    getValue={(id: number, value: string) => this.setEconActivity(id, value)}
                                    tapAdd={() => this.setState({showEconActivity: true})} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                {this.getModalEconActivity()}
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setEconActivity(id: number, value: string) {
        var json = this.state.json as any 
        json['sgepatividadeseconomicas'] = id 

        this.setState({
            json: json
        })
    }

    private getModalEconActivity() {
        let viewModel = new EconActivityViewModel()
        return (
            <Modal show={this.state.showEconActivity} dialogClassName="modal-full-screen">
                <Modal.Body>
                    <EconActivityViewController
                    navigation={this.props.navigation} 
                    delegate={this} 
                    idPrimary={0} 
                    viewModel={viewModel}
                    isShowHeader={false}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    private save() {
        var json = this.state.json as any
        if(json["sgepatividadeseconomicas"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["sgepatividadeseconomicas"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        json["sgeppessoa"] = this.props.idPerson

        this.setState({isLoading: true})

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            this.setState({isLoading: false})
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['sgeppessoaatividadeseconomicas'] = this.props.idPrimary

        this.setState({isLoading: true})

        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })
                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}