import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { PropertyConfigViewControllerDelegate, PropertyConfigViewController } from '../../Screen/PropertyConfig/PropertyConfigViewController';
import { PropertyConfigViewModel } from '../../Screen/PropertyConfig/PropertyConfigViewModel';
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import { RealEstateRegistrationConfigViewController, RealEstateRegistrationConfigViewControllerDelegate } from '../../Screen/RealEstateRegistrationConfig/RealEstateRegistrationConfigViewController';
import { RealEstateRegistrationConfigViewModel } from '../../Screen/RealEstateRegistrationConfig/RealEstateRegistrationConfigViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class CemeteryConfigCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, PropertyConfigViewControllerDelegate, RealEstateRegistrationConfigViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routePropertyTable(),
            this.routePropertyForm(),
            this.routeRealEstateRegistrationTable(),
            this.routeRealEstateRegistrationForm()
        ]
    }

    private routePropertyTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geppropriedadeconfiguracao")
        return (<ProtectedRoute requiredPermissions={['cemiterio.config_prop.*']} path="/:base/cadastro-cemiterio-propriedade-configuracao" exact={true} component={(props: any) => {
            var filter = new TableGenericFilter('Origem', 'C')
            return (<TableGenericViewController 
                permissions={{
                    add: 'cemiterio.config_prop.adicionar',
                    edit: 'cemiterio.config_prop.editar',
                    delete: 'cemiterio.config_prop.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Configuração Propriedade do Cemitério"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routePropertyForm(): JSX.Element {
        var viewModel = new PropertyConfigViewModel()
        return (<ProtectedRoute requiredPermissions={['cemiterio.config_prop.adicionar', 'cemiterio.config_prop.editar']} path="/:base/cadastro-cemiterio-propriedade-configuracao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<PropertyConfigViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                title={"Configuração Propriedade do Cemitério"}
                pointType={'C'}
            />)
        }}/>)
    }

    private routeRealEstateRegistrationTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepcadastroimobiliarioconfiguracao")
        return (<ProtectedRoute requiredPermissions={['cemiterio.config_edif.*']} path="/:base/cadastro-cemiterio-cadastro-tecnico-configuracao" exact={true} component={(props: any) => {
            var filter = new TableGenericFilter('Origem', 'C')
            return (<TableGenericViewController
                permissions={{
                    add: 'cemiterio.config_edif.adicionar',
                    edit: 'cemiterio.config_edif.editar',
                    delete: 'cemiterio.config_edif.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Configuração Edificação do Cemitério"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeRealEstateRegistrationForm(): JSX.Element {
        var viewModel = new RealEstateRegistrationConfigViewModel()
        return (<ProtectedRoute requiredPermissions={['cemiterio.config_edif.adicionar', 'cemiterio.config_edif.editar']} path="/:base/cadastro-cemiterio-cadastro-tecnico-configuracao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<RealEstateRegistrationConfigViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                title={"Configuração Edificação Imobiliário"}
                pointType={'C'}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geppropriedadeconfiguracao") {
            navigation.history.push(`cadastro-cemiterio-propriedade-configuracao/cad/0`);
            return;
        } else if(viewModel.getTable() == "gepcadastroimobiliarioconfiguracao") {
            navigation.history.push(`cadastro-cemiterio-cadastro-tecnico-configuracao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geppropriedadeconfiguracao") {
            navigation.history.push(`cadastro-cemiterio-propriedade-configuracao/cad/${idSelection}`);
            return;
        } else if(viewModel.getTable() == "gepcadastroimobiliarioconfiguracao") {
            navigation.history.push(`cadastro-cemiterio-cadastro-tecnico-configuracao/cad/${idSelection}`);
            return;
        }
    }

}