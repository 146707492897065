import { Request } from '../../Servidor/Request';

export class ImportRealStateRegistrationInteractor {
    public fetchImportData(request: FormData): PromiseLike<boolean> {
        return new Promise((res, rej) => {
            return new Request().requestFile("importacao-dados/salvar-cadastro-imobiliario", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(true)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchData(request: FormData): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestFile("importacao-dados/buscar-dados-cadastro-imobiliario", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}