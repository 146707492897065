import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { EconActivityViewController, EconActivityViewControllerDelegate } from "../../Screen/EconActivity/EconActivityViewController";
import { EconActivityViewModel } from "../../Screen/EconActivity/EconActivityViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";

export class EconActivityCoordinator implements EconActivityViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepatividadeseconomicas")
        return (<ProtectedRoute requiredPermissions={['info_bas.ativ_econ.*']} path="/:base/atividades-economicas" exact={true} component={(props:any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.ativ_econ.adicionar',
                    edit: 'info_bas.ativ_econ.editar',
                    delete: 'info_bas.ativ_econ.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Atividades Econômicas"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new EconActivityViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.ativ_econ.adicionar', 'info_bas.ativ_econ.editar']} path="/:base/atividades-economicas/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<EconActivityViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                isShowHeader={true}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepatividadeseconomicas"){
            navigation.history.push(`atividades-economicas/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepatividadeseconomicas"){
            navigation.history.push(`atividades-economicas/cad/${idSelection}`);
            return;
        }
    }
}