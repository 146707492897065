import { ImportRealStateRegistrationInteractor } from "../../Coordinator/ImportRealStateRegistration/ImportRealStateRegistrationInteractor";

export class ImportRealStateRegistrationViewModel {
    private interactor: ImportRealStateRegistrationInteractor;

	constructor() {
		this.interactor = new ImportRealStateRegistrationInteractor();
    }
    
    public fetchImportData(request: FormData): PromiseLike<boolean> {
        return new Promise((res, rej) => {
            return this.interactor.fetchImportData(request).then(() => {
                res(true)
            }, e => {
                rej(e);
            })
        });
    }

    public fetchData(request: FormData): PromiseLike<any> {
        return new Promise((res, rej) => {
            return this.interactor.fetchData(request).then((rs) => {
                res(rs)
            }, e => {
                rej(e);
            })
        });
    }
}