import {FarmProductRequest, FarmProductStructRequest, FarmProductInteractor} from "../../Coordinator/FarmProduct/FarmProductInteractor";

export class FarmProductViewModel {
    private interactor: FarmProductInteractor;

    constructor() {
        this.interactor = new FarmProductInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new FarmProductRequest(
            obj["sgepprodutosagropecuarios"] ? parseInt(obj["sgepprodutosagropecuarios"]) : 0,
            obj["sgeptpprodagropecuario"] ? parseInt(obj["sgeptpprodagropecuario"]) : 0,
            obj["nome"] ? obj["nome"] : "",
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(true)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new FarmProductStructRequest(
            obj["sgepprodutosagropecuarios"] ? parseInt(obj["sgepprodutosagropecuarios"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchSelectType(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchSelectType().then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    } 
}