import { Request } from '../../Servidor/Request';

export class PropertyFormObtainingRequest {
    private sgepformaobtencao: number;
    private nome: string;

	constructor(sgepformaobtencao: number, nome: string) {
		this.sgepformaobtencao = sgepformaobtencao;
		this.nome = nome;
	}


    /**
     * Getter sgepformaobtencao
     * @return {number}
     */
	public getSgepformaobtencao(): number {
		return this.sgepformaobtencao;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class PropertyFormObtainingStructRequest {
    private sgepformaobtencao: number;

	constructor(sgepformaobtencao: number) {
        this.sgepformaobtencao = sgepformaobtencao;
	}


    /**
     * Getter sgepformaobtencao
     * @return {number}
     */
	public getSgepformaobtencao(): number {
		return this.sgepformaobtencao;
	}

}

export class PropertyFormObtainingStructResponse {
    private sgepformaobtencao: number;
    private nome: string;

	constructor(sgepformaobtencao: number, nome: string) {
		this.sgepformaobtencao = sgepformaobtencao;
		this.nome = nome;
	}


    /**
     * Getter sgepformaobtencao
     * @return {number}
     */
	public getSgepformaobtencao(): number {
		return this.sgepformaobtencao;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class PropertyFormObtainingInteractor {
    public fetchSave(request: PropertyFormObtainingRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/forma-obtencao/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PropertyFormObtainingStructRequest): PromiseLike<PropertyFormObtainingStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/forma-obtencao/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}