import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { FarmProductTypeViewController, FarmProductTypeViewControllerDelegate } from "../../Screen/FarmProductType/FarmProductTypeViewController";
import { FarmProductTypeViewModel } from "../../Screen/FarmProductType/FarmProductTypeViewModel";
import { FarmProductViewController} from "../../Screen/FarmProduct/FarmProductViewController";
import { FarmProductViewModel} from "../../Screen/FarmProduct/FarmProductViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from "../../Components/ProtectedRoute";

export class FarmProductTypeCoordinator implements FarmProductTypeViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[]{
        return[
            this.routeTableTypeProduct(),
            this.routeFormTypeProduct(),
            this.routeTableToProduct(),
            this.routeFormToProduct()
        ]
    }

    private routeTableTypeProduct(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Produto", 
            action: (navigation: any, item: any, component: React.Component) => this.routerProduct(navigation, item),
            isSelection: true})

        var viewModel = new TableGenericViewModel("geptpprodagropecuario")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.tipo_produto.*']} path="/:base/tipo-produto-agropecuario" exact={true} component={(props:any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.tipo_produto.adicionar',
                    edit: 'censo_agrop.tipo_produto.editar',
                    delete: 'censo_agrop.tipo_produto.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Tipo de Produto Agropecuário"}
                options={arrayOptions}
            />)
        }}/>)
    }

    private routeFormTypeProduct(): JSX.Element {
        var viewModel = new FarmProductTypeViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.tipo_produto.adicionar', 'censo_agrop.tipo_produto.editar']} path="/:base/tipo-produto-agropecuario/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<FarmProductTypeViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    private routeTableToProduct(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepprodutosagropecuarios")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.produtos.*']} path="/:base/tipo-produto-agropecuario/:id/produto-agropecuario" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Tipo', idFk)
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.produtos.adicionar',
                    edit: 'censo_agrop.produtos.editar',
                    delete: 'censo_agrop.produtos.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Produtos Agropecuários"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormToProduct(): JSX.Element {
        var viewModel = new FarmProductViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.produtos.adicionar', 'censo_agrop.produtos.editar']} path="/:base/tipo-produto-agropecuario/:idProdType/produto-agropecuario/cad/:id" exact={true} component={(props: any) => {
            var idProdType = props.match.params.idProdType
            var idPk = props.match.params.id
            return (<FarmProductViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idProdType={idProdType}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "geptpprodagropecuario"){
            navigation.history.push(`tipo-produto-agropecuario/cad/0`);
            return;
        }
        
        if(viewModel.getTable() == "gepprodutosagropecuarios" && filter){
            var database = localStorage.getItem('database')
            var tipo =  filter[0].getValue()
            navigation.history.push(`/${database}/tipo-produto-agropecuario/${tipo}/produto-agropecuario/cad/0`)
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "geptpprodagropecuario"){
            navigation.history.push(`tipo-produto-agropecuario/cad/${idSelection}`);
            return;
        }

        if(viewModel.getTable() == "gepprodutosagropecuarios" && filter){
            var database = localStorage.getItem('database')
            var tipo =  filter[0].getValue()
            navigation.history.push(`/${database}/tipo-produto-agropecuario/${tipo}/produto-agropecuario/cad/${idSelection}`)
        }
    }

    private routerProduct(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/tipo-produto-agropecuario/${item}/produto-agropecuario/`);
    }
}