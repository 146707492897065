import { Request } from "../../Servidor/Request";

export class AdminProcessRequest {
    private sgepprocessoadministrativo: number;
    private numeroprotocolo: string;
    private responsavel: number;
    private solicitante: number;
    private sgepprocessoadministrativostatus: number;
    private dataabertura: string;
    private dataconclusao: string;
    private descricao: string;
    private observacao: string;
    private sgepprocessoadministrativopai: number;
    private sshape: number;
    private sgeppropriedade: number;
    private sgeppropedificacoes: number;
    private sgeptipoprocesso: number;

	constructor($sgepprocessoadministrativo: number, $numeroprotocolo: string, $responsavel: number, $solicitante: number, $sgepprocessoadministrativostatus: number, $dataabertura: string, $dataconclusao: string, $descricao: string, $observacao: string, $sgepprocessoadministrativopai: number, $sshape: number, $sgeppropriedade: number, $sgeppropedificacoes: number, $sgeptipoprocesso: number) {
        this.sgepprocessoadministrativo = $sgepprocessoadministrativo;
        this.numeroprotocolo = $numeroprotocolo;
		this.responsavel = $responsavel;
		this.solicitante = $solicitante;
		this.sgepprocessoadministrativostatus = $sgepprocessoadministrativostatus;
		this.dataabertura = $dataabertura;
		this.dataconclusao = $dataconclusao;
		this.descricao = $descricao;
        this.observacao = $observacao;
        this.sgepprocessoadministrativopai = $sgepprocessoadministrativopai;
		this.sshape = $sshape;
        this.sgeppropriedade = $sgeppropriedade;
        this.sgeppropedificacoes = $sgeppropedificacoes;
        this.sgeptipoprocesso = $sgeptipoprocesso;
	}
    

    /**
     * Getter $sgepprocessoadministrativo
     * @return {number}
     */
	public get $sgepprocessoadministrativo(): number {
		return this.sgepprocessoadministrativo;
    }
    

    /**
     * Getter $numeroprotocolo
     * @return {string}
     */
	public get $numeroprotocolo(): string {
		return this.numeroprotocolo;
	}

    /**
     * Getter $responsavel
     * @return {number}
     */
	public get $responsavel(): number {
		return this.responsavel;
	}

    /**
     * Getter $solicitante
     * @return {number}
     */
	public get $solicitante(): number {
		return this.solicitante;
	}

    /**
     * Getter $sgepprocessoadministrativostatus
     * @return {number}
     */
	public get $sgepprocessoadministrativostatus(): number {
		return this.sgepprocessoadministrativostatus;
	}

    /**
     * Getter $dataabertura
     * @return {string}
     */
	public get $dataabertura(): string {
		return this.dataabertura;
	}

    /**
     * Getter $dataconclusao
     * @return {string}
     */
	public get $dataconclusao(): string {
		return this.dataconclusao;
	}

    /**
     * Getter $descricao
     * @return {string}
     */
	public get $descricao(): string {
		return this.descricao;
	}

    /**
     * Getter $observacao
     * @return {string}
     */
	public get $observacao(): string {
		return this.observacao;
	}

    /**
     * Getter $sshape
     * @return {number}
     */
	public get $sshape(): number {
		return this.sshape;
    }
    

    /**
     * Getter $sgepprocessoadministrativopai
     * @return {number}
     */
	public get $sgepprocessoadministrativopai(): number {
		return this.sgepprocessoadministrativopai;
	}

    /**
     * Getter $sgeppropriedade
     * @return {number}
     */
	public get $sgeppropriedade(): number {
		return this.sgeppropriedade;
	}

    /**
     * Getter $sgeppropedificacoes
     * @return {number}
     */
	public get $sgeppropedificacoes(): number {
		return this.sgeppropedificacoes;
	}
    
    /**
     * Getter $sgeptipoprocesso
     * @return {number}
     */
	public get $sgeptipoprocesso(): number {
		return this.sgeptipoprocesso;
	}
}

export class AdminProcessStructRequest {
    private sgepprocessoadministrativo: number;

    constructor (sgepprocessoadministrativo:number){
        this.sgepprocessoadministrativo = sgepprocessoadministrativo;
    }

    /**
     * Getter sgepprocessoadministrativostatus
     * @return {number}
     */
    public getSgepprocessoadministrativo(): number {
        return this.sgepprocessoadministrativo;
    }
}

export class AdminProcessInteractor {
    public fetchSave(request: AdminProcessRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("processo-administrativo/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: AdminProcessStructRequest): PromiseLike<AdminProcessRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("processo-administrativo/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchProfessional(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/profissionais/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchStatus(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("processo-administrativo/status/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchTipos(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("tipo-processo/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}