import { TypeOfGraveInteractor, TypeOfGraveRequest, TypeOfGraveStructRequest} from "../../Coordinator/TypeOfGrave/TypeOfGraveInteractor";

export class TypeOfGraveViewModel {
    private interactor: TypeOfGraveInteractor;

	constructor() {
		this.interactor = new TypeOfGraveInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new TypeOfGraveRequest(
            obj["sgeptipotumulo"] ? parseInt(obj["sgeptipotumulo"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new TypeOfGraveStructRequest(
            obj["sgeptipotumulo"] ? parseInt(obj["sgeptipotumulo"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}