import React from "react";
import ProtectedRoute from '../../Components/ProtectedRoute';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { AdminProcessStatusViewController, AdminProcessStatusViewControllerDelegate } from "../../Screen/AdminProcessStatus/AdminProcessStatusViewController";
import { AdminProcessStatusViewModel } from "../../Screen/AdminProcessStatus/AdminProcessStatusViewModel";


export class AdminProcessStatusCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, AdminProcessStatusViewControllerDelegate{
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepprocessoadministrativostatus")
            return (<ProtectedRoute requiredPermissions={['proc_admin.status.*']} path="/:base/processo-administrativo-status" exact={true} component={(props:any) => {
                return (<TableGenericViewController
                    permissions={{
                        add: 'proc_admin.status.adicionar',
                        edit: 'proc_admin.status.editar',
                        delete: 'proc_admin.status.excluir',
                    }}
                    navigation={props}
                    viewModel={viewModel}
                    delegate={this}
                    title={"Status do Processo Administrativo"}
                />)
            }}/>)
        }

    private routeForm(): JSX.Element {
        var viewModel = new AdminProcessStatusViewModel()
            return (<ProtectedRoute requiredPermissions={['proc_admin.status.adicionar', 'proc_admin.status.editar']} path="/:base/processo-administrativo-status/cad/:id" exact={true} component={(props:any) => {
                var idPk = props.match.params.id
                return (<AdminProcessStatusViewController  
                    navigation={props}
                    viewModel={viewModel}
                    delegate={this}
                    idPrimary={idPk}
                />)
            }}/>)
        }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepprocessoadministrativostatus"){
            navigation.history.push(`processo-administrativo-status/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepprocessoadministrativostatus"){
            navigation.history.push(`processo-administrativo-status/cad/${idSelection}`);
            return;
        }
    }

}
