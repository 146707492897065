import { Request } from '../../Servidor/Request';

export class ProfessionalRequest {
    private sgepusuariocargo: number;
    private cusuario: number;
    private sgeppessoa: number;

	constructor(sgepusuariocargo: number, cusuario: number, sgeppessoa: number) {
		this.sgepusuariocargo = sgepusuariocargo;
		this.cusuario = cusuario;
		this.sgeppessoa = sgeppessoa;
	}


    /**
     * Getter sgepusuariocargo
     * @return {number}
     */
	public getSgepusuariocargo(): number {
		return this.sgepusuariocargo;
	}

    /**
     * Getter cusuario
     * @return {number}
     */
	public getCusuario(): number {
		return this.cusuario;
	}

    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}

}

export class ProfessionalResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success
    }
    

    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class ProfessionalStructRequest {
    private sgepusuariocargo: number;

    constructor(sgepusuariocargo: number) {
        this.sgepusuariocargo = sgepusuariocargo;
    }


    /**
     * Getter sgepusuariocargo
     * @return {number}
     */
	public getSgepusuariocargo(): number {
		return this.sgepusuariocargo;
	}
}

export class ProfessionalStructResponse {
    private sgepusuariocargo: number;
    private cusuario: number;
    private sgeppessoa: number;

	constructor(sgepusuariocargo: number, cusuario: number, sgeppessoa: number) {
		this.sgepusuariocargo = sgepusuariocargo;
		this.cusuario = cusuario;
		this.sgeppessoa = sgeppessoa;
	}


    /**
     * Getter sgepusuariocargo
     * @return {number}
     */
	public getSgepusuariocargo(): number {
		return this.sgepusuariocargo;
	}

    /**
     * Getter cusuario
     * @return {number}
     */
	public getCusuario(): number {
		return this.cusuario;
	}

    /**
     * Getter sgeppessoa
     * @return {number}
     */
	public getSgeppessoa(): number {
		return this.sgeppessoa;
	}
}

export class ProfessionalInteractor {
    public fetchSave(request: ProfessionalRequest): PromiseLike<ProfessionalResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/profissional/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new ProfessionalResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: ProfessionalStructRequest): PromiseLike<ProfessionalResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/profissional/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchUser(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/usuarios/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchPerson(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/pessoas/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}