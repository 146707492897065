import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { ProfessionalViewControllerDelegate, ProfessionalViewController } from "../../Screen/Professional/ProfessionalViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { ProfessionalViewModel } from '../../Screen/Professional/ProfessionalViewModel';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class ProfessionalCoordinator implements IndexCoordinatorDelegate, ProfessionalViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTableProfessional(),
            this.routeFormProfessional()
        ]
    }

    private routeTableProfessional(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepusuariocargo")
        return (<ProtectedRoute requiredPermissions={['info_bas.cad_profissionais.*']} path="/:base/profissionais" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.cad_profissionais.adicionar',
                    edit: 'info_bas.cad_profissionais.editar',
                    delete: 'info_bas.cad_profissionais.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Profissionais"}
            />)
        }}/>)
    }

    private routeFormProfessional(): JSX.Element {
        var viewModel = new ProfessionalViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.cad_profissionais.adicionar', 'info_bas.cad_profissionais.editar']} path="/:base/profissionais/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<ProfessionalViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepusuariocargo") {
            navigation.history.push(`profissionais/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepusuariocargo") {
            navigation.history.push(`profissionais/cad/${idSelection}`);
            return;
        }
    }

}