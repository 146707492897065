import React, { useEffect, useState } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';

import XIcon from '../Imagens/close.png';

interface Option {
  value: string
  label: string
}
interface MultiSelectFieldProps {
  options: Option[];
  label: string
  selectedItens: (selectedItens: Array<number>) => void
}

const MultiSelectField = ({ options, label, selectedItens }: MultiSelectFieldProps) => {
    const [selectedOptions, setSelectedOptions] = useState<any[]>([])
    const [availableOptions, setAvailableOptions] = useState<any[]>(options)

    function handleSelectChange(e: any) {
      const selectedOption = options.find((option: any) => option.value == e.target.value)
      if (selectedOption && !selectedOptions.includes(e.target.value)) {
        const allSelected = [...selectedOptions, selectedOption]
        setSelectedOptions(allSelected);
        localStorage.setItem('selectedLayer', JSON.stringify(allSelected))
        let availables = options.filter((option) => {
          return !allSelected.some(item => item.value === option.value);
        })
        setAvailableOptions(availables)

      }
    };

    function removeOption(value: string) {
      const updatedSelectedOptions = selectedOptions.filter(option => option !== value);
      let updatedOptions = options.filter((option) => {
        return !updatedSelectedOptions.some((item) => item.value === option.value);
      })
      setAvailableOptions(updatedOptions)
      setSelectedOptions(updatedSelectedOptions);
      localStorage.setItem('selectedLayer', JSON.stringify(updatedSelectedOptions))
    };


    useEffect(() => {
      selectedItens(selectedOptions.map((item) => {
        return item.value
      }))
    }, [selectedOptions])

    useEffect(() => {
      const layerStoraged = JSON.parse(String(localStorage.getItem('selectedLayer')));
      if (layerStoraged) {
        let availables = options.filter((option) => {
          return !layerStoraged.some((item: any) => item.value === option.value);
        })
        setAvailableOptions(availables)
        setSelectedOptions(layerStoraged);
      }
    }, []);

    return (
      <div>
        <Form.Group controlId="formMultiSelect">
          <Form.Label>{label}</Form.Label>
          <Form.Control as="select" value={''} onChange={handleSelectChange}>
            <option value="">Selecione...</option>
            {availableOptions.map((option: any, key: any) => (
              <option key={key} value={option.value}>{option.label}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <h6>Camadas Selecionadas</h6>
        <Row className='multi-select-list smallScrollbal mt-1'>
          {selectedOptions.map((option: any, key: any) => (
            <Col lg={12} key={key}>
              <span onClick={() => removeOption(option)}>
                <Image
                  style={{ margin: 5, padding: 1, cursor: "pointer", alignItems: 'center' }}
                  src={XIcon}
                  width={12}
                />
                {option.label}
              </span>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  export default MultiSelectField;
