import { Request } from "../../Servidor/Request";

export class DocumentTypeLinkRequest {
    private sgeppessoatpdoc: number;
    private sgeptpdoc: number;
    private valor: string;
    private sgeppessoa: number;

    constructor (sgeppessoatpdoc:number, sgeptpdoc: number, valor: string, sgeppessoa: number) {
        this.sgeppessoatpdoc = sgeppessoatpdoc;
        this.sgeptpdoc = sgeptpdoc;
        this.valor = valor;
        this.sgeppessoa = sgeppessoa;
    }

    /**
     * Getter sgeppessoatpdoc
     * @return {number}
     */
    public getSgeppessoatpdoc(): number {
        return this.sgeppessoatpdoc;
    }

    /**
     * Getter sgeptpdoc
     * @return {number}
     */
    public getSgeptpdoc(): number {
        return this.sgeptpdoc;
    }

    /**
     * Getter valor
     * @return {string}
     */
    public getValor(): string {
        return this.valor;
    }

    /**
     * Getter sgeppessoa
     * @return {number}
     */
     public getSgeppessoa(): number {
        return this.sgeppessoa;
    }
}

export class DocumentTypeLinkStructRequest {
    private sgeppessoatpdoc: number;

    constructor (sgeppessoatpdoc: number){
        this.sgeppessoatpdoc = sgeppessoatpdoc;
    }

    /**
     * Getter sgeppessoatpdoc
     * @return {number}
     */
    public getSgeppessoatpdoc(): number {
        return this.sgeppessoatpdoc;
    }
}

export class DocTypeSelect {
    private idPessoa: number;

    constructor(idPessoa: number) {
        this.idPessoa = idPessoa;
	}

    /**
     * Getter idPessoa
     * @return {number}
     */
	public getIdPessoa(): number {
		return this.idPessoa;
	}
}

export class DocumentTypeLinkInteractor {
    public fetchSave(request: DocumentTypeLinkRequest): PromiseLike<boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("pessoas/tipo-documento/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }
                res(true) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: DocumentTypeLinkStructRequest): PromiseLike<DocumentTypeLinkRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("pessoas/tipo-documento/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDocType(request: DocTypeSelect): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/tipo-documento/buscar-select", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}