import {CourseGroupRequest, CourseGroupStructRequest, CourseGroupInteractor} from "../../Coordinator/CourseGroup/CourseGroupInteractor";

export class CourseGroupViewModel {
    private interactor: CourseGroupInteractor;

    constructor() {
		this.interactor = new CourseGroupInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new CourseGroupRequest(
            obj["cgepcursogrupo"] ? parseInt(obj["cgepcursogrupo"]) : 0,
            obj["nome"] ? obj["nome"] : "",
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new CourseGroupStructRequest(
            obj["cgepcursogrupo"] ? parseInt(obj["cgepcursogrupo"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}
