import React from 'react';
import { Route} from 'react-router-dom'
import { LayerViewControllerDelegate, LayerViewController } from "../../Screen/Layer/LayerViewController";
import { LayerViewModel } from '../../Screen/Layer/LayerViewModel';
import { IndexCoordinatorDelegate } from '../IndexCoordinator';
import { TableGenericViewModel } from '../../Screen/TableGeneric/TableGenericViewModel';
import { TableGenericViewControllerDelegate, TableGenericViewController, NavOptions } from '../../Screen/TableGeneric/TableGenericViewController';
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import { LayerAttributeViewModel } from '../../Screen/LayerAttribute/LayerAttributeViewModel';
import { LayerAttributeViewController } from '../../Screen/LayerAttribute/LayerAttributeViewController';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class LayerCoordinator implements IndexCoordinatorDelegate, LayerViewControllerDelegate, TableGenericViewControllerDelegate {

    public getRoute(): Array<JSX.Element> {
        return [
            this.routeTableLayer(),
            this.routeFormLayer(),
            this.routeTableLayerAttributes(),
            this.routeFormAttribute()
        ]
    }

    private routeTableLayer(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Atributos", 
            action: (navigation: any, item: any, component: React.Component) => this.showAttributes(navigation, item, component), 
            isSelection: true
        })

        var arrayActions = new Array<NavOptions>()
        arrayActions.push({
            title: "Importar ortofoto", 
            action: (navigation: any, item: any, component: React.Component) => this.importTiff(navigation, item, component), 
            isSelection: true, 
            uploadFile: true,
            multipleFile: false
        })
        arrayActions.push({
            title: "Importar shape", 
            action: (navigation: any, item: any, component: React.Component) => this.changeImportShape(navigation, item, component), 
            isSelection: true, 
            uploadFile: true,
            multipleFile: true
        })
        arrayActions.push({
            title: "Importar dxf", 
            action: (navigation: any, item: any, component: React.Component) => this.changeImportDXF(navigation, item, component), 
            isSelection: true, 
            uploadFile: true,
            multipleFile: false
        })
        arrayActions.push({
            title: "Deletar ortofoto", 
            action: (navigation: any, item: any, component: React.Component) => this.deleteTiff(navigation, item, component), 
            isSelection: true, 
            uploadFile: false,
            multipleFile: false
        })
        arrayActions.push({
            title: "Deletar shapes", 
            action: (navigation: any, item: any, component: React.Component) => this.deleteShapes(navigation, item, component), 
            isSelection: true, 
            uploadFile: false,
            multipleFile: false
        })

        var viewModel = new TableGenericViewModel("camada")
        return (<ProtectedRoute requiredPermissions={['gis.camadas.*']} path="/:base/camadas" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'gis.camadas.adicionar',
                    edit: 'gis.camadas.editar',
                    delete: 'gis.camadas.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Camadas"}
                options={arrayOptions}
                actions={arrayActions}
            />)
        }}/>)
    }

    private routeFormLayer(): JSX.Element {
        var viewModel = new LayerViewModel()
        return (<ProtectedRoute requiredPermissions={['gis.camadas.adicionar', 'gis.camadas.editar']} path="/:base/camadas/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<LayerViewController navigation={props} viewModel={viewModel} delegate={this} idPrimary={idPk}/>)
        }}/>)
    }

    private routeTableLayerAttributes(): JSX.Element {
        var viewModel = new TableGenericViewModel("camadaatributo")
        return (<ProtectedRoute requiredPermissions={['gis.camadas.*']} path="/:base/camada/:id/atributos" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter("Camada", idFk)
            return (<TableGenericViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Atributos"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormAttribute(): JSX.Element {
        var viewModel = new LayerAttributeViewModel()
        return (<ProtectedRoute requiredPermissions={['gis.camadas.adicionar', 'gis.camadas.editar']} path="/:base/camada/:idFk/atributos/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            var idFk = props.match.params.idFk
            return (<LayerAttributeViewController navigation={props} viewModel={viewModel} delegate={this} idForeign={idFk} idPrimary={idPk}/>)
        }}/>)
    }

    public showAttributes(navigation: any, item: any, component: React.Component) {
        navigation.history.push(`camada/${item}/atributos`);
    }

    private importTiff(navigation: any, item: any, component: React.Component) {
        var files = item.files as Array<any>
        var json = {
            scamada: item.json['id']
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(json));
        component.setState({
            showLoading: true
        })

        var viewModelLayer = new LayerViewModel()
        viewModelLayer.fetchImportTiff(formData).then((rs) => {
            component.setState({
                showLoading: false
            })

            toast.success("Ortofoto importada com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    public changeImportShape(navigation: any, item: any, component: React.Component) {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja substituir shapes já existentes?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.importShape(item, component, true)
                }
            },
            {
                label: 'Não',
                onClick: () => {
                    this.importShape(item, component, false)
                }
            }]
        })
    }

    public changeImportDXF(navigation: any, item: any, component: React.Component) {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja substituir shapes já existentes?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.importDXF(item, component, true)
                }
            },
            {
                label: 'Não',
                onClick: () => {
                    this.importDXF(item, component, false)
                }
            }]
        })
    }

    public importShape(item: any, component: React.Component, isReadyShapes: boolean) {
        var files = item.files as Array<any>
        var json = {
            scamada: item.json['id'],
            deleteshapes: isReadyShapes
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(json));
        component.setState({
            showLoading: true
        })

        var viewModelLayer = new LayerViewModel()
        viewModelLayer.fetchImportShape(formData).then((rs) => {
            component.setState({
                showLoading: false
            })

            toast.success("Arquivos importados com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    public importDXF(item: any, component: React.Component, isReadyShapes: boolean) {
        var files = item.files as Array<any>
        var json = {
            scamada: item.json['id'],
            deleteshapes: isReadyShapes
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(json));
        component.setState({
            showLoading: true
        })

        var viewModelLayer = new LayerViewModel()
        viewModelLayer.fetchImportDXF(formData).then((rs) => {
            component.setState({
                showLoading: false
            })

            toast.success("Arquivos importados com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }, e => {
            component.setState({
                showLoading: false
            })

            toast.error(e.Mensagem, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    public deleteTiff(navigation: any, item: any, component: React.Component) {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir a ortofoto dessa camada?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var json = {scamada: item.id}
                    component.setState({
                        showLoading: true
                    })

                    var viewModelLayer = new LayerViewModel()
                    viewModelLayer.fetchRemoveTiff(json).then((rs) => {
                        component.setState({
                            showLoading: false
                        })

                        toast.success("Ortofoto deletada com sucesso!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }, e => {
                        component.setState({
                            showLoading: false
                        })

                        toast.error(e.Mensagem, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    public deleteShapes(navigation: any, item: any, component: React.Component) {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir os shapes dessa camada?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    var json = {scamada: item.id}
                    component.setState({
                        showLoading: true
                    })

                    var viewModelLayer = new LayerViewModel()
                    viewModelLayer.fetchRemoveShape(json).then((rs) => {
                        component.setState({
                            showLoading: false
                        })

                        toast.success("Shapes deletados com sucesso!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }, e => {
                        component.setState({
                            showLoading: false
                        })

                        toast.error(e.Mensagem, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    public tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    public add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "camada") {
            navigation.history.push(`camadas/cad/0`);
            return;
        }

        if(viewModel.getTable() == "camadaatributo") {
            navigation.history.push(`atributos/cad/0`);
            return;
        }
    }

    public edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "camada") {
            navigation.history.push(`camadas/cad/${idSelection}`);
            return;
        }

        if(viewModel.getTable() == "camadaatributo") {
            navigation.history.push(`atributos/cad/${idSelection}`);
            return;
        }
    }
}