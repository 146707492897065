import L from 'leaflet';

L.LegendControl = L.Control.extend({
    onAdd: function (map) {
        this.container = L.DomUtil.create('div', 'container-fluid legend');

        return this.container
    },
    update: function(props) {
        let item = L.DomUtil.create('div', '', this.container)
        var itens = ''
        if(props.list != undefined) {
            props.list.forEach((item) => {
                var geomType = ''
                if(item['type'] == 'ponto') {
                    geomType = `<image class="img-fluid" src="${item['image']}" />`
                } else if(item['type'] == 'poligono') {
                    geomType = `<div style="background-color: ${item['color']}; width: 24px; height: 24px;"></div>`
                } else if(item['type'] == 'linha') {
                    geomType = `<div style="background-color: ${item['color']}; width: 1px; height: 24px; margin-left: 10px;"></div>`
                }

                itens += `
                <div class="row">
                    <div class="col-2">
                        ${geomType}
                    </div>
                    <div class="col-10" style="text-align: left;">
                        <p>${item['layer']}</p>
                    </div>
                </div>`
            })
        }

        item.innerHTML = `
        <div class="row">
            <div class="col">
                <h5>Legenda</h5>
            </div>
        </div>
        ${itens}
        `
    }
});

L.legendControl = function(options) {
    return new L.LegendControl(options);
};