import {DocumentTypeRequest, DocumentTypeStructRequest, DocumentTypeInteractor} from "../../Coordinator/DocumentType/DocumentTypeInteractor";

export class DocumentTypeViewModel {
    private interactor: DocumentTypeInteractor;

    constructor() {
        this.interactor = new DocumentTypeInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new DocumentTypeRequest(
            obj["sgeptpdoc"] ? parseInt(obj["sgeptpdoc"]) : 0,
            obj["tipo"] ? obj["tipo"] : "",
            obj["nome"] ? obj["nome"] : "",
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(true)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new DocumentTypeStructRequest(
            obj["sgeptpdoc"] ? parseInt(obj["sgeptpdoc"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }
}