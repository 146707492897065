import React from 'react'
import { ImportRealStateRegistrationViewModel } from './ImportRealStateRegistrationViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button,  } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";

interface MyProps {
    navigation: any
    delegate: ImportRealStateRegistrationViewControllerDelegate
    viewModel: ImportRealStateRegistrationViewModel
}

export interface ImportRealStateRegistrationViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class ImportRealStateRegistrationViewController extends React.Component<MyProps>{

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        dataResponse: {},
        typeData: new Array<any>(),
        fieldId: new Array<any>(),
        fields: new Array<any>()
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadTypeData()
    }

    render() {
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Importar Cadastro Imobiliário</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Tipo de cadastro</Form.Label>
                                    <Select
                                    id="tipocadastro"
                                    value={this.formEvent.onValueSelect('select', 'tipocadastro', this.state.typeData)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "tipocadastro")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.typeData}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Arquivo</Form.Label>
                                    <input 
                                    type="file"
                                    onChange={(e: any) => this.onChangeFile(e)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Button type="button" onClick={(e: any) => this.loadData()} variant="primary">Buscar dados</Button>
                                </Col>
                            </Row>
                            {this.state.fields.length > 0 && 
                            <fieldset className="fieldset" style={{marginTop: 20}}>
                                <legend>Campo de identificação do imóvel</legend>
                                <Row>{this.state.fieldId}</Row>
                            </fieldset>}
                            {this.state.fields.length > 0 && 
                            <fieldset className="fieldset" style={{marginTop: 20}}>
                                <legend>Configuração de campos</legend>
                                <Row>{this.state.fields}</Row>
                            </fieldset>}
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Importar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private onChangeFile(e: any) {
        var json = this.state.json as any
        json['files'] = e.target.files

        this.setState({json: json})
    }

    private loadData() {
        var json = this.state.json as any
        if(json['tipocadastro'] == undefined) {
            this.showFieldsEmpty("Primeiro é necessário selecionar o tipo de cadastro!")
            return 
        }

        if(json['files'] == undefined) {
            this.showFieldsEmpty("Nenhum arquivo selecionado!")
            return 
        }

        var files = json['files'] as Array<any>
        var jsonBody = {
            tipocadastro: json['tipocadastro']
        }

        var formData = new FormData()
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(jsonBody));
        this.setState({isLoading: true})

        this.props.viewModel.fetchData(formData).then((rs) => {
            this.setState({isLoading: false})
            console.log(rs)
            this.loadFields(rs)
        }, e => {
            this.setState({isLoading: false})
            this.showFieldsEmpty(JSON.stringify(e))
        })
    }

    private loadFields(result: any) {
        var arrayTypeData = new Array<any>()
        var resultFile = result['columnsExcel'] as Array<any> 
        resultFile.forEach((value) => {
            arrayTypeData.push({value: value, label: value, check: false})
        })

        var arrayFields = new Array<any>()
        var resultAttributes = result['attributes'] as Array<any> 
        resultAttributes.forEach((value) => {
            arrayFields.push((
                <Col lg="4" xs="12" className="bottom10">
                    <Form.Label>{value['nome']}</Form.Label>
                    <Select
                    id={value['id']}
                    value={this.formEvent.onValueSelect('select', value['id'], this.state.fields)}
                    onChange={(e: any) => this.formEvent.onChange("select", e, value['id'])}
                    closeMenuOnSelect={true}
                    placeholder={"Selecione"}
                    options={arrayTypeData}
                    isMulti={false}
                    />
                </Col>
            ))
        })

        var fieldId = new Array<any>()
        fieldId.push((
            <Col lg="4" xs="12" className="bottom10">
                <Form.Label className="isRequired">Inscrição do imóvel</Form.Label>
                <Select
                id={'idVinculo'}
                value={this.formEvent.onValueSelect('select', 'idVinculo', this.state.fields)}
                onChange={(e: any) => this.formEvent.onChange("select", e, 'idVinculo')}
                closeMenuOnSelect={true}
                placeholder={"Selecione"}
                options={arrayTypeData}
                isMulti={false}
                />
            </Col>
        ))

        this.setState({
            fieldId: fieldId,
            fields: arrayFields
        })
    }

    private loadTypeData() {
        var arrayTypeData = new Array<any>()
        arrayTypeData.push({value: "edificacao", label: "Cadastro de unidade", check: false})
        arrayTypeData.push({value: "propriedade", label: "Cadastro de terreno", check: false})
        
        this.setState({
            typeData: arrayTypeData
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private save() {
        var json = this.state.json as any
        if(json['tipocadastro'] == undefined) {
            this.showFieldsEmpty("Primeiro é necessário selecionar o tipo de cadastro!")
            return 
        }

        if(json['files'] == undefined) {
            this.showFieldsEmpty("Nenhum arquivo selecionado!")
            return 
        }

        if(json['idVinculo'] == undefined) {
            this.showFieldsEmpty("Campo obrigatório não preenchido")
            return 
        }

        var files = json['files'] as Array<any>
        var jsonBody = Object.assign({}, json) 
        delete jsonBody['files']

        var formData = new FormData()
        for (var i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
        }

        formData.append('body', JSON.stringify(jsonBody));
        this.setState({isLoading: true})

        this.props.viewModel.fetchImportData(formData).then((rs) => {
            this.setState({isLoading: false})
            window.location.reload()
        }, e => {
            this.setState({isLoading: false})
            this.showFieldsEmpty(JSON.stringify(e))
        })
    }
}