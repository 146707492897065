import { Request } from '../../Servidor/Request';

export class VisitRequest {
    private sgepvisita: number;
    private data: string;
    private hora: string; 
    private sgepusuariocargo: number;
    private sgepmotivovisita: number;
    private obs: string;
    private sgeppontos: number;
    private sshape: number;
    private sgepprocessoadministrativo: number;
    private sgeppropriedade: number;
    private sgeppropedificacoes: number;


	constructor(sgepvisita: number, data: string, hora: string, sgepusuariocargo: number, sgepmotivovisita: number, obs: string, sgeppontos: number, sshape: number, sgepprocessoadministrativo: number, sgeppropriedade: number, sgeppropedificacoes: number) {
		this.sgepvisita = sgepvisita;
		this.data = data;
		this.hora = hora;
		this.sgepusuariocargo = sgepusuariocargo;
		this.sgepmotivovisita = sgepmotivovisita;
		this.obs = obs;
		this.sgeppontos = sgeppontos;
        this.sshape = sshape;
        this.sgepprocessoadministrativo = sgepprocessoadministrativo;
        this.sgeppropriedade = sgeppropriedade
        this.sgeppropedificacoes = sgeppropedificacoes
	}


    /**
     * Getter sgepvisita
     * @return {number}
     */
	public getSgepvisita(): number {
		return this.sgepvisita;
	}

    /**
     * Getter data
     * @return {string}
     */
	public getData(): string {
		return this.data;
	}

    /**
     * Getter hora
     * @return {string}
     */
	public getHora(): string {
		return this.hora;
	}

    /**
     * Getter sgepusuariocargo
     * @return {number}
     */
	public getSgepusuariocargo(): number {
		return this.sgepusuariocargo;
	}

    /**
     * Getter sgepmotivovisita
     * @return {number}
     */
	public getSgepmotivovisita(): number {
		return this.sgepmotivovisita;
	}

    /**
     * Getter obs
     * @return {string}
     */
	public getObs(): string {
		return this.obs;
	}

    /**
     * Getter sgeppontos
     * @return {number}
     */
	public getSgeppontos(): number {
		return this.sgeppontos;
	}

    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
    }
    

    /**
     * Getter $sgepprocessoadministrativo
     * @return {number}
     */
	public get $sgepprocessoadministrativo(): number {
		return this.sgepprocessoadministrativo;
	}
    
    /**
     * Getter $sgeppropriedade
     * @return {number}
     */
	public get $sgeppropriedade(): number {
		return this.sgeppropriedade;
	}

    /**
     * Getter $sgeppropedificacoes
     * @return {number}
     */
	public get $sgeppropedificacoes(): number {
		return this.sgeppropedificacoes;
	}
}

export class VisitResponse {
    private success: boolean;

    constructor(success: boolean) {
        this.success = success;
    }


    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}
}

export class VisitStructRequest {
    private sgepvisita: number;

	constructor(sgepvisita: number) {
        this.sgepvisita = sgepvisita;
	}


    /**
     * Getter sgepvisita
     * @return {number}
     */
	public getSgepvisita(): number {
		return this.sgepvisita;
	}

}

export class VisitStructResponse {
    private sgepvisita: number;
    private data: string;
    private hora: string; 
    private sgepusuariocargo: number;
    private sgepmotivovisita: number;
    private obs: string;
    private sgeppontos: number;
    private sshape: number;
    private sgepprocessoadministrativo: number;


	constructor(sgepvisita: number, data: string, hora: string, sgepusuariocargo: number, sgepmotivovisita: number, obs: string, sgeppontos: number, sshape: number, sgepprocessoadministrativo: number) {
		this.sgepvisita = sgepvisita;
		this.data = data;
		this.hora = hora;
		this.sgepusuariocargo = sgepusuariocargo;
		this.sgepmotivovisita = sgepmotivovisita;
		this.obs = obs;
		this.sgeppontos = sgeppontos;
        this.sshape = sshape;
        this.sgepprocessoadministrativo = sgepprocessoadministrativo
	}


    /**
     * Getter sgepvisita
     * @return {number}
     */
	public getSgepvisita(): number {
		return this.sgepvisita;
	}

    /**
     * Getter data
     * @return {string}
     */
	public getData(): string {
		return this.data;
	}

    /**
     * Getter hora
     * @return {string}
     */
	public getHora(): string {
		return this.hora;
	}

    /**
     * Getter sgepusuariocargo
     * @return {number}
     */
	public getSgepusuariocargo(): number {
		return this.sgepusuariocargo;
	}

    /**
     * Getter sgepmotivovisita
     * @return {number}
     */
	public getSgepmotivovisita(): number {
		return this.sgepmotivovisita;
	}

    /**
     * Getter obs
     * @return {string}
     */
	public getObs(): string {
		return this.obs;
	}

    /**
     * Getter sgeppontos
     * @return {number}
     */
	public getSgeppontos(): number {
		return this.sgeppontos;
	}

    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
	}
}

export class VisitInteractor {
    public fetchSave(request: VisitRequest): PromiseLike<VisitResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/visita/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new VisitResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: VisitStructRequest): PromiseLike<VisitStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/visita/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchProfessional(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/profissionais/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchVisitReason(id: number): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/motivo-visita/buscar-select", {sgepgrupomotivovisita: id}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchGroupVisitReason(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/grupo-motivo-visita/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}