import {SpecialNeedsRequest, SpecialNeedsStructRequest, SpecialNeedsInteractor} from "../../Coordinator/SpecialNeeds/SpecialNeedsInteractor";

export class SpecialNeedsViewModel {
    private interactor: SpecialNeedsInteractor;

    constructor() {
        this.interactor = new SpecialNeedsInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new SpecialNeedsRequest(
            obj["cgepnecessidadesespecial"] ? parseInt(obj["cgepnecessidadesespecial"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["idgoverno"] ? obj["idgoverno"] : "",
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new SpecialNeedsStructRequest(
            obj["cgepnecessidadesespecial"] ? parseInt(obj["cgepnecessidadesespecial"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }
}