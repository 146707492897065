import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { AdminProcessMovementViewController, AdminProcessMovementViewControllerDelegate } from "../../Screen/AdminProcessMovement/AdminProcessMovementViewController";
import { AdminProcessMovementViewModel } from "../../Screen/AdminProcessMovement/AdminProcessMovementViewModel";
import { TableGenericFilter } from "../TableGeneric/TableGenericInteractor";
import ProtectedRoute from '../../Components/ProtectedRoute';


export class AdminProcessMovementCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, AdminProcessMovementViewControllerDelegate{
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepprocessoadministrativomovimentacao")
            return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.*']} path="/:base/processo-administrativo/:id/movimentacoes" exact={true} component={(props:any) => {
                var filter = new TableGenericFilter('ProcessoAdm', props.match.params.id)
                return (<TableGenericViewController
                    permissions={{
                        add: 'proc_admin.proc_admin.adicionar',
                        edit: 'proc_admin.proc_admin.editar',
                        delete: 'proc_admin.proc_admin.excluir',
                    }}
                    navigation={props}
                    viewModel={viewModel}
                    delegate={this}
                    title={"Movimentações Processo Administrativo"}
                    filter={[filter]}
                />)
            }}/>)
        }

    private routeForm(): JSX.Element {
        var viewModel = new AdminProcessMovementViewModel()
            return (<ProtectedRoute requiredPermissions={['proc_admin.proc_admin.adicionar', 'proc_admin.proc_admin.editar']} path="/:base/processo-administrativo/:idAdminProcess/movimentacao/cad/:id" exact={true} component={(props:any) => {
                var idPk = props.match.params.id
                var idAdminProcess = props.match.params.idAdminProcess
                return (<AdminProcessMovementViewController  
                    navigation={props}
                    viewModel={viewModel}
                    delegate={this}
                    idPrimary={idPk}
                    idAdminProcess={idAdminProcess}
                />)
            }}/>)
        }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepprocessoadministrativomovimentacao"){
            var database = localStorage.getItem('database')
            if(filter && filter.length == 1) {
                navigation.history.push(`/${database}/processo-administrativo/${filter[0].getValue()}/movimentacao/cad/0`);
                return;
            }
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gepprocessoadministrativomovimentacao"){
            var database = localStorage.getItem('database')
            if(filter && filter.length == 1) {
                navigation.history.push(`/${database}/processo-administrativo/${filter[0].getValue()}/movimentacao/cad/${idSelection}`);
                return;
            }
        }
    }

}