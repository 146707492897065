import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { DocumentTypeViewController, DocumentTypeViewControllerDelegate } from "../../Screen/DocumentType/DocumentTypeViewController";
import { DocumentTypeViewModel } from "../../Screen/DocumentType/DocumentTypeViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import ProtectedRoute from "../../Components/ProtectedRoute";

export class DocumentTypeCoordinator implements DocumentTypeViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[]{
        return[
            this.routeTableDocTp(),
            this.routeFormDocTp(),
        ]
    }

    private routeTableDocTp(): JSX.Element {
        var viewModel = new TableGenericViewModel("geptpdoc")
        return (<ProtectedRoute requiredPermissions={['info_bas.tipo_doc.*']} path="/:base/tipo-documento" exact={true} component={(props:any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'info_bas.tipo_doc.adicionar',
                    edit: 'info_bas.tipo_doc.editar',
                    delete: 'info_bas.tipo_doc.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Tipo de Documento"}
            />)
        }}/>)
    }

    private routeFormDocTp(): JSX.Element {
        var viewModel = new DocumentTypeViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.tipo_doc.adicionar', 'info_bas.tipo_doc.editar']} path="/:base/tipo-documento/cad/:id" exact={true} component={(props:any) => {
            var idPk = props.match.params.id
            return (<DocumentTypeViewController  
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "geptpdoc"){
            navigation.history.push(`tipo-documento/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "geptpdoc"){
            navigation.history.push(`tipo-documento/cad/${idSelection}`);
            return;
        }
    }
}