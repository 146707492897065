import React from 'react'
import { UserGroupViewModel } from './UserGroupViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Accordion, Card } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 

import "../../Estilo/Form.css";
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';

interface MyProps {
    navigation: any
    delegate: UserGroupViewControllerDelegate
    viewModel: UserGroupViewModel
    idPrimary: number
}

interface MyState {
    isLoading: boolean
    json: any
    permissions: any[]
}

export interface UserGroupViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class UserGroupViewController extends React.Component<MyProps, MyState>{

    private formEvent: FormEvent
    state: MyState = {
        isLoading: false,
        json: {},
        permissions: []
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
	}

    componentDidMount() {
        this.loadPermissions()
        if(this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    private loadPermissions() {
        this.props.viewModel.fetchSelectPermissions().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.id, label: item.value, check: false})
            }

            this.setState({
                permissions: array
            })
        });
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Grupo de Usuário</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                    id="cgrusuario"
                                    defaultValue={json['cgrusuario'] ? json['cgrusuario'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                    id="grusuario"
                                    defaultValue={json['grusuario'] ? json['grusuario'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                    placeholder="Digite o nome" 
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10" >
                                    <Form.Label className="isRequired">Permissões</Form.Label>
                                    <Accordion defaultActiveKey="0">
                                        {Object.entries(
                                        this.state.permissions.reduce((acc, permission, index) => {
                                            const parts = permission.label.split('>');
                                            const [category, subCategory, action] = parts.map((part: any) => part.trim());
                                            if (!acc[category]) {
                                            acc[category] = {};
                                            }
                                            if (!acc[category][subCategory]) {
                                            acc[category][subCategory] = [];
                                            }
                                            acc[category][subCategory].push(
                                                <Form.Check 
                                                type='checkbox'
                                                id={`permission-${permission.value}`}
                                                label={permission.label}
                                                key={permission.value}
                                                value={permission.value}
                                                style={{ marginRight: '20px' }}
                                                checked={this.formEvent.isChecked("permissions", permission.value)}
                                                onChange={(e: any) => this.formEvent.onChange('checkbox', e, "permissions")}
                                                />
                                            );
                                            return acc;
                                        }, {})
                                        ).map(([category, subCategoryObj]: any[], index) => (
                                        <Card key={index}>
                                            <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                                                {category}
                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body>
                                                {Object.entries(subCategoryObj).map(([subCategory, checkboxes]) => (
                                                <>
                                                    <strong>{subCategory}</strong>
                                                    {checkboxes}
                                                </>
                                                ))}
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        ))}
                                 </Accordion>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private save() {
        var json = this.state.json as any
        if(json["grusuario"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if(json["grusuario"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any 
        json['cgrusuario'] = this.props.idPrimary

        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                resultado[0]["permissions"] = resultado[0]["permissions"].map((t: any)=> t.cgeppermissao)
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}