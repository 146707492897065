import {AdminProcessRequest, AdminProcessStructRequest, AdminProcessInteractor} from "../../Coordinator/AdminProcess/AdminProcessInteractor";
import moment from 'moment';

export class AdminProcessViewModel {
    private interactor: AdminProcessInteractor;

    constructor() {
		this.interactor = new AdminProcessInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new AdminProcessRequest(
            obj["sgepprocessoadministrativo"] ? parseInt(obj["sgepprocessoadministrativo"]) : 0,
            obj["numeroprotocolo"] ? obj["numeroprotocolo"] : "",
            obj["responsavel"] ? parseInt(obj["responsavel"]) : 0,
            obj["solicitante"] ? parseInt(obj["solicitante"]) : 0,
            obj["sgepprocessoadministrativostatus"] ? parseInt(obj["sgepprocessoadministrativostatus"]) : 0,
            obj["dataabertura"] ? moment(obj["dataabertura"], 'DD/MM/YYYY').format('YYYY-MM-DD') : "",
            obj["dataconclusao"] ? moment(obj["dataconclusao"], 'DD/MM/YYYY').format('YYYY-MM-DD') : "",
            obj["descricao"] ? obj["descricao"] : "",
            obj["observacao"] ? obj["observacao"] : "",
            obj["sgepprocessoadministrativopai"] ? parseInt(obj["sgepprocessoadministrativopai"]) : 0,
            obj["sshape"] ? parseInt(obj["sshape"]) : 0,
            obj["sgeppropriedade"] ? parseInt(obj["sgeppropriedade"]) : 0,
            obj["sgeppropedificacoes"] ? parseInt(obj["sgeppropedificacoes"]) : 0,
            obj["sgeptipoprocesso"] ? parseInt(obj["sgeptipoprocesso"]) : 0
        )
        
        return new Promise<void>((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new AdminProcessStructRequest(
            obj["sgepprocessoadministrativo"] ? parseInt(obj["sgepprocessoadministrativo"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchProfessional(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchProfessional().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchStatus(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchStatus().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchTipos(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchTipos().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}