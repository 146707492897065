import { UserRequest, UserInteractor, UserStructRequest } from "../../Coordinator/User/UserInteractor";

export class UserViewModel {
    private interactor: UserInteractor;

	constructor() {
		this.interactor = new UserInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new UserRequest(
            obj["cusuario"] ? parseInt(obj["cusuario"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["nomecompleto"] ? obj["nomecompleto"] : "",
            obj["email"] ? obj["email"] : "",
            obj["ativo"] ? obj["ativo"] : "",
            obj["grusuario"] ? obj["grusuario"] : [],
            obj["permissions"] ? obj["permissions"] : []
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new UserStructRequest(
            obj["cusuario"] ? parseInt(obj["cusuario"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
                var resultado = result 
                if(resultado.length > 0) {
                    var arrayGrUsuario = new Array<number>()
                    for(var i = 0; i < resultado[0]['grusuario'].length; i++) {
                        var grusuario = resultado[0]['grusuario'][i]
                        arrayGrUsuario.push(grusuario['cgrusuario'])
                    }
                    let arrayPermissions = resultado[0]['permissions'].map(t=> t["cgeppermissao"])
                    resultado[0]['grusuario'] = arrayGrUsuario
                    resultado[0]['permissions'] = arrayPermissions
                }

				return res(resultado)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectUserGroup(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchSelectUserGroup().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectPermissions(): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchSelectPermissions().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
        })
    }
}