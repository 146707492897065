import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { MarkerImageViewModel } from '../../Screen/MarkerImage/MarkerImageViewModel';
import { MarkerImageViewController, MarkerImageViewControllerDelegate } from '../../Screen/MarkerImage/MarkerImageViewController';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class MarkerImageCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, MarkerImageViewControllerDelegate {
    public getRoute(): Array<JSX.Element> {
        return [
            this.routeTableMarkerImage(),
            this.routeFormMarkerImage()
        ]
    }

    private routeTableMarkerImage(): JSX.Element {
        var viewModel = new TableGenericViewModel("marcadorimagem")
        return (<ProtectedRoute requiredPermissions={['gis.marc_imagem.*']} path="/:base/camada-marcador-imagem" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'gis.marc_imagem.adicionar',
                    edit: 'gis.marc_imagem.editar',
                    delete: 'gis.marc_imagem.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Marcador de imagem"}
            />)
        }}/>)
    }

    private routeFormMarkerImage(): JSX.Element {
        var viewModel = new MarkerImageViewModel()
        return (<ProtectedRoute requiredPermissions={['gis.marc_imagem.adicionar', 'gis.marc_imagem.editar']} path="/:base/camada-marcador-imagem/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<MarkerImageViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this}
                idPrimary={idPk}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "marcadorimagem") {
            navigation.history.push(`camada-marcador-imagem/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "marcadorimagem") {
            navigation.history.push(`camada-marcador-imagem/cad/${idSelection}`);
            return;
        }
    }

    
}