import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { RealEstateRegistrationConfigViewController, RealEstateRegistrationConfigViewControllerDelegate } from '../../Screen/RealEstateRegistrationConfig/RealEstateRegistrationConfigViewController';
import { RealEstateRegistrationConfigViewModel } from '../../Screen/RealEstateRegistrationConfig/RealEstateRegistrationConfigViewModel';
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class RealEstateRegistrationConfigCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, RealEstateRegistrationConfigViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepcadastroimobiliarioconfiguracao")
        return (<ProtectedRoute requiredPermissions={['cad_imob.config.*']} path="/:base/cadastro-imobiliario-configuracao" exact={true} component={(props: any) => {
            var filter = new TableGenericFilter('Origem', 'C', '!=')
            return (<TableGenericViewController
                permissions={{
                    add: 'cad_imob.config.adicionar',
                    edit: 'cad_imob.config.editar',
                    delete: 'cad_imob.config.excluir',
                }} 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Configuração Cadastro Imobiliário"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new RealEstateRegistrationConfigViewModel()
        return (<ProtectedRoute requiredPermissions={['cad_imob.config.adicionar', 'cad_imob.config.editar']} path="/:base/cadastro-imobiliario-configuracao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<RealEstateRegistrationConfigViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                title={"Configuração Cadastro Imobiliário"}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepcadastroimobiliarioconfiguracao") {
            navigation.history.push(`cadastro-imobiliario-configuracao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gepcadastroimobiliarioconfiguracao") {
            navigation.history.push(`cadastro-imobiliario-configuracao/cad/${idSelection}`);
            return;
        }
    }

}