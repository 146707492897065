import { TopographyInteractor, TopographyRequest, TopographyStructRequest} from "../../Coordinator/Topography/TopographyInteractor";

export class TopographyViewModel {
    private interactor: TopographyInteractor;

	constructor() {
		this.interactor = new TopographyInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new TopographyRequest(
            obj["sgeptopografia"] ? parseInt(obj["sgeptopografia"]) : 0,
            obj["nome"] ? obj["nome"] : ""
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res()
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new TopographyStructRequest(
            obj["sgeptopografia"] ? parseInt(obj["sgeptopografia"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}