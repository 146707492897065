import { Request } from '../../Servidor/Request';

export class MapInitialDrawSaveRequest {
    private type: string;
    private geojson: any;
    private sshape: number;
    private scamada?: number;

	constructor(type: string, geojson: any, sshape: number, scamada?: number) {
		this.type = type;
		this.geojson = geojson;
		this.sshape = sshape;
		this.scamada = scamada;
    }
    

    /**
     * Getter type
     * @return {string}
     */
	public getType(): string {
		return this.type;
	}

    /**
     * Getter geojson
     * @return {any}
     */
	public getGeojson(): any {
		return this.geojson;
	}

    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
	}

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada ? this.scamada : 0;
	}

}

export class MapInitialDrawSaveResponse {
    private success: Boolean;

	constructor(success: Boolean) {
        this.success = success;
	}

    /**
     * Getter success
     * @return {Boolean}
     */
	public getSuccess(): Boolean {
		return this.success;
	}

}

export class PointRemoveRequest {
    private sshape: number;

    constructor(sshape: number) {
        this.sshape = sshape
    }


    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
	}

}

export class PointRemoveResponse {
    private success: Boolean;

    constructor(success: Boolean) {
        this.success = success
    }


    /**
     * Getter success
     * @return {Boolean}
     */
	public getSuccess(): Boolean {
		return this.success;
	}

}

export class MapInitialInteractor {
    public fetchSaveDraw(request: Array<MapInitialDrawSaveRequest>): PromiseLike<MapInitialDrawSaveResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("camada/salvar-camada-draw", {draw: request}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new MapInitialDrawSaveResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchRemovePoint(request: PointRemoveRequest): PromiseLike<PointRemoveResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("tabela/deletarPonto", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new PointRemoveResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchMapPDF(request: PointRemoveRequest): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("relatorio/mapa/gerar-pdf-ponto", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs)
            }, e => {
                rej(e);
            });
        });
    }
}