import { Request } from '../../Servidor/Request';

export class DistrictRequest {
    private sgepdistrito: number;
    private nome: string;
    private sigla: string;

	constructor(sgepdistrito: number, nome: string, sigla: string) {
		this.sgepdistrito = sgepdistrito;
		this.nome = nome;
        this.sigla = sigla;
	}


    /**
     * Getter sgepdistrito
     * @return {number}
     */
	public getSgepdistrito(): number {
		return this.sgepdistrito;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}

    /**
     * Getter sigla
     * @return {string}
     */
	public getSigla(): string {
		return this.sigla;
	}
}

export class DistrictStructRequest {
    private sgepdistrito: number;

	constructor(sgepdistrito: number) {
        this.sgepdistrito = sgepdistrito;
	}


    /**
     * Getter sgepdistrito
     * @return {number}
     */
	public getSgepdistrito(): number {
		return this.sgepdistrito;
	}

}

export class DistrictStructResponse {
    private sgepdistrito: number;
    private nome: string;

	constructor(sgepdistrito: number, nome: string) {
		this.sgepdistrito = sgepdistrito;
		this.nome = nome;
	}


    /**
     * Getter sgepdistrito
     * @return {number}
     */
	public getSgepdistrito(): number {
		return this.sgepdistrito;
	}

    /**
     * Getter nome
     * @return {string}
     */
	public getNome(): string {
		return this.nome;
	}
}

export class DistrictInteractor {
    public fetchSave(request: DistrictRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/distrito/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = rs.Status == "Sucesso"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: DistrictStructRequest): PromiseLike<DistrictStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("propriedade/distrito/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}