import React from "react";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericViewController, TableGenericViewControllerDelegate, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { CourseGroupViewController, CourseGroupViewControllerDelegate } from "../../Screen/CourseGroup/CourseGroupViewController";
import { CourseGroupViewModel } from "../../Screen/CourseGroup/CourseGroupViewModel";
import { CourseViewModel } from "../../Screen/Course/CourseViewModel";
import { CourseViewController } from "../../Screen/Course/CourseViewController";
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from "../../Components/ProtectedRoute";

export class CourseGroupCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, CourseGroupViewControllerDelegate{
    getRoute(): JSX.Element[]{
        return[
            this.routeTable(),
            this.routeForm(),
            this.routeTableCourse(),
            this.routeFormCourse()
        ]
    }

    private routeTable(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Curso", 
            action: (navigation: any, item: any, component: React.Component) => this.routerCourse(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepcursogrupo")
            return (<ProtectedRoute requiredPermissions={['info_bas.grupo_curso.*']} path="/:base/grupo-curso" exact={true} component={(props:any) => {
                return (<TableGenericViewController
                    permissions={{
                        add: 'info_bas.grupo_curso.adicionar',
                        edit: 'info_bas.grupo_curso.editar',
                        delete: 'info_bas.grupo_curso.excluir',
                    }}
                    navigation={props}
                    viewModel={viewModel}
                    delegate={this}
                    title={"Grupo de Curso"}
                    options={arrayOptions}
                />)
            }}/>)
        }
    
    private routeForm(): JSX.Element {
        var viewModel = new CourseGroupViewModel()
            return (<ProtectedRoute requiredPermissions={['info_bas.grupo_curso.adicionar', 'info_bas.grupo_curso.editar']} path="/:base/grupo-curso/cad/:id" exact={true} component={(props:any) => {
                var idPk = props.match.params.id
                return (<CourseGroupViewController
                    navigation={props}
                    viewModel={viewModel}
                    delegate={this}
                    idPrimary={idPk}
                     />)            
            }}/>)
    }

    private routeTableCourse(): JSX.Element{ 
        var viewModel = new TableGenericViewModel("gepcurso")
        return (<ProtectedRoute requiredPermissions={['info_bas.grupo_curso.*']} path="/:base/grupo-curso/:id/curso" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Grupo', idFk)
            return (<TableGenericViewController 
                permissions={{
                    add: 'info_bas.grupo_curso.adicionar',
                    edit: 'info_bas.grupo_curso.editar',
                    delete: 'info_bas.grupo_curso.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Curso"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormCourse(): JSX.Element {
        var viewModel = new CourseViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.grupo_curso.adicionar', 'info_bas.grupo_curso.editar']} path="/:base/grupo-curso/:idGroup/curso/cad/:id" exact={true} component={(props: any) => {
            var idGroup = props.match.params.idGroup
            var idPk = props.match.params.id
            return (<CourseViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idGroup={idGroup}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void{
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void{
        var filter = viewModel.getFilter()

        if(viewModel.getTable() == "gepcursogrupo"){
            navigation.history.push(`grupo-curso/cad/0`);
            return;
        }

        if(viewModel.getTable() == "gepcurso" && filter){
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/grupo-curso/${filter[0].getValue()}/curso/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void{
        if(viewModel.getTable() == "gepcursogrupo"){
            navigation.history.push(`grupo-curso/cad/${idSelection}`);
            return;
        }

        if(viewModel.getTable() == "gepcurso"){
            navigation.history.push(`curso/cad/${idSelection}`)
            return;
        }
    }

    private routerCourse(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/grupo-curso/${item}/curso`);
    }
}