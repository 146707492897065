import { Request } from '../../Servidor/Request';

export class LocationInitialRequest {
    private latitude: string;
    private longitude: string;
    private srid: number;

	constructor(latitude: string, longitude: string, srid: number) {
		this.latitude = latitude;
		this.longitude = longitude;
        this.srid = srid
	}


    /**
     * Getter latitude
     * @return {string}
     */
	public getLatitude(): string {
		return this.latitude;
	}

    /**
     * Getter longitude
     * @return {string}
     */
	public getLongitude(): string {
		return this.longitude;
	}

    /**
     * Getter srid
     * @return {number}
     */
	public getSrid(): number {
		return this.srid;
	}

}

export class LocationInitialResponse {
    private success: Boolean;

	constructor(success: Boolean) {
        this.success = success;
	}


    /**
     * Getter success
     * @return {Boolean}
     */
	public getSuccess(): Boolean {
		return this.success;
	}

}

export class LocationInitialStructResponse {
    private latitude: string;
    private longitude: string; 

	constructor(latitude: string, longitude: string) {
        this.latitude = latitude
        this.longitude = longitude
	}


    /**
     * Getter latitude
     * @return {string}
     */
	public getLatitude(): string {
		return this.latitude;
	}

    /**
     * Getter longitude
     * @return {string}
     */
	public getLongitude(): string {
		return this.longitude;
	}

}

export class LocationInitialInteractor {
    public fetchSave(request: LocationInitialRequest): PromiseLike<LocationInitialResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/localizacao-inicial/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                let response = new LocationInitialResponse(rs.Status == "Sucesso")
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(): PromiseLike<LocationInitialStructResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/localizacao-inicial/buscar", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectSRID(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("localizacao-inicial/buscar-srid", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}