import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { AddressViewControllerDelegate, AddressViewController } from "../../Screen/Address/AddressViewController";
import { TableGenericViewControllerDelegate, TableGenericViewController } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { AddressViewModel } from '../../Screen/Address/AddressViewModel';
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class AddressCoordinator implements IndexCoordinatorDelegate, AddressViewControllerDelegate, TableGenericViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTablePerson(),
            this.routeFormPerson(),
            this.routeFormPoint()
        ]
    }

    private routeTablePerson(): JSX.Element {
        var viewModel = new TableGenericViewModel("gependereco")
        return (<ProtectedRoute requiredPermissions={['info_bas.class_pessoa.*']} path="/:base/pessoas/:id/enderecos" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Pessoa', idFk)
            return (<TableGenericViewController 
                permissions={{
                    add: 'info_bas.class_pessoa.adicionar',
                    edit: 'info_bas.class_pessoa.editar',
                    delete: 'info_bas.class_pessoa.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Endereços"}
                filter={[filter]}
            />)
        }}/>)
    }

    private routeFormPerson(): JSX.Element {
        var viewModel = new AddressViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.class_pessoa.adicionar', 'info_bas.class_pessoa.editar']} path="/:base/pessoas/:idPerson/enderecos/cad/:id" exact={true} component={(props: any) => {
            var idPerson = props.match.params.idPerson
            var idPk = props.match.params.id
            return (<AddressViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                idPerson={idPerson}/>)
        }}/>)
    }

    private routeFormPoint(): JSX.Element {
        var viewModel = new AddressViewModel()
        return (<ProtectedRoute requiredPermissions={['info_bas.class_pessoa.adicionar', 'info_bas.class_pessoa.editar']} path="/:base/ponto/:idPoint/endereco" exact={true} component={(props: any) => {
            var idPoint = props.match.params.idPoint
            return (<AddressViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={0}
                idPoint={idPoint}/>)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if(viewModel.getTable() == "gependereco" && filter) {
            var database = localStorage.getItem('database')
            var typeFk = filter[0].getColumn() == "Pessoa" ? 'pessoas' : ''
            navigation.history.push(`/${database}/${typeFk}/${filter[0].getValue()}/enderecos/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "gependereco") {
            navigation.history.push(`enderecos/cad/${idSelection}`);
            return;
        }
    }

}