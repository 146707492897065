import { VisitReasonInteractor, VisitReasonRequest, VisitReasonStructRequest } from "../../Coordinator/VisitReason/VisitReasonInteractor";

export class VisitReasonViewModel {
    private interactor: VisitReasonInteractor;

	constructor() {
		this.interactor = new VisitReasonInteractor();
	}

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new VisitReasonRequest(
            obj["sgepmotivovisita"] ? parseInt(obj["sgepmotivovisita"]) : 0,
            obj["gepmotivovisita"] ? obj["gepmotivovisita"] : "",
			obj["sgepgrupomotivovisita"] ? parseInt(obj["sgepgrupomotivovisita"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new VisitReasonStructRequest(
            obj["sgepmotivovisita"] ? parseInt(obj["sgepmotivovisita"]) : 0
        )
        
        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

	public fetchGroupVisitReason(): PromiseLike<any> {
        return new Promise((res, rej) => {
			this.interactor.fetchGroupVisitReason().then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }
}