import {CustodyTypeRequest, CustodyTypeStructRequest, CustodyTypeInteractor} from "../../Coordinator/CustodyType/CustodyTypeInteractor";

export class CustodyTypeViewModel {
    private interactor: CustodyTypeInteractor;

    constructor() {
        this.interactor = new CustodyTypeInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new CustodyTypeRequest(
            obj["sgeptpresponsavel"] ? parseInt(obj["sgeptpresponsavel"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["cesus"] ? obj["cesus"] : "",
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new CustodyTypeStructRequest(
            obj["sgeptpresponsavel"] ? parseInt(obj["sgeptpresponsavel"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }
}
