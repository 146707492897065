import {EconActivityLinkRequest, EconActivityLinkStructRequest, EconActivityLinkInteractor, EconActivitySelect} from "../../Coordinator/EconActivityLink/EconActivityLinkInteractor";

export class EconActivityLinkViewModel {
    private interactor: EconActivityLinkInteractor;

    constructor() {
        this.interactor = new EconActivityLinkInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new EconActivityLinkRequest(
            obj["sgeppessoaatividadeseconomicas"] ? parseInt(obj["sgeppessoaatividadeseconomicas"]) : 0,
            obj["sgepatividadeseconomicas"] ? parseInt(obj["sgepatividadeseconomicas"]) : 0,
            obj["sgeppessoa"] ? parseInt(obj["sgeppessoa"]) : 0,
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(true)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new EconActivityLinkStructRequest(
            obj["sgeppessoaatividadeseconomicas"] ? parseInt(obj["sgeppessoaatividadeseconomicas"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchEconActivity(idPerson: number): PromiseLike<any> {
        let request = new EconActivitySelect(idPerson)

        return new Promise((res, rej) => {
			this.interactor.fetchEconActivity(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
	}
}