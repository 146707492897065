import { Request } from '../../Servidor/Request';

export class PointRequest {
    private sshape: number;
    private scamada: number;
    private tipo: string;
    private cgepsituacoesshape: number;
    private origem: string;
    private latitude: string;
    private longitude: string;
    private sgeptipoponto?: number;

	constructor(sshape: number, scamada: number, tipo: string, cgepsituacoesshape: number, origem: string, latitude: string, longitude: string, sgeptipoponto?: number) {
		this.sshape = sshape;
		this.scamada = scamada;
        this.tipo = tipo;
		this.cgepsituacoesshape = cgepsituacoesshape;
		this.origem = origem;
		this.latitude = latitude;
		this.longitude = longitude;
        this.sgeptipoponto = sgeptipoponto;
	}


    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
	}

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

    /**
     * Getter tipo
     * @return {number}
     */
	public getTipo(): string {
		return this.tipo;
	}

    /**
     * Getter cgepsituacoesshape
     * @return {number}
     */
	public getCgepsituacoesshape(): number {
		return this.cgepsituacoesshape;
	}

    /**
     * Getter origem
     * @return {string}
     */
	public getOrigem(): string {
		return this.origem;
	}

    /**
     * Getter latitude
     * @return {string}
     */
	public getLatitude(): string {
		return this.latitude;
	}

    /**
     * Getter longitude
     * @return {string}
     */
	public getLongitude(): string {
		return this.longitude;
	}

    /**
     * Getter sgeptipoponto
     * @return {number}
     */
    public getSgeptipoponto(): number | undefined {
        return this.sgeptipoponto;
    }


}

export class PointResponse {
    private success: boolean;

	constructor(success: boolean) {
        this.success = success;
	}


    /**
     * Getter success
     * @return {boolean}
     */
	public getSuccess(): boolean {
		return this.success;
	}

}

export class PointStructRequest {
    private sshape: number;

    constructor(sshape: number) {
        this.sshape = sshape;
    }


    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
	}

}

export class PointStructResponse {
    private sshape: number;
    private scamada: number;
    private cgepsituacoesshape: number;
    private origem: string;
    private geom: any;

	constructor(sshape: number, scamada: number, cgepsituacoesshape: number, origem: string, geom: any) {
        this.sshape = sshape;
        this.scamada = scamada;
        this.cgepsituacoesshape = cgepsituacoesshape;
        this.origem = origem;
        this.geom = geom;
    }
    

    /**
     * Getter sshape
     * @return {number}
     */
	public getSshape(): number {
		return this.sshape;
	}

    /**
     * Getter scamada
     * @return {number}
     */
	public getScamada(): number {
		return this.scamada;
	}

    /**
     * Getter cgepsituacoesshape
     * @return {number}
     */
	public getCgepsituacoesshape(): number {
		return this.cgepsituacoesshape;
	}

    /**
     * Getter origem
     * @return {string}
     */
	public getOrigem(): string {
		return this.origem;
	}

    /**
     * Getter geom
     * @return {any}
     */
	public getGeom(): any {
		return this.geom;
	}

}

export class PointInteractor {
    public fetchSave(request: PointRequest): PromiseLike<[PointStructResponse]> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/ponto/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: PointStructRequest): PromiseLike<[PointStructResponse]> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/ponto/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
    
    public fetchImmobileSituation(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/ponto/situacao-imovel", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchSelectLayers(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("camada/buscar-camadas-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchPointTypes(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/tipo-ponto/buscar-select", {}).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}